import { useCallback } from "react";
import { SecurityTaskStatus } from "../../../../../../core/cmst/issues-page/data";
import { useIssueTheme } from "../../../hooks/useIssueTheme";
import { CMST_Chip_Variants } from "../Chip/data";
import {
  DeleteButton,
  Select,
  SelectValueContainer,
  SelectValueContainerRoot,
  SingleValueRoot,
  TaskActionsRoot,
  TaskChip,
  TaskLabel,
  TaskRoot,
} from "./styled";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

const statusOptions = [
  {
    label: "Completed",
    value: SecurityTaskStatus.COMPLETED,
  },
  {
    label: "Pending",
    value: SecurityTaskStatus.PENDING,
  },
];

export const Task = ({ data }) => {
  const theme = useIssueTheme();

  const taskStatus = statusOptions.filter(
    (status) => status.value === data.status
  );

  const ControlComponent = useCallback(
    ({ children, innerRef, ...props }) => {
      const themeVariant =
        data.status === SecurityTaskStatus.COMPLETED
          ? CMST_Chip_Variants.SUCCESS
          : CMST_Chip_Variants.DEFAULT;
      const bgColor = theme.palette.background[themeVariant];
      return (
        <SelectValueContainer ref={innerRef} {...props} bgColor={bgColor}>
          {children}
        </SelectValueContainer>
      );
    },
    [data, theme]
  );

  const SingleValue = useCallback(
    ({ children, innerRef, ...props }) => {
      const themeVariant =
        data.status === SecurityTaskStatus.COMPLETED
          ? CMST_Chip_Variants.SUCCESS
          : CMST_Chip_Variants.DEFAULT;
      const textColor = theme.palette.text[themeVariant];
      return (
        <SingleValueRoot ref={innerRef} {...props} textColor={textColor}>
          {children}
        </SingleValueRoot>
      );
    },
    [data, theme]
  );

  return (
    <TaskRoot>
      <TaskLabel>{data?.label}</TaskLabel>
      <TaskActionsRoot>
        <TaskChip variant={CMST_Chip_Variants.SECONDARY} label={data.team} />
        <Select
          options={statusOptions}
          value={taskStatus}
          placeholder='Status'
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: null,
            Control: ControlComponent,
            SingleValue,
            ValueContainer: SelectValueContainerRoot,
          }}
        />
        <DeleteButton disabled>
          <DeleteIcon />
        </DeleteButton>
      </TaskActionsRoot>
    </TaskRoot>
  );
};
