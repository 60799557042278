import React from "react";
import {
  authenticateGithub,
  sendAwsOnboarding,
  sendBitbucketOnboarding,
} from "../../utils/api";

import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import MultiStepSidebar from "../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import createButtonConfig from "../../shared/functions/buttons";
import { addIntegration } from "../../redux/actions/integrationActions";
import { useAuth0 } from "@auth0/auth0-react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import {
  BitbucketIntegrationCompleteConfig,
  BitbucketIntegrationCompleteContent,
} from "./bitbucketIntegrationComplete";
import {
  BitbucketIntegrationConfig,
  BitbucketIntegrationContent,
} from "./bitbucketIntegrationContent";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import BannerAlert from "../../components/banners/ErrorAlert";

const BitbucketIntegration = ({ cancelUrl }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [sidebarStep, setSidebarStep] = React.useState(1);
  const { getAccessTokenSilently } = useAuth0();
  const [errors, setErrors] = React.useState([]);

  const [labelName, setLabelName] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [workspace, setWorkspace] = React.useState(null);
  const [appToken, setAppToken] = React.useState(null);
  const [domainUrl, setDomainUrl] = React.useState(null);

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  // Define onChange handlers for each input field
  const handleLabelNameChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setLabelName(value);
  };

  const handleUsernameChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setUsername(value);
  };

  const handleAppTokenChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setAppToken(value);
  };
  const handleDomainUrlChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setDomainUrl(value);
  };

  const handleWorkspaceChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setWorkspace(value);
  };

  const sidebarSteps = [
    { title: "Setup Integration", description: "Setup Bitbucket Integration" },
    { title: "Completion", description: "" },
  ];

  const multiSteps = (
    <MultiStepSidebar steps={sidebarSteps} currentStep={sidebarStep} />
  );

  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
  };

  const onContinue = async () => {
    setErrors([]); // Clear previous errors

    if (sidebarStep === 1) {
      try {
        const auth0Token = await getAccessTokenSilently();
        await sendBitbucketOnboarding(
          labelName,
          username,
          appToken,
          domainUrl,
          auth0Token
        );
        setSidebarStep(2);
        return;
      } catch (error) {
        let newErrors = [];
        if (typeof error.response?.data?.detail === "string") {
          newErrors.push(`${error.response.data.detail}`);
        } else if (error.response?.data?.detail) {
          newErrors = error.response.data.detail.map((detail) => {
            const errorLocation =
              detail.loc.length > 1
                ? detail.loc.slice(1).join(".")
                : "unknown location";
            return `${errorLocation}: ${detail.msg || "An error occurred"}`;
          });
        } else {
          newErrors = ["Failed to onboard Bitbucket. Please try again."];
        }

        // Update the errors state with new errors
        setErrors((currentErrors) => [...currentErrors, ...newErrors]);
        return; // Ensure function execution stops here after handling the error
      }
    } else if (sidebarStep === 2) {
      dispatch(addIntegration("bitbucket"));
      history.push(cancelUrl);
    }
  };

  const footerButtonsStepOne = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel", false),
    createButtonConfig(
      "Continue",
      "right",
      onContinue,
      <KeyboardArrowRightOutlinedIcon />,
      "right",
      false
    ),
  ];
  const footerButtonsComplete = [
    createButtonConfig(
      "Done",
      "right",
      onContinue,
      <DoneOutlinedIcon />,
      "left",
      false
    ),
  ];

  const pageSteps = [
    {
      headerLogo: BitbucketIntegrationConfig.logo,
      headerTitle: BitbucketIntegrationConfig.headerTitle,
      content: (
        <BitbucketIntegrationContent
          handleLabelNameChange={handleLabelNameChange}
          handleUsernameChange={handleUsernameChange}
          handleAppTokenChange={handleAppTokenChange}
          handleWorkspaceChange={handleWorkspaceChange}
          handleDomainUrlChange={handleDomainUrlChange}
        />
      ),
      footerButtons: footerButtonsStepOne,
    },
    {
      headerLogo: BitbucketIntegrationCompleteConfig.logo,
      headerTitle: BitbucketIntegrationCompleteConfig.headerTitle,
      content: <BitbucketIntegrationCompleteContent />,
      footerButtons: footerButtonsComplete,
    },
  ];
  return (
    <>
      {errors.length > 0 &&
        errors.map((error, index) => (
          <BannerAlert
            key={index}
            message={error}
            topOffset={index * 80 + 20} // Adjusted for additional spacing; tweak as necessary
          />
        ))}

      <IntegrationLayout
        multisteps={multiSteps}
        headerLogo={pageSteps[sidebarStep - 1]?.headerLogo}
        headerTitle={pageSteps[sidebarStep - 1]?.headerTitle}
        content={pageSteps[sidebarStep - 1]?.content}
        footerButtons={pageSteps[sidebarStep - 1]?.footerButtons}
      />
    </>
  );
};

export default BitbucketIntegration;
