import { createAsyncThunk } from "@reduxjs/toolkit";
import { InventoryService } from "../../../services/inventoryService/index";
import { checkAssetOptionsCacheExpired } from "../../../core/asset-inventory/checkAssetOptionsCacheExpired";

export const getAssetOptions = createAsyncThunk(
  "inventory-asset-options",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const data = state.inventory.assetOptions.data;
      const assetOptionsApiConfig = state.inventory.assetOptions.apiConfig;
      const cacheExpired = checkAssetOptionsCacheExpired(assetOptionsApiConfig);

      const payload = {
        data,
        lastQueryTime: assetOptionsApiConfig.lastQueryTime,
      };
      if (!cacheExpired) return payload;
      const response = await InventoryService.getInstance().getAssetOptions();
      payload.data = response;
      const updatedQueryTime = new Date().getTime();
      payload.lastQueryTime = updatedQueryTime;
      return payload;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
