import { useMemo } from "react";
import { FilterGroup } from "../components/FilterGoup";

export const useFilterGroupsNodes = ({
  data,
  operators,
  conjunctions,
  onDeleteFilterGroups,
  updateFilterGroup,
}) => {
  return useMemo(() => {
    return data.map((filterGroup, index) => {
      const withConjunction = index > 0;
      return (
        <FilterGroup
          key={filterGroup.id}
          withConjunction={withConjunction}
          data={filterGroup}
          operatorOptions={operators}
          conjunctionOptions={conjunctions}
          onDeleteFilterGroup={onDeleteFilterGroups}
          updateFilterGroup={updateFilterGroup}
        />
      );
    });
  }, [data, operators, conjunctions]);
};
