import { useEffect, useState } from "react";
import { handleAssetInfoFetchErrors } from "../errorHandling";
import { useAuth0 } from "@auth0/auth0-react";
import { getGraphAssetInfo } from "../../../utils/api";

export const useAssetInfo = (assetId, open) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchAssetData = async () => {
    try {
      setLoading(true);
      setError(null);
      setData(null);
      const token = await getAccessTokenSilently();
      const results = await getGraphAssetInfo(assetId, token);
      const transformed = transformAssetInfo(results);
      setData(transformed);
    } catch (error) {
      const errorMessage = handleAssetInfoFetchErrors;
      setError(errorMessage);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (assetId && open) {
      fetchAssetData();
    }
  }, [assetId, open]);

  return {
    data,
    error,
    loading,
  };
};

function transformAssetInfo(info) {
  info.status = info.status || "Unknown";
  info.technical_summary = info.technical_summary || "N/A";
  info.business_purpose = info.business_purpose || "N/A";
  info.operational_owners = info.operational_owners ?? [];
  info.business_owners = info.business_owners ?? [];
  info.evidence = info.evidence ?? [];
  return info;
}
