import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useSavedQueriesTableColumns } from "../../../hooks/useSavedQueriesTableColumns";

const initialPaginationState = { pageSize: 0, pageIndex: 0 };

export const SavedQueriesTable = ({
  pagination = initialPaginationState,
  isLoading = false,
  data = [],
}) => {
  const columns = useSavedQueriesTableColumns();
  const table = useMaterialReactTable({
    data,
    columns,
    state: {
      pagination,
      isLoading,
    },
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    layoutMode: "grid",
    muiTopToolbarProps: {
      hidden: true,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },

    muiBottomToolbarProps: {
      hidden: true,
      sx: {
        marginTop: "auto",
        height: "50px",
        minHeight: 0,
      },
    },
  });

  return <MaterialReactTable table={table} />;
};
