import { MaterialReactTable } from "material-react-table";
import { GroupDataTableSectionContainer, SectionHeading } from "../../styled";
import { useGroupingTable } from "../../../../../../hooks/useGroupingTable";

export const GroupingDataTable = ({ title = "", children }) => {
  const onClickRow = () => {
    //
  };

  const table = useGroupingTable({
    onClickRow,
  });

  return (
    <GroupDataTableSectionContainer>
      <SectionHeading>{title}</SectionHeading>
      <div>
        <MaterialReactTable table={table} />
      </div>
    </GroupDataTableSectionContainer>
  );
};
