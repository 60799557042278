const payloadEncoder = (payload) => {
  return btoa(JSON.stringify(payload));
};

const payloadDecoder = (encodedString) => {
  return atob(encodedString);
};

const encodeFiltersToUrl = (payload) => {
  return payloadEncoder(payload);
};

const decodeUrlToFilters = (encodedStr) => {
  const decodedStr = payloadDecoder(encodedStr);
  return JSON.parse(decodedStr);
};

export const InventoryFilterUrlParser = Object.freeze({
  encodeFiltersToUrl,
  decodeUrlToFilters,
});
