import { Typography } from "@mui/material";
import Dropdown from "./Dropdown";
import {
  ConditionDropdown,
  DeleteIcon,
  FilterGroupContainer,
  FilterGroupContentWrapper,
  FilterGroupErrorMessage,
  FilterGroupSection,
  FilterSelectorRegion,
} from "./styled";
import { ConditionOptions } from "./constants";

import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ConditionComponent from "../../../../utils/ConditionComponent";

const FilterGroup = ({
  data,
  onDeleteFilterGroup,
  onUpdateFilterGroup,
  categoryOptions,
  categoryValueSet,
  filterGroups,
  getCategoryValueOptions,
  transformGroupLabel,
}) => {
  const [categorySearchString, setCategorySearchString] = useState("");

  /**
   * transform selected filter group label
   */
  if (transformGroupLabel) {
    removeCategoryValueSize(data);
  }

  const valueOptions = useMemo(() => {
    if (data.category.value) {
      let options = [];
      if (getCategoryValueOptions) {
        options = getCategoryValueOptions(data.category) ?? [];
      } else {
        const option = categoryValueSet[data.category.value];
        options = Array.from(option?.values() ?? []);
      }
      return options.map((value) => ({ label: value, value }));
    }
    return [];
  }, [data, categoryValueSet]);

  const handleOnChangeCondition = (condition) => {
    const updatedFilterGroup = { ...data, condition };
    onUpdateFilterGroup(updatedFilterGroup);
  };

  const handleOnChangeCategory = (category) => {
    const updatedFilterGroup = {
      ...data,
      category,
      values: [],
      condition: {},
    };

    onUpdateFilterGroup(updatedFilterGroup);
  };

  const handleOnChangeValues = (values) => {
    const updatedFilterGroup = { ...data, values };
    onUpdateFilterGroup(updatedFilterGroup);
  };

  /**
   *
   * @param {*} option
   * @param {*} filterGroups
   * @returns
   *
   *@description if option returns true, it is ignored and returned when false
   */
  const filterSelectedCategoryOption = (option, filterGroups) => {
    return !filterGroups.some((group) => {
      if (!categorySearchString) return group.category.value === option.value;
      return !option.label.includes(categorySearchString);
    });
  };

  const onInputChange = (keyword) => {
    setCategorySearchString(keyword);
  };

  return (
    <FilterGroupContainer>
      <FilterGroupContentWrapper>
        <FilterSelectorRegion>
          <FilterGroupSection>
            <Typography variant="caption">Category</Typography>
            <Dropdown
              onInputChange={onInputChange}
              options={categoryOptions}
              onChange={handleOnChangeCategory}
              value={data.category}
              filterOption={(category) =>
                filterSelectedCategoryOption(category, filterGroups)
              }
            />
          </FilterGroupSection>
          <FilterGroupSection sx={{ width: "100px" }}>
            <Typography variant="caption">Condition</Typography>
            <ConditionDropdown
              options={ConditionOptions}
              onChange={handleOnChangeCondition}
              value={data.condition}
            />
          </FilterGroupSection>
          <FilterGroupSection>
            <Typography variant="caption">Value(s)</Typography>
            <Dropdown
              options={valueOptions}
              onChange={handleOnChangeValues}
              value={data.values}
              isMulti
              sx={{ width: "300px" }}
            />
          </FilterGroupSection>
        </FilterSelectorRegion>
        <DeleteIcon
          sx={{ flexShrink: 0, width: "24px" }}
          onClick={(ev) => onDeleteFilterGroup(ev)}
        />
      </FilterGroupContentWrapper>
      <ConditionComponent condition={data.warning}>
        <FilterGroupErrorMessage>
          <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
          <Typography variant="caption" fontWeight={600}>
            {data.warning}
          </Typography>
        </FilterGroupErrorMessage>
      </ConditionComponent>
    </FilterGroupContainer>
  );
};

FilterGroup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    condition: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    category: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
  onDeleteFilterGroup: PropTypes.func,
  onUpdateFilterGroup: PropTypes.func,
};

export default FilterGroup;

function removeCategoryValueSize(data) {
  const category = data.category;
  if (category.label) {
    const { label } = category;
    const labelWithoutValuesCount = label.split(" ")?.[0] ?? "";
    category.label = labelWithoutValuesCount;
  }
  return data;
}
