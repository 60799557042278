export function convertTagsToTagMap(list, getName) {
  return list.reduce((store, tag) => {
    const source = tag.source ?? "";
    let name = tag.name ?? "";
    if (getName) name = getName(tag);
    const key = `${source}.${name}`;
    store[key] = tag;
    return store;
  }, {});
}
