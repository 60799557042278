import { useState } from "react";
import {
  IntegrationValueMutatorsContext,
  IntegrationValuesStateContext,
} from "./contexts";

export const IntegrationsProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState("");
  const [integrationName, setIntegrationName] = useState("");

  return (
    <IntegrationValueMutatorsContext.Provider
      value={{
        setAccessToken,
        setIntegrationName,
      }}
    >
      <IntegrationValuesStateContext.Provider
        value={{
          accessToken,
          integrationName,
        }}
      >
        {children}
      </IntegrationValuesStateContext.Provider>
    </IntegrationValueMutatorsContext.Provider>
  );
};
