import { CMSTService } from "../../../../services/CMSTService";
import { Actions } from "../stateManagement/actions";
import { useDispatch } from "./useDispatch";
import { useGlobalState } from "./useGlobalState";

export function useSavedQueryAlert() {
  const { showNewAlertView, newAlertPayload } = useGlobalState();
  const dispatch = useDispatch();

  const openView = (payload) => {
    dispatch({
      type: Actions.OPEN_NEW_ALERT_VIEW,
      payload,
    });
  };

  const closeView = (payload) => {
    dispatch({
      type: Actions.CLOSE_NEW_ALERT_VIEW,
    });
  };

  const saveAlert = async (payload) => {
    try {
      dispatch({
        type: Actions.CREATE_ALERT_PENDING,
      });
      await CMSTService.getInstance().createQueryAlert(payload);
      dispatch({
        type: Actions.CREATE_ALERT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: Actions.CREATE_ALERT_ERROR,
        payload: error.payload,
      });
    }
  };

  return {
    ...newAlertPayload,
    openState: showNewAlertView,
    openView,
    closeView,
    saveAlert,
  };
}
