import { Route, Redirect } from "react-router-dom";
import ConditionComponent from "../../utils/ConditionComponent";

const ProtectedRoute = ({
  component: Component,
  path,
  routeGuard,
  routeProps,
  ...componentProps
}) => {
  const canRender = routeGuard?.();
  return (
    <Route
      path={path}
      {...routeProps}
      render={(props) => {
        return (
          <ConditionComponent
            condition={canRender}
            falsyNode={<Redirect to='/unauthorized-access' />}
          >
            <Component {...props} {...componentProps} />
          </ConditionComponent>
        );
      }}
    />
  );
};

export default ProtectedRoute;
