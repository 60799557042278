import { Typography } from "@mui/material";
import { ControlSwitch, SelectFieldAsColumnRoot } from "../styled";

export const SelectFieldAsColumn = (props) => {
  return (
    <SelectFieldAsColumnRoot
      {...props}
      control={<ControlSwitch />}
      label={<Typography variant='caption'>Add to Columns</Typography>}
    />
  );
};
