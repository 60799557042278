import { StateActions } from "./actions";
import { InitialState } from "./initialState";

export const StateReducer = (_state, action) => {
  const state = { ..._state };
  if (action.type === StateActions.resetState) {
    return InitialState;
  }

  if (action.type === StateActions.setRequestFailure) {
    state.loading = false;
    state.error = action.payload;
    state.data = null;
    return state;
  }
  if (action.type === StateActions.setRequestPending) {
    state.data = null;
    state.loading = true;
    state.error = null;
    return state;
  }
  if (action.type === StateActions.setRequestSuccess) {
    state.data = action.payload;
    state.loading = false;
    state.error = null;
    return state;
  }
  if (action.type === StateActions.setLoading) {
    state.loading = true;
    state.data = null;
    return state;
  }

  return state;
};
