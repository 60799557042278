import { useState } from "react";
import { generateRandomStr } from "../../../utils/helperMethods/generateId";
import { createOptionId } from "../../Grouping/utils/createOptionId";

export const useFilterGroupController = (defaultValues) => {
  const [data, setData] = useState([]);

  const createFilterGroup = () => {
    const filterGroup = {
      id: generateRandomStr(),
      operator: {},
      values: [],
      field: {},
      payload: {},
      conjunction: defaultValues?.conjunction ?? {},
      addToColumns: false,
    };
    setData([...data, filterGroup]);
  };

  const removeFilterGroup = (filterGroup) => {
    const filteredFilterGroups = data.filter(
      (group) => group.id !== filterGroup.id
    );
    setData(filteredFilterGroups);
  };

  const updateFilterGroup = (filterGroup) => {
    const checkFieldIsInColumn = (field) => {
      const fieldAsColumnId = createOptionId(field, "options");
      const found = defaultValues.columns?.find(
        (col) => col.id === fieldAsColumnId
      );
      return !!found;
    };

    const updatedFilterGroups = data.map((group) => {
      if (group.id === filterGroup.id) {
        const groupId = createOptionId(group.field, "payload");
        const filterGroupId = createOptionId(filterGroup.field, "payload");
        if (groupId !== filterGroupId) {
          return {
            ...filterGroup,
            addToColumns: checkFieldIsInColumn(filterGroup.field),
          };
        }
        return filterGroup;
      }
      return group;
    });

    setData(updatedFilterGroups);
  };

  const reset = () => setData([]);

  const forceSetData = (data) => setData(data);

  return {
    createFilterGroup,
    removeFilterGroup,
    updateFilterGroup,
    reset,
    forceSetData,
    localFilters: data,
  };
};
