import { useContext } from "react";
import { IntegrationValuesStateContext } from "../context/contexts";

export const useGetIntegrationState = () => {
  return useContext(IntegrationValuesStateContext).state;
};

export const useSetIntegrationState = () => {
  return useContext(IntegrationValuesStateContext).dispatch;
};
