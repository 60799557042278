import React, { useMemo } from "react";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import MultiStepSidebar from "../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AwsCloudformationStepOneContent, stepOneConfig } from "./stepOne";
import { AwsCloudformationStepTwoContent, stepTwoConfig } from "./stepTwo";
import createButtonConfig from "../../shared/functions/buttons";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { AwsCloudformationStepThreeContent } from "./stepThree";
import { sendAwsOnboarding } from "../../utils/api";
import { addIntegration } from "../../redux/actions/integrationActions";
import { useAuth0 } from "@auth0/auth0-react";
import { defaultArnRole } from "./data";
import { IntegrationsProvider } from "./context/Provider";
import { useAwsCloudFormationIntegrationValues } from "./hooks/useAwsCloudFormationIntegrationValues";
import { useAwsCloudFormationIntegrationValueMutators } from "./hooks/useAwsCloudFormationIntegrationValuesMutators";

const AwsCloudformation = ({ cancelUrl }) => {
  const dispatch = useDispatch();
  const [sidebarStep, setSidebarStep] = React.useState(1);
  const {
    account,
    externalId,
    role,
    selfRole,
    integrationName,
    isManual,
    arnRole,
  } = useAwsCloudFormationIntegrationValues();
  const { setAccount, setExternalId, setRole, setSelfRole } =
    useAwsCloudFormationIntegrationValueMutators();

  const [isGenerated, setIsGenerated] = React.useState(false);
  const [awsError, setAwsError] = React.useState("");
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const canContinue = () => {
    return (
      account.length === 12 &&
      externalId.length === 36 &&
      role.length &&
      !!integrationName
    );
  };

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
  };

  const handleRoleChange = (event) => {
    const role = event.target ? event.target.value : event;
    setRole(role);
  };

  const handleExternalIdChange = (event) => {
    const newId = event.target ? event.target.value : event;
    const validCharsRegex = /^[0-9a-f\-]*$/i;
    if (validCharsRegex.test(newId)) {
      setExternalId(newId);
    } else {
      console.log("Invalid character input");
    }
  };

  const handleSelfRoleChange = (event) => {
    const selfRole = event.target ? event.target.value : event;
    setSelfRole(selfRole);
  };

  const onCompleteIntegration = () => {
    history.push("/integrations/");
  };

  const onContinue = async () => {
    if (sidebarStep === 3) {
      dispatch(addIntegration("aws"));

      history.push(cancelUrl);
    }

    if (sidebarStep === 1) {
      try {
        setAwsError("");
        const auth0Token = await getAccessTokenSilently();
        const integrationRole = isManual ? arnRole : role;
        await sendAwsOnboarding(
          account,
          integrationRole,
          externalId,
          selfRole,
          auth0Token,
          integrationName,
        );
        setSidebarStep(sidebarStep + 1);
        if (!isManual) {
          window.open(
            `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=https://s3.amazonaws.com/pandosec.com/platform/pandosec_template.yml&param_ExternalID=${externalId}&stackName=pandosecurity-readonly`,
            "_blank",
          );
        }
      } catch (error) {
        handleAwsError(error.message);
      }
    } else {
      if (sidebarStep < sidebarSteps.length) {
        setSidebarStep(sidebarStep + 1);
      }
    }
  };

  const handleAccountChange = (changeEvent, generated) => {
    const MAX_LENGTH = 12;
    const newValue = changeEvent.target.value;
    if (newValue.length > MAX_LENGTH) return;
    setAccount(newValue);

    if (newValue) {
      setRole(defaultArnRole.replace("{account_id}", newValue));
    } else {
      setRole("");
    }
    setIsGenerated(generated);
  };

  // Handle AWS error
  const handleAwsError = (error) => {
    setAwsError(error);
  };

  // Sidebar
  const sidebarSteps = useMemo(() => {
    if (isManual)
      return [
        {
          title: "Account details",
          description: "Create read-only assume role",
        },
        { title: "Completion" },
      ];
    return [
      { title: "Account details", description: "Create read-only assume role" },
      { title: "Integration", description: "Integration details" },
      { title: "Completion" },
    ];
  }, [isManual]);

  const multiSteps = (
    <MultiStepSidebar steps={sidebarSteps} currentStep={sidebarStep} />
  );

  const footerButtonsStepOne = useMemo(() => {
    const buttons = [
      createButtonConfig("Cancel", "left", onCancel, null, "cancel", false),
    ];
    if (isManual) {
      buttons.push(
        createButtonConfig(
          "Done",
          "right",
          onContinue,
          <DoneOutlinedIcon />,
          "left",
          !canContinue(),
        ),
      );
    } else {
      buttons.push(
        createButtonConfig(
          "Launch Stack",
          "right",
          onContinue,
          <LayersOutlinedIcon />,
          "left",
          !canContinue(),
        ),
      );
    }
    return buttons;
  }, [
    sidebarStep,
    account,
    externalId,
    role,
    selfRole,
    integrationName,
    isManual,
    arnRole,
    awsError,
  ]);

  const footerButtonsStepTwo = [
    createButtonConfig(
      "continue",
      "right",
      onContinue,
      <KeyboardArrowRightOutlinedIcon />,
      "left",
      false,
    ),
  ];
  const footerButtonsThreeTwo = [
    createButtonConfig(
      "Done",
      "right",
      onContinue,
      <DoneOutlinedIcon />,
      "left",
      false,
    ),
  ];

  const onCompleteIntegrationBtns = [
    createButtonConfig(
      "Done",
      "right",
      onCompleteIntegration,
      <DoneOutlinedIcon />,
      "left",
      false,
    ),
  ];

  const pageSteps = useMemo(() => {
    return [
      {
        headerLogo: stepOneConfig.awsLogo,
        headerTitle: stepOneConfig.headerTitle,
        content: (
          <AwsCloudformationStepOneContent
            account={account}
            isGenerated={isGenerated}
            awsError={awsError}
            role={role}
            externalId={externalId}
            selfRole={selfRole}
            handleAccountChange={handleAccountChange}
            handleRoleChange={handleRoleChange}
            handleExternalIdChange={handleExternalIdChange}
            handleSelfRoleChange={handleSelfRoleChange}
          />
        ),
        footerButtons: footerButtonsStepOne,
      },
      {
        headerLogo: stepTwoConfig.awsLogo,
        headerTitle: stepTwoConfig.headerTitle,
        content: <AwsCloudformationStepTwoContent />,
        footerButtons: footerButtonsStepTwo,
      },
      {
        headerLogo: stepOneConfig.awsLogo,
        headerTitle: "Complete",
        content: <AwsCloudformationStepThreeContent />,
        footerButtons: footerButtonsThreeTwo,
      },
    ];
  }, [
    isGenerated,
    awsError,
    account,
    externalId,
    role,
    selfRole,
    integrationName,
    isManual,
    arnRole,
    footerButtonsStepOne,
    footerButtonsStepTwo,
    footerButtonsThreeTwo,
  ]);

  const manualPageSteps = useMemo(() => {
    return [
      {
        headerLogo: stepOneConfig.awsLogo,
        headerTitle: stepOneConfig.headerTitle,
        content: (
          <AwsCloudformationStepOneContent
            account={account}
            isGenerated={isGenerated}
            awsError={awsError}
            role={role}
            externalId={externalId}
            selfRole={selfRole}
            handleAccountChange={handleAccountChange}
            handleRoleChange={handleRoleChange}
            handleExternalIdChange={handleExternalIdChange}
            handleSelfRoleChange={handleSelfRoleChange}
          />
        ),
        footerButtons: footerButtonsStepOne,
      },
      {
        headerLogo: stepOneConfig.awsLogo,
        headerTitle: "Complete",
        content: <AwsCloudformationStepThreeContent />,
        footerButtons: onCompleteIntegrationBtns,
      },
    ];
  }, [
    stepOneConfig,
    account,
    isGenerated,
    awsError,
    role,
    externalId,
    selfRole,
    footerButtonsThreeTwo,
    footerButtonsStepOne,
  ]);

  const integrationSteps = useMemo(() => {
    return isManual ? manualPageSteps : pageSteps;
  }, [pageSteps, isManual, manualPageSteps]);

  return (
    <IntegrationLayout
      multisteps={multiSteps}
      headerLogo={integrationSteps[sidebarStep - 1]?.headerLogo}
      headerTitle={integrationSteps[sidebarStep - 1]?.headerTitle}
      content={integrationSteps[sidebarStep - 1]?.content}
      footerButtons={integrationSteps[sidebarStep - 1]?.footerButtons}
    />
  );
};

const Component = (props) => {
  return (
    <IntegrationsProvider {...props}>
      <AwsCloudformation {...props} />
    </IntegrationsProvider>
  );
};

export default Component;
