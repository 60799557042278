export function getInitialGroupingValueFromAssetOptions(
  group,
  assetOptionsMap
) {
  const { value, source } = group;
  const options = assetOptionsMap[source]?.[value]?.values ?? [];
  const option = {
    value: options[0],
    group,
  };
  return option;
}
