import { Typography } from "@mui/material";
import {
  Header,
  InsightsBlocksRoot,
  PageContent,
  PageRoot,
} from "./component/styled";
import { CMSTInsightCard } from "../../../shared-component/CMSTInsightCard/index";
import { InsightsSection } from "../../../shared-component/CMSTLayout";
import { useProjectsSummaryState } from "../hooks/useProjectsSummaryState";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProjectsSummary } from "../../../../state-management/CMST/thunks/getProjectsSummary";
import { CMSTActions } from "../../../../state-management/CMST";
import { DefaultCMSTProjectsTableColumns } from "../../../../core/cmst/projects-table/defaultColumns";
import AssetTable from "../../../shared-component/AssetTable";
import { useProjectsTableState } from "../hooks/useProjectsTableState";
import { getProjectsSchema } from "../../../../state-management/CMST/thunks/getProjectsSchema";
import { CMSTService } from "../../../../services/CMSTService";
import { useAppNavigator } from "../../../../hooks/useAppNavigator";
import { LocalStorage } from "../../../../utils/browserUtils/LocalStorage";

const PageEntry = () => {
  const { data } = useProjectsSummaryState();
  const { options } = useProjectsTableState();
  const dispatch = useDispatch();
  const { push } = useAppNavigator();

  const setTableColumns = (newCols) => {
    dispatch(CMSTActions.setProjectsTableColumns(newCols));
  };

  const fetchSummary = () => {
    dispatch(getProjectsSummary());
    dispatch(getProjectsSchema());
    /**
     * @todo remove dependency between default columns structure and internal options structure
     * 1. default columns must be a generic structure
     * 2. a adapter will be used to transform the default columns structure to columns structure using the options results
     * 3. columns creation logic must be migrated to application level logic to be reused
     */
    setTableColumns(DefaultCMSTProjectsTableColumns);
  };

  const TableService = {
    getTableData: CMSTService.getInstance().getProjects,
    saveQuery: CMSTService.getInstance().saveQuery,
  };

  const createCurrentUrlWithoutOrigin = () => {
    const fromBase = window.location.href;
    return fromBase.replace(window.location.origin, "");
  };

  const handleOnClickAsset = (asset) => {
    const id = asset.data?.project?.id;
    if (!id) {
      /**
       * @todo show warning alert to user and throw log.
       * Consider an advanced error logging system that will alert devs of engineering error
       */
      return;
    }
    const fromUrl = createCurrentUrlWithoutOrigin();
    const path = `/cmst/projects/${id}/issues`;
    LocalStorage.setItem("cmst-projects-config", { fromUrl });
    push(path);
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  return (
    <PageRoot>
      <Header>
        <Typography variant='pageHeading'>
          Change Manager Security Tracker
        </Typography>
      </Header>
      <PageContent className='scrollable'>
        <InsightsSection>
          <Typography variant='h4'>Monitored Projects</Typography>
          <InsightsBlocksRoot>
            <CMSTInsightCard
              label='Issues with security concerns'
              value={data?.projects_with_security_concerns}
              variant='error'
            />
            <CMSTInsightCard
              label='Total JIRA projects'
              value={data?.total_jira_projects}
              variant='default'
            />
            <CMSTInsightCard
              label='Stake holders involved Projects'
              value={data?.stakeholders_involved}
              variant='success'
            />
          </InsightsBlocksRoot>
        </InsightsSection>
        <AssetTable
          defaultColumns={DefaultCMSTProjectsTableColumns}
          schema={options}
          key={`${Object.keys(options)}`}
          service={TableService}
          onClickAsset={handleOnClickAsset}
        />
      </PageContent>
    </PageRoot>
  );
};

export default PageEntry;
