import React from "react";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import styles from "./MapComponent.module.css"; // Make sure the path is correct

function MapComponent({ numberOfServices }) {
  return (
    <div className={styles.mapContainer}>
      <div className={styles.arrowContainer}>
        <div className={styles.typeCell}>
          <MapOutlinedIcon className={styles.mapIcon} />
          <span className={styles.mapLabel}>Map</span>
        </div>
      </div>
      <div className={styles.servicesContainer}>
        <span className={styles.serviceCount}>{numberOfServices} Services</span>
      </div>
    </div>
  );
}

export default MapComponent;
