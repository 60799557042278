import { useReducer } from "react";
import { Reducer } from "../stateManagement/reducer";
import { InitialState } from "../stateManagement/initialState";
import { GlobalStateContext } from "./defs";

export const GlobalStateProvider = ({ children, ...props }) => {
  const [state, updaterFn] = useReducer(Reducer, InitialState);

  return (
    <GlobalStateContext.Provider value={{ state, updaterFn }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
