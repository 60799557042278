import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Popover,
  styled,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import { Button } from "reactstrap";

export const MenuContainer = styled(Popover)(({ theme }) => {
  return `
  & .MuiPopover-paper {
    width: 900px;
    height: 700px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
     border:1px solid ${theme.palette.grey[400]};
    box-shadow: ${theme.shadows[2]};
    background-color: ${theme.palette.background.default};
  }
`;
});

export const SelectedGroupingContentContainer = styled("div")`
  height: calc(100% - 24px);
`;

export const MenuContentContainer = styled("div")`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  padding: 12px;
  flex: 1;
  overflow-y: hidden;
`;

export const MenuFooter = styled("div")`
  flex-shrink: 0;
  display: flex;
  height: 56px;
  border-top: 1px solid #ccc;
  justify-content: space-between;
  padding: 6px 12px;
  box-sizing: border-box;
  margin-top: auto;
`;

export const Label = styled((props) => (
  <Typography {...props} variant='body2' />
))`
  color: #000033;
`;

export const SelectionsContainer = styled("div")`
  min-height: 100px;
  max-height: 200px;
  grid-row: 2 / 3;
  grid-column: 1 / -1;
`;

export const SearchSectionContainer = styled("div")`
  min-height: 80px;
  max-height: 100px;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
`;

export const PlatformDataSection = styled("div")(() => {
  return `
  grid-row: 3/4;
  grid-column: 1 / 2;
  overflow-y: hidden;
  `;
});

export const UserDataSection = styled("div")(() => {
  return `
  grid-row: 3/4;
  grid-column: 2 / 3;
  overflow-y: hidden;
  padding-bottom: 12px;
  `;
});

export const SectionContent = styled("div")`
  overflow-y: auto;
  height: calc(100% - 14px);
  padding-bottom: 6px;
`;

export const CustomFieldsSection = styled(SectionContent)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const CustomFieldSectionContainer = styled("div")`
  height: 50%;
  overflow: hidden;
  flex-shrink: 0;
  padding-bottom: 24px;
`;

export const CustomFieldSectionContentContainer = styled("div")`
  height: 100%;
  overflow-x: hidden;
`;

export const GroupOptionContainer = styled(Accordion)(() => {
  return `
  &.MuiAccordion-root {
  box-shadow: none; 
  &::before {
  display: none;
  }
  }
  & .MuiCollapse-root{
    transition-duration: 1s !important;
    transition-timing-function: linear;
  }

  `;
});

export const GroupOptionSummary = styled(AccordionSummary)(() => {
  return `
  &.MuiAccordionSummary-root {
    background-color: rgb(243, 244, 246);
    padding: 0 12px !important;
    border-radius: 6px;
    min-height: 32px;
    box-sizing: border-box;
    height: 32px !important; 
    border-bottom: none;
    width: 100%;
    border:none !important;
  }
  `;
});

export const GroupOptionValues = styled(
  forwardRef(({ children, ...props }, ref) => (
    <AccordionDetails ref={ref} {...props}>
      <ul>{children}</ul>
    </AccordionDetails>
  ))
)(() => {
  return `
  &.MuiAccordionDetails-root {
  padding: 0;
  }
  `;
});

export const GroupOptionValueContainer = styled("li")`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: flex-start;
  padding: 4px;
  border-radius: 4px;
  border-bottom: 1px solid #aaaaaa40;
  &:hover {
    cursor: pointer;
    background-color: #cccccc40;
  }
  & :hover {
    cursor: pointer;
  }
  &:not(:first-child) {
    margin-top: 2px;
  }
  &:first-child {
    margin-top: 6px;
  }
`;

export const OptionValueContainer = styled("div")`
  display: flex;
`;

export const OriginationTypeContainer = styled("div")`
  align-items: flex-end;
  flex-wrap: wrap;
  display: flex;
  row-gap: 4px;
  column-gap: 4px;
`;

export const OriginationTypeLabel = styled(({ children, ...props }) => (
  <Typography variant='caption' {...props}>
    {children}
  </Typography>
))(({ color }) => {
  const bg = `${color}40`;

  return `
  background-color: ${bg};
  color: ${color};
  padding: 4px;
  border-radius: 4px;
  `;
});

export const ExpandOriginationTypesButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;
