import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { lightModeTheme } from "./lightMode";

export const ThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={lightModeTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
