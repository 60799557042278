import { Actions } from "../stateManagement/actions";
import { useAssetTableState } from "./useAssetTableState";
import { useStateDispatch } from "./useStateDispatch";

export function useGroupingState() {
  const { grouping } = useAssetTableState();
  const dispatch = useStateDispatch();

  const setter = (payload) => {
    dispatch({
      type: Actions.SET_GROUPING,
      payload,
    });
  };
  return [grouping, setter];
}
