export const Actions = Object.freeze({
  FETCH_QUERIES_PENDING: "fetch-queries-pending",
  FETCH_QUERIES_SUCCESS: "fetch-queries-success",
  FETCH_QUERIES_ERROR: "fetch-queries-error",
  SET_PAGINATION: "set-pagination",
  OPEN_DELETE_ACTION: "open-delete-action",
  CLOSE_DELETE_ACTION: "close-delete-action",
  DELETE_ACTION_PENDING: "delete-action-pending",
  DELETE_ACTION_SUCCESS: "delete-action-success",
  DELETE_ACTION_ERROR: "delete-action-error",
  SET_EDIT_ACTION_MODAL_PAYLOAD: "set-action-modal-payload",
  OPEN_EDIT_ACTION_MODAL: "open-edit-action",
  CLOSE_EDIT_ACTION_MODAL: "close-edit-action",
  EDIT_ACTION_PENDING: "edit-action-pending",
  EDIT_ACTION_SUCCESS: "edit-action-success",
  EDIT_ACTION_ERROR: "edit-action-error",
});
