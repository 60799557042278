import { useEffect, useState } from "react";
import { useUserInfo } from "../../../../../hooks/useAppUser";
import { useAuth0 } from "@auth0/auth0-react";
import { getAdapters } from "../../../../../utils/api";

export const useOrganizationAdapters = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { role, organizationId } = useUserInfo();
  const { getAccessTokenSilently } = useAuth0();

  const initStates = () => {
    setLoading(true);
    setError(null);
    setData([]);
  };

  const removeAdapter = (adapterToDelete) => {
    const filtered = data.filter(
      (adapter) => adapter.uuid !== adapterToDelete.uuid,
    );
    setData(filtered);
  };

  const fetchAdapters = async () => {
    try {
      initStates();
      const token = await getAccessTokenSilently();
      const result = await getAdapters({
        token,
        organization_id: organizationId,
      });
      setData(result);
    } catch (error) {
      setData(null);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isAdmin = role === "admin";
    if (isAdmin) {
      fetchAdapters();
    }
  }, [role]);

  return {
    data,
    loading,
    error,
    removeAdapter,
  };
};
