import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

export const Root = styled(Dialog)(({ theme }) => {
  return `
    & .MuiDialog-paper {
    width: 300px;
    max-height: 600px;
    overflow-y: auto;
    background-color: ${theme.palette.background.paper};
    }
  `;
});

export const Title = styled(DialogTitle)(() => {
  return `
  
  `;
});

export const Body = styled(DialogContent)(() => {
  return `
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  `;
});

export const Footer = styled(DialogActions)(() => {
  return `
  
  `;
});

export const CancelButton = styled(ButtonBase)`
  && {
    height: 32px;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 4px;
    background-color: #cccccc30;
    border: 1px solid #cccccc70;
    color: #505050 !important;
  }
`;

export const DeleteButton = styled(IconButton)`
  &&& {
    background-color: #f91c5110;
    border-radius: 4px;
    padding: 4px !important;
    height: 32px !important;
    color: #f91c5190 !important;
    border: 1px solid #f91c5160 !important;
    :focus {
      outline: none;
    }
  }
`;
