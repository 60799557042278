import { Typography } from "@mui/material";
import { InsightsBlocksRoot } from "./component/styled";
import { PageNavigationButton } from "../../../shared-component/Navigation";
import { useAppNavigator } from "../../../../hooks/useAppNavigator";
import { useIssuesTableState } from "../hooks/useIssuesTableState";
import { CMSTInsightCard } from "../../../shared-component/CMSTInsightCard";
import {
  Header,
  InsightsSection,
  PageContent,
  PageRoot,
} from "../../../shared-component/CMSTLayout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getIssuesSummary } from "../../../../state-management/CMST/thunks/getIssuesSummary";
import { useIssuesSummary } from "../hooks/useIssuesSummary";
import { CMSTActions } from "../../../../state-management/CMST";
import { DefaultCMSTIssuesTableColumns } from "../../../../core/cmst/issues-table/defaultColumns";
import AssetTable from "../../../shared-component/AssetTable";
import { CMSTService } from "../../../../services/CMSTService";
import { LocalStorage } from "../../../../utils/browserUtils/LocalStorage";

const PageEntry = (props) => {
  const { push } = useAppNavigator();
  const dispatch = useDispatch();
  const { options } = useIssuesTableState();

  const { data } = useIssuesSummary();

  const fetchInitialIssuesData = async (id) => {
    dispatch(getIssuesSummary({ projectId: id }));
  };

  const { id } = useParams();

  const resetFilters = () => {
    dispatch(CMSTActions.setIssuesTableFilters([]));
  };

  function handleOnGoBack(ev) {
    resetFilters();
    const path = "/cmst/projects";
    const state = LocalStorage.getItem("cmst-projects-config");
    push(state.fromUrl ?? path);
  }

  const TableService = {
    getTableData: CMSTService.getInstance().getProjectIssues,
    saveQuery: CMSTService.getInstance().saveQuery,
  };

  const handleOnClickAsset = (asset) => {
    const id = asset.data?.id;
    if (!id) {
      /**
       * @todo show warning alert to user and throw log.
       * Consider an advanced error logging system that will alert devs of engineering error
       */
      return;
    }
    const projectId = asset.data.project.id;
    const path = `/cmst/projects/${projectId}/issues/${id}`;
    push(path, asset);
  };

  useEffect(() => {
    if (id) {
      fetchInitialIssuesData(id);
    }
  }, [id]);

  return (
    <PageRoot>
      <Header>
        <PageNavigationButton
          onClick={handleOnGoBack}
          label={"Back to Projects"}
        />
        <Typography variant='pageHeading'>
          Change Manager Security Tracker / issues
        </Typography>
      </Header>
      <PageContent className='scrollable'>
        <InsightsSection>
          <Typography variant='h4'>Monitored Issues</Typography>
          <InsightsBlocksRoot>
            <CMSTInsightCard
              label='Issues with security concerns'
              value={data?.issues_with_security_concerns}
              variant='error'
            />
            <CMSTInsightCard
              label='Total Issues'
              value={data?.issues}
              variant='default'
            />
            <CMSTInsightCard
              label='Stake holders involved Issues'
              value={data?.stakeholders}
              variant='success'
            />
          </InsightsBlocksRoot>
        </InsightsSection>
        <AssetTable
          defaultColumns={DefaultCMSTIssuesTableColumns}
          schema={options}
          key={`${Object.keys(options)}`}
          service={TableService}
          onClickAsset={handleOnClickAsset}
          defaultPayload={{ projectId: id }}
        />
      </PageContent>
    </PageRoot>
  );
};

export default PageEntry;
