import IntegrationCard from "../../integrationCard";
import {
  IntegrationCardsContainer,
  Section,
  SectionHeading,
  SectionSubHeading,
} from "./styled";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";

export const AvailableConnectors = ({ data }) => {
  return (
    <Section>
      <SectionHeading>
        <ElectricalServicesIcon />
        <SectionSubHeading>Available Connectors / Adapters</SectionSubHeading>
      </SectionHeading>

      <IntegrationCardsContainer>
        {data.map((connector, index) => (
          <IntegrationCard
            key={connector.uid}
            data={connector}
            onClick={connector?.onClick}
            isComingSoon={connector?.isComingSoon}
          />
        ))}
      </IntegrationCardsContainer>
    </Section>
  );
};
