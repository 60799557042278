import { getListValidValues } from "./getListValidValues";

export const EMPTY_VALUE = "N/A";

function calculateAssetName(asset) {
  if (!asset || !asset?.common_fields) return [];
  const names = getListValidValues(asset.common_fields.name);
  if (names.length === 0) return [];
  const firstName = names[0];
  return [firstName];
}

export const selectAssetDetails = (data) => {
  const assetData = data.asset;
  const details = {
    description: "",
    names: [],
    id: "",
    sources: assetData.sources ?? [],
    accountId: [],
    regions: [],
  };
  if (!assetData) return details;

  if (assetData.description) {
    assetData.description = assetData.description.replace(
      /technical summary/i,
      ""
    );
  }
  details.description = assetData.description || EMPTY_VALUE;
  details.names = calculateAssetName(assetData);
  details.id = assetData.id;

  return details;
};
