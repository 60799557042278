export const IntegrationInitialState = {
  hostname: "",
  userServiceId: "",
  apiToken: "",
  verifySSL: false,
  httpsProxy: "",
  httpsProxyUsername: "",
  httpsProxyPassword: "",
  step: 1,
  loading: false,
  error: null,
  name: "",
};
