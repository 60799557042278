import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ButtonRoot } from "./styled";
import { Typography } from "@mui/material";

export const PageNavigationButton = ({ label: _label, ...props }) => {
  const label = _label ?? "Back";

  return (
    <ButtonRoot {...props}>
      <KeyboardBackspaceIcon />
      <Typography variant='body2'>{label}</Typography>
    </ButtonRoot>
  );
};
