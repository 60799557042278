import React, { useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./module.css";
import IntegrationCard from "../../integrationCard";
import {
  Container,
  EmptyDataSection,
  EmptySectionText,
  Heading,
  IntegrationCardsContainer,
  IntegrationsHeader,
  PersonIcon,
  Row,
  ScrollableContainer,
  SearchInput,
  SearchWrapper,
  Section,
  SectionHeading,
  SectionSubHeading,
  SubTitleSection,
  SubTitleText,
} from "./styled";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { useUserInfo } from "../../../../hooks/useAppUser";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { AvailableConnectors } from "./AvailableConnectors";
import { useOrganizationAdapters } from "./hooks/useOrganisationAdapters";
import { useIntegrationsDataSources } from "./hooks/useIntegrationsDataSources";
import { transformAdaptersToConnectors } from "./utils/transformAdaptersToConnectors";
import { DeleteConnectorDialog } from "./DeleteConnectorDialog";

const ConnectorsStep = () => {
  const { role } = useUserInfo();
  const IS_ADMIN = role === "admin";
  const [integrationToDelete, setIntegrationToDelete] = useState(null);
  const { data: organizationAdapters, removeAdapter } =
    useOrganizationAdapters();
  const createdConnectors = useMemo(() => {
    return transformAdaptersToConnectors(organizationAdapters);
  }, [organizationAdapters]);

  const dataSourcesData = useIntegrationsDataSources();
  const [filteredDataSourcesData, setFilteredDataSourcesData] =
    useState(dataSourcesData);
  const [connectorSearchText, setConnectorSearchText] = useState("");

  const handleConnectorSearchTextChange = (e) => {
    const searchText = e.target.value;
    setConnectorSearchText(searchText);

    const filtered = Object.values(dataSourcesData).filter((dataSource) =>
      dataSource.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredDataSourcesData(filtered);
  };

  const onIntegrationDelete = (ev, data) => {
    setIntegrationToDelete(data);
  };
  const closeDeleteDialog = () => setIntegrationToDelete(null);

  return (
    <>
      <DeleteConnectorDialog
        data={integrationToDelete}
        open={Boolean(integrationToDelete)}
        onClose={closeDeleteDialog}
        onDeleteSuccess={removeAdapter}
      />

      <Container>
        <IntegrationsHeader>
          <Heading>Setup Connectors</Heading>
          <SubTitleSection>
            <SubTitleText>
              Are you excited to explore the amazing world of Pando Security?
              Simply connect a connector to get started! It's simpler than
              training a dog to fetch a newspaper!
            </SubTitleText>
            <SearchWrapper>
              <SearchInput
                type="text"
                placeholder="Search..."
                autoComplete="off"
                value={connectorSearchText}
                onChange={handleConnectorSearchTextChange}
              />
              <SearchIcon />
            </SearchWrapper>
          </SubTitleSection>
        </IntegrationsHeader>

        <ScrollableContainer>
          <AvailableConnectors data={filteredDataSourcesData} />
          <ConditionComponent condition={IS_ADMIN}>
            <Section>
              <SectionHeading marginTop={"54px !important"}>
                <Row>
                  <PersonIcon />
                  <ElectricalServicesIcon />
                </Row>
                <SectionSubHeading>
                  Created Connectors / Adapters
                </SectionSubHeading>
              </SectionHeading>
              <ConditionComponent
                condition={createdConnectors.length}
                falsyNode={
                  <EmptyDataSection>
                    <EmptySectionText variant="subtitle2">
                      No Connectors Found
                    </EmptySectionText>
                  </EmptyDataSection>
                }
              >
                <IntegrationCardsContainer>
                  {createdConnectors.map((connector) => (
                    <IntegrationCard
                      key={connector.uuid}
                      data={connector}
                      onDelete={onIntegrationDelete}
                      isConnected={true}
                    />
                  ))}
                </IntegrationCardsContainer>
              </ConditionComponent>
            </Section>
          </ConditionComponent>
        </ScrollableContainer>
      </Container>
    </>
  );
};

export default ConnectorsStep;
