import { MaterialReactTable } from "material-react-table";
import { useRelationTable } from "../hooks/useRelationTable";
import {
  SubPageContainer,
  SubPageContentContainer,
  SubPageHeading,
} from "../styled";
import { useGetAssetDetailState } from "../hooks/useStateManager";
import ConditionComponent from "../../../../../utils/ConditionComponent";
import { EmptyDataSection } from "../../AssetDetailDrawer/EmptySections";

export const RelationsPage = () => {
  const { data } = useGetAssetDetailState();
  const table = useRelationTable();

  const hasRelatedAssets = !!data.related_assets?.length;

  return (
    <SubPageContainer>
      <SubPageHeading>Relations</SubPageHeading>
      <ConditionComponent
        condition={hasRelatedAssets}
        falsyNode={<EmptyDataSection label={"No related assets"} />}
      >
        <SubPageContentContainer>
          <MaterialReactTable table={table} />
        </SubPageContentContainer>
      </ConditionComponent>
    </SubPageContainer>
  );
};
