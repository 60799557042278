import { useState } from "react";
import { generateRandomStr } from "../../../../../utils/helperMethods/generateId";

export const useFilterGroupsController = () => {
  const [data, setData] = useState([]);

  const createFilterGroup = () => {
    const filterGroup = {
      id: generateRandomStr(),
      condition: {},
      values: [],
      category: {},
    };
    setData([...data, filterGroup]);
  };

  const removeFilterGroup = (filterGroup) => {
    const filteredFilterGroups = data.filter(
      (group) => group.id !== filterGroup.id,
    );
    setData(filteredFilterGroups);
  };

  const updateFilterGroup = (filterGroup) => {
    const updatedFilterGroups = data.map((group) => {
      if (group.id === filterGroup.id) return filterGroup;
      return group;
    });
    setData(updatedFilterGroups);
  };

  const reset = () => setData([]);

  const forceSetData = (data) => setData(data);

  return {
    createFilterGroup,
    removeFilterGroup,
    updateFilterGroup,
    filterGroups: data,
    reset,
    forceSetData,
  };
};
