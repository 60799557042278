import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

const slice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUserInfoAction(state, action) {
      const { payload } = action;
      state.organizationId = payload.organizationId;
      state.privileges = payload.privileges;
      state.role = payload.role;
    },
  },
});

const UserStateActions = slice.actions;
const reducer = slice.reducer;
export { reducer, UserStateActions };
