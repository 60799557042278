import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  card: {
    background: "var(--white, #FFF);",
    boxShadow: "0px 0px 59px 0px rgba(0, 39, 51, 0.10) inset",
    flexGrow: 1,
    width: `calc(100%)`,
  },
  title: {
    color: "var(--dark-blue, #002733)",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "27px",
    paddingBottom: "10px",
  },
  divider: {
    background: "#EEEFF0",
  },
  contentRow: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: "row",
  },
  contentColumn: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: "column",
  },
});

const CustomCard = ({ title, children, direction = "row" }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Use column direction for small screens, otherwise use the specified direction
  const contentClass =
    direction === "row" ? classes.contentRow : classes.contentColumn;

  const flexDirection = isSmallScreen ? "column" : direction;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title}>{title}</Typography>
        <Box className={classes.divider}>
          <Divider />
        </Box>
        <Box className={clsx(flexDirection, contentClass)}>{children}</Box>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
