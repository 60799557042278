import { Button, styled } from "@mui/material";

export const ButtonRoot = styled(Button)(({ theme }) => {
  const { palette } = theme;
  const color = palette.secondary.main;

  return `
  min-width: 0;
  min-height: 0;
  display: flex;
  align-items:center;
  column-gap: 12px;
  padding: 4px 0;
  color: ${color};

  &:hover {
  background-color: initial;
  }

  & > svg {
  color: ${color};
  }
  `;
});
