import { LoadingSection } from "../../../components/LayoutFillers";
import { AssetTableContent } from "./content";
import { usePageMeta } from "./hooks/usePageMeta";

const AssetInventoryPageEntryView = () => {
  const state = usePageMeta();
  const { isLoading } = state;

  if (isLoading) {
    return <LoadingSection />;
  }

  return <AssetTableContent />;
};

export default AssetInventoryPageEntryView;
