import { useGetAssetDetailState } from "./useStateManager";
import { useMaterialReactTable } from "material-react-table";
import { TruncatedCell } from "../../../../../containers/inventory/AssetsTable/styled";
import { ValueText } from "../styled";
import { EMPTY_VALUE } from "../subPages/utils/assetDetailsSelector";
import { useMemo } from "react";

export const OwnerType = Object.freeze({
  BUSINESS: "business",
  TECHNICAL: "technical",
});

export const useOwnersTable = (type) => {
  const { data } = useGetAssetDetailState();

  const tableData = useMemo(() => {
    if (!data) return [];
    const { business_owners, technical_owners } = data;
    if (type === OwnerType.BUSINESS) return business_owners;
    if (type === OwnerType.TECHNICAL) return technical_owners;
    return [];
  }, [type, data]);

  const table = useMaterialReactTable({
    data: tableData,
    columns: [
      {
        accessorKey: "name",
        header: "Name",
        Cell({ cell }) {
          const value = cell.getValue() || EMPTY_VALUE;

          return (
            <TruncatedCell>
              <ValueText isna={value === EMPTY_VALUE}>{value}</ValueText>
            </TruncatedCell>
          );
        },
      },

      {
        accessorKey: "email",
        header: "Email",
        Cell({ cell }) {
          const value = cell.getValue() || EMPTY_VALUE;

          return (
            <TruncatedCell>
              <ValueText isna={value === EMPTY_VALUE}>{value}</ValueText>
            </TruncatedCell>
          );
        },
      },

      {
        accessorKey: "is_active",
        header: "Status",
        Cell({ cell }) {
          const value = cell.getValue();
          const status = value ? "Active" : "Inactive";

          return (
            <TruncatedCell>
              <ValueText>{status}</ValueText>
            </TruncatedCell>
          );
        },
      },
    ],
    manualPagination: false,
    manualFiltering: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    muiTopToolbarProps: {
      hidden: true,
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        maxHeight: "500px",
        minHeight: "200px",
        paddingBottom: "24px",
      },
    },
  });

  return table;
};
