import { styled } from "@mui/material";

export const TableWrapper = styled("div")`
  flex: 1;
  margin-top: 24px;
`;

export const TableContent = styled("div")`
  flex: 1;
  overflow-y: hidden;
  border: 0.5px solid #c7c7c7;
  border-radius: 8px;
`;

export const TableTopSection = styled("div")`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
`;
