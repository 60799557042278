import { useReducer } from "react";
import { Reducer } from "../stateManagement/reducer";
import { InitialState } from "../stateManagement/initialState";
import { GlobalStateDispatchContext, IssuesTableStateContext } from "./defs";

export const GlobalStateProvider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  return (
    <GlobalStateDispatchContext.Provider value={dispatch}>
      <IssuesTableStateContext.Provider value={state.issuesTableState}>
        {children}
      </IssuesTableStateContext.Provider>
    </GlobalStateDispatchContext.Provider>
  );
};
