const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const organization_id = localStorage.getItem("organization_id") || "";

const UserService = {
  async getUsers(token) {
    const url = `${BASE_URL}/organization/users`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-ORG-ID": organization_id,
      },
    });

    return await response.json();
  },

  async inviteUser(token, email, name, last_name, role) {
    const url = `${BASE_URL}/user/invite`;

    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-ORG-ID": organization_id,
      },
      body: JSON.stringify({
        email,
        name: name,
        family_name: last_name,
        role,
      }),
    });
  },

  async resetPassword(token, email) {
    const url = `${BASE_URL}/user/reset_password`;

    return await fetch(`${url}?email=${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-ORG-ID": organization_id,
      },
    });
  },

  async deleteUserByEmail(token, email) {
    const url = `${BASE_URL}/user/delete_user_by_email`;

    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-ORG-ID": organization_id,
      },
      body: JSON.stringify({
        email,
      }),
    });
  },

  async getUserRole(token) {
    const url = `${BASE_URL}/user/get_user_role`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-ORG-ID": organization_id,
      },
    });

    return await response.json();
  },
};

export default UserService;
