import { createContext } from "react";

export const IntegrationValuesStateContext = createContext({
  apiToken: "",
  domainUrl: "",
  email: "",
  integrationName: "",
});

export const IntegrationValueMutatorsContext = createContext({
  setApiToken: () => null,
  setDomainUrl: () => null,
  setEmail: () => null,
  setIntegrationName: () => null,
});
