import { useMemo } from "react";
import { useColumnsState } from "./useColumnsState";
import { createTableColumn } from "../../../pages/cmst/utils/createColumn";

export function useTableColumns() {
  const cols = useColumnsState()[0];
  return useMemo(() => {
    return createTableColumn(cols);
  }, [cols]);
}
