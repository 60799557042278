/*
Themes

colors
- primary
- secondary
- error
- success
- info
- grey
- 

shadow

fonts

space

breakpoints
*/

import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { defaultTheme } from "./default";

const theme = createTheme({
  palette: {
    primary: {
      main: "#002733",
      light: "#00FFB2",
    },
    success: {
      main: "#00A372",
    },
    error: {
      main: "#F91C51",
    },
    warning: {
      main: "#E6C200",
    },
    info: {
      main: "#5B83EA",
    },
    grey: {
      main: "#8F9699",
      400: "#EEEFF0",
      500: "#ccc",
    },
    background: {
      default: "#fff",
      paper: "#FCFDFD",
      dropdownPaper: "#F6F7F7",
    },
  },
  shadows: {
    2: "0px 0px 20px 0px #0027331A",
    0: "none",
  },
  border: {
    main: "1px solid #ccc",
  },
});

export const lightModeTheme = deepmerge(defaultTheme, theme);
