import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Select from "react-select";
import ReactPaginate from "react-paginate";
import { styled } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import DescriptionIcon from "@mui/icons-material/Description"; // for JSON
import TableChartIcon from "@mui/icons-material/TableChart";
import Typography from "@material-ui/core/Typography"; // for CSV
import { ReactComponent as LowIcon } from "../assets/severity/low.svg";
import { ReactComponent as HighIcon } from "../assets/severity/high.svg";
import { ReactComponent as MediumIcon } from "../assets/severity/medium.svg";
import { ReactComponent as CriticalIcon } from "../assets/severity/critical.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

function extractServiceName(url) {
  // split the url by the '/' delimiter
  var splitUrl = url.split("/");
  // if url does not contain '/', return an empty string
  if (splitUrl.length <= 1) {
    return "";
  }
  // take the second part and split it by the ':' delimiter
  var servicePart = splitUrl[1].split(":");
  // return the first part which is the service name
  return servicePart[0];
}

const StyledHeaderCell = styled(TableCell)({
  backgroundColor: "#000033",
  color: "#00ffb2",
  height: "50px", // Adjust the height value as needed
});
const StyledTableCell = styled(TableCell)({
  maxWidth: "200px",
  overflowY: "auto",
});

// sticky header
const StickyTableHead = styled(TableHead)({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#ffffff",
});

const flattenData = (data) => {
  let flattenedData = [];
  data.forEach((item) => {
    if (item.properties) {
      item.properties.forEach((vuln) => {
        flattenedData.push({
          Name: item.name,
          ArtifactName: item.ArtifactName,
          Entity: item.ArtifactName, // Add Entity
          VulnerabilityID: vuln.VulnerabilityID,
          PkgName: vuln.PkgName,
          InstalledVersion: vuln.InstalledVersion,
          FixedVersion: vuln.FixedVersion,
          Title: vuln.Title,
          Description: vuln.Description,
          Severity: vuln.Severity,
        });
      });
    }
  });
  return flattenedData;
};

const removeDuplicates = (data) => {
  let uniqueData = [];
  // check both for vulnerability ID and artifact name
  data.forEach((item) => {
    if (
      !uniqueData.some(
        (vuln) =>
          vuln.VulnerabilityID === item.VulnerabilityID &&
          vuln.ArtifactName === item.ArtifactName,
      )
    ) {
      uniqueData.push(item);
    }
  });
  return uniqueData;
};

const VulnerabilitiesTable = ({ data }) => {
  const flattenedData = removeDuplicates(flattenData(data));
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const pageSize = 10;

  const start = currentPage * pageSize;
  const end = start + pageSize;

  // Sort
  const [sortKey, setSortKey] = useState("Severity");
  const [sortOrder, setSortOrder] = useState("asc");

  // Select
  const [selectedOptions, setSelectedOptions] = useState([]);
  const artifactNames = [
    ...new Set(flattenedData.map((item) => item.ArtifactName)),
  ];
  const selectOptions = artifactNames.map((name) => ({
    value: name,
    label: name,
  }));

  const location = useLocation();
  // get params
  const params = new URLSearchParams(location.search);
  const param = params.get("entity");

  useEffect(() => {
    // If the parameter matches one of the options, select it
    const selected = artifactNames.find((item) => item.includes(param));
    if (selected) {
      setSelectedOptions([selected]);
    }
  }, []);

  const handleSort = (key) => {
    let order = "asc";
    if (sortKey === key && sortOrder === "asc") {
      order = "desc";
    }
    setSortKey(key);
    setSortOrder(order);
  };
  const ClickableHeaderCell = styled(StyledHeaderCell)({
    cursor: "pointer",
  });

  // Drop down Export logic
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //

  const exportToCsv = (data) => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(data[0]);
    let csv = data.map((row) =>
      header
        .map((fieldName) => {
          let value = row[fieldName];
          if (value === null || value === undefined) {
            value = "";
          }
          value = value.toString();
          const escapedValue = value.replace(/"/g, '""'); // Escape any double quotes.
          return `"${escapedValue}"`; // Enclose each value in double quotes.
        })
        .join(","),
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "export.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToJson = (objectData) => {
    let filename = "export.json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(objectData, null, 2)))],
        { type: contentType },
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href =
        "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(objectData, null, 2));
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  let filteredData = flattenedData
    .filter((row) =>
      selectedOptions.length > 0
        ? selectedOptions.includes(row.ArtifactName)
        : true,
    )
    .filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );

  filteredData = filteredData.sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    } else {
      return a[sortKey] < b[sortKey] ? 1 : -1;
    }
  });

  const displayedData = filteredData.slice(start, end);
  const pageCount = Math.ceil(filteredData.length / pageSize);

  return (
    <div className="container-fixed">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingBottom: "10px", paddingRight: "1px" }}
      >
        <Grid item>
          <h5>Vulnerabilities</h5>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            size="small"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4,
                width: "10ch",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                exportToJson(flattenedData);
                handleClose();
              }}
              style={{ height: "30px" }}
            >
              <Box alignItems="center">
                <DescriptionIcon fontSize="small" />
                <Typography variant="caption">JSON</Typography>
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToCsv(flattenedData);
                handleClose();
              }}
              style={{ height: "30px" }}
            >
              <Box alignItems="center">
                <TableChartIcon fontSize="small" />
                <Typography variant="caption">CSV</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <div className="controls-container">
        <div className="select-container">
          <Select
            isMulti
            name="artifactNames"
            options={selectOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOption) => {
              setSelectedOptions(selectedOption.map((option) => option.value));
            }}
            value={selectOptions.filter((option) =>
              selectedOptions.includes(option.value),
            )}
          />
        </div>
        <input
          className="search-input"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: "200px",
            padding: "5px 10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div>
      <TableContainer component={Paper} elevation={3}>
        <Table
          aria-label="customized table"
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <StickyTableHead>
            <tr>
              <ClickableHeaderCell onClick={() => handleSort("Entity")}>
                Entity
              </ClickableHeaderCell>
              <ClickableHeaderCell onClick={() => handleSort("Name")}>
                Name
              </ClickableHeaderCell>
              <ClickableHeaderCell onClick={() => handleSort("ArtifactName")}>
                ArtifactName
              </ClickableHeaderCell>
              <ClickableHeaderCell
                onClick={() => handleSort("VulnerabilityID")}
              >
                VulnerabilityID
              </ClickableHeaderCell>
              <ClickableHeaderCell onClick={() => handleSort("PkgName")}>
                PkgName
              </ClickableHeaderCell>
              <ClickableHeaderCell
                onClick={() => handleSort("InstalledVersion")}
              >
                InstalledVersion
              </ClickableHeaderCell>
              <ClickableHeaderCell onClick={() => handleSort("FixedVersion")}>
                FixedVersion
              </ClickableHeaderCell>
              <ClickableHeaderCell onClick={() => handleSort("Title")}>
                Title
              </ClickableHeaderCell>
              <ClickableHeaderCell onClick={() => handleSort("Description")}>
                Description
              </ClickableHeaderCell>
              <ClickableHeaderCell onClick={() => handleSort("Severity")}>
                Severity
              </ClickableHeaderCell>
            </tr>
          </StickyTableHead>
          <TableBody>
            {displayedData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  {extractServiceName(row.Entity)}
                </StyledTableCell>
                <StyledTableCell>{row.Name}</StyledTableCell>
                <StyledTableCell>{row.ArtifactName}</StyledTableCell>
                <StyledTableCell>
                  <a
                    href={`https://nvd.nist.gov/vuln/detail/${encodeURIComponent(row.VulnerabilityID)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.VulnerabilityID}
                  </a>
                </StyledTableCell>
                <StyledTableCell>{row.PkgName}</StyledTableCell>
                <StyledTableCell>{row.InstalledVersion}</StyledTableCell>
                <StyledTableCell>{row.FixedVersion}</StyledTableCell>
                <StyledTableCell>{row.Title}</StyledTableCell>
                <StyledTableCell style={{ overflow: true }}>
                  <Box
                    display={"flex"}
                    height={"120px"}
                    style={{ overflowX: "hidden" }}
                  >
                    {row.Description}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    {row.Severity === "CRITICAL" && (
                      <Box paddingRight={1}>
                        <CriticalIcon />
                      </Box>
                    )}
                    {row.Severity === "HIGH" && (
                      <Box paddingRight={1}>
                        <HighIcon />
                      </Box>
                    )}
                    {row.Severity === "MEDIUM" && (
                      <Box paddingRight={1}>
                        <MediumIcon />
                      </Box>
                    )}
                    {row.Severity === "LOW" && (
                      <Box paddingRight={1}>
                        <LowIcon />
                      </Box>
                    )}
                    <Box>{row.Severity}</Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ReactPaginate
        forcePage={currentPage}
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default VulnerabilitiesTable;
