import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import OverviewTable from "./OverviewTable";

import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import WorkOutlineIcon from "@mui/icons-material/Work";
import SimCardAlertOutlinedIcon from "@mui/icons-material/SimCardAlertOutlined";
import FeatureService from "../services/feturesService";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(4),
    backgroundColor: "#fff",
    color: "#00ffb2", // your template color
    boxShadow: "0 3px 5px 2px rgba(125, 125, 125, .3)",
    borderRadius: "15px",
    textTransform: "uppercase",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",

    color: "black",
    fontFamily: "Inter",
  },
  type: {
    textTransform: "uppercase",
    fontSize: "1rem",
  },
  childCard: {
    margin: theme.spacing(2),
    width: 200,
    backgroundColor: "#000066", // your template color
    color: "#00ffb2", // your template color
    boxShadow: "0 3px 5px 2px rgba(125, 125, 125, .3)",
    borderRadius: "15px",
  },
  deploymentCard: {
    margin: theme.spacing(2),
    width: 200,
    backgroundColor: "#000079", // a different color for the new card
    color: "#00ffb2",
    boxShadow: "0 3px 5px 2px rgba(125, 125, 125, .3)",
    borderRadius: "15px",
  },

  cardContent: {
    padding: theme.spacing(3),
  },
  full_screen: {
    width: "90%",
    height: "100%",
  },
  root_card: {
    minWidth: 275,
    margin: theme.spacing(4),
    backgroundColor: "#fff",
    color: "#00ffb2", // your template color
    boxShadow: "0 3px 5px 2px rgba(125, 125, 125, .3)",
    textTransform: "uppercase",
    position: "relative",
    fontFamily: "Inter",
    // textTransform: 'none',
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "93%",
    fontFamily: "Inter",
    fontWeight: "600",
    textTransform: "none",
  },
  suitcaseIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

function splitString(str) {
  let output = [];

  // Extract the part before parentheses
  let beforeParenthesis = str.match(/^[^(]+/)[0].trim();
  output.push(beforeParenthesis);

  // Extract the part within parentheses
  let insideParenthesis = str.match(/\(([^)]+)\)/);
  if (insideParenthesis) {
    let splitInsideParenthesis = insideParenthesis[1]
      .split(",")
      .map((item) => item.trim());
    output.push(...splitInsideParenthesis);
  }

  return output;
}

function flattenData(data) {
  let combined = [];

  data.forEach((item) => {
    if (item.source) {
      item.source.vulnerability_count = item.source_vul_count; // Add vulnerability count to the source entity
      combined.push(item.source);
    }
    if (item.target) {
      item.target.vulnerability_count = item.target_vul_count; // Add vulnerability count to the target entity
      combined.push(item.target);
    }
  });

  const uniqueCombined = Array.from(new Set(combined.map((c) => c.id))).map(
    (id) => {
      return combined.find((c) => c.id === id);
    },
  );

  return uniqueCombined;
}

function transformData(flattenedData) {
  return flattenedData
    .map((obj) => {
      let {
        BusinessProcess,
        BusinessOwner,
        BusinessOwnerPosition,
        id,
        businessProcess,
        business_process,
      } = obj;
      if (!BusinessProcess && businessProcess) {
        BusinessProcess = businessProcess;
      }
      if (!BusinessProcess && business_process) {
        BusinessProcess = business_process;
      }

      let processNames;
      if (BusinessProcess && BusinessProcess.includes("(")) {
        processNames = BusinessProcess.split("(")[1].split(")")[0].split(", ");
      } else {
        processNames = [BusinessProcess]; // Default to the BusinessProcess name itself if no parentheses are found
      }

      const entitiesCount = id ? 1 : 0; // Adjust this according to your logic
      const securityIssues =
        obj.vulnerability_count || obj.vulnerability_count || 0; // Adjust this according to your logic
      const criticality = "Low"; // Placeholder, adjust this according to your logic

      return processNames.map((processName) => ({
        BusinessProcess: processName,
        BusinessOwner,
        BusinessOwnerPosition,
        Criticality: criticality,
        EntitiesCount: entitiesCount,
        SecurityIssues: securityIssues,
      }));
    })
    .flat();
}

const PandoOverview = ({ data }) => {
  const [instanceCount, setInstanceCount] = useState(0);
  const [typeCounts, setTypeCounts] = useState({});
  const classes = useStyles();
  const [businessProcesses, setBusinessProcesses] = useState([]);
  const [deploymentData, setDeploymentData] = useState({}); // new state for deployment data
  const [vulnerabilitiesCount, setVulnerabilitiesCount] = useState(0);
  const [features, setFeatures] = useState({});
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let types = {};
    let deployments = {};
    let uniqueVulnerabilities = new Set();
    let businessProcessSet = new Set();
    let totalVulnerabilityCount = 0;

    let flattened = flattenData(data);
    let transformed = transformData(flattened);

    transformed.forEach((item) => {
      businessProcessSet.add(item.BusinessProcess);
      if (item.BusinessProcess) {
        splitString(item.BusinessProcess).forEach((process) => {
          businessProcessSet.add(process);
        });
      }
    });

    flattened.forEach((item) => {
      if (!types[item.type]) {
        types[item.type] = new Set();
      }
      types[item.type].add(item.id);

      if (!deployments[item.deployment]) {
        deployments[item.deployment] = new Set();
      }
      deployments[item.deployment].add(item.id);

      if (item.vulnerability_count) {
        totalVulnerabilityCount += item.vulnerability_count;
        uniqueVulnerabilities.add(item.id);
      }
    });
    // console.log('Total Vulnerability Count:', totalVulnerabilityCount);
    // console.log('Unique Vulnerabilities:', uniqueVulnerabilities.size);

    setInstanceCount(flattened.length);
    setTypeCounts(types);
    setDeploymentData(deployments);
    setVulnerabilitiesCount(totalVulnerabilityCount);
    setBusinessProcesses(Array.from(businessProcessSet));
  }, [data]);

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      FeatureService.getFeatures(token).then((response) => {
        setFeatures(response);
      }),
    );
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Grid container className={classes.cards}>
          <Grid xs={4} item>
            <Card className={classes.root_card} variant="outlined">
              <CardContent className={classes.content}>
                <Typography
                  variant="body2"
                  style={{
                    color: "#002733",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  Business Processes
                </Typography>{" "}
                <br />
                <Typography variant="h4" component="h2" gutterBottom>
                  {businessProcesses.length}
                </Typography>
                <WorkOutlineIcon
                  style={{ position: "absolute", top: "15", right: "15" }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card className={classes.root_card} variant="outlined">
              <CardContent className={classes.content}>
                <Typography
                  variant="body2"
                  style={{
                    color: "#002733",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  Entities
                </Typography>{" "}
                <br />
                <Typography variant="h4" component="h2" gutterBottom>
                  {instanceCount}
                </Typography>
                <BubbleChartOutlinedIcon
                  style={{ position: "absolute", top: "15", right: "15" }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card className={classes.root_card} variant="outlined">
              <CardContent className={classes.content}>
                <Typography
                  variant="body2"
                  style={{
                    color: "#002733",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  Unique Security Issues
                </Typography>{" "}
                <br />
                <Typography variant="h4" component="h2" gutterBottom>
                  {vulnerabilitiesCount}
                </Typography>
                <SimCardAlertOutlinedIcon
                  style={{ position: "absolute", top: "15", right: "15" }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Grid
        hidden={!features.BUSINESS_PROCESSES}
        container
        className={classes.container}
      >
        <Grid item className={classes.full_screen}>
          <OverviewTable data={data} />
        </Grid>
      </Grid>
    </>
  );
};

export default PandoOverview;
