import { Button } from "@mui/material";
import { ButtonGroupContainer, DataFilteringFooterContainer } from "../styled";
import AddIcon from "@mui/icons-material/Add";

export const DataFilteringFooter = ({
  onClickAddFilter,
  onClickApplyFilters,
  onClickResetFilters,
}) => {
  return (
    <DataFilteringFooterContainer>
      <ButtonGroupContainer>
        <Button variant='contained' onClick={onClickAddFilter}>
          <AddIcon sx={{ fontSize: "14px" }} />
          Add Filter
        </Button>
      </ButtonGroupContainer>

      <ButtonGroupContainer>
        <Button variant='outlined' onClick={onClickResetFilters}>
          Reset
        </Button>

        <Button variant='contained' onClick={onClickApplyFilters}>
          <AddIcon sx={{ fontSize: "14px" }} />
          Apply Filters
        </Button>
      </ButtonGroupContainer>
    </DataFilteringFooterContainer>
  );
};
