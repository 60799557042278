import React from "react";
import ConditionComponent from "../../../utils/ConditionComponent";
import { Grouping } from "./components/Grouping";
import { InventoryTable } from "./components/InventoryTable/index";
import { useIsGrouping } from "./hooks/useIsGrouping";
import { usePrimaryTableColumnState } from "./hooks/usePrimaryTableColumnState";

export const AssetTableContent = () => {
  const isGrouping = useIsGrouping();

  const [primaryTableColumns] = usePrimaryTableColumnState();

  return (
    <div>
      <ConditionComponent
        condition={isGrouping}
        falsyNode={<InventoryTable selectedColumns={primaryTableColumns} />}
      >
        <Grouping />
      </ConditionComponent>
    </div>
  );
};
