import {
  CellContainer,
  TruncatedCell,
} from "../../../../containers/inventory/AssetsTable/styled";

export const useColumns = () => {
  return [
    {
      header: "Query Name",
      accessorKey: "name",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Description",
      accessorKey: "description",
      size: 200,
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Created By",
      accessorKey: "created_by",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Last Run",
      accessorKey: "last_run",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Assigned Actions",
      accessorKey: "assigned_actions",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
  ];
};
