import { useDispatch } from "./useDispatch";
import { Actions } from "../stateManagement/actions";
import { useGlobalState } from "./useGlobalState";

export function usePaginationState() {
  const { paginationConfig: pagination } = useGlobalState();
  const dispatch = useDispatch();
  const setter = (payload) => {
    dispatch({
      type: Actions.SET_PAGINATION,
      payload,
    });
  };
  return [pagination, setter];
}
