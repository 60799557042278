import React from "react";
import { Typography } from "@mui/material";
import styles from "../styles.module.css";

function IntegrationMethodDescription() {
  return (
    <>
      <Typography
        variant="body1"
        className={styles.integrationMethodDesc}
        sx={{ mt: "21.5px" }}
      >
        For AWS integration with Pando, you must set up an IAM Role in your AWS
        account.
      </Typography>
      <Typography variant="body1" className={styles.integrationMethodDesc}>
        This role gives Pando read-only access to the metadata of AWS services,
        including 'Get', 'List', and 'Describe' permissions.
      </Typography>
      <Typography variant="body1" className={styles.integrationMethodDesc}>
        Deployment can be accomplished using an AWS CloudFormation template.
      </Typography>
      <Typography variant="body1" className={styles.integrationMethodDesc}>
        This role grants Pando read-only permissions.
      </Typography>
    </>
  );
}

export default IntegrationMethodDescription;
