export const Actions = Object.freeze({
  FETCH_QUERIES_PENDING: "fetch-queries-pending",
  FETCH_QUERIES_SUCCESS: "fetch-queries-success",
  FETCH_QUERIES_ERROR: "fetch-queries-error",
  SET_PAGINATION: "set-pagination",
  OPEN_NEW_ALERT_VIEW: "open-new-alert-view",
  CLOSE_NEW_ALERT_VIEW: "close-new-alert-view",
  CREATE_ALERT_PENDING: "create-alert-pending",
  CREATE_ALERT_SUCCESS: "create-alert-success",
  CREATE_ALERT_ERROR: "create-alert-error",
  SHOW_DELETE_VIEW: "show-delete-view",
  CLOSE_DELETE_VIEW: "close-delete-view",
  DELETE_QUERY_PENDING: "delete-query-pending",
  DELETE_QUERY_SUCCESS: "delete-query-success",
  DELETE_QUERY_ERROR: "delete-query-error",

  SHOW_EDIT_QUERY_VIEW: "show-edit-query-view",
  CLOSE_EDIT_QUERY_VIEW: "close-edit-query-view",
  EDIT_QUERY_PENDING: "edit-query-pending",
  EDIT_QUERY_SUCCESS: "edit-query-success",
  EDIT_QUERY_ERROR: "edit-query-error",
});
