import {
  BottomContentWrapper,
  MenuContainer,
  MenuContentContainer,
  SelectorContainer,
} from "./styled";
import { Fragment, useCallback, useMemo, useState } from "react";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { FilterTriggerButtonRef } from "./globalRefs";
import { MenuHeader } from "./MenuHeader";
import { MenuFooter } from "./MenuFooter";
import FilterGroup from "./FilterGroup";
import { useFilterGroupsController } from "./hooks/useFilterGroupsController";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { EmptyFilters } from "./EmptyFilters";
import PropTypes from "prop-types";
import useDerivedFilterOptions from "../../hooks/useDerivedFilterOptions";
import { transformTagKeysToList } from "./utils";
import { validateFilterGroups } from "./validateFilterGroups";
import { FormControlLabel, Switch, Typography } from "@mui/material";

const ANCHOR_TOP = 68;
const ANCHOR_LEFT = 500;

const FilterSelector = ({
  onApplyFilters,
  filters,
  graphData,
  graphDataTagKeys,
  menuContainerProps = {},
  showRelatedNodesParentState,
}) => {
  const tagKeys = useMemo(() => {
    return transformTagKeysToList(graphDataTagKeys);
  }, [graphDataTagKeys]);

  const graphNodes = useMemo(() => {
    return graphData?.nodes ?? [];
  }, [graphData]);

  const [menuOpened, setMenuOpened] = useState(null);
  const [showRelatedNodes, setShowRelatedNode] = useState(false);
  const {
    createFilterGroup,
    filterGroups,
    removeFilterGroup,
    updateFilterGroup,
    reset,
    forceSetData,
  } = useFilterGroupsController(filters);

  const { categories, categoryValuesSet } = useDerivedFilterOptions(
    graphNodes,
    tagKeys,
    filterGroups,
  );

  const handleOnToggleMenu = (ev) => {
    setMenuOpened((p) => !p);
    if (!menuOpened) {
      forceSetData(filters);
      setShowRelatedNode(showRelatedNodesParentState);
    }
  };

  const handleCloseMenu = () => {
    setMenuOpened(false);
  };

  const handleOnApplyFilters = () => {
    const { data, errorsFound } = validateFilterGroups(filterGroups);
    if (errorsFound) {
      forceSetData(data);
    } else {
      onApplyFilters(filterGroups, showRelatedNodes);
      handleCloseMenu();
    }
  };

  const handleOnChangeShowRelatedNodes = () => {
    setShowRelatedNode((p) => !p);
  };

  const filterGroupMapRenderer = useCallback(
    (filterGroup) => {
      return (
        <FilterGroup
          key={filterGroup.id}
          data={filterGroup}
          onDeleteFilterGroup={() => removeFilterGroup(filterGroup)}
          onUpdateFilterGroup={updateFilterGroup}
          categoryOptions={categories}
          filterGroups={filterGroups}
          categoryValueSet={categoryValuesSet}
        />
      );
    },
    [filterGroups, categories],
  );

  return (
    <Fragment>
      <SelectorContainer
        onClick={handleOnToggleMenu}
        ref={FilterTriggerButtonRef}
        disableRipple
      >
        <FilterAltIcon />
        Filter Assets
        <ArrowDropUpOutlinedIcon className={menuOpened ? "opened" : "closed"} />
      </SelectorContainer>
      <MenuContainer
        anchorEl={FilterTriggerButtonRef.current}
        open={menuOpened}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={{
          left: ANCHOR_LEFT,
          top: ANCHOR_TOP,
        }}
        {...menuContainerProps}
      >
        <MenuHeader handleOnClose={handleCloseMenu} />
        <MenuContentContainer>
          <ConditionComponent condition={filterGroups.length > 0}>
            {filterGroups.map(filterGroupMapRenderer)}
          </ConditionComponent>
          <ConditionComponent condition={filterGroups.length === 0}>
            <EmptyFilters />
          </ConditionComponent>
          <BottomContentWrapper>
            <FormControlLabel
              onChange={handleOnChangeShowRelatedNodes}
              sx={{
                marginBottom: 0,
                height: "32px !important",
              }}
              value='show related nodes'
              control={
                <Switch
                  checked={showRelatedNodes}
                  size='small'
                  sx={{
                    "&.MuiSwitch-root": {
                      height: "24px !important",
                      right: "6px !important",
                      position: "relative",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "#000033 !important",

                      opacity: showRelatedNodes
                        ? "1 !important"
                        : "0.5 !important",
                    },
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "#00ffb2 !important",
                      opacity: "1 !important",
                    },
                  }}
                />
              }
              label={
                <Typography variant='body2'>Show related nodes</Typography>
              }
            />
          </BottomContentWrapper>
        </MenuContentContainer>
        <MenuFooter
          handleOnReset={reset}
          handleOnCancel={handleCloseMenu}
          handleOnClickCreateFilterGroup={createFilterGroup}
          handleOnApply={handleOnApplyFilters}
        />
      </MenuContainer>
    </Fragment>
  );
};

FilterSelector.propTypes = {
  onApplyFilters: PropTypes.func,
};

export default FilterSelector;
