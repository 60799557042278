import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
  typography: {
    h1: {
      fontSize: "2rem",
      lineHeight: "1.25",
      letterSpacing: "0.005em",
      fontWeight: 400,
    },
    h2: {
      fontSize: "1.5rem",
      lineHeight: "1.3",
      letterSpacing: "0.005em",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.3rem",
      lineHeight: "1.35",
      letterSpacing: "0.005em",
      fontWeight: 400,
    },
    h4: {
      fontSize: "1.125rem",
      lineHeight: "1.4",
      letterSpacing: "0.0075em",
      fontWeight: 400,
    },
    h5: {
      fontSize: "1rem",
      lineHeight: "1.45",
      letterSpacing: "0.0075em",
      fontWeight: 400,
    },
    h6: {
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.01em",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.01em",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      lineHeight: "1.57",
      letterSpacing: "0.01em",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.015em",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.43",
      letterSpacing: "0.015em",
      fontWeight: 400,
    },
    button: {
      fontSize: "0.875rem",
      lineHeight: "1.75",
      letterSpacing: "0.02em",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.75rem",
      lineHeight: "1.66",
      letterSpacing: "0.03em",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.75rem",
      lineHeight: "2.66",
      letterSpacing: "0.08em",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          ":focus": {
            outline: "none",
          },
        },
      },
    },
  },
});
