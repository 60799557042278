import { Fragment, useMemo, useState } from "react";
import { InventoryTableActionButton } from "../../pages/inventory/components/ActionButton/index";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { MenuHeader } from "../../../views/G6Graph/components/FilterSelector/MenuHeader";
import { DataFilteringFooter } from "../../../components/Filtering/components/Footer";
import ConditionComponent from "../../../utils/ConditionComponent";
import { MenuContentContainer } from "../../../components/Filtering/styled";
import { EmptyFilters } from "../../../views/G6Graph/components/FilterSelector/EmptyFilters";
import { useFilterGroupController } from "../../../components/Filtering/hooks/useFilterGroupController";
import { SelectorButtonRef } from "./refs";
import { FilterGroup } from "./components/FilterGroup";
import { FilterMenuContainer } from "./styled";
import { useTableOperators } from "../../../hooks/data-filtering/useOperators";
import { useTableConjunctions } from "../../../hooks/data-filtering/useConjunctions";
import { getOperatorsByValueType } from "../../../core/data-filtering/getOperatorsByValueType";
import { filterGroupValidator } from "../../../core/data-filtering/filterGroupsValidator";

export const TableFilterSelector = ({
  fieldOptions,
  filters,
  onApplyFilters,
}) => {
  const [open, setOpen] = useState(false);
  const conjunctions = useTableConjunctions();
  const operators = useTableOperators();

  const {
    createFilterGroup,
    removeFilterGroup,
    reset: resetFilters,
    updateFilterGroup,
    localFilters,
    forceSetData,
  } = useFilterGroupController({ conjunction: conjunctions[0] });

  const getOperatorOptionsByFilterGroup = (group) => {
    return getOperatorsByValueType(group.field.valueTypes);
  };

  const filterGroupNodes = useMemo(() => {
    return localFilters.map((filterGroup, index) => {
      const withConjunction = index > 0;
      return (
        <FilterGroup
          key={filterGroup.id}
          withConjunction={withConjunction}
          data={filterGroup}
          fieldOptionsMap={fieldOptions}
          operatorOptions={operators}
          conjunctionOptions={conjunctions}
          onDeleteFilterGroup={removeFilterGroup}
          updateFilterGroup={updateFilterGroup}
          getOperatorOptionsByFilterGroup={getOperatorOptionsByFilterGroup}
        />
      );
    });
  }, [localFilters, operators, conjunctions, fieldOptions]);

  const handleOnClick = () => {
    setOpen(!open);
    if (!open && filters) {
      forceSetData(filters);
    }
  };

  const handleOnClose = () => {
    setOpen(false);
  };
  const handleOnClickApplyFilters = () => {
    const { data, errorsFound } = filterGroupValidator(localFilters);
    if (errorsFound) {
      forceSetData(data);
    } else {
      onApplyFilters(localFilters);
      handleOnClose();
    }
  };

  return (
    <Fragment>
      <InventoryTableActionButton
        ref={SelectorButtonRef}
        actionCount={filters.length}
        tooltipProps={{
          show: true,
          title: "Filter",
        }}
        onClick={handleOnClick}
        icon={<FilterAltIcon />}
      />
      <FilterMenuContainer
        anchorEl={SelectorButtonRef.current}
        open={open}
        onClose={handleOnClose}
        anchorReference='anchorEl'
        anchorOrigin={{
          horizontal: "left",
          vertical: 40,
        }}
      >
        <MenuHeader handleOnClose={handleOnClose} />

        <MenuContentContainer className='scrollable'>
          <ConditionComponent condition={!!localFilters.length}>
            {filterGroupNodes}
          </ConditionComponent>
          <ConditionComponent condition={!localFilters.length}>
            <EmptyFilters children={""} />
          </ConditionComponent>
        </MenuContentContainer>

        <DataFilteringFooter
          onClickAddFilter={createFilterGroup}
          onClickResetFilters={resetFilters}
          onClickApplyFilters={handleOnClickApplyFilters}
        />
      </FilterMenuContainer>
    </Fragment>
  );
};
