const registerEventHandlers = (graph, updateSelectedNode) => {
  graph.on("node:mouseenter", (evt) => {
    const { item } = evt;
    graph.setItemState(item, "active", true);
  });

  graph.on("node:mouseleave", (evt) => {
    const { item } = evt;
    graph.setItemState(item, "active", false);
  });

  let selectedNode = null; // Add this line at the beginning of your registerEventHandlers function
  graph.on("node:click", (evt) => {
    const { item } = evt;
    const model = item.getModel();
    updateSelectedNode(model);
    const nodeId = model.id;
    if (selectedNode) {
      graph.updateItem(selectedNode, { size: [30] }); // Reset the size of the previously selected node
    }

    selectedNode = item; // Update the selectedNode variable

    graph.updateItem(item, {
      size: [40], // Make the selected node bigger
    });

    // Reset all edges to default color before highlighting the selected ones
    graph.getEdges().forEach((edge) => {
      const edgeModel = edge.getModel();
      graph.updateItem(edge, {
        style: {
          stroke: "#B6BCC6",
          endArrow: {
            ...edgeModel.style.endArrow,
            fill: "#B6BCC6",
            stroke: "#B6BCC6",
          },
          opacity: 1,
        },
        labelCfg: {
          style: {
            fill: "#B6BCC6",
            opacity: 1,
          },
        },
      });
    });

    const connectedNodeIds = graph.getEdges().reduce((ids, edge) => {
      const edgeModel = edge.getModel();
      if (edgeModel.source === nodeId) {
        ids.push(edgeModel.target);
      } else if (edgeModel.target === nodeId) {
        ids.push(edgeModel.source);
      }
      return ids;
    }, []);

    // Highlight selected node and connected nodes, dim all others
    graph.getNodes().forEach((node) => {
      const nodeModel = node.getModel();
      if (nodeModel.id === nodeId || connectedNodeIds.includes(nodeModel.id)) {
        graph.updateItem(node, {
          style: { opacity: 1 },
        });
      } else {
        graph.updateItem(node, {
          style: { opacity: 0.2 },
        });
      }
    });

    // Highlight the edges connected to the selected node
    graph.getEdges().forEach((edge) => {
      const edgeModel = edge.getModel();
      if (edgeModel.source === nodeId || edgeModel.target === nodeId) {
        graph.updateItem(edge, {
          style: {
            stroke: "rgba(255,117,150,0.63)",
            fill: "#FF7596",
            endArrow: {
              ...edgeModel.style.endArrow,
              fill: "#FF7596",
              stroke: "#FF7596",
            },
            opacity: 1,
          },
          labelCfg: {
            style: {
              fill: "#FF7596",
            },
          },
        });
      } else {
        graph.updateItem(edge, {
          style: { opacity: 0.2 },
        });
      }
    });

    graph.paint();
  });

  // Adding combo hover events
  graph.on("combo:mouseenter", (evt) => {
    const { item } = evt;
    const comboId = item.get("id");

    graph.getEdges().forEach((edge) => {
      const edgeModel = edge.getModel();
      if (edgeModel.source === comboId || edgeModel.target === comboId) {
        graph.setItemState(edge, "active", false);
      }
    });
  });

  graph.on("afterrender", () => {
    // Keeps combos collapse
    graph.getCombos().forEach((combo) => {
      graph.collapseCombo(combo);
      graph.layout();
      graph.refreshPositions();
    });
  });

  graph.on("combo:mouseleave", (evt) => {
    const { item } = evt;
    const comboId = item.get("id");

    graph.getEdges().forEach((edge) => {
      const edgeModel = edge.getModel();
      if (edgeModel.source === comboId || edgeModel.target === comboId) {
        graph.setItemState(edge, "active", false);
      }
    });
  });

  graph.on("canvas:click", (evt) => {
    // Reset all nodes and edges to their default opacity and styles
    graph.getNodes().forEach((node) => {
      graph.updateItem(node, {
        style: { opacity: 1 },
      });
    });

    graph.getEdges().forEach((edge) => {
      const edgeModel = edge.getModel();

      if (selectedNode) {
        graph.updateItem(selectedNode, { size: [30] }); // Reset the size of the selected node when the canvas is clicked
        selectedNode = null;
      }

      graph.updateItem(edge, {
        style: {
          stroke: "#B6BCC6",
          endArrow: {
            ...edgeModel.style.endArrow,
            fill: "#B6BCC6",
            stroke: "#B6BCC6",
          },
          opacity: 1,
        },
        labelCfg: {
          style: {
            fill: "#000",
          },
        },
      });
    });

    updateSelectedNode(null);

    graph.paint();
  });
};
export default registerEventHandlers; // This makes it a default export
