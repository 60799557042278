import { useState } from "react";
import {
  AvatarSection,
  CollapseNavButton,
  LinksSection,
  LinkTab,
  LogoRoot,
  LogoSection,
  LogoutTab,
  LogoutTabsRoot,
  NestedTab,
  NestedTabContent,
  NestedTabSummary,
  SidebarRoot,
  TabLabel,
} from "./styled";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import logo from "../../../assets/long-logo.svg";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import SimCardAlertOutlinedIcon from "@mui/icons-material/SimCardAlertOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ConditionComponent from "../../../utils/ConditionComponent";
import { AppFeatures } from "../../../core/features";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import DOMPurify from "dompurify";
import { Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useUserInfo } from "../../../hooks/useAppUser";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";

export const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const { privileges } = useUserInfo();
  const location = useLocation();
  const { logout, user } = useAuth0();

  const handleToggleSidebar = () => setOpen(!open);

  const checkIsActiveLink = (path) => location.pathname === path;

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const sanitizedUserName = DOMPurify.sanitize(user.name);

  return (
    <SidebarRoot variant='permanent' open={open}>
      <LogoSection>
        <LogoRoot src={logo} alt='Pando Icon' />
      </LogoSection>

      <AvatarSection>
        <PersonIcon />
        <Typography variant='body2'>Welcome, {sanitizedUserName}</Typography>
      </AvatarSection>

      <LinksSection>
        <ConditionComponent condition={privileges[AppFeatures.OVERVIEW]}>
          <LinkTab to='/overview' isActiveLink={checkIsActiveLink("/overview")}>
            <TableChartOutlinedIcon />
            <TabLabel>Overview</TabLabel>
          </LinkTab>
        </ConditionComponent>

        <ConditionComponent condition={privileges[AppFeatures.GRAPH]}>
          <LinkTab to='/graph' isActiveLink={checkIsActiveLink("/graph")}>
            <HubOutlinedIcon />
            <TabLabel>Graph</TabLabel>
          </LinkTab>
        </ConditionComponent>

        <ConditionComponent condition={privileges[AppFeatures.CHAT]}>
          <LinkTab to='/chat' isActiveLink={checkIsActiveLink("/chat")}>
            <MessageOutlinedIcon />
            <TabLabel>Chat</TabLabel>
          </LinkTab>
        </ConditionComponent>

        <ConditionComponent condition={privileges[AppFeatures.VULNERABILITIES]}>
          <LinkTab
            to='/vulnerability'
            isActiveLink={checkIsActiveLink("/vulnerability")}
          >
            <SimCardAlertOutlinedIcon />
            <TabLabel>Security Issues</TabLabel>
          </LinkTab>
        </ConditionComponent>

        <ConditionComponent condition={privileges[AppFeatures.COUNTERMEASURES]}>
          <LinkTab to='/findings' isActiveLink={checkIsActiveLink("/findings")}>
            <DescriptionOutlinedIcon />
            <TabLabel>Findings</TabLabel>
          </LinkTab>
        </ConditionComponent>

        <ConditionComponent condition={privileges[AppFeatures.QUANTIFICATION]}>
          <LinkTab
            to='/quantification'
            isActiveLink={checkIsActiveLink("/quantification")}
          >
            <AccountBalanceWalletOutlinedIcon />
            <TabLabel>Quantification</TabLabel>
          </LinkTab>
        </ConditionComponent>

        <NestedTab>
          <NestedTabSummary>
            <InventoryOutlinedIcon />
            <TabLabel>Inventory</TabLabel>
          </NestedTabSummary>
          <NestedTabContent>
            <LinkTab
              to='/inventory'
              isActiveLink={checkIsActiveLink("/inventory")}
            >
              <TabLabel>Assets</TabLabel>
            </LinkTab>

            <ConditionComponent
              condition={privileges[AppFeatures.SAVED_QUERIES]}
            >
              <LinkTab
                isNested
                to='/inventory-queries'
                isActiveLink={checkIsActiveLink("/inventory-queries")}
              >
                <TabLabel>Saved Queries</TabLabel>
              </LinkTab>
              <LinkTab
                isNested
                to='/inventory-actions'
                isActiveLink={checkIsActiveLink("/inventory-actions")}
              >
                <TabLabel>Actions</TabLabel>
              </LinkTab>
            </ConditionComponent>
          </NestedTabContent>
        </NestedTab>

        <ConditionComponent condition={privileges[AppFeatures.CMST]}>
          <NestedTab>
            <NestedTabSummary>
              <LineAxisIcon />
              <TabLabel>CMST</TabLabel>
            </NestedTabSummary>
            <NestedTabContent>
              <LinkTab
                to='/cmst/projects'
                isActiveLink={checkIsActiveLink("/cmst/projects")}
              >
                <TabLabel>Projects</TabLabel>
              </LinkTab>

              <LinkTab
                isNested
                to='/cmst/queries'
                isActiveLink={checkIsActiveLink("/cmst/queries")}
              >
                <TabLabel>Saved Queries</TabLabel>
              </LinkTab>
              <LinkTab
                isNested
                to='/cmst/actions'
                isActiveLink={checkIsActiveLink("/cmst/actions")}
              >
                <TabLabel>Actions</TabLabel>
              </LinkTab>
            </NestedTabContent>
          </NestedTab>
        </ConditionComponent>

        <ConditionComponent condition={privileges[AppFeatures.INITIATIVES]}>
          <LinkTab
            to='/initiatives'
            isActiveLink={checkIsActiveLink("/initiatives")}
          >
            <TableRowsOutlinedIcon />
            <TabLabel>Initiatives</TabLabel>
          </LinkTab>
        </ConditionComponent>

        <ConditionComponent condition={privileges[AppFeatures.SETTINGS]}>
          <LinkTab to='/settings' isActiveLink={checkIsActiveLink("/settings")}>
            <SettingsApplicationsIcon />
            <TabLabel>Settings</TabLabel>
          </LinkTab>
        </ConditionComponent>
      </LinksSection>

      <LogoutTabsRoot>
        <LogoutTab onClick={handleLogout}>
          <LogoutOutlinedIcon />
          <TabLabel>Logout</TabLabel>
        </LogoutTab>
        <CollapseNavButton open={open} onClick={handleToggleSidebar}>
          <KeyboardDoubleArrowLeftIcon />
        </CollapseNavButton>
      </LogoutTabsRoot>
    </SidebarRoot>
  );
};
