export const AppFeatures = Object.freeze({
  BUSINESS_PROCESSES: "BUSINESS_PROCESSES",
  CHAT: "CHAT",
  GRAPHV6: "GRAPHV6",
  ONBOARDINGV2: "ONBOARDINGV2",
  SETTINGS: "SETTINGS",
  USER_MGMT: "USER_MGMT",
  VULNERABILITIES: "VULNERABILITIES",
  COUNTERMEASURES: "COUNTERMEASURES",
  QUANTIFICATION: "QUANTIFICATION",
  INVENTORY: "INVENTORY",
  INITIATIVES: "INITIATIVES",
  SAVED_QUERIES: "SAVED_QUERIES",
  CMST: "CMST",
  OVERVIEW: "OVERVIEW",
  GRAPH: "GRAPH",
});
