import awsLogo from "../../../assets/images/AWSDark.svg";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export const AwsCloudformationStepThreeContent = () => {
  return (
    <Box>
      <Stack direction="row" sx={{ alignItems: "flex-end", gap: "18.9px" }}>
        <Typography variant="h6">Integration Complete!</Typography>
      </Stack>
    </Box>
  );
};

export const stepTwoConfig = {
  awsLogo: awsLogo,
  headerTitle: "Integrating AWS using CloudFormation",
};
