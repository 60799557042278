import { useMemo } from "react";
import { useGetAssetDetailState } from "../hooks/useStateManager";
import {
  SubPageContainer,
  SubPageContentContainer,
  SubPageHeading,
  SubPageSubHeading,
  ValueText,
} from "../styled";
import { format } from "date-fns";
import { TicketSummary } from "./components/TicketSummary";
import ConditionComponent from "../../../../../utils/ConditionComponent";

export const TrackingPage = () => {
  const { data } = useGetAssetDetailState();

  const tickets = data.tickets ?? [];

  const hasTickets = tickets.length > 0;

  const ticketsNode = useMemo(() => {
    return tickets.map((ticket, index) => {
      const key = ticket._id ?? index;
      const transformedData = transformTicket(ticket);
      return <TicketSummary key={key} data={transformedData} />;
    });
  }, [data]);

  return (
    <SubPageContainer>
      <SubPageHeading>Tracking Information</SubPageHeading>

      <ConditionComponent
        condition={hasTickets}
        falsyNode={
          <ValueText isna>No Tracking Information available</ValueText>
        }
      >
        <SubPageContentContainer
          className='scrollable'
          sx={{ rowGap: "24px", overflowX: "hidden" }}
        >
          <SubPageSubHeading>Tickets</SubPageSubHeading>
          {ticketsNode}
        </SubPageContentContainer>
      </ConditionComponent>
    </SubPageContainer>
  );
};

function transformTicket(data) {
  const date = data.created_at;
  if (date) {
    const formatted = format(new Date(date), "EEEE - MMM/dd/yyyy");
    data.date = formatted;
  }

  return data;
}
