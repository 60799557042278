import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Typography, Box } from "@mui/material";

function Speedometer(props) {
  const { value, size } = props;
  let { fontSize } = props;
  const targetValue = value > 0 ? (value * 100) / 10 : 100; // If value is 0, then targetValue will be 100

  let color = "primary"; // default color

  if (value === 0)
    color = "green"; // If value is 0, set the color to light green
  else if (value >= 0.1 && value <= 3.9) color = "green";
  else if (value >= 4.0 && value <= 6.9) color = "gold";
  else if (value >= 7.0 && value <= 8.9) color = "orange";
  else if (value >= 9.0 && value <= 10.0) color = "red";

  if (!fontSize) {
    fontSize = 16;
  }

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        thickness={5}
        size={size || 60}
        value={targetValue}
        // color={color}
        sx={{ color: color }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{
            fontWeight: "bold",
            color: color,
            fontSize: fontSize + "px",
          }}
        >
          {`${Math.round(value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

Speedometer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Speedometer;
