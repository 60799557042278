import { Typography } from "@mui/material";
import { DataCountContainer } from "./styled";
import { forwardRef } from "react";

export const DataCount = forwardRef(({ data }, ref) => {
  return (
    <DataCountContainer ref={ref}>
      <Typography variant='caption'>+ {data.count}</Typography>
    </DataCountContainer>
  );
});
