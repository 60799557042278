import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Button } from "reactstrap";
import GitHubIcon from "@material-ui/icons/GitHub";
import { useLocation } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";

const GITHUB_CLIENT_ID = "7fb75e3b108747c19873";
const GITHUB_REDIRECT_URI =
  process.env.REACT_APP_FRONTEND_URL + "/github_auth/";
const IS_ON_PREMISES = process.env.REACT_APP_IS_ON_PREMISES === "true";

// Helper function to parse the query string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ConnectorComponent = () => {
  const query = useQuery();
  const code = query.get("code");
  const { getAccessTokenSilently } = useAuth0();
  const [isLoaded, setIsLoaded] = useState(false);

  // State to track connections
  const [connections, setConnections] = useState({});
  useEffect(() => {
    const checkConnections = async () => {
      try {
        // Get Auth0 token
        const auth0Token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${auth0Token}`, // Also send Auth0 token in headers for authentication
          },
        };

        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/connectors/connection_status",
          config,
        );

        // Set connections to the response
        setConnections(response.data);
        setIsLoaded(true);
      } catch (error) {
        console.log("Error", error);
      }
    };

    // Execute the function when component mounts
    checkConnections();
  }, []);

  const handleGitHubLogin = async () => {
    const url = `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&redirect_uri=${GITHUB_REDIRECT_URI}&scope=repo:read,read:org`;
    window.location.href = url; // Use window.location.href to redirect the current tab
  };

  if (!isLoaded) {
    return <></>;
  }

  return (
    <div>
      <h2
        style={{
          paddingTop: "20px",
          position: "relative",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "27px",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #ccc",
          color: "#002733",
        }}
      >
        Connect Assets to the Platform
      </h2>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Container
            style={{
              padding: 20,
              borderRadius: "12px",
              boxShadow:
                "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
              width: "300px",
              height: "100px",
              margin: "20px",
            }}
            className="mb-5"
          >
            {connections.github ? (
              <div
                style={{
                  paddingLeft: "35px",
                  paddingTop: "15px",
                  color: "green",
                  fontSize: "large",
                  alignContent: "center",
                }}
              >
                {" "}
                <GitHubIcon /> Connect to GitHub
              </div>
            ) : (
              <Button
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  color: "black",
                }}
                onClick={handleGitHubLogin}
              >
                <GitHubIcon /> Connect to GitHub
              </Button>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};

console.log(process.env.REACT_APP_IS_ON_PREMISES);

export default !IS_ON_PREMISES
  ? withAuthenticationRequired(ConnectorComponent, {
      onRedirecting: () => <Loading />,
    })
  : ConnectorComponent;
