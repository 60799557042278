import { Actions } from "../stateManagement/actions";
import { usePrimaryTableState } from "./usePrimaryTableState";
import { useStateDispatch } from "./useStateDispatch";

export function usePrimaryTablePagination() {
  const { paginationConfig } = usePrimaryTableState();

  const dispatch = useStateDispatch();

  const setter = (payload) => {
    dispatch({
      type: Actions.SET_PRIMARY_TABLE_PAGINATION,
      payload,
    });
  };
  return [paginationConfig, setter];
}
