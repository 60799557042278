import { CircularProgress } from "@mui/material";
import { Container } from "./styled";

export const LoadingIndicator = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};
