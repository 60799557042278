export const AssetPropertyType = Object.freeze({
  USER: "user",
  PLATFORM: "field",
  PLATFORM_COMMON_FIELD: "platform_common-field",
});

export const ApiPropertyTypeSuffix = Object.freeze({
  [AssetPropertyType.PLATFORM]: "properties",
  [AssetPropertyType.USER]: "tags",
});
