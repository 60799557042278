export const InitialState = Object.freeze({
  meta: {},
  data: [],
  error: false,
  errorPayload: null,
  showDeleteActionView: false,
  deleteActionPayload: {
    data: null,
    isLoading: false,
    error: false,
    errorPayload: null,
  },
  paginationConfig: {
    pageIndex: 0,
    pageSize: 20,
  },
  showEditActionView: false,
  editActionModalPayload: {
    isLoading: false,
    error: false,
    errorPayload: null,
    data: {
      queryUuid: "",
      name: "",
      condition: "",
      description: "",
      threshold: 0,
      notificationMethods: [],
    },
  },
});
