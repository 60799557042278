// ButtonToggle.js
import React from "react";
import { Button } from "@mui/material";
import styles from "./ButtonToggle.module.css";

function ButtonToggle({ label, toggled, onClick }) {
  return (
    <button
      className={`${styles.buttonToggle} ${toggled ? styles.toggled : ""}`}
      onClick={onClick}
    >
      <span className={styles.toggleText}>{label}</span>
    </button>
  );
}

export default ButtonToggle;
