import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import { RenderWithContext } from "../../utils/hocs/RenderWithContext";
import { IntegrationProvider } from "./context/Provider";
import { useIntegrationMultiStepSidebar } from "./hooks/useIntegrationMultiStepSidebar";
import logo from "../../assets/images/hibob.jpg";
import { useIntegrationView } from "./hooks/useIntegrationView";
import { useIntegrationButtons } from "./hooks/useIntegrationFooterButtons";
import { Fragment } from "react";
import ConditionComponent from "../../utils/ConditionComponent";
import BannerAlert from "../../components/banners/ErrorAlert";
import { useGetIntegrationState } from "./hooks/useIntegrationState";

const Component = () => {
  const MultiStepSidebar = useIntegrationMultiStepSidebar();
  const FooterButtons = useIntegrationButtons();
  const View = useIntegrationView();
  const { error } = useGetIntegrationState();

  return (
    <Fragment>
      <IntegrationLayout
        multisteps={MultiStepSidebar}
        headerLogo={logo}
        headerTitle={"Hibob Integration"}
        footerButtons={FooterButtons}
        content={View}
      />
      <ConditionComponent condition={error}>
        <BannerAlert message={error} />
      </ConditionComponent>
    </Fragment>
  );
};

export default RenderWithContext(Component, IntegrationProvider);
