import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  styled,
  Switch,
  InputBase,
} from "@mui/material";

export const FormItem = styled(FormControlLabel)(() => {
  return `
  `;
});

export const Checkbox = styled(MuiCheckbox)(() => {
  return ``;
});

export const Radio = styled(Switch)(() => {
  return ``;
});

export const FormGroupContainer = styled("div")(() => {
  return `
  display: flex;
  flex-direction: column;
  `;
});

export const InputsContainer = styled("div")(() => {
  return `
  margin-top: 12px;
  margin-left: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  `;
});

export const FormLabelWithInputContainer = styled("div")(() => {
  return `
  display: flex;
  `;
});

export const InlineInput = styled(InputBase)(() => {
  return `
  display: inline;
  border: 1px solid gray;
  width: 60px;
  margin-left: 12px;
  padding: 0 4px;
  border-radius: 4px;
  `;
});
