export const muiStyles = {
  input: {
    padding: "0 4px",
    fontSize: "14px",
    minWidth: "300px",
  },
  inputsSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "24px",
    marginTop: "24px",
  },
};
