// reducers/integrationReducer.js
import {
  SET_INTEGRATIONS,
  ADD_INTEGRATION,
  REMOVE_INTEGRATION,
} from "../actions/actionTypes";

const initialState = {
  integrations: [],
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
      };
    case ADD_INTEGRATION:
      return {
        ...state,
        integrations: [...state.integrations, action.payload],
      };
    case REMOVE_INTEGRATION:
      return {
        ...state,
        integrations: state.integrations.filter(
          (integration) => integration.id !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default integrationReducer;
