import { toast } from "react-toastify";

export function showAlert(content, config = {}) {
  toast(content, { autoClose: true, hideProgressBar: true, ...config });
}

export function showErrorAlert(content, config = {}) {
  toast(content, {
    autoClose: true,
    type: "error",
    hideProgressBar: true,
    ...config,
  });
}

export function showSuccessfulOperationAlert(content, config = {}) {
  toast(content, {
    autoClose: true,
    type: "success",
    hideProgressBar: true,
    ...config,
  });
}
