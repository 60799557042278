import G6 from "@antv/g6";

const defaultNodeConfig = {
  type: "icon-node",
  size: [30],
};

const defaultEdgeConfig = {
  type: "line",
  lineWidth: 1,
  size: 1,
  color: "#B6BCC6",
  style: {
    endArrow: {
      path: "M 0,0 L 6,3 L 6,-3 Z", // Further updated path for an even smaller arrow
      d: 0,
      fill: "#B6BCC6",
      stroke: "#B6BCC6",
    },
    startArrow: false,
  },
  labelCfg: {
    autoRotate: true,
    refX: 0,
    refY: 0,
    style: {
      fontSize: 10,
      // fill: '#DCDCDC',
      // stroke: '#fff', // Same color as the background to create an "interrupted" line effect
      lineWidth: 10, // Adjust as needed to cover the underlying edge line
    },
  },
};

const edgeStateStylesConfig = {
  selected: {
    stroke: "#FF7596", // Updated hover style
    fill: "#FF7596",
    endArrow: {
      path: "M 0,0 L 6,3 L 6,-3 Z",
      fill: "#FF7596",
      stroke: "#FF7596",
    },
  },
  active: {
    stroke: "#FF7596", // Updated hover style
    fill: "#FF7596",
    endArrow: {
      path: "M 0,0 L 6,3 L 6,-3 Z",
      fill: "#FF7596",
      stroke: "#FF7596",
    },
  },
  focus: {
    stroke: "#FF7596", // Updated hover style
    fill: "#FF7596",
  },
};

const comboStateStylesConfig = {
  active: {
    stroke: "#FF7596", // Updated hover style
    fill: "rgba(255,117,150,0.04)",
  },
  hover: {
    stroke: "#FF7596", // Updated hover style
    fill: "#FF7596",
  },
  click: {
    stroke: "#FF7596", // Updated hover style
    fill: "#FF7596",
  },
  focus: {
    stroke: "#FF7596", // Updated hover style
    fill: "#FF7596",
  },
};

const defaultComboConfig = {
  type: "rect",
  padding: 20,
  /* The minimum size of the combo. combo 最小大小 */
  // size: [250, 250],
  /* style for the keyShape */
  style: {
    lineWidth: 5,
    stroke: "#000033", // Updated hover style
    fill: "rgb(255,255,255)",
  },
  labelCfg: {
    /* label's offset to the keyShape */
    refY: 20,
    /* label's position, options: center, top, bottom, left, right */
    position: "bottom",
    /* label's style */
    style: {
      fontSize: 20,
      fontFamily: "inter",
      paddingLeft: "0px",
    },
  },
};

function createModesConfig() {
  return {
    default: [
      "drag-combo",
      "drag-node",
      "activate-relations",
      {
        type: "collapse-expand-combo",
        relayout: false,
      },
      {
        type: "brush-select",
        includeEdges: true,
      },
      {
        type: "zoom-canvas",
        enableOptimize: true,
        sensitivity: 3,
      },
      {
        type: "drag-canvas",
        enableOptimize: true,
        sensitivity: 2,
      },
    ],
  };
}

export function createG6Graph({
  width,
  height,
  plugins,
  layout,
  container,
  onLayoutEnd,
}) {
  const modes = createModesConfig();
  const graph = new G6.Graph({
    width,
    height,
    fitCenter: true,
    fitView: true,
    groupByTypes: false,
    fitViewPadding: [100, 300, 100, 300],
    animate: false,
    enabledStack: true,
    plugins,
    layout,
    container,
    modes,
    defaultNode: defaultNodeConfig,
    defaultEdge: defaultEdgeConfig,
    defaultCombo: defaultComboConfig,
    edgeStateStyles: edgeStateStylesConfig,
    comboStateStyles: comboStateStylesConfig,
    onLayoutEnd,
  });

  return graph;
}
