import React from "react";
import { Grid } from "@mui/material";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Content from "../components/Content";
import PandoTable from "../components/PandoTable";

const IS_ON_PREMISES = process.env.REACT_APP_IS_ON_PREMISES === "true";

export const TableComponent = () => {
  const { user } = useAuth0();

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: "30px",
          paddingRight: "10px",
          paddingTop: "30px",
        }}
      >
        <Content
          apiPath="/graph/all"
          component={PandoTable}
          width={"100%"}
          height={"900px"}
          title={"Entity Relationships"}
        />
      </Grid>
    </Grid>
  );
};

export default !IS_ON_PREMISES
  ? withAuthenticationRequired(TableComponent, {
      onRedirecting: () => <Loading />,
    })
  : TableComponent;
