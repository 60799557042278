export function formValidator(form) {
  const errors = [];
  if (!form.name) {
    const err = { field: "name", label: "Provide name" };
    errors.push(err);
  }
  if (!form.condition) {
    const err = { field: "condition", label: "Provide condition" };
    errors.push(err);
  }

  if (form.condition && [undefined, null].includes(form.threshold)) {
    const err = {
      field: "threshold",
      label: "This condition requires a threshold value",
    };
    errors.push(err);
  }

  if (!form.notificationMethods.length) {
    const err = {
      field: "notificationMethods",
      label: "Select at least 1 notification method",
    };
    errors.push(err);
  }

  return errors;
}
