import { useReducer } from "react";
import { IntegrationValuesStateContext } from "./contexts";
import { IntegrationInitialState } from "./state";
import { HibobIntegrationReducer } from "./reducer";

export const IntegrationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    HibobIntegrationReducer,
    IntegrationInitialState
  );

  return (
    <IntegrationValuesStateContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </IntegrationValuesStateContext.Provider>
  );
};
