// GraphIcons.js

import amazonLambda from "./assets/amazon-lambda.svg";
import amazonSqs from "./assets/amazon-sqs.svg";
import amazonSns from "./assets/amazon-sns.svg";
import httpSvg from "./assets/http.svg";
import amazonEcr from "./assets/amazon-ecr.svg";
import amazonElasticache from "./assets/amazon-elasticache.svg";
import amazonECS from "./assets/amazon-ecs.svg";
import amazonRDS from "./assets/amazon-rds.svg";
import amazonBinstalk from "./assets/amazon-binstalk.svg";
import rabbitmqSvg from "./assets/rabbitmq.svg";
import amazonCloudwatchAlarm from "./assets/amazon-cloudwatch-alarm.svg";
import securityWAF from "./assets/security-waf.svg";
import amazonS3 from "./assets/amazon-s3.svg";
import genericServer from "./assets/generic-server.svg";
import apigatewayv1 from "./assets/amazon-apigatewayv1.svg";
import email from "./assets/email.svg";
import amazonFirehose from "./assets/amazon-firehose.svg";
import amazonEC2 from "./assets/amazon-ec2.svg";
import amazonELB from "./assets/amazon-elb.svg";

const icons = {
  lambda: amazonLambda,
  Queue: amazonSqs,
  http: httpSvg,
  ecr_images: amazonEcr,
  elasticache: amazonElasticache,
  ecs: amazonECS,
  rds: amazonRDS,
  elasticbeanstalk: amazonBinstalk,
  sqs: amazonSqs,
  sns: amazonSns,
  saas: rabbitmqSvg,
  waf: securityWAF,
  s3: amazonS3,
  genericServer: genericServer,
  apigateway: apigatewayv1,
  apigatewayv2: apigatewayv1,
  cloudwatch_alarm: amazonCloudwatchAlarm,
  firehose: amazonFirehose,
  autoscaling_group: amazonEC2,
  elb: amazonELB,
  elbv2: amazonELB,
  alb: amazonELB,
  email: email,
  ec2: amazonEC2,
  albv2: amazonELB,
};

export default icons;
