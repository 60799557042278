import { Typography } from "@mui/material";
import { Root, Value } from "./styled";

export const CMSTInsightCard = ({
  variant = "default",
  value = 0,
  label = "Insight",
}) => {
  return (
    <Root>
      <Value insightVariant={variant}>{value}</Value>
      <Typography variant='body2'>{label}</Typography>
    </Root>
  );
};
