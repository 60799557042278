import React from "react";
import { Box, InputBase, TextField, Typography } from "@mui/material";
import styles from "../Bitbucket.module.css";
import bitbucketLogo from "../../../assets/images/bitbucket.svg";

export const BitbucketIntegrationContent = ({
  handleLabelNameChange,
  handleUsernameChange,
  handleAppTokenChange,
  handleWorkspaceChange,
  handleDomainUrlChange,
}) => {
  const [showSensitive, setShowSensitive] = React.useState(false);
  const toggleSensitive = () => {
    setShowSensitive(!showSensitive);
  };

  return (
    <Box sx={{ display: "flex", gap: "50px", paddingLeft: "20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Box>
          <Typography variant="body1" className="integrationMethodDesc">
            To create a read-only access token in Bitbucket, follow these steps:
            <ol>
              <li>
                Log into your <strong>Bitbucket account.</strong>
              </li>
              <li>
                Click settings button on the top right, then select{" "}
                <strong>'Personal Bitbucket settings'</strong>.
              </li>
              <li>
                In 'Personal Bitbucket settings' and click on{" "}
                <strong>'App passwords'</strong>.
              </li>
              <li>
                Click <strong>'Create app password'.</strong>
              </li>
              <li>
                <strong>Label</strong> your token for easy identification.
              </li>
              <li>
                Under permissions, select all{" "}
                <strong>read-related permissions.</strong>
              </li>
              <li>
                Click <strong>'Create'</strong> to generate the token.
              </li>
              <li>
                Enter the required information and paste the token in the{" "}
                <strong>'App Token'</strong>.
              </li>
            </ol>
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: 600,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <Typography
                variant="subtitle1"
                className={styles.bitbucketInputText}
              >
                Label Name *
              </Typography>
              <InputBase
                id="labelName"
                value={null}
                onChange={handleLabelNameChange}
                placeholder=""
                readOnly={null}
                classes={{ root: styles.bitbucketInput }}
              />
            </Box>
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <Typography
                variant="subtitle1"
                className={styles.bitbucketInputText}
              >
                Username *
              </Typography>
              <InputBase
                id="username"
                label="Username"
                value={null}
                onChange={handleUsernameChange}
                placeholder=""
                readOnly={null}
                classes={{ root: styles.bitbucketInput }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <Typography
                variant="subtitle1"
                className={styles.bitbucketInputText}
              >
                App Token *
              </Typography>
              <InputBase
                id="appToken"
                value={null}
                onChange={handleAppTokenChange}
                placeholder=""
                readOnly={null}
                classes={{ root: styles.bitbucketInput }}
              />
            </Box>
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <Typography
                variant="subtitle1"
                className={styles.bitbucketInputText}
              >
                Domain URL (on-prem)
              </Typography>
              <InputBase
                id="url"
                label="url"
                value={null}
                s
                onChange={handleDomainUrlChange}
                placeholder="https://bitbucket.domain"
                readOnly={null}
                classes={{ root: styles.bitbucketInput }}
              />
            </Box>

            {/*<Box sx={{ flexBasis: '50%', display: 'flex', flexDirection: 'column', padding: '20px'}}>*/}
            {/*    <Typography variant="subtitle1" className={styles.bitbucketInputText}>*/}
            {/*        Workspace **/}
            {/*    </Typography>*/}
            {/*    <InputBase*/}
            {/*        id="workspace"*/}
            {/*        label="workspace"*/}
            {/*        value={null}*/}
            {/*        onChange={handleWorkspaceChange}*/}
            {/*        placeholder=""*/}
            {/*        readOnly={null}*/}
            {/*        classes={{root: styles.bitbucketInput}}*/}
            {/*    />*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const BitbucketIntegrationConfig = {
  logo: bitbucketLogo,
  headerTitle: "Setup Bitbucket Integration",
};
