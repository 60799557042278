export const defaultGraphCategories = [
  "category",
  "id",
  "name",
  "source",
  "provider",
  "account",
  "region",
  "deployment",
  "operational_owner",
  "business_owner",
  "business_process",
];

export const graphLayoutOptions = ["Default", "Combo", "Dagre", "Circular"];

export const GraphGroupingTarget = Object.freeze({
  ALL: "all",
  VISIBLE_DATA: "visible_data",
});
