import React from "react";
import styles from "./suggestionbutton.module.css";

const SuggestionButton = ({ text, onClick }) => (
  <button className={styles.suggestionButton} onClick={onClick}>
    {text}
  </button>
);

export default SuggestionButton;
