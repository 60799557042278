import { Button, styled } from "@mui/material";

export const TableActionsRoot = styled("div")(() => {
  return `
  display: flex;
  align-items:center;
  column-gap: 6px;
  `;
});

export const RowAction = styled(Button)(({ theme }) => {
  return `
  background-color: ${theme.palette.primary.main};
  min-width: 0;
  font-size: 12px !important;
  color: #fff !important;
  height: 26px !important;
  `;
});
export const DeleteButton = styled(RowAction)(({ theme: { palette } }) => {
  return `
  background-color: ${palette.error.main};
  :hover {
    background-color: ${palette.error.main};
  
  }
  `;
});

export const EditButton = styled(RowAction)(({ theme: { palette } }) => {
  return `
  background-color: ${palette.secondary.main};
  :hover {
    background-color: ${palette.secondary.main};
  }
  `;
});
export const RunButton = styled(RowAction)(({ theme: { palette } }) => {
  return `
  background-color: ${palette.success.main};
   :hover {
     background-color: ${palette.success.main};;
  }
  `;
});

export const AlertButton = styled(RowAction)(({ theme: { palette } }) => {
  return `
  background-color: ${palette.warning.main};
  display:flex;
  align-items:center;
  column-gap: 2px;
  :hover {
    background-color: ${palette.warning.main}70;
  }
  `;
});
