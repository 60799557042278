export function Service(_apiClient) {
  let apiClient = _apiClient;

  this.getPrivileges = async () => {
    const path = "/features/list";
    const response = await apiClient.get(path, { isProtected: true });
    return {
      data: response.data,
      meta: {},
    };
  };

  this.getRole = async () => {
    const path = "/user/get_user_role";
    const response = await apiClient.get(path, { isProtected: true });
    return {
      data: response.data,
      meta: {},
    };
  };

  return this;
}
