import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./styles.module.css";

export default function MultiStepSidebar({ steps, currentStep }) {
  return (
    <Box className={styles.sidebar}>
      <Box className={styles.sidebarStepTracksWrapper}>
        {steps.map((step, index) => {
          const isCompletedStep = currentStep > index;
          const isActiveStep = currentStep === index + 1;
          return (
            <React.Fragment key={`stepFragment-${index}`}>
              <Box
                className={
                  isActiveStep
                    ? styles.integrationGradientBullet
                    : styles.integrationBullet
                }
              />
              {index < steps.length - 1 && (
                <Box
                  className={`${styles.integrationStepLine} ${isCompletedStep ? styles.active : ""}`}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
      <Box className={styles.sidebarStepTextWrappers}>
        {steps.map((step, index) => (
          <React.Fragment key={`textWrapper-${index}`}>
            <Box
              className={styles.sidebarStepTextWrapper}
              sx={{ mt: index === 0 ? "-7px" : "34px" }}
            >
              <Typography
                variant="h4"
                className={`${styles.sidebarStepTitle} ${currentStep <= index ? styles.incomplete : ""}`}
              >
                {step.title}
              </Typography>
              {step.description && (
                <Typography
                  variant="body1"
                  className={`${styles.sidebarStepDesc} ${currentStep <= index ? styles.incomplete : ""}`}
                >
                  {step.description}
                </Typography>
              )}
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
}
