import React, { useMemo } from "react";
import { ButtonBase } from "@mui/material";
import "../module.css";

function IntegrationMethod({ logo, name, onClick, disabled }) {
  const logoElement = useMemo(() => {
    if (typeof logo === "string") return <img src={logo} alt={name} />;
    if (React.isValidElement(logo)) return logo;
    return null;
  }, [logo]);

  return (
    <ButtonBase
      className="integrationMethod"
      onClick={onClick}
      disabled={disabled}
    >
      {logoElement}
      <span className="integrationMethodText">{name}</span>
    </ButtonBase>
  );
}

export default IntegrationMethod;
