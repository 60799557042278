import ConditionComponent from "../../../../utils/ConditionComponent";
import {
  AssetInfoContainer,
  BusinessInsightsContent,
  CopyValueButton,
  InfoLabel,
  InfoValue,
  NAValue,
} from "./styled";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { useCopyToClipboard } from "../../../../hooks/useCopyToClipboard";

export const BusinessInsights = ({ data }) => {
  const [hoverActive, setHoverActive] = useState(false);
  const { copied, onClickCopy } = useCopyToClipboard({
    value: data.business_purpose,
  });

  const handleOnMouseEnter = () => setHoverActive(true);
  const handleOnMouseLeave = () => setHoverActive(false);

  return (
    <AssetInfoContainer
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <InfoLabel>Business Insights</InfoLabel>
      <ConditionComponent
        condition={data.business_purpose !== "N/A"}
        falsyNode={<NAValue>N/A</NAValue>}
      >
        <BusinessInsightsContent>
          <InfoValue>{data.business_purpose}</InfoValue>
        </BusinessInsightsContent>
        <ConditionComponent condition={hoverActive}>
          <CopyValueButton top="8px !important" bottom="" onClick={onClickCopy}>
            <ConditionComponent
              condition={copied}
              falsyNode={<ContentCopyIcon />}
            >
              <CheckIcon />
            </ConditionComponent>
          </CopyValueButton>
        </ConditionComponent>
      </ConditionComponent>
    </AssetInfoContainer>
  );
};
