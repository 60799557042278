import { createAsyncThunk } from "@reduxjs/toolkit";
import { CMSTService } from "../../../services/CMSTService";

export const getProjects = createAsyncThunk(
  "projects",
  async (payload, thunkApi) => {
    try {
      //
      const res = await CMSTService.getInstance().getProjects(payload);
      return thunkApi.fulfillWithValue(res);
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
