import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { InputBase, Box, Grid, Button } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./style.module.css";

const TdComponent = (props) => {
  const [hasEllipsis, setHasEllipsis] = useState(true);
  let cell = props.cell;
  let callback = props.callback;
  return (
    <td {...cell.getCellProps()}>
      <Box
        className={styles.ellipsis}
        onClick={() => {
          setHasEllipsis(!hasEllipsis);
          callback(cell);
        }}
      >
        {cell.render("Cell")}
      </Box>
    </td>
  );
};

const SpacedTable = ({ columns, data, title, callback }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const canPreviousPage = pageIndex !== 0;
  const canNextPage = pageIndex < pageCount - 1;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", md: "calc(50% - 8px)" },
        height: "100%",
        position: "relative",
      }}
    >
      <Box>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box
              sx={{
                display: "flex", // Add flex layout
                alignItems: "center", // Align items center
                color: "var(--dark-blue, #002733)",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                paddingLeft: "10px",
              }}
            >
              <Box paddingRight="10px">{title}</Box>
            </Box>
          </Grid>
          <Grid item sx={{ paddingRight: "10px" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                border: "1px solid rgba(0,0,0,.2)",
                borderRadius: "8px",
                py: "2px", // Reduced padding for thinner appearance
                px: "8px",
              }}
            >
              <Grid item>
                <SearchIcon fontSize="small" />
              </Grid>
              <Grid item>
                <InputBase
                  type="text"
                  onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                  placeholder="Search..."
                  sx={{
                    fontSize: "12px", // Smaller font size
                    lineHeight: "12px", // Adjust line height
                    padding: "1px", // Reduce padding for a smaller appearance
                  }}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {/* Set minimum height here */}
        <table {...getTableProps()} className={styles.customtable}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span style={{ display: "inline-block", width: "1px" }}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon style={{ fontSize: "16px" }} />
                        ) : (
                          <ArrowUpwardIcon style={{ fontSize: "16px" }} />
                        )
                      ) : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <TdComponent cell={cell} callback={callback} />;
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className={styles.noresults}>
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Box>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "#fff",
          }}
        >
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            sx={{
              color: "#002733",
              "&:focus": { outline: "2px solid #002733" },
            }}
          >
            <FirstPageIcon />
          </Button>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            sx={{
              color: "#002733",
              "&:focus": { outline: "2px solid #002733" },
            }}
          >
            <NavigateBeforeIcon />
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            sx={{
              color: "#002733",
              "&:focus": { outline: "2px solid #002733" },
            }}
          >
            <NavigateNextIcon />
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            sx={{
              color: "#002733",
              "&:focus": { outline: "2px solid #002733" },
            }}
          >
            <LastPageIcon />
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};

export default SpacedTable;
