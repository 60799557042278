import { useEffect } from "react";
import { useFetchPrimaryTableData } from "./useFetchPrimaryTableData";
import { useSaveTableView } from "./useSaveTableView";
import { InventoryFilterUrlParser } from "../../../pages/inventory/utils/filterToUrlModule";
import { TableConfigKeys } from "../../../../containers/inventory/AssetsTable/config";
import { InitialState } from "../stateManagement/initialState";
import { useStateDispatch } from "./useStateDispatch";
import { Actions } from "../stateManagement/actions";
import { useFetchGroupingTableData } from "./useFetchGrouingTableData";
import { useAssetTableState } from "./useAssetTableState";
import { createGroupingOptionsFromSchema } from "../utils/createGroupingOptionsFromSchema";

export function useInitTable() {
  const fetchPrimaryTableData = useFetchPrimaryTableData();
  const fetchGroupingTableData = useFetchGroupingTableData();
  const dispatch = useStateDispatch();
  const { schema } = useAssetTableState();

  const { getView } = useSaveTableView();

  const fetchInitialTableData = () => {
    const initialView = getView();
    if (initialView) {
      const config = InventoryFilterUrlParser.decodeUrlToFilters(initialView);
      const wasGrouping = !!config.grouping?.group;
      const initialState = {
        grouping: [],
        currentGroupingOption: null,
        currentGroupingOptions: [],
        filters: config.filters,
        searchKeyword: config.search,
        primaryTable: {
          paginationConfig: InitialState.primaryTable.paginationConfig,
        },
        groupingTable: {
          paginationConfig: InitialState.primaryTable.paginationConfig,
        },
      };
      if (wasGrouping) {
        const { group, option } = config.grouping;

        const options = createGroupingOptionsFromSchema([group], schema);
        initialState.grouping = [group];
        initialState.currentGroupingOption = option;
        initialState.currentGroupingOptions = options;
        initialState.groupingTable.paginationConfig = config.pagination;
      } else {
        initialState.primaryTable.paginationConfig = config.pagination;
      }

      dispatch({ type: Actions.INIT_TABLE_STATES, payload: initialState });
      if (wasGrouping) {
        fetchGroupingTableData(config, { [TableConfigKeys.SAVE_VIEW]: false });
      } else {
        fetchPrimaryTableData(config, { [TableConfigKeys.SAVE_VIEW]: false });
      }
    } else {
      fetchPrimaryTableData(undefined, { [TableConfigKeys.SAVE_VIEW]: false });
    }
  };

  useEffect(() => {
    fetchInitialTableData();
  }, [schema]);
}
