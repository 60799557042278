import { Fragment, useMemo, useState } from "react";
import { FilterGroupButton } from "../../views/G6Graph/components/FilterSelector/styled";
import { GroupByTriggerButtonRef } from "../../views/G6Graph/components/FilterSelector/globalRefs";
import { MenuHeader } from "../../views/G6Graph/components/FilterSelector/MenuHeader";
import {
  CustomFieldsSection,
  GroupOptionContainer,
  GroupOptionSummary,
  GroupOptionValueContainer,
  GroupOptionValues,
  Label,
  MenuContainer,
  MenuContentContainer,
  MenuFooter,
  OptionValueContainer,
  OriginationTypeContainer,
  OriginationTypeLabel,
  PlatformDataSection,
  SearchSectionContainer,
  SectionContent,
  SelectedGroupingContentContainer,
  SelectionsContainer,
  UserDataSection,
} from "./styled";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import {
  ListItemCheckbox,
  ListItemText,
  ListSectionLabel,
  StyledInput,
} from "../G6Graph/GraphGrouping/styled";
import { EmptyDataSection } from "../EmptyData";
import { DataGroupsSources } from "./data";
import ConditionComponent from "../../utils/ConditionComponent";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTransition } from "react";
import { Button } from "reactstrap";
import { OriginationTypeView } from "./components/OriginationType";
import { LoadingSection } from "../LayoutFillers";
import { Typography } from "@mui/material";
import { useDeferredValue } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import { InventoryTableActionButton } from "../../ui/pages/inventory/components/ActionButton";

const ORIGINATION_TYPE_DISPLAY_LIMIT = 3;

function getDataOptions(data, key) {
  const results = { options: [], valueHash: {} };
  if (!data || !key || !data?.[key]) return results;
  results.valueHash = data[key];
  results.options = Object.keys(data[key]);

  return results;
}

function getCustomFieldsFromData(data) {
  const fieldsToIgnoreAsCustom = new Set(["platform"]);
  if (!data) return [];
  const fields = Object.keys(data).filter(
    (field) => !fieldsToIgnoreAsCustom.has(field)
  );
  return fields;
}

/**
 *
 * @param {*} param0
 * @returns
 * @emits effect used in DataTable component to update grouped data results
 */
export const DataGrouping = ({
  menuContainerProps = {},
  data,
  valueAccessorStates,
}) => {
  const [selectedValues, setSelectedValues] = valueAccessorStates;
  const [menuOpened, setMenuOpened] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [topLevelSelectedOption, setTopLevelSelectedOption] = useState();
  const [controlledAccordionStates, setControlledAccordionStates] = useState(
    {}
  );
  const startSearchTransition = useTransition()[1];
  const [show, setShow] = useState(false);
  const [renderingModalContent, modalContentTransition] = useTransition();
  const selectedOption = useDeferredValue(topLevelSelectedOption);

  const selectedOptionLabel = useMemo(() => {
    if (topLevelSelectedOption) {
      return `${topLevelSelectedOption.source} - ${topLevelSelectedOption.label}`;
    }
    return "";
  }, [topLevelSelectedOption]);

  const handleOnClickGroupValue = (option) => {
    let optionToSet = option;
    const isSelected =
      option.label === selectedOption?.label &&
      option.source === selectedOption?.source;
    if (selectedOption && isSelected) {
      optionToSet = null;
    }
    if (optionToSet) {
      setTopLevelSelectedOption(optionToSet);
      setSelectedValues([optionToSet]);
    } else {
      setTopLevelSelectedOption(null);
      setSelectedValues([]);
    }
  };

  const createAccordionControlPayloadKey = (controlPayload) => {
    return `${controlPayload.source}`;
  };

  const handleOnChangeAccordionState = (accordionControlPayload) => {
    const key = createAccordionControlPayloadKey(accordionControlPayload);
    let updatedControlledAccordionState = { ...controlledAccordionStates };
    if (controlledAccordionStates[key]) {
      updatedControlledAccordionState[key] = false;
    } else {
      updatedControlledAccordionState[key] = true;
    }
    setControlledAccordionStates(updatedControlledAccordionState);
  };

  const getAccordionIsExpanded = (accordionControlPayload) => {
    const key = createAccordionControlPayloadKey(accordionControlPayload);
    return controlledAccordionStates[key] || false;
  };

  const getOptionIsChecked = (selectedOption, option) => {
    if (!selectedOption || !option) return false;
    return (
      selectedOption.label === option.label &&
      selectedOption.source === option.source
    );
  };

  /**
   *
   * @param {*} option
   * @param {*} searchQuery
   * @returns
   * empty, wildcard, str
   * str, wildcard, empt
   * vpc*
   */
  const execWildcardSearch = (option, searchQuery) => {
    const searchParts = searchQuery.split("*");
    let matches = true;
    searchParts.forEach((part) => {
      if (!part) {
        const valueMatchesSearch = option.value?.toLowerCase()?.includes(part);
        const typeMatchesSearch = option.originationTypes?.some((type) =>
          type?.toLowerCase().includes(part)
        );
        matches = matches && (valueMatchesSearch || typeMatchesSearch);
      } else {
        const valueMatchesSearch = option.value?.toLowerCase()?.includes(part);
        const typeMatchesSearch = option.originationTypes?.some((type) =>
          type?.toLowerCase().includes(part)
        );
        matches = matches && (valueMatchesSearch || typeMatchesSearch);
      }
    });
    return matches;
  };

  const execDefaultSearch = (option, searchQuery) => {
    const valueMatchesSearch = option.value
      ?.toLowerCase()
      ?.includes(searchQuery);
    const typeMatchesSearch = option.originationTypes?.some((type) =>
      type?.toLowerCase().includes(searchQuery)
    );
    return valueMatchesSearch || !!typeMatchesSearch;
  };

  const checkOptionMatchesSearchQuery = (option, searchQuery) => {
    const areValidTypes =
      typeof searchValue === "string" || typeof option === "string";
    if (!searchQuery) return true;
    if (!option || !areValidTypes) return false;

    const searchIsWildCard = searchQuery.includes("*");
    const searchQueryStr = searchQuery.toLowerCase();
    if (searchIsWildCard) {
      return execWildcardSearch(option, searchQueryStr);
    } else return execDefaultSearch(option, searchQueryStr);
  };

  /**
   * Optimize;
   * filter nodes for only valid values before saving
   *
   * - move node creation outside.
   */
  const platformFieldsNodes = useMemo(() => {
    const { options, valueHash } = getDataOptions(data, "platform");
    const nodes = [];
    options.forEach((option) => {
      const optionValues = valueHash[option].values;
      const originationTypes = valueHash[option].types ?? [];
      const optionValueCount = optionValues.length;
      if (!optionValueCount) return;
      let displayOriginationTypes = [];
      if (originationTypes.length) {
        displayOriginationTypes = originationTypes.slice(
          0,
          ORIGINATION_TYPE_DISPLAY_LIMIT
        );
      }
      const additionalOriginationTypes =
        originationTypes.length - ORIGINATION_TYPE_DISPLAY_LIMIT;
      const showAdditionalOriginationTypeCounter =
        additionalOriginationTypes > 0;

      const label = `${option} (${optionValueCount})`;
      const searchOptionPayload = {
        value: option,
        originationTypes,
      };
      const matchesSearchQuery = checkOptionMatchesSearchQuery(
        searchOptionPayload,
        searchValue
      );
      if (!matchesSearchQuery) {
        return;
      }

      const payload = {
        label,
        value: option,
        source: DataGroupsSources.PLATFORM,
      };

      const checked = getOptionIsChecked(selectedOption, payload);
      const key = `group-option-${option}`;
      nodes.push(
        <GroupOptionValueContainer key={key}>
          <OptionValueContainer>
            <ListItemCheckbox
              id={key}
              type='checkbox'
              checked={checked}
              onChange={(ev) => {
                handleOnClickGroupValue(payload);
              }}
            />
            <ListItemText htmlFor={key}>{label}</ListItemText>
          </OptionValueContainer>
          <OriginationTypeContainer>
            {displayOriginationTypes.map((type) => (
              <OriginationTypeLabel key={type}>{type}</OriginationTypeLabel>
            ))}
            <ConditionComponent
              condition={showAdditionalOriginationTypeCounter}
            >
              <Button>+{additionalOriginationTypes}</Button>
            </ConditionComponent>
          </OriginationTypeContainer>
        </GroupOptionValueContainer>
      );
    });

    return nodes;
  }, [data, selectedOption, searchValue, setSelectedValues]);

  const customFieldsNodes = useMemo(() => {
    const customFields = getCustomFieldsFromData(data);

    return customFields.map((field) => {
      const { options, valueHash } = getDataOptions(data, field);
      const heading = field.toUpperCase();

      const customFieldNodes = (function () {
        const nodes = [];
        options.forEach((option) => {
          const optionValues = valueHash[option].values;
          const originationTypes = valueHash[option].types ?? [];
          const optionValueCount = optionValues.length;
          if (!optionValueCount) return;

          const label = `${option} (${optionValueCount})`;
          const searchOptionPayload = {
            value: option,
            originationTypes,
          };
          const matchesSearchQuery = checkOptionMatchesSearchQuery(
            searchOptionPayload,
            searchValue
          );
          if (!matchesSearchQuery) {
            return;
          }

          const key = `group-option-${option}`;
          const payload = {
            label,
            value: option,
            source: field,
          };
          const checked = getOptionIsChecked(selectedOption, payload);

          nodes.push(
            <GroupOptionValueContainer key={key}>
              <OptionValueContainer>
                <ListItemCheckbox
                  type='checkbox'
                  checked={checked}
                  onChange={(ev) => {
                    handleOnClickGroupValue(payload);
                  }}
                />
                <ListItemText htmlFor={key}>{label}</ListItemText>
              </OptionValueContainer>
              <OriginationTypeView data={originationTypes ?? []} />
            </GroupOptionValueContainer>
          );
        });
        return nodes;
      })();

      const accordionControlPayload = {
        source: field,
      };

      const expanded =
        !!searchValue || getAccordionIsExpanded(accordionControlPayload);

      return (
        <GroupOptionContainer
          disableGutters
          expanded={expanded}
          onChange={() => handleOnChangeAccordionState(accordionControlPayload)}
          slotProps={{ transition: { unmountOnExit: true } }}
        >
          <GroupOptionSummary expandIcon={<ArrowRightIcon />}>
            {heading}
          </GroupOptionSummary>
          <GroupOptionValues className='scrollable'>
            {customFieldNodes}
          </GroupOptionValues>
        </GroupOptionContainer>
      );
    });
  }, [
    data,
    selectedOption,
    searchValue,
    controlledAccordionStates,
    setSelectedValues,
  ]);

  const handleOnToggleMenu = () => {
    setSearchValue("");
    if (!menuOpened) {
      if (selectedValues.length) {
        setTopLevelSelectedOption(selectedValues[0]);
      }
      modalContentTransition(() => {
        setShow(true);
      });
    }

    setMenuOpened((p) => !p);
  };

  const handleCloseMenu = () => {
    setMenuOpened(false);
    setShow(false);
  };

  const handleClearSelection = () => {
    setSelectedValues([]);
    setTopLevelSelectedOption(null);
    handleCloseMenu();
  };

  const handleOnChangeSearch = (ev) => {
    const value = ev.target.value;
    startSearchTransition(() => {
      setSearchValue(value);
    });
  };

  return (
    <Fragment>
      <InventoryTableActionButton
        tooltipProps={{
          show: true,
          title: "Group Table",
        }}
        isActive
        actionCount={selectedValues.length}
        ref={GroupByTriggerButtonRef}
        onClick={handleOnToggleMenu}
        icon={<LayersIcon />}
      />
      <MenuContainer
        anchorEl={GroupByTriggerButtonRef.current}
        open={menuOpened}
        onClose={handleCloseMenu}
        anchorReference='anchorEl'
        anchorOrigin={{
          horizontal: "left",
          vertical: 40,
        }}
        {...menuContainerProps}
      >
        <MenuHeader handleOnClose={handleCloseMenu} />
        <ConditionComponent condition={renderingModalContent}>
          <LoadingSection>
            <Typography variant='caption'>One moment...</Typography>
          </LoadingSection>
        </ConditionComponent>
        <ConditionComponent condition={show}>
          <MenuContentContainer>
            <SearchSectionContainer>
              <ListSectionLabel>Search</ListSectionLabel>
              <SelectedGroupingContentContainer>
                <StyledInput
                  onChange={handleOnChangeSearch}
                  placeholder='Search...'
                />
              </SelectedGroupingContentContainer>
            </SearchSectionContainer>

            <SelectionsContainer>
              <ListSectionLabel>Current Grouping</ListSectionLabel>
              <SelectedGroupingContentContainer>
                <ConditionComponent
                  condition={topLevelSelectedOption}
                  falsyNode={<EmptyDataSection label={"No Active Grouping"} />}
                >
                  <GroupOptionValueContainer>
                    <OptionValueContainer>
                      <ListItemCheckbox
                        id={"selected-asset-grouping"}
                        type='checkbox'
                        checked
                        onChange={(ev) => {
                          handleOnClickGroupValue(topLevelSelectedOption);
                        }}
                      />
                      <ListItemText htmlFor={"selected-asset-grouping"}>
                        {selectedOptionLabel}
                      </ListItemText>
                    </OptionValueContainer>
                  </GroupOptionValueContainer>
                </ConditionComponent>
              </SelectedGroupingContentContainer>
            </SelectionsContainer>

            <PlatformDataSection>
              <ListSectionLabel>Platform Fields</ListSectionLabel>
              <SectionContent className='scrollable'>
                {platformFieldsNodes}
              </SectionContent>
            </PlatformDataSection>
            <UserDataSection>
              <ListSectionLabel>Custom Fields</ListSectionLabel>
              <CustomFieldsSection className='scrollable'>
                {customFieldsNodes}
              </CustomFieldsSection>
            </UserDataSection>
          </MenuContentContainer>
        </ConditionComponent>
        <MenuFooter>
          <FilterGroupButton
            sx={{ marginLeft: "0 !important" }}
            variant='text'
            border='1px solid #dee0e4'
            disableRipple
            bgColor='#F7F9FE'
            onClick={handleClearSelection}
          >
            <DeleteSweepOutlinedIcon />
            <Label>Clear All Groupings</Label>
          </FilterGroupButton>
        </MenuFooter>
      </MenuContainer>
    </Fragment>
  );
};
