import { EMPTY_VALUE } from "./assetDetailsSelector";
import { getListValidValues } from "./getListValidValues";

function calculateAssetField(asset, field) {
  if (!asset || !asset?.common_fields) return [];
  const values = getListValidValues(asset.common_fields[field]);
  if (values.length === 0) return [];
  return values;
}

export function selectGeneralAssetDetails(data) {
  const assetData = data.asset;
  const generalDetails = {
    accountId: [],
    region: [],
    status: [],
    arn: [],
    clients: [],
    containerId: [],
    hostName: [],
    macAddress: [],
    privateIp: [],
    publicIp: [],
    url: [],
    description: "",
    sources: [],
    type: [],
  };

  generalDetails.accountId = calculateAssetField(assetData, "account_id");
  generalDetails.region = calculateAssetField(assetData, "region");
  generalDetails.arn = calculateAssetField(assetData, "arn");
  generalDetails.clients = calculateAssetField(assetData, "clients");
  generalDetails.containerId = calculateAssetField(assetData, "container_id");
  generalDetails.hostName = calculateAssetField(assetData, "hostname");
  generalDetails.macAddress = calculateAssetField(assetData, "mac_address");
  generalDetails.privateIp = calculateAssetField(assetData, "private_ip");
  generalDetails.publicIp = calculateAssetField(assetData, "public_ip");
  generalDetails.url = calculateAssetField(assetData, "url");
  generalDetails.description = assetData.description || EMPTY_VALUE;
  generalDetails.sources = assetData.sources;
  generalDetails.type = assetData.types;

  return generalDetails;
}
