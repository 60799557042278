import { EmptyValueOperatorsMap } from "./operators";

export function filterGroupValidator(filterGroups) {
  let errorsFound = false;

  const data = filterGroups.map((filterGroup, index) => {
    const { group, hasError } = validateGroup(filterGroup, index);
    errorsFound ||= hasError;
    return group;
  });

  return { errorsFound, data };
}

function validateValues(group) {
  if (
    (!group.values || !group.values.length) &&
    !EmptyValueOperatorsMap[group.operator.value]
  ) {
    return true;
  }
  return false;
}

function validateGroup(group, index) {
  const missingFields = [];
  if (!group.field.value) {
    missingFields.push("Field");
  }
  if (!group.operator.value) {
    missingFields.push("Operator");
  }

  const valuesError = validateValues(group);
  if (valuesError) missingFields.push("Value (s)");

  if (index && !group.conjunction.value) {
    missingFields.push("Conjunction");
  }

  const hasError = !!missingFields.length;
  if (hasError) {
    const missingFieldsAsStrings = missingFields.join(", ");
    const conjunction = missingFields.length > 1 ? "are" : "is";
    group.warning = `${missingFieldsAsStrings} ${conjunction} empty. Select a value to proceed`;
  } else if (group.warning) {
    group.warning = "";
  }
  return { group, hasError };
}
