import { SavedQueryStateActions } from "./actions";

export const Reducer = (_state, action) => {
  const state = { ..._state };
  if (action.type === SavedQueryStateActions.queryPending) {
    state.savedQueriesTableState.isLoading = true;
    state.savedQueriesTableState.error = null;
    return state;
  }
  if (action.type === SavedQueryStateActions.queryCompleted) {
    state.savedQueriesTableState.isLoading = false;
    state.savedQueriesTableState.error = null;
    state.savedQueriesTableState.data = action.payload.data;
    state.savedQueriesTableState.meta = action.payload.meta;
    return state;
  }

  if (action.type === SavedQueryStateActions.queryFailed) {
    state.savedQueriesTableState.isLoading = false;
    state.savedQueriesTableState.error = action.payload;
    state.savedQueriesTableState.data = [];
    state.savedQueriesTableState.meta = {};
    return state;
  }
  return state;
};
