import { GroupingDataTable } from "./components/GroupingDataTable";
import { GroupOptionsView } from "./components/GroupOptions";
import { Container } from "./styled";

export const GroupingView = () => {
  return (
    <Container>
      <GroupOptionsView />
      <GroupingDataTable />
    </Container>
  );
};
