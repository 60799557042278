export function formValidator(form) {
  const errors = [];

  if (!form.name) {
    const err = { field: "name", message: "Name cannot be empty" };
    errors.push(err);
  }
  if (!form.description) {
    const err = {
      field: "description",
      message: "description cannot be empty",
    };
    errors.push(err);
  }

  return errors;
}
