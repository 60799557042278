import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import rootReducer from "./rootReducer";
import { migrations } from "./storeMigrations";
import { setupListeners } from "@reduxjs/toolkit/query";

const persistConfig = {
  key: "root",
  storage,
  version: 0,
  migrate: createMigrate(migrations, { debug: true }),
  blacklist: ["cmst"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

const persistedStore = persistStore(store);
setupListeners(store.dispatch);
export { persistedStore, store };
