export class InvalidPayloadError extends Error {
  #errors = [];
  constructor(message, errors) {
    super(message);
    this.#errors = errors;
  }
  get errors() {
    return this.#errors;
  }
}
