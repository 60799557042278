import { Chip, styled } from "@mui/material";

export const ChipRoot = styled(Chip)(
  ({ bgColor: _bg, mainColor, textColor, theme }) => {
    const { grey } = theme.palette;
    const color = mainColor ? `${mainColor}` : textColor ? textColor : "#000";
    const bg = mainColor ? `${mainColor}30` : _bg ? _bg : grey[500];
    return `
  min-width: 0;
  max-width: fit-content;
  background-color: ${bg};
  & .MuiChip-label {
  color: ${color};
  font-weight: 400 !important;
  }
  `;
  }
);
