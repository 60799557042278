import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import pandoLogo from "../../../assets/images/pando-logo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ProgressBar } from "react-step-progress-bar";
import "./module.css";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const stage = useSelector((state) => state.onboarding.currentStep); // Adjust the path according to your state structure
  const totalStages = 2; // Total number of stages
  const progressPercent = ((stage - 1) / totalStages) * 100;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutWithRedirect = () => {
    handleClose(); // Close the menu
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <>
      <AppBar position="sticky" className="appbar">
        <Toolbar className="toolbar">
          <img src={pandoLogo} alt="pando logo" className="logo" />
          {!isAuthenticated && (
            <Button color="primary" onClick={() => loginWithRedirect()}>
              Log in
            </Button>
          )}
          {isAuthenticated && (
            <Box className="appbar-profile">
              <Box className="avatar">
                {user.name.slice(0, 2).toUpperCase()}
              </Box>
              <IconButton size="small" sx={{ ml: 2 }} onClick={handleMenu}>
                <KeyboardArrowDownIcon sx={{ fill: "#C7C7C7" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={logoutWithRedirect}
                  style={{
                    fontSize: "16px",
                    color: "#333",
                    padding: "10px 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #eee",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#f5f5f5")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "white")
                  }
                >
                  Log out
                </MenuItem>
              </Menu>
            </Box>
          )}

          <Box
            className="stepsWrapper"
            sx={{
              gap: { xs: "20px", sm: "50px", md: "168px" },
            }}
          >
            <Box
              className="stepProgressBar"
              sx={{ width: "calc(100% + 52px)" }}
            >
              <ProgressBar
                percent={progressPercent}
                filledBackground="linear-gradient(270deg, #03FFB2 -73.21%, #FF7596 99.99%)"
                height="6px"
              />
            </Box>
            <Box className="stepItem">
              <p className="stepText">Welcome</p>
            </Box>
            <Box className="stepItem">
              <p className="stepText">Connectors</p>
            </Box>
            <Box className="stepItem">
              <p className="stepText">Complete</p>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
