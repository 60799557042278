import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Box, Card, Divider } from "@mui/material";
import { Container } from "reactstrap";
import Speedometer from "../Speedometer";

const data = [
  { x: "2023-01-01", y: 2 },
  { x: "2023-02-01", y: 10 },
  { x: "2023-03-01", y: 7 },
  { x: "2023-04-01", y: 6 },
  { x: "2023-05-01", y: 5 },
  { x: "2023-06-01", y: 9 },
  { x: "2023-07-01", y: 3 },
  { x: "2023-08-01", y: 4 },
  { x: "2023-09-01", y: 1 },
  { x: "2023-10-01", y: 3 },
  { x: "2023-01-01", y: 2 },
  { x: "2023-02-01", y: 10 },
  { x: "2023-03-01", y: 7 },
  { x: "2023-04-01", y: 6 },
  { x: "2023-05-01", y: 5 },
  { x: "2023-06-01", y: 9 },
  { x: "2023-07-01", y: 3 },
  { x: "2023-08-01", y: 4 },
  { x: "2023-09-01", y: 1 },
  { x: "2023-10-01", y: 3 },
  { x: "2023-01-01", y: 2 },
  { x: "2023-02-01", y: 10 },
  { x: "2023-03-01", y: 7 },
  { x: "2023-04-01", y: 6 },
  { x: "2023-05-01", y: 5 },
  { x: "2023-06-01", y: 9 },
  { x: "2023-07-01", y: 3 },
  { x: "2023-08-01", y: 4 },
  { x: "2023-09-01", y: 1 },
  { x: "2023-10-01", y: 3 },
  { x: "2023-01-01", y: 2 },
  { x: "2023-02-01", y: 10 },
  { x: "2023-03-01", y: 7 },
  { x: "2023-04-01", y: 6 },
  { x: "2023-05-01", y: 5 },
  { x: "2023-06-01", y: 9 },
  { x: "2023-07-01", y: 3 },
  { x: "2023-08-01", y: 4 },
  { x: "2023-09-01", y: 1 },
  { x: "2023-10-01", y: 3 },
];

const ActiveDot = (props) => (
  <circle
    {...props}
    r={4}
    stroke="url(#colorUv)"
    strokeWidth={3}
    fill="url(#colorUv)"
  />
);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        style={{
          transform: "translateY(-80px)", // This line moves the tooltip up by 20 pixels
          display: "flex",
          width: "215px",
          padding: "12px",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "12px",
          background: "#FFF",
          alignSelf: "stretch",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Existing content */}
        <Box
          style={{
            color: "var(--gray, #8F9699)",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16px", // 133.333%
          }}
        >
          {`Date: ${label}`}
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            style={{
              color: "var(--gray, #8F9699)",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "16px", // 133.333%
            }}
          >
            {`Pando Score: ${payload[0].value}`}
          </Box>
          {/*<Box style={{*/}
          {/*    color: 'var(--dark-blue, #002733)',*/}
          {/*    fontFamily: 'Inter',*/}
          {/*    fontSize: '24px',*/}
          {/*    fontStyle: 'normal',*/}
          {/*    fontWeight: 600,*/}
          {/*    lineHeight: '33px'*/}
          {/*}}>*/}
          {/*    {`${payload[0].value}`}*/}
          {/*</Box>*/}
        </Box>
        {/*/!* New row with three sections *!/*/}
        {/*<Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>*/}
        {/*    <Box style={{*/}
        {/*        color: 'var(--dark-blue, #002733)',*/}
        {/*        fontFamily: 'Inter',*/}
        {/*        fontSize: '12px',*/}
        {/*        fontStyle: 'normal',*/}
        {/*        fontWeight: 400,*/}
        {/*        lineHeight: '16px'*/}
        {/*    }}>*/}
        {/*        {`Likelihood: ${payload[0].likelihood || 21}%`}*/}
        {/*    </Box>*/}
        {/*    <Box style={{*/}
        {/*        color: '#002733',*/}
        {/*        padding: '6px'*/}
        {/*    }}>*/}
        {/*        |*/}
        {/*    </Box>*/}
        {/*    <Box style={{*/}
        {/*        color: 'var(--dark-blue, #002733)',*/}
        {/*        fontFamily: 'Inter',*/}
        {/*        fontSize: '12px',*/}
        {/*        fontStyle: 'normal',*/}
        {/*        fontWeight: 400,*/}
        {/*        lineHeight: '16px'*/}
        {/*    }}>*/}
        {/*        {`Impact: ${payload[0].impact || '$27M'}`}*/}
        {/*    </Box>*/}
        {/*</Box>*/}
      </Box>
    );
  }

  return null;
};

const CustomAxisTick = ({ x, y, payload, isY }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-3}
        dy={isY ? 0 : 16}
        textAnchor="middle"
        fill="#CCC"
        fontSize="12px"
        fontFamily="Inter"
        fontWeight={400}
        lineHeight="16px"
        style={{ fontStyle: "normal" }}
      >
        {payload.value}
      </text>
    </g>
  );
};

function GradientAreaChart({ data, speedometerValue }) {
  console.log(data);
  const [isHovered, setIsHovered] = React.useState(false);
  if (data.length === 0) {
    return (
      <Container
        fluid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <Card
          style={{
            display: "flex",
            padding: "20px",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            borderRadius: "8px",
            background: "#F3F6FD",
          }}
        >
          <Box
            style={{
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "33px",
              textAlign: "center",
            }}
          >
            Uh-oh! Looks like the chart is feeling lonely.
          </Box>
          <Box
            style={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px",
              textAlign: "center",
            }}
          >
            Time to start executing those suggestions and feed the chart with
            data! 😜
          </Box>
        </Card>
      </Container>
    );
  }

  return (
    <Container
      fluid
      style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}
    >
      <ResponsiveContainer width="90%" height={350}>
        <AreaChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#FF7596" stopOpacity={1} />
              <stop offset="50%" stopColor="#FFE975" stopOpacity={1} />
              <stop offset="100%" stopColor="#00FFB2" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis hide={true} dataKey="x" tick={<CustomAxisTick />} />
          <YAxis tick={<CustomAxisTick isY />} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Area
            type="monotone"
            dataKey="y"
            stroke="url(#colorUv)"
            strokeWidth={isHovered ? 3 : 2}
            fill="url(#colorUv)"
            fillOpacity={0.1}
            activeDot={<ActiveDot />}
          />
        </AreaChart>
      </ResponsiveContainer>
      <Card
        style={{
          display: "flex",
          width: "193px",
          height: "330px",
          padding: "20px",
          flexDirection: "column",
          // alignItems: 'flex-start',
          gap: "20px",
          borderRadius: "8px",
          border: "1px solid rgba(92, 117, 125, 0.20)",
          background: "#F3F6FD",
        }}
      >
        {/*<Box style={{*/}
        {/*    color: '#002733',*/}
        {/*    textAlign: 'left',*/}
        {/*    fontFamily: 'Inter',*/}
        {/*    padding: '4px',*/}
        {/*}}>*/}
        {/*    <Box style={{*/}
        {/*        fontSize: '14px',*/}
        {/*        fontStyle: 'normal',*/}
        {/*        fontWeight: 400,*/}
        {/*        lineHeight: '19px' // 135.714%*/}
        {/*    }}>*/}
        {/*        Loss Estimation*/}
        {/*    </Box>*/}
        {/*    <Box style={{*/}
        {/*        fontSize: '24px',*/}
        {/*        fontStyle: 'normal',*/}
        {/*        fontWeight: 600,*/}
        {/*        lineHeight: '44px' // 137.5%*/}
        {/*    }}>*/}
        {/*        $40M*/}
        {/*    </Box>*/}
        {/*</Box>*/}

        {/*<Box style={{ width: '100%' }}>*/}
        {/*    <Divider sx={{ opacity: 0.1, backgroundColor: '#002733', height: '1px', width: '100%' }} />*/}
        {/*</Box>*/}
        {/*<Box style={{*/}
        {/*    color: '#002733',*/}
        {/*    textAlign: 'left',*/}
        {/*    fontFamily: 'Inter',*/}
        {/*    padding: '4px',*/}
        {/*}}>*/}
        {/*    <Box style={{*/}
        {/*        color: '#002733',*/}
        {/*        textAlign: 'center',*/}
        {/*        fontFamily: 'Inter',*/}
        {/*        fontSize: '14px',*/}
        {/*        fontStyle: 'normal',*/}
        {/*        fontWeight: 400,*/}
        {/*        lineHeight: '19px',*/}
        {/*    }}>*/}
        {/*        Likelihood*/}
        {/*    </Box>*/}
        {/*    <Box style={{ display: 'flex', alignItems: 'baseline' }}>*/}
        {/*        <Box style={{*/}
        {/*            color: '#002733',*/}
        {/*            fontFamily: 'Inter',*/}
        {/*            fontSize: '24px',*/}
        {/*            fontStyle: 'normal',*/}
        {/*            fontWeight: 600,*/}
        {/*            lineHeight: '33px' // 137.5%*/}
        {/*        }}>*/}
        {/*            30*/}
        {/*        </Box>*/}
        {/*        <Box style={{*/}
        {/*            color: '#002733',*/}
        {/*            fontFamily: 'Inter',*/}
        {/*            fontSize: '20px',*/}
        {/*            fontStyle: 'normal',*/}
        {/*            fontWeight: 600,*/}
        {/*            lineHeight: '27px'*/}
        {/*        }}>*/}
        {/*            %*/}
        {/*        </Box>*/}
        {/*    </Box>*/}
        {/*</Box>*/}
        {/*<Box style={{*/}
        {/*    color: '#002733',*/}
        {/*    textAlign: 'left',*/}
        {/*    fontFamily: 'Inter',*/}
        {/*    padding: '4px',*/}
        {/*}}>*/}
        {/*    <Box style={{*/}
        {/*        color: '#002733',*/}
        {/*        textAlign: 'center',*/}
        {/*        fontFamily: 'Inter',*/}
        {/*        fontSize: '14px',*/}
        {/*        fontStyle: 'normal',*/}
        {/*        fontWeight: 400,*/}
        {/*        lineHeight: '19px',*/}
        {/*    }}>*/}
        {/*        Business Impact*/}
        {/*    </Box>*/}
        {/*    <Box style={{ display: 'flex', alignItems: 'baseline' }}>*/}
        {/*        <Box style={{*/}
        {/*            color: '#002733',*/}
        {/*            fontFamily: 'Inter',*/}
        {/*            fontSize: '24px',*/}
        {/*            fontStyle: 'normal',*/}
        {/*            fontWeight: 600,*/}
        {/*            lineHeight: '33px' // 137.5%*/}
        {/*        }}>*/}
        {/*            $66*/}
        {/*        </Box>*/}
        {/*        <Box style={{*/}
        {/*            color: '#002733',*/}
        {/*            fontFamily: 'Inter',*/}
        {/*            fontSize: '20px',*/}
        {/*            fontStyle: 'normal',*/}
        {/*            fontWeight: 600,*/}
        {/*            lineHeight: '27px'*/}
        {/*        }}>*/}
        {/*            M*/}
        {/*        </Box>*/}
        {/*    </Box>*/}
        {/*</Box>*/}
        <Box
          style={{
            color: "#002733",
            textAlign: "center",
            fontFamily: "Inter",
            padding: "4px",
          }}
        >
          <Box
            style={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "19px", // 135.714%
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Current <br /> Pando Score
          </Box>
          <Box
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Divider
              sx={{
                opacity: 0.1,
                backgroundColor: "#002733",
                height: "1px",
                width: "100%",
              }}
            />
          </Box>
          <Box
            style={{
              marginTop: "60px",
            }}
          >
            <Speedometer value={speedometerValue} />
          </Box>
        </Box>
      </Card>
    </Container>
  );
}

export default GradientAreaChart;

// You'll need to define or import Container, Card, and Box as per your project's design system.
