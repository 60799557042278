export const ConditionOptions = [
  {
    label: "IS",
    value: "is",
  },
  {
    label: "IS-NOT",
    value: "is not",
  },
];

export const FilterConditions = {
  IS: "is",
  IS_NOT: "is not",
  CONTAINS: "contains",
};
