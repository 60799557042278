import { createRef, Fragment, useRef, useState } from "react";
import { InventoryTableActionButton } from "../../pages/inventory/components/ActionButton/index";
import { SelectOptionsMenu } from "../SelectOptionsMenu";
import LayersIcon from "@mui/icons-material/Layers";

const ref = createRef();

export const TableGroupingSelector = ({
  checkOptionIsDisabled,
  schema,
  data,
  setData,
}) => {
  const [open, setOpen] = useState(false);
  const menuTwoWayBindingRef = useRef(null);

  const handleOpenSelector = () => {
    menuTwoWayBindingRef.current.onOpenMenu?.();
    setOpen(!open);
  };

  const handleOnClose = () => {
    menuTwoWayBindingRef.current.onClose?.();
    setOpen(false);
  };

  return (
    <Fragment>
      <InventoryTableActionButton
        ref={ref}
        actionCount={data.length}
        tooltipProps={{
          show: true,
          title: "Group Assets",
        }}
        onClick={handleOpenSelector}
        icon={<LayersIcon />}
      />
      <SelectOptionsMenu
        isMulti={false}
        opened={open}
        title='Grouping'
        anchorEl={ref.current}
        onClose={handleOnClose}
        twoWayBindingRef={menuTwoWayBindingRef}
        elements={{
          selectedValues: true,
          footer: true,
        }}
        values={data}
        setValues={setData}
        data={schema}
        checkOptionIsDisabled={checkOptionIsDisabled}
      />
    </Fragment>
  );
};
