import { useMaterialReactTable } from "material-react-table";
import { useGroupingTableState } from "./useGroupingTableState";
import { useTableColumns } from "./useTableColumns";
import { useFetchGroupingTableData } from "./useFetchGrouingTableData";
import { useGroupingTablePagination } from "./useGroupingTablePaginationState";
import { useAssetsTableMethods } from "./useTableMethods";
import { checkTableIPaginationIsFirstRender } from "../../../../containers/inventory/AssetsTable/utils/checkTableIPaginationIsFirstRender";

export const useGroupingTable = ({ ...props }) => {
  const { data, isLoading, meta } = useGroupingTableState();
  const columns = useTableColumns();
  const fetch = useFetchGroupingTableData();
  const [pagination, setPagination] = useGroupingTablePagination();
  const { onClickAsset } = useAssetsTableMethods();

  const handleOnPaginationChange = (setter) => {
    const newPagination = setter(pagination);
    const isFirstRender = checkTableIPaginationIsFirstRender(
      pagination,
      newPagination
    );
    if (isFirstRender) return;
    const payload = {
      pagination: newPagination,
    };
    setPagination(newPagination);
    fetch(payload);
  };

  const onClickRow = (ev, row) => {
    onClickAsset(row.original);
  };

  return useMaterialReactTable({
    data,
    columns,
    state: {
      isLoading,
      pagination,
    },
    onPaginationChange: handleOnPaginationChange,
    rowCount: meta?.total ?? 0,
    paginationDisplayMode: "pages",
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePagination: true,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    layoutMode: "grid",
    manualPagination: true,
    muiTableBodyRowProps({ row }) {
      return {
        onClick: (ev) => onClickRow(ev, row),
        sx: {
          cursor: "pointer",
        },
      };
    },
    muiTableContainerProps: {
      sx: {
        height: "calc(100% - 72px)",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        height: "40px",
      },
    },
    ...props,
  });
};
