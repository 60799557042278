export const DefaultCMSTIssuesTableColumns = Object.freeze([
  {
    label: "data.id",
    value: "data.id",
    source: "platform",
    valueTypes: ["str"],
    id: "options-platform.data.id",
    checked: false,
  },
  {
    label: "data.description",
    value: "data.description",
    source: "platform",
    valueTypes: ["str"],
    id: "options-platform.data.description",
    checked: false,
  },
  {
    label: "data.reporter.name",
    value: "data.reporter.name",
    source: "platform",
    valueTypes: ["str"],
    id: "options-platform.data.reporter.name",
    checked: false,
  },
  {
    label: "data.summary",
    value: "data.summary",
    source: "platform",
    valueTypes: ["str"],
    id: "options-platform.data.summary",
    checked: false,
  },
  {
    label: "data.platform_summary.security_relevance_score",
    value: "data.platform_summary.security_relevance_score",
    source: "platform",
    valueTypes: ["float"],
    id: "options-platform.data.platform_summary.security_relevance_score",
    checked: false,
    disabled: false,
  },
]);
