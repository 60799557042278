import { useFetchGroupingTableData } from "./useFetchGrouingTableData";
import { useFetchPrimaryTableData } from "./useFetchPrimaryTableData";
import { useIsGrouping } from "./useIsGrouping";

export function useSearch() {
  const isGrouping = useIsGrouping();
  const fetchGroupingData = useFetchGroupingTableData();
  const fetchPrimaryData = useFetchPrimaryTableData();

  const func = ({ query }) => {
    if (isGrouping) {
      fetchGroupingData({ search: query });
    } else {
      fetchPrimaryData({ search: query });
    }
  };
  return func;
}
