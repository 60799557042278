import axios from "axios";
import { validateApiClientOptions } from "./validateApiClientOptions";
import { getTokenBeforeRequestInterceptorFactory } from "./getTokenBeforeRequestInterceptor";

function Client(url, organizationId) {
  const api = axios.create({
    baseURL: url,
    headers: {
      "Content-Type": "application/json",
      "X-ORG-ID": organizationId,
    },
  });
  api.getPayload = () => {
    return { url, organizationId, key: organizationId };
  };
  return api;
}

function registerClientInterceptors(
  client,
  requestInterceptor,
  responseInterceptor
) {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(responseInterceptor);
}

export const ApiClient = (function () {
  let client;

  let _clientPayload = {
    url: "",
    organizationId: "",
    isInitialized: false,
    options: {
      registerInterceptors: false,
    },
    dependencies: {
      authManager: null,
      requestInterceptor: null,
      responseInterceptor: null,
    },
  };

  const getInstance = () => {
    if (!_clientPayload.isInitialized) {
      console.log(
        "Api client not initialized. Initialize client before getting instance"
      );
      return null;
    }
    if (!client) {
      client = new Client(_clientPayload.url, _clientPayload.organizationId);
      if (_clientPayload.options.registerInterceptors) {
        const getTokenBeforeRequestInterceptor =
          getTokenBeforeRequestInterceptorFactory(
            _clientPayload.dependencies.authManager
          );
        _clientPayload.dependencies.requestInterceptor =
          getTokenBeforeRequestInterceptor;

        registerClientInterceptors(
          client,
          _clientPayload.dependencies.requestInterceptor
        );
      }
    }
    return client;
  };

  const setAuthManager = (manager) => {
    _clientPayload.dependencies.authManager = manager;
  };

  const init = (url, organizationId, options = {}) => {
    const { isValid } = validateApiClientOptions(options);
    if (isValid) {
      _clientPayload.options = { ..._clientPayload.options, ...options };
    }
    _clientPayload.url = url;
    _clientPayload.organizationId = organizationId;
    _clientPayload.isInitialized = true;
  };

  return {
    getInstance,
    init,
    setAuthManager,
  };
})();
