import React from "react";
import { Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CardContainer,
  CardTopSection,
  ComingSoon,
  ConnectButton,
  ConnectedChipContainer,
  DeleteButton,
  Footer,
  ImageContainer,
} from "./styled";
import ConditionComponent from "../../../utils/ConditionComponent";
import { useUserInfo } from "../../../hooks/useAppUser";

const IntegrationCard = ({
  isConnected,
  isComingSoon,
  onClick,
  onDelete,
  data,
}) => {
  const { name, logo } = data;
  const { role } = useUserInfo();
  const IS_ADMIN = role === "admin";

  const node = (
    <ConditionComponent
      condition={!isComingSoon}
      falsyNode={<ComingSoon>Coming Soon</ComingSoon>}
    >
      <ConnectButton onClick={onClick}>Connect</ConnectButton>
    </ConditionComponent>
  );

  return (
    <CardContainer>
      <CardTopSection>
        <ImageContainer>
          <img
            src={logo}
            style={{ width: 32, borderRadius: "4px" }}
            alt={`${name} logo`}
          />
        </ImageContainer>
        <Typography variant='body1'>{name}</Typography>
      </CardTopSection>
      <Footer>
        <ConditionComponent condition={isConnected} falsyNode={node}>
          <ConnectedChipContainer>Connected</ConnectedChipContainer>
          <ConditionComponent condition={IS_ADMIN}>
            <DeleteButton onClick={(ev) => onDelete(ev, data)}>
              <DeleteIcon sx={{ fontSize: "20px !important" }} />
            </DeleteButton>
          </ConditionComponent>
        </ConditionComponent>
      </Footer>
      {/* < */}
    </CardContainer>
  );
  // );
};

export default IntegrationCard;
