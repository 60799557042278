export async function copyToClipboard(val, onFailure) {
  const customErrorHandling = (err) => {
    console.error(`Failed to copy text ${val} to clipboard`, err);
  };

  try {
    await navigator.clipboard.writeText(val);
  } catch (error) {
    onFailure?.(error) ?? customErrorHandling(error);
  }
}
