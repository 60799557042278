export function Service(_apiClient) {
  let apiClient = _apiClient;

  this.getQueries = async () => {
    const path = "/center/inventory/queries";
    const {
      data: { limit, page, queries: data, total },
    } = await apiClient.get(path, { isProtected: true });
    return {
      data,
      meta: {
        token: true,
        limit,
        page,
        total,
      },
    };
  };

  this.getAssetOptions = async () => {
    const path = "/inventory/schema";
    const response = await apiClient.get(path, { isProtected: true });
    return response.data;
  };

  this.getActions = async (payload) => {
    const { pagination } = payload;

    const path = `/center/inventory/rules?page=${
      pagination.pageIndex + 1
    }&limit=${pagination.pageSize}`;

    const { data } = await apiClient.get(path, { isProtected: true });
    const { rules, total, limit, page } = data;
    return { data: rules, meta: { total, limit, page } };
  };

  this.createQuery = async (payload) => {
    const path = `/center/inventory/queries`;
    await apiClient.post(path, payload, { isProtected: true });
  };

  this.getQueries = async (payload) => {
    const { pagination } = payload;

    const path = `/center/inventory/queries?page=${
      pagination.pageIndex + 1
    }&limit=${pagination.pageSize}`;

    const { data } = await apiClient.get(path, { isProtected: true });
    const { queries, total, limit, page } = data;
    return { data: queries, meta: { total, limit, page } };
  };

  this.editQuery = async (payload) => {
    const path = `/center/inventory/queries/${payload.query_uuid}`;
    delete payload.table_name;
    delete payload.query_uuid;
    delete payload.created_by;
    await apiClient.put(path, payload, { isProtected: true });
  };

  this.deleteQuery = async (payload) => {
    const path = `/center/inventory/queries/${payload.queryUuid}`;
    await apiClient.delete(path, { isProtected: true });
  };

  this.createAction = async (payload) => {
    const path = `/center/inventory/rules`;
    await apiClient.post(path, payload, { isProtected: true });
  };

  this.getActions = async (payload) => {
    const { pagination } = payload;

    const path = `/center/inventory/rules?page=${
      pagination.pageIndex + 1
    }&limit=${pagination.pageSize}`;

    const { data } = await apiClient.get(path, { isProtected: true });
    const { rules, total, limit, page } = data;
    return { data: rules, meta: { total, limit, page } };
  };

  this.editAction = async (payload) => {
    const path = `/center/inventory/rules/${payload.ruleUuid}`;
    await apiClient.put(path, payload, { isProtected: true });
  };

  this.deleteAction = async (payload) => {
    const path = `/center/inventory/rules/${payload.ruleUuid}`;
    await apiClient.delete(path, { isProtected: true });
  };

  return this;
}
