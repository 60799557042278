export function getTokenBeforeRequestInterceptorFactory(authManager) {
  return async function (config = {}) {
    if (config.isProtected) {
      const token = await authManager.getToken();
      const updatedConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
      return updatedConfig;
    }
  };
}
