import { useMemo, useState } from "react";
import {
  AssetInfoContainer,
  CopyValueButton,
  InfoLabel,
  InfoValueContainer,
  NAValue,
  InfoValue as StyledInfoValue,
} from "./styled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ConditionComponent from "../../../../utils/ConditionComponent";
import CheckIcon from "@mui/icons-material/Check";
import { checkValueIsPrimitive } from "../../../../utils/typeUtils/valueTypeCheckerUtils";
import { useCopyToClipboard } from "../../../../hooks/useCopyToClipboard";

export const InfoValue = ({
  value,
  label,
  labelContainer,
  container,
  children,
  copyToClipboardValue,
}) => {
  const [hoverActive, setHoverActive] = useState(false);

  const clipboardValue = useMemo(() => {
    let valueToCopy = copyToClipboardValue;
    if (value && checkValueIsPrimitive(value)) valueToCopy = value;
    return valueToCopy;
  }, [copyToClipboardValue, value]);
  const { copied, onClickCopy } = useCopyToClipboard({ value: clipboardValue });

  const handleOnMouseEnter = () => setHoverActive(true);
  const handleOnMouseLeave = () => setHoverActive(false);

  const LabelContainer = useMemo(() => {
    if (!labelContainer) return InfoLabel;
    return labelContainer;
  }, [labelContainer]);

  const Container = useMemo(() => {
    if (!container) return AssetInfoContainer;
    return container;
  }, [container]);

  return (
    <Container
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <LabelContainer>{label}</LabelContainer>

      <ConditionComponent condition={value && !children} falsyNode={children}>
        <InfoValueContainer>
          <ConditionComponent
            condition={value !== "N/A"}
            falsyNode={<NAValue>N/A</NAValue>}
          >
            <StyledInfoValue>{value}</StyledInfoValue>
          </ConditionComponent>
        </InfoValueContainer>
      </ConditionComponent>
      <ConditionComponent condition={(!value || !children) && hoverActive}>
        <CopyValueButton onClick={onClickCopy}>
          <ConditionComponent
            condition={copied}
            falsyNode={<ContentCopyIcon />}
          >
            <CheckIcon />
          </ConditionComponent>
        </CopyValueButton>
      </ConditionComponent>
    </Container>
  );
};
