import React, { Fragment } from "react";

import FAIRRiskForm from "../components/CRQ/FAIRRiskForm";
import CustomCard from "../components/CustomCard";
import ROSIInfoBox from "../components/CRQ/ROSIInfoBox";
import { Box, Grid } from "@material-ui/core";

const CRQ = () => (
  <Box>
    <CustomCard title={"Event Based Calculator\n"} direction="row">
      <Grid container direction="c" alignItems="stretch" spacing={2}>
        {" "}
        {/* This will make children stretch */}
        <Grid item xs={12} md={4} style={{ display: "flex" }}>
          <ROSIInfoBox />
        </Grid>
        <Grid item xs={12} md={8}>
          <FAIRRiskForm />
        </Grid>
      </Grid>
    </CustomCard>
  </Box>
);

export default CRQ;
