import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "react-collapse";
import Sidebar from "react-sidebar";
import Graph from "react-vis-network-graph";

import amazonEcr from "../assets/icons/amazon-ecr.svg";
import amazonElasticache from "../assets/icons/amazon-elasticache.svg";
import amazonLambda from "../assets/icons/amazon-lambda.svg";
import amazonSqs from "../assets/icons/amazon-sqs.svg";
import genericServer from "../assets/icons/generic-server.svg";
import amazonECS from "../assets/icons/amazon-ecs.svg";
import httpSvg from "../assets/icons/http.svg";
import amazonRDS from "../assets/icons/amazon-rds.svg";
import securityWAF from "../assets/icons/security-waf.svg";
import amazonS3 from "../assets/icons/amazon-s3.svg";
import amazonBinstalk from "../assets/icons/amazon-binstalk.svg";
import rabbitmqSvg from "../assets/icons/rabbitmq.svg";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

const PandoGraph = (properties) => {
  // filter
  const [filterValue, setFilterValue] = useState(""); // Initialize filter value
  const lowerCaseFilterValue = filterValue.toLowerCase();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isOpen, setIsOpen] = useState({});

  const [graphData, setGraphData] = useState({ nodes: [], edges: [] });
  const { title, data, width = "100%", height = "100%" } = properties;
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [combo, setCombo] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState(new Set());

  const [groupingOption, setGroupingOption] = useState("none");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [hierarchicalLayout, setHierarchicalLayout] = useState(false);

  const location = useLocation();
  // get params
  const params = new URLSearchParams(location.search);
  const param = params.get("bp");

  let features = properties.features;
  if (!features) {
    features = {};
  }

  useEffect(() => {
    // If the parameter matches one of the options, select it
    if (param) {
      setFilterValue(param);
    }
  }, []);

  const toggleLayout = () => {
    setHierarchicalLayout(!hierarchicalLayout);
  };

  const toggleCollapse = (id) => {
    setIsOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const toggle = () => setModal(!modal);

  const handleDoubleClickNode = (params) => {
    const nodeId = params.nodes[0];
    if (nodeId) {
      setExpandedNodes((prevExpandedNodes) => {
        const newExpandedNodes = new Set(prevExpandedNodes);
        if (newExpandedNodes.has(nodeId)) {
          newExpandedNodes.delete(nodeId);
        } else {
          newExpandedNodes.add(nodeId);
        }
        return newExpandedNodes;
      });
    }
  };

  const getBusinessProcess = (node) => {
    if (node.businessProcess) {
      return node.businessProcess.replaceAll("Wallet Management", "");
    }

    return "Undefined";
  };

  const getEntityTypeImage = (entityDeploy) => {
    switch (entityDeploy) {
      case "lambda":
        return amazonLambda;
      case "Queue":
        return amazonSqs;
      case "http":
        return httpSvg;
      case "ecr_images":
        return amazonEcr;
      case "elasticache":
        return amazonElasticache;
      case "ecs":
        return amazonECS;
      case "rds":
        return amazonRDS;
      case "elasticbeanstalk":
        return amazonBinstalk;
      case "sqs":
        return amazonSqs;
      case "saas":
        return rabbitmqSvg;
      case "waf":
        return securityWAF;
      case "s3":
        return amazonS3;

      default:
        return genericServer;
    }
  };

  const suggestedValues = data.reduce((values, { source, target }) => {
    const uniqueValues = new Set(values);

    if (source) {
      if (source["name"]) uniqueValues.add(source["name"]);
      if (source["id"]) uniqueValues.add(source["id"]);
      uniqueValues.add(source["provider"] || "AWS");
      uniqueValues.add(source["target"] || "AWS");

      if (source["commiters"]) {
        try {
          const parsed = JSON.parse(source["commiters"]);
          Object.keys(parsed).forEach((key) => uniqueValues.add(key));
        } catch (e) {
          console.error("Error parsing source committers", e);
        }
      }

      if (
        source["identifiers"] &&
        source["identifiers"].length > 0 &&
        typeof source["identifiers"][0] === "string"
      ) {
        const splits = source["identifiers"][0].split(":");
        if (splits.length > 4) uniqueValues.add(splits[4]);
        if (splits.length > 3) uniqueValues.add(splits[3]);
      }

      if (source["OperationalOwner"])
        uniqueValues.add(source["OperationalOwner"]);
      if (source["BusinessOwner"]) uniqueValues.add(source["BusinessOwner"]);
      if (source["BusinessProcess"])
        uniqueValues.add(source["BusinessProcess"]);
      if (source["type"]) uniqueValues.add(source["type"]);
    }

    if (target) {
      if (target["name"]) uniqueValues.add(target["name"]);
      if (target["id"]) uniqueValues.add(target["id"]);

      if (target["commiters"]) {
        try {
          const parsed = JSON.parse(target["commiters"]);
          Object.keys(parsed).forEach((key) => uniqueValues.add(key));
        } catch (e) {
          console.error("Error parsing target committers", e);
        }
      }

      if (
        target["identifiers"] &&
        target["identifiers"].length > 0 &&
        typeof target["identifiers"][0] === "string"
      ) {
        const splits = target["identifiers"][0].split(":");
        if (splits.length > 4) uniqueValues.add(splits[4]);
        if (splits.length > 3) uniqueValues.add(splits[3]);
      }

      if (target["OperationalOwner"])
        uniqueValues.add(target["OperationalOwner"]);
      if (target["BusinessOwner"]) uniqueValues.add(target["BusinessOwner"]);
      if (target["BusinessProcess"])
        uniqueValues.add(target["BusinessProcess"]);
      if (target["type"]) uniqueValues.add(target["type"]);
    }

    return Array.from(uniqueValues);
  }, []);

  useEffect(() => {
    const nodesMap = new Map();
    const edges = [];
    let c = 0;
    data.forEach(
      ({ source, target, type, source_vul_count, target_vul_count }) => {
        if (source) {
          if (
            source["operational_owner"] &&
            source["operational_owner"] != "Unknown"
          ) {
          }

          if (!nodesMap.has(source["id"])) {
            nodesMap.set(source["id"], {
              id: source["id"] || "None",
              title: source["name"],
              provider: source["provider"] || "AWS",
              region: source["region"],
              deployment: source["deployment"],
              commiters: source["commiters"],
              accountNumber: source["account_id"],
              serviceRegion: source["region"],
              label: source["name"],
              group: source["type"],
              operationalOwner: source["operational_owner"],
              businessOwner: source["BusinessOwner"],
              businessProcess:
                source["BusinessProcess"] ||
                source["businessProcess"] ||
                source["business_process"],
              shape: "image",
              image: getEntityTypeImage(source["deployment"] || source["type"]),
              outsideLabel: source_vul_count,
            });
          }
        }

        if (target) {
          if (!nodesMap.has(target["id"])) {
            nodesMap.set(target["id"], {
              id: target["id"] || "None",
              label: target["name"],
              group: target["type"],
              accountNumber: target["account_id"],
              serviceRegion: target["region"],
              provider: target["provider"] || "AWS",
              region: target["region"],
              deployment: target["deployment"],
              commiters: target["commiters"],
              operationalOwner: target["operational_owner"],
              businessOwner: target["BusinessOwner"],
              businessProcess:
                target["BusinessProcess"] ||
                target["businessProcess"] ||
                target["business_process"],
              shape: "image",
              image: getEntityTypeImage(target["deployment"] || target["type"]),
              outsideLabel: target_vul_count,
            });
          }
        }

        if (source && target) {
          edges.push({
            from: source["id"],
            to: target["id"],
            label: groupingOption !== "none" ? "" : type,
          });
        } else {
          console.log("Source or target is null");
        }
      },
    );

    let nodesArray = [...nodesMap.values()];

    if (filterValue !== "") {
      nodesArray = nodesArray.filter((node) =>
        Object.values(node).some(
          (value) =>
            value !== undefined &&
            value !== null &&
            value.toString().toLowerCase().includes(lowerCaseFilterValue),
        ),
      );
    }

    if (groupingOption !== "none") {
      const groupedNodes = [];
      const groupedEdges = [];

      nodesMap.forEach((node) => {
        const comboId = node[groupingOption];
        const comboNodeIndex = groupedNodes.findIndex((n) => n.id === comboId);

        if (comboNodeIndex === -1) {
          groupedNodes.push({
            ...node,
            id: comboId,
            label: `${comboId}`,
            longLabel: `${node.label}`,
            count: 1,
          });
        } else {
          groupedNodes[comboNodeIndex].longLabel += `, ${node.label}`;
          groupedNodes[comboNodeIndex].count++;
        }
      });

      edges.forEach((edge) => {
        const sourceComboId = nodesMap.get(edge.from)[groupingOption];
        const targetComboId = nodesMap.get(edge.to)[groupingOption];

        if (sourceComboId !== targetComboId) {
          groupedEdges.push({
            ...edge,
            from: sourceComboId,
            to: targetComboId,
          });
        }
      });

      setGraphData({
        nodes: groupedNodes,
        edges: groupedEdges,
      });
    } else {
      setGraphData({ nodes: nodesArray, edges });
    }
  }, [data, groupingOption, filterValue]);

  const graphRef = useRef();

  const handleAfterDrawing = (ctx) => {
    if (graphRef.current && graphRef.current.Network) {
      graphData.nodes.forEach((node) => {
        // if (!node.outsideLabel) {
        //   return;
        // }
        const position = graphRef.current.Network.getPositions([node.id])[
          node.id
        ];

        if (node.outsideLabel === undefined || node.outsideLabel === 0) {
          return;
        }

        ctx.beginPath();
        ctx.arc(position.x + 25, position.y - 25, 14.4, 0, 2 * Math.PI, false); // 72.5 * 0.8 = 58
        ctx.fillStyle = "#F91F53";
        ctx.fill();
        ctx.stroke();

        ctx.font = "16px Inter";
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.fillText(node.outsideLabel, position.x + 25, position.y - 21);
      });
    }
  };

  const options = {
    interaction: {
      hover: true,
      tooltipDelay: 300000, // Dont shop popup when node is hover
      navigationButtons: true,
    },
    nodes: {
      size: 40,
      color: {
        background: "rgba(0,0,0,0)",
      },
      scaling: {
        min: 5,
        max: 30,
      },
      font: {
        size: 15,
        face: "Tahoma",
      },
    },
    edges: {
      arrows: {
        to: {
          enabled: true,
          type: "arrow",
        },
      },
      width: 1.15,
      dashes: true,
      smooth: {
        type: "continuous",
      },
      font: {
        size: 10,
      },
      color: "gray",
    },
    layout: hierarchicalLayout
      ? {
          hierarchical: {
            enabled: hierarchicalLayout,
            direction: "UD",
            sortMethod: "directed",
            nodeSpacing: 350,
            levelSeparation: 300,
          },
        }
      : {
          improvedLayout: true,
        },
    physics: hierarchicalLayout
      ? {
          enabled: false,
        }
      : {
          stabilization: {
            enabled: true,
            iterations: 5000,
            updateInterval: 25,
          },
          barnesHut: {
            gravitationalConstant: -15000,
            springConstant: 0.01,
            springLength: 295,
          },
        },
  };

  const style = {
    height: "100%",
    width: "100%",
    border: "2px solid lightgray",
    backgroundColor: "#EEEEEE",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };
  const styleContainerGraph = {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  };

  const [popup, setPopup] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });
  const containerRef = useRef();

  const events = {
    select: function (event) {
      var { nodes, edges } = event;
      if (nodes.length === 0) {
        return;
      }
      setSelectedNode(nodes[0]);
      onSetSidebarOpen(true);

      toggle();
      setModalTitle(nodes[0]);

      const node = graphData.nodes.find((node) => node.id === nodes[0]);
      setModalBody(
        <div>
          <div>Entity Type: {node.group}</div>
          <div>Entity Name: {node.label}</div>
          <div>Entity ID: {node.id}</div>
          <div>Account: {node.accountNumber}</div>
          <div>Operational Owner: {node.operationalOwner}</div>
          <div>Business Owner: {node.businessOwner}</div>
          <div>Business Process: {node.businessProcess}</div>
        </div>,
      );
    },
    afterDrawing: handleAfterDrawing,
    // hoverNode: handleHoverNode,
    // blurNode: handleBlurNode,
    // doubleClick: handleDoubleClickNode,
  };

  // Change handler for the filter input
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const getOperationOwner = (node) => {
    if (node.operationalOwner) {
      return node.operationalOwner;
    }

    if (node.commiters) {
      let data = JSON.parse(node.commiters);
      return Object.keys(data).join(", ");
    }

    return "Undefined";
  };

  return (
    <div ref={containerRef} style={styleContainerGraph}>
      <div className="controls-container-graph">
        <input
          autoComplete="off"
          list="suggestedValues"
          type="text"
          placeholder="Filter entities..."
          value={filterValue}
          onChange={handleFilterChange}
          style={{
            backgroundColor: "#F5F5F5",
            color: "#333333",
            border: "1px solid #CCCCCC",
            borderRadius: "5px",
            padding: "8px 40px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            fontWeight: "600",
            fontSize: "14px",
            transition: "background-color 0.2s ease-in-out",
          }}
        />
        <datalist id="suggestedValues">
          {suggestedValues.map((value, index) => (
            <option value={value} key={index} />
          ))}
        </datalist>

        {popup.visible && (
          <>
            <div
              style={{
                position: "absolute",
                left: popup.x + "px",
                top: popup.y + "px",
                backgroundColor: "rgba(255, 255, 255, 1)",
                padding: "10px",
                borderRadius: "5px",
                boxShadow:
                  "0 10px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
                border: "1px solid #d4d4d4",
                fontFamily: "Arial, sans-serif",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#333333",
                whiteSpace: "nowrap",
                zIndex: 1000,
                overflow: "auto",
              }}
            >
              {popup.content}
            </div>
          </>
        )}
        <div className="search-container">
          <button
            style={{
              backgroundColor: "#F5F5F5",
              color: "#333333",
              border: "1px solid #CCCCCC",
              borderRadius: "5px",
              padding: "8px 40px",
              marginLeft: "10px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              fontWeight: "600",
              fontSize: "14px",
              transition: "background-color 0.2s ease-in-out",
            }}
            onClick={toggleLayout}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#E0E0E0")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#F5F5F5")}
          >
            {hierarchicalLayout ? "Default Layout" : "Hierarchical Layout"}
          </button>
        </div>
      </div>
      <div className="h5">{title}</div>
      <div className="graph-container">
        <Sidebar
          sidebar={
            <div>
              {selectedNode && (
                <>
                  {graphData.nodes.map((node) => (
                    <div key={node.id}>
                      {node.id === selectedNode && (
                        <>
                          <Box padding="28px" alignItems="flex-start">
                            <h1
                              style={{
                                color: "var(--dark-blue, #002733)",
                                fontFamily: "Inter",
                                fontSize: "32px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "44px",
                              }}
                            >
                              {node.label?.split("/")[1] || node.label}
                            </h1>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            padding="28px"
                            alignItems="flex-start" // align items to the start
                            borderTop="0.5px solid var(--light-gray, #CCC)"
                          >
                            <h1
                              onClick={() =>
                                toggleCollapse(`profile_${node.id}`)
                              }
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              Entity Profile
                              {!isOpen[`profile_${node.id}`] ? (
                                <ExpandLessIcon style={{ marginLeft: "5px" }} />
                              ) : (
                                <ExpandMoreIcon style={{ marginLeft: "5px" }} />
                              )}
                            </h1>
                            <Collapse
                              isOpened={!isOpen[`profile_${node.id}`] || false}
                            >
                              <div>
                                Provider: {node.provider || "Undefined"}
                              </div>
                              <div>Region: {node.region || "Undefined"}</div>
                              <div>
                                Deployment: {node.deployment || "Undefined"}
                              </div>
                              <div>
                                Account Number:{" "}
                                {node.accountNumber || "Undefined"}
                              </div>
                              <div>
                                Entity Type:{" "}
                                {node.group === "ecr_images"
                                  ? "Application"
                                  : node.group === "elasticcache"
                                    ? "DataSource"
                                    : node.group === "http"
                                      ? "ExternalAPI"
                                      : node.group}
                              </div>
                              <div>Entity ID: {node.id || "Undefined"}</div>
                            </Collapse>
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="column"
                            padding="28px"
                            alignItems="flex-start" // align items to the start
                            borderTop="0.5px solid var(--light-gray, #CCC)"
                          >
                            <h1
                              onClick={() =>
                                toggleCollapse(`operational_${node.id}`)
                              }
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              Operational Overview
                              {!isOpen[`operational_${node.id}`] ? (
                                <ExpandLessIcon style={{ marginLeft: "5px" }} />
                              ) : (
                                <ExpandMoreIcon style={{ marginLeft: "5px" }} />
                              )}
                            </h1>
                            <Collapse
                              isOpened={
                                !isOpen[`operational_${node.id}`] || false
                              }
                            >
                              <div
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                <div>
                                  Operational Owner: {getOperationOwner(node)}
                                </div>
                                {features["COMMITTERS"] && (
                                  <div>
                                    Committers:
                                    {node.commiters ? (
                                      <ul>
                                        {Object.entries(
                                          JSON.parse(node.commiters),
                                        )
                                          .map(([commiter, details]) => {
                                            // Convert "first_commit" and "last_commit" to Date objects and shorten them
                                            const firstCommitDate = new Date(
                                              details.first_commit,
                                            );
                                            const lastCommitDate = new Date(
                                              details.last_commit,
                                            );
                                            details.first_commit =
                                              firstCommitDate
                                                .toISOString()
                                                .slice(0, 10);
                                            details.last_commit = lastCommitDate
                                              .toISOString()
                                              .slice(0, 10);
                                            return [commiter, details];
                                          })
                                          .sort((a, b) => {
                                            // Sort by "last_commit" in descending order
                                            const dateA = new Date(
                                              a[1]["last_commit"],
                                            );
                                            const dateB = new Date(
                                              b[1]["last_commit"],
                                            );
                                            return dateB - dateA;
                                          })
                                          .map(([commiter, details]) => (
                                            <li key={commiter}>
                                              {commiter} - {details.count}{" "}
                                              Commits
                                              <br />
                                              Last Commit: {details.last_commit}
                                              <br />
                                              First Commit:{" "}
                                              {details.first_commit}
                                            </li>
                                          ))}
                                      </ul>
                                    ) : (
                                      "Undefined"
                                    )}
                                  </div>
                                )}
                              </div>
                            </Collapse>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            padding="28px"
                            alignItems="flex-start" // align items to the start
                            borderTop="0.5px solid var(--light-gray, #CCC)"
                          >
                            <h1
                              onClick={() =>
                                toggleCollapse(`business_${node.id}`)
                              }
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              Business Overview
                              {!isOpen[`business_${node.id}`] ? (
                                <ExpandLessIcon style={{ marginLeft: "5px" }} />
                              ) : (
                                <ExpandMoreIcon style={{ marginLeft: "5px" }} />
                              )}
                            </h1>
                            <Collapse
                              isOpened={!isOpen[`business_${node.id}`] || false}
                            >
                              <div>
                                Business Owner:{" "}
                                {node.businessOwner || "Undefined"}
                              </div>
                              <div>
                                Business Process: {getBusinessProcess(node)}
                              </div>
                            </Collapse>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            padding="28px"
                            alignItems="flex-start" // align items to the start
                            borderTop="0.5px solid var(--light-gray, #CCC)"
                          >
                            <h1
                              onClick={() =>
                                toggleCollapse(`vulnerability_${node.id}`)
                              }
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              Security Issues
                              {!isOpen[`vulnerability_${node.id}`] ? (
                                <ExpandLessIcon style={{ marginLeft: "5px" }} />
                              ) : (
                                <ExpandMoreIcon style={{ marginLeft: "5px" }} />
                              )}
                            </h1>
                            <Collapse
                              isOpened={
                                !isOpen[`vulnerability_${node.id}`] || false
                              }
                            >
                              <div>{node.outsideLabel} Vulnerabilities</div>
                              <div>
                                <a
                                  target="blank"
                                  hidden={!node.label}
                                  href={`/vulnerability?entity=${node.label}`}
                                >
                                  Show
                                </a>
                              </div>
                            </Collapse>
                          </Box>
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          }
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={{
            sidebar: {
              width: "20%",
              minWidth: "400px",
              height: "100vh",
              flexShrink: 0,
              fontFamily: "Inter",
              background: "var(--white, #FFF)",
              boxShadow: "10px 0px 20px 0px rgba(0, 39, 51, 0.10)",
              overflowWrap: "break-word", // Wrap long text
            },
          }}
          pullRight={true}
        >
          <Graph
            key={hierarchicalLayout ? "hierarchical" : "default"}
            ref={graphRef}
            graph={graphData}
            options={options}
            style={style}
            events={events}
          />
        </Sidebar>
      </div>
    </div>
  );
};

export default PandoGraph;
