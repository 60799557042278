import { createContext } from "react";
import { AssetManagementConfig } from "../../../../app/asset-management/config";
import { InitialState } from "../stateManagement/initialState";

export const PrimaryTableContext = createContext({
  data: [],
  meta: {},
  isLoading: false,
  error: false,
  errorPayload: null,
  paginationConfig: {
    pageSize: AssetManagementConfig.DEFAULT_PAGE_SIZE,
    pageIndex: 0,
  },
});
export const GroupingTableContext = createContext();
export const AssetTableStateContext = createContext({
  columns: [],
  grouping: [],
  currentGroupingOption: null,
  currentGroupingOptions: [],
  schema: {},
  searchKeyword: "",
  filters: [],
});
export const MethodsContext = createContext({
  dispatch: () => null,
  fetchData: () => null,
  fetchPrimaryTableData: () => null,
  fetchGroupingTableData: () => null,
  onClickAsset: () => null,
  saveQuery: () => null,
});

export const SaveQueryPayloadContext = createContext(
  InitialState.saveQueryPayload
);
