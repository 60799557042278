import { DropdownComponent } from "./styled";
import PropTypes from "prop-types";

const RenderNothing = () => null;

const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "6px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const Dropdown = (props) => {
  return (
    <DropdownComponent
      placeholder=""
      {...props}
      styles={styles}
      components={{
        IndicatorSeparator: RenderNothing,
        ClearIndicator: RenderNothing,
      }}
    />
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  value: PropTypes.any,
};

export default Dropdown;
