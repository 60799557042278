export class JSONUtils {
  static #native = JSON;

  static stringify(value) {
    try {
      if (typeof value === "string") return value;
      const toStr = this.#native.stringify(value);
      return toStr;
    } catch (error) {
      return null;
    }
  }

  static parse(value) {
    try {
      return this.#native.parse(value);
    } catch (error) {
      return null;
    }
  }
}
