import { Actions } from "./actions";

export const Reducer = (_state, action) => {
  if (action.type === Actions.FETCH_QUERIES_PENDING) {
    const state = { ..._state };
    state.isLoading = true;
    state.error = false;
    state.errorPayload = null;
    state.data = [];
    return state;
  }
  if (action.type === Actions.FETCH_QUERIES_SUCCESS) {
    const state = { ..._state };
    state.isLoading = false;
    state.error = false;
    state.errorPayload = null;
    state.data = action.payload.data;
    state.meta = action.payload.meta;
    return state;
  }
  if (action.type === Actions.FETCH_QUERIES_ERROR) {
    const state = { ..._state };
    state.isLoading = false;
    state.error = true;
    state.errorPayload = action.payload;
    state.data = [];
    return state;
  }
  if (action.type === Actions.SET_PAGINATION) {
    const state = { ..._state };
    state.paginationConfig = action.payload;
    return state;
  }
  if (action.type === Actions.OPEN_NEW_ALERT_VIEW) {
    const state = { ..._state };
    const newAlertPayload = { ...state.newAlertPayload, data: action.payload };
    state.showNewAlertView = true;
    state.newAlertPayload = newAlertPayload;
    return state;
  }
  if (action.type === Actions.CLOSE_NEW_ALERT_VIEW) {
    const state = { ..._state };
    const newAlertPayload = { ...state.newAlertPayload, data: null };
    state.newAlertPayload = newAlertPayload;
    state.showNewAlertView = false;
    return state;
  }
  if (action.type === Actions.CREATE_ALERT_PENDING) {
    const state = { ..._state };
    const newAlertPayload = {
      ...state.newAlertPayload,
      data: null,
      isLoading: true,
      error: false,
      errorPayload: null,
    };
    state.newAlertPayload = newAlertPayload;
    return state;
  }
  if (action.type === Actions.CREATE_ALERT_SUCCESS) {
    const state = { ..._state };
    const newAlertPayload = {
      ...state.newAlertPayload,
      data: null,
      isLoading: false,
      error: false,
      errorPayload: null,
    };
    state.newAlertPayload = newAlertPayload;
    state.showNewAlertView = false;
    return state;
  }
  if (action.type === Actions.CREATE_ALERT_ERROR) {
    const state = { ..._state };
    const newAlertPayload = {
      ...state.newAlertPayload,
      data: null,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
    };
    state.newAlertPayload = newAlertPayload;
    return state;
  }

  if (action.type === Actions.SHOW_DELETE_VIEW) {
    const state = { ..._state };
    const deleteQueryPayload = {
      ...state.deleteQueryPayload,
      open: true,
      data: action.payload,
      isLoading: false,
    };
    state.deleteQueryPayload = deleteQueryPayload;
    return state;
  }

  if (action.type === Actions.SHOW_EDIT_QUERY_VIEW) {
    const state = { ..._state };
    const { anchor, ...data } = action.payload;
    const editQueryPayload = {
      ...state.editQueryPayload,
      open: true,
      data,
      isLoading: false,
      anchor,
    };
    state.editQueryPayload = editQueryPayload;
    return state;
  }

  if (action.type === Actions.CLOSE_DELETE_VIEW) {
    const state = { ..._state };
    const deleteQueryPayload = {
      ...state.deleteQueryPayload,
      open: false,
      data: null,
    };
    state.deleteQueryPayload = deleteQueryPayload;
    return state;
  }

  if (action.type === Actions.CLOSE_EDIT_QUERY_VIEW) {
    const state = { ..._state };
    const editQueryPayload = {
      ...state.editQueryPayload,
      open: false,
      data: null,
      anchor: null,
    };
    state.editQueryPayload = editQueryPayload;
    return state;
  }

  if (action.type === Actions.DELETE_QUERY_PENDING) {
    const state = { ..._state };
    const deleteQueryPayload = {
      ...state.deleteQueryPayload,
      isLoading: true,
    };
    state.deleteQueryPayload = deleteQueryPayload;
    return state;
  }

  if (action.type === Actions.EDIT_QUERY_PENDING) {
    const state = { ..._state };
    const editQueryPayload = {
      ...state.editQueryPayload,
      isLoading: true,
    };
    state.editQueryPayload = editQueryPayload;
    return state;
  }

  if (action.type === Actions.DELETE_QUERY_SUCCESS) {
    const state = { ..._state };

    const deleteQueryPayload = {
      ...state.deleteQueryPayload,
      isLoading: false,
      open: false,
      error: false,
      errorPayload: null,
    };

    state.deleteQueryPayload = deleteQueryPayload;
    return state;
  }

  if (action.type === Actions.EDIT_QUERY_SUCCESS) {
    const state = { ..._state };

    const editQueryPayload = {
      ...state.editQueryPayload,
      isLoading: false,
      open: false,
      error: false,
      errorPayload: null,
    };

    const updatedQuery = action.payload;
    const updatedData = state.data.map((query) => {
      const isEditedQuery = query.query_uuid === updatedQuery.queryUuid;
      if (isEditedQuery) return updatedQuery;
      return query;
    });
    console.log(updatedData, state.data, action.payload);
    state.data = updatedData;
    state.editQueryPayload = editQueryPayload;
    return state;
  }

  if (action.type === Actions.DELETE_QUERY_ERROR) {
    const state = { ..._state };

    const deleteQueryPayload = {
      ...state.deleteQueryPayload,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
    };

    state.deleteQueryPayload = deleteQueryPayload;
    return state;
  }

  if (action.type === Actions.EDIT_QUERY_ERROR) {
    const state = { ..._state };

    const editQueryPayload = {
      ...state.editQueryPayload,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
    };

    state.editQueryPayload = editQueryPayload;
    return state;
  }

  return { ..._state };
};
