import * as React from "react";
import SpacedTable from "../components/SpacedTable/SpacedTable";
import { Box, Divider, Menu } from "@mui/material";
import { updateCounterMeasureStatus, getCountermeasures } from "../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@material-ui/core/Typography";
import { Card, CardContent } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "reactstrap";
import { useEffect, useState } from "react";
import CustomCard from "../components/CustomCard";
import GradientLineChart from "../components/Charts/GradientAreaChart";
import Drawer from "@material-ui/core/Drawer";
import { styled } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const StatusButton = ({ value, row, updateStatus, rowId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [status, setStatus] = React.useState(value);
  const [speedometerValue, setSpeedometerValue] = React.useState(value);
  const open = Boolean(anchorEl);

  const statusLabels = {
    0: "Open",
    1: "In Progress",
    2: "Closed",
  };

  useEffect(() => {
    setStatus(value);
  }, [value]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (newStatus) => {
    updateStatus(row.original, newStatus); // Update the status externally

    setStatus(newStatus); // Update the local status state

    // Close the menu
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="status-button"
        aria-controls={open ? "status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          backgroundColor: "#000033",
          color: "#00FFB2",
          fontSize: "0.75rem",
          padding: "4px 8px",
          border: "none", // To remove any default borders
          width: "90px", // Fixed width, you can adjust as needed
          borderRadius: "4px",
        }}
      >
        {statusLabels[value]}
      </Button>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "status-button",
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick(0)}>Open</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(1)}>In Progress</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(2)}>Closed</MenuItem>
      </Menu>
    </div>
  );
};

const SpacedTableComponent = () => {
  const { getAccessTokenSilently } = useAuth0(); // Extract getAccessTokenSilently from useAuth0
  const [data, setData] = useState([]);
  const [suggested, setSuggested] = useState([]);
  const [gradientChartData, setGradientChartData] = useState([]);
  const [speedometerValue, setSpeedometerValue] = useState(0); // You can set the initial value as needed
  const [modal, setModal] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [pandoContext, setPandoContext] = useState("");
  const [relatedEntities, setRelatedEntities] = useState([]);

  const toggle = (data) => {
    setModal(!modal);

    if (data.row) {
      console.log(data);
      setDrawerTitle(data.row.original.title);
      setModalText(data.row.original.countermeasures);
      setPandoContext(data.row.original.pando_context);
      setRelatedEntities(data.row.original.related_entities);
      console.log(data.row.original.related_entities);
    }
  };

  const transformData = (apiData) => {
    return apiData.map((item) => ({
      id: item.id,
      risk: item.risk_score.toString(), // Assuming risk_score is the appropriate value
      countermeasures: item.description,
      status: item.status, // Mapping status to the corresponding action string
      done_date: item.done_date,
      title: item.title,
      related_entities: item.related_entities,
      pando_context: item.pando_context,
    }));
  };

  const groupByDateAndAverageRisk = (data, overallAverageRisk) => {
    // Sort the data by date in descending order (latest to earliest)
    const sortedData = data.sort(
      (a, b) => new Date(a.done_date) - new Date(b.done_date),
    );

    // Group by date and calculate the average risk
    const groupedByDate = sortedData.reduce((acc, item) => {
      const date = item.done_date.split(" ")[0]; // Split by space to get only the date
      if (!acc[date]) acc[date] = [];
      acc[date].push(parseFloat(item.risk));
      return acc;
    }, {});

    // Convert the grouped data into the desired format
    const result = Object.entries(groupedByDate).map(([date, risks]) => {
      const sum = risks.reduce((sum, risk) => sum + risk, 0);
      const average = sum / risks.length;
      return {
        x: date, // Use the date without time
        y: average.toFixed(1),
      };
    });

    // Append the overall average risk for the "now" date
    result.push({
      x: "Now", // Use the current date without time
      y: overallAverageRisk,
    });

    return result;
  };

  const fetchData = () => {
    getAccessTokenSilently()
      .then((token) => getCountermeasures(token))
      .then((response) => {
        const suggestedItems = response.filter(
          (item) => item.status === 0 || item.status === 1,
        );
        const executedItems = response.filter((item) => item.status === 2);

        const suggestedData = transformData(suggestedItems);
        const executedData = transformData(executedItems);

        // Calculate the overall average risk from suggestedData
        const suggestedRiskScores = suggestedData.map((item) =>
          parseFloat(item.risk),
        );
        const averageRiskScore =
          suggestedRiskScores.reduce((sum, score) => sum + score, 0) /
          suggestedRiskScores.length;
        const validAverageRiskScore = isNaN(averageRiskScore)
          ? 0
          : Number(averageRiskScore.toFixed(2)); // Convert to 2 decimal places

        // Pass the overall average risk to the grouping function
        const gradientChartData = groupByDateAndAverageRisk(
          executedData,
          validAverageRiskScore,
        );
        setGradientChartData(gradientChartData);

        setSpeedometerValue(validAverageRiskScore);
        setSuggested(suggestedData);
        setData(executedData);
      });
  };

  const updateStatus = (rowData, newStatus) => {
    getAccessTokenSilently()
      .then((token) => updateCounterMeasureStatus(token, rowData.id, newStatus))
      .then(() => {
        fetchData(); // Re-fetch data after status update
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Pando Score",
        accessor: "risk",
        Cell: ({ value }) => parseFloat(value).toFixed(1), // Convert to 2 decimal places
      },
      {
        Header: "Countermeasures",
        accessor: "countermeasures",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => (
          <StatusButton
            key={row.id}
            value={value}
            row={row}
            updateStatus={updateStatus}
            rowId={row.id}
          />
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, [getAccessTokenSilently]);

  const renderCard = (title, content) => (
    <Card
      variant="outlined"
      style={{ padding: "4px", flex: "1", margin: "8px" }}
    >
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#000033",
        }}
      >
        <Typography
          variant="body2"
          style={{
            flex: "1",
            fontSize: "50px",
            fontWeight: "bold",
            color: "#000033",
            fontFamily: "Inter",
          }}
        >
          {content}
        </Typography>
        <Typography
          variant="h8"
          style={{ flex: "1", fontSize: "14px", fontFamily: "Inter" }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Drawer
        anchor="right"
        open={modal}
        onClose={toggle}
        PaperProps={{ style: { minWidth: "400px", width: "25vw" } }}
      >
        <div>
          <IconButton onClick={toggle}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Typography style={{ padding: 8 }} variant="h4" align="left">
          {drawerTitle}
        </Typography>
        <Typography variant="body1" align="left"></Typography>
        <Box p={2}>
          <Typography variant="h6">Description</Typography>
          {/* Add your components here for the "Technical" section */}
          {modalText}
        </Box>
        <Divider></Divider>
        <Box p={2}>
          <Typography variant="h6">Pando Context</Typography>
          {/* Add your components here for the "Pando" section */}
          {pandoContext}
          <br />
          <b hidden={relatedEntities.length < 1}>
            Related entities:
            <ul>
              {relatedEntities.map((entity, index) => {
                console.log(entity);
                return (
                  <li key={index}>
                    <a href={"/graph?bp=" + entity}>{entity}</a>
                  </li>
                );
              })}
            </ul>
          </b>{" "}
          <br />
          {/*<b>Related business units:</b> <br />*/}
        </Box>
      </Drawer>
      <Box
        bgcolor="#FAFBFB"
        padding="16px"
        overflow="auto"
        width="100%"
        boxSizing="border-box"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          columnGap="16px" // Gap between cards
          marginBottom="10px" // Gap between first and second rows
        >
          {renderCard("Suggested", `${suggested.length}`)}{" "}
          {/* Display the number of suggested items */}
          {/*{renderCard('Risk Score', <Speedometer value={speedometerValue} />)}*/}
          {renderCard("Executed", `${data.length}`)}{" "}
          {/* Display the number of executed items */}
        </Box>
        <Box marginBottom="32px">
          <CustomCard title={"Risk Posture Overview"} direction="row">
            <GradientLineChart
              data={gradientChartData}
              speedometerValue={speedometerValue}
            />
          </CustomCard>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          columnGap="32px"
          rowGap="32px"
          marginBottom="32px" // Added gap between this component and the last one
        >
          <CustomCard title={"Countermeasures"} direction="row">
            <SpacedTable
              callback={toggle}
              columns={columns}
              data={suggested}
              title={"Suggested"}
              updateStatus={updateStatus}
            />{" "}
            {/* Pass updateStatus down */}
            <SpacedTable
              callback={toggle}
              columns={columns}
              data={data}
              title={"Executed"}
              updateStatus={updateStatus}
            />{" "}
            {/* Pass updateStatus down */}
          </CustomCard>
        </Box>
      </Box>
    </>
  );
};

export default SpacedTableComponent;
