const InventoryAssetInfoConfig = Object.freeze({
  GENERAL: "general",
  RELATIONS: "relations",
  TRACKING: "tracking",
  SECURITY: "security",
  BUSINESS: "business",
});

const PagesConfig = Object.freeze({
  INVENTORY: "inventory",
});

export const AppRoutingConfig = Object.freeze({
  PagesConfig,
  [PagesConfig.INVENTORY]: {
    AssetInfo: InventoryAssetInfoConfig,
  },
});
