export const InitialState = {
  issuesTableState: {
    isLoading: false,
    paginationConfig: {
      pageIndex: 0,
      pageSize: 20,
    },
    filters: {},
    data: [],
    meta: {},
    error: null,
  },
};
