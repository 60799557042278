import { Chip } from "../../../../../shared-component/Chip";
import { CMST_Chip_Variants } from "./data";
import { useIssueTheme } from "../../../hooks/useIssueTheme";

export const CMSTChip = ({
  variant = CMST_Chip_Variants.DEFAULT,
  ...props
}) => {
  const {
    palette: { background, text },
  } = useIssueTheme();

  return (
    <Chip
      size='small'
      textColor={text[variant]}
      bgColor={background[variant]}
      {...props}
    />
  );
};
