import { useHistory } from "react-router-dom";

export function useAppNavigator() {
  const history = useHistory();

  return {
    push: history.push,
    goBack: history.goBack,
    history,
  };
}
