import { CoreDataTypes } from "../../../../core/data-types";
// @ts-check
import { TruncatedCell } from "../../../../containers/inventory/AssetsTable/styled";

/**
 *
 * @typedef {import('material-react-table').MRT_ColumnDef<any>} ColumnType
 */

export const useSavedQueriesTableColumns = () => {
  /**
   * @type {ColumnType[]}
   */
  const columns = [
    {
      header: "Query Name",
      accessorKey: "name",
      size: 180,
      Cell({ cell }) {
        const value = cell.getValue() || CoreDataTypes.N_A;

        // @ts-ignore
        return <TruncatedCell>{value}</TruncatedCell>;
      },
    },
    {
      header: "Description",
      accessorKey: "description",
      size: 200,
      Cell({ cell }) {
        const value = cell.getValue() || CoreDataTypes.N_A;

        // @ts-ignore
        return <TruncatedCell>{value}</TruncatedCell>;
      },
    },
    {
      header: "Created By",
      accessorKey: "owner",
      size: 120,

      Cell({ cell }) {
        const value = cell.getValue() || CoreDataTypes.N_A;

        // @ts-ignore
        return <TruncatedCell>{value}</TruncatedCell>;
      },
    },
    {
      header: "Last Run",
      accessorKey: "last_run",
      size: 110,
      Cell({ cell }) {
        const value = cell.getValue() || CoreDataTypes.N_A;

        // @ts-ignore
        return <TruncatedCell>{value}</TruncatedCell>;
      },
    },
    {
      header: "Assigned Actions",
      accessorKey: "assigned_actions",
      Cell({ cell }) {
        const value = cell.getValue() || CoreDataTypes.N_A;

        // @ts-ignore
        return <TruncatedCell>{value}</TruncatedCell>;
      },
    },
  ];
  return columns;
};
