import {
  useCallback,
  useImperativeHandle,
  useMemo,
  useTransition,
} from "react";
import { useState } from "react";
import ConditionComponent from "../../../utils/ConditionComponent";
import {
  ButtonLabel,
  CustomFieldsSection,
  FilterGroupButton,
  ListSectionLabel,
  MenuContainer,
  MenuContentContainer,
  MenuFooter,
  SearchSectionContainer,
  SelectedGroupingContentContainer,
  SelectionsContainer,
  StyledInput,
  UserDataSection,
} from "./styled";
import { Header } from "./components/Header";
import { LoadingSection } from "../../../components/LayoutFillers";
import { Typography } from "@mui/material";
import { EmptyDataSection } from "../../../components/EmptyData";
import { createOptionId } from "../../../components/Grouping/utils/createOptionId";
import { GroupOption } from "../../../components/Grouping/components/GroupOptionValue";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import { useSelectOptionMenuOption } from "./hooks/useSelectOptionMenuOption";

export const SelectOptionsMenu = ({
  onClose,
  anchorEl,
  opened,
  menuContainerProps = {},
  elements = {},
  values = [],
  setValues,
  placeholder = "No Data",
  title = "",
  isMulti = true,
  checkOptionIsSelectedFn,
  data,
  optionFilterFn,
  onReset,
  twoWayBindingRef,
  checkOptionIsDisabled,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showContent, setShowContent] = useState();
  const startSearchTransition = useTransition()[1];
  const [renderingModalContent, startModalContentTransition] = useTransition();

  const noDataPlaceholder = useMemo(() => {
    if (placeholder) return placeholder;
    if (title) return `No selected ${title} data`;

    return "No data";
  }, [placeholder, title]);

  const removeOptionFromValues = (option) => {
    const newValues = values.filter(({ id }) => id !== option.id);
    setValues(newValues);
  };

  const addOptionToValues = (option) => {
    let newValues;
    if (isMulti) {
      newValues = [...values];
      newValues.push(option);
    } else {
      newValues = [option];
    }
    setValues(newValues);
  };

  const checkOptionIsChecked = (option) => {
    if (checkOptionIsSelectedFn) return checkOptionIsSelectedFn(option);
    return values.some((val) => {
      let id = val.id;
      if (!id) {
        id = createOptionId(val, "options");
      }
      return id === option.id;
    });
  };

  const handleOnClickGroupValue = (ev, option) => {
    const isSelected = checkOptionIsChecked(option);
    if (isSelected) {
      removeOptionFromValues(option);
    } else {
      addOptionToValues(option);
    }
  };

  /**
   *
   * @param {*} option
   * @param {*} searchQuery
   * @returns
   * empty, wildcard, str
   * str, wildcard, empty
   * vpc*
   */
  const execWildcardSearch = (option, searchQuery) => {
    const searchParts = searchQuery.split("*");
    let matches = true;
    searchParts.forEach((part) => {
      if (!part) {
        const valueMatchesSearch = option.value?.toLowerCase()?.includes(part);
        const typeMatchesSearch = option.originationTypes?.some((type) =>
          type?.toLowerCase().includes(part)
        );
        matches = matches && (valueMatchesSearch || typeMatchesSearch);
      } else {
        const valueMatchesSearch = option.value?.toLowerCase()?.includes(part);
        const typeMatchesSearch = option.originationTypes?.some((type) =>
          type?.toLowerCase().includes(part)
        );
        matches = matches && (valueMatchesSearch || typeMatchesSearch);
      }
    });
    return matches;
  };

  const execDefaultSearch = (option, searchQuery) => {
    const valueMatchesSearch = option.value
      ?.toLowerCase()
      ?.includes(searchQuery);
    const typeMatchesSearch = option.originationTypes?.some((type) =>
      type?.toLowerCase().includes(searchQuery)
    );
    return valueMatchesSearch || !!typeMatchesSearch;
  };

  const checkOptionMatchesSearchQuery = useCallback(
    (option) => {
      const areValidTypes =
        typeof searchValue === "string" || typeof option === "string";
      if (!searchValue) return true;
      if (!option || !areValidTypes) return false;

      const searchIsWildCard = searchValue.includes("*");
      const searchQueryStr = searchValue.toLowerCase();
      if (searchIsWildCard) {
        return execWildcardSearch(option, searchQueryStr);
      } else return execDefaultSearch(option, searchQueryStr);
    },
    [searchValue]
  );

  const options = useSelectOptionMenuOption({
    data,
    getOptionCheckedState: checkOptionIsChecked,
    handleOnClickGroupValue,
    shouldReturnEmpty: !opened,
    checkOptionMatchesSearchQuery,
    searchValue,
    optionFilterFn,
    isOpen: opened,
    checkOptionIsDisabled,
  });

  const handleClearSelection = () => {
    const newValues = values.filter((val) => checkOptionIsDisabled?.(val));
    setValues(newValues);
    onReset();
  };

  const handleOnChangeSearch = (ev) => {
    const value = ev.target.value;
    startSearchTransition(() => {
      setSearchValue(value);
    });
  };

  useImperativeHandle(twoWayBindingRef, () => {
    return {
      onOpenMenu() {
        startModalContentTransition(function () {
          setShowContent(true);
        });
      },
      onClose() {
        setShowContent(false);
        setSearchValue("");
      },
    };
  });

  return (
    <MenuContainer
      anchorEl={anchorEl}
      open={opened}
      anchorOrigin={{
        horizontal: "left",
        vertical: 40,
      }}
      onClose={onClose}
      {...menuContainerProps}
    >
      <Header handleOnClose={onClose} />

      <ConditionComponent condition={renderingModalContent}>
        <LoadingSection>
          <Typography variant='caption'>One moment...</Typography>
        </LoadingSection>
      </ConditionComponent>

      <ConditionComponent condition={!renderingModalContent && showContent}>
        <MenuContentContainer>
          <SearchSectionContainer>
            <ListSectionLabel>Search</ListSectionLabel>
            <SelectedGroupingContentContainer>
              <StyledInput
                onChange={handleOnChangeSearch}
                placeholder='Search...'
              />
            </SelectedGroupingContentContainer>
          </SearchSectionContainer>

          <ConditionComponent condition={!!elements.selectedValues}>
            <SelectionsContainer>
              <ListSectionLabel>Selected Values</ListSectionLabel>
              <SelectedGroupingContentContainer className='scrollable'>
                <ConditionComponent
                  condition={!!values.length}
                  falsyNode={<EmptyDataSection label={noDataPlaceholder} />}
                >
                  {values.map((selectedValue) => {
                    const key = createOptionId(
                      selectedValue,
                      "current grouping"
                    );
                    const data = {
                      ...selectedValue,
                      checked: true,
                      disabled: checkOptionIsDisabled?.(selectedValue) ?? false,
                    };

                    return (
                      <GroupOption
                        key={key}
                        data={data}
                        handleOnClickGroupValue={handleOnClickGroupValue}
                      />
                    );
                  })}
                </ConditionComponent>
              </SelectedGroupingContentContainer>
            </SelectionsContainer>
          </ConditionComponent>
          <UserDataSection>
            <ListSectionLabel>Fields</ListSectionLabel>
            <CustomFieldsSection className='scrollable'>
              {options}
            </CustomFieldsSection>
          </UserDataSection>
        </MenuContentContainer>
      </ConditionComponent>

      <ConditionComponent condition={elements.footer}>
        <MenuFooter>
          <FilterGroupButton
            sx={{ marginLeft: "0 !important" }}
            variant='text'
            border='1px solid #dee0e4'
            disableRipple
            bgColor='#F7F9FE'
            onClick={handleClearSelection}
          >
            <DeleteSweepOutlinedIcon />
            <ButtonLabel>Clear All</ButtonLabel>
          </FilterGroupButton>
        </MenuFooter>
      </ConditionComponent>
    </MenuContainer>
  );
};
