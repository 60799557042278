import { PageHeader, PageLayout } from "../../shared-component/PageLayout";
import { AssetDetailsView } from "../../../pages/inventory/components/AssetDetailsView";
import AssetsTable from "../../../containers/inventory/AssetsTable";
import { PageTableSection } from "./components/styled";
import { Typography } from "@mui/material";
import ConditionComponent from "../../../utils/ConditionComponent";
import { usePageMeta } from "../../../containers/inventory/AssetsTable/hooks/usePageMeta";
import { InventoryActions } from "../../../containers/inventory/AssetsTable/components/InventoryActions";
import { RenderWithContext } from "../../../utils/hocs/RenderWithContext";
import { AssetsTableContextProvider } from "../../../containers/inventory/AssetsTable/context/provider";
import { usePageData } from "../../../containers/inventory/AssetsTable/hooks/usePageData";

const InventoryAssetsPage = () => {
  const { isLoading } = usePageMeta();
  const { assetDetailVisible, selectedAssetForDetails } = usePageData();

  return (
    <PageLayout>
      <PageHeader>
        <Typography variant='pageHeading'>Assets Inventory</Typography>
        <ConditionComponent condition={!isLoading} falsyNode={null}>
          <InventoryActions />
        </ConditionComponent>
      </PageHeader>
      <PageTableSection>
        <AssetsTable />
      </PageTableSection>

      <AssetDetailsView
        open={assetDetailVisible}
        data={selectedAssetForDetails}
      />
    </PageLayout>
  );
};

export default RenderWithContext(
  InventoryAssetsPage,
  AssetsTableContextProvider
);
