import { styled } from "@mui/material";

export const EmptyGraphContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  & > * {
    color: #00000080;
  }
`;
