import { Service } from "./Service";

export const InventoryService = (function () {
  let _instance = null;
  let _apiClient = null;
  const state = {
    isReady: false,
  };

  const getInstance = () => {
    if (!_instance) {
      _instance = new Service(_apiClient);
    }
    return _instance;
  };

  const setApiClient = (client) => {
    _apiClient = client;
    state.isReady = true;
  };

  const getServiceIsReadyState = () => state.isReady;

  return {
    getInstance,
    setApiClient,
    getServiceIsReadyState,
  };
})();
