import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import Content from "../components/Content";
import VulnerabilitiesTable from "../components/VulnerabilityTable";

export const VulnerabilitiesView = () => {
  const { user } = useAuth0();

  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <div style={{ width: "90%" }}>
        <Content
          component={VulnerabilitiesTable}
          apiPath="/vulnerability/trivy/container/all"
          width={"100%"}
          title={"Vulnerabilities"}
        />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(VulnerabilitiesView, {
  onRedirecting: () => <Loading />,
});
