import React from "react";
import { Typography, ButtonBase } from "@mui/material";
import styles from "./styles.module.css";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

export const CardFooterButton = ({
  onClick,
  disabled,
  text,
  IconComponent,
  iconPosition = "left",
}) => {
  return (
    <ButtonBase
      disabled={disabled}
      className={`${styles.footerBtn} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
    >
      {iconPosition === "left" && IconComponent && (
        <IconComponent
          className={`${styles.btnIcon} ${disabled ? styles.disabled : ""}`}
        />
      )}
      <Typography
        className={`${styles.btnText} ${disabled ? styles.disabled : ""}`}
      >
        {text}
      </Typography>
      {iconPosition === "right" && IconComponent && (
        <IconComponent
          className={`${styles.btnIcon} ${disabled ? styles.disabled : ""}`}
        />
      )}
    </ButtonBase>
  );
};

export const CardFooter = ({
  onBack,
  onContinue,
  backDisabled,
  continueDisabled,
  backText,
  continueText,
  showBackButton = true,
  showContinueButton = true,
}) => {
  return (
    <>
      {showBackButton && (
        <CardFooterButton
          onClick={onBack}
          disabled={backDisabled}
          text={backText || "Back"}
          IconComponent={KeyboardArrowLeftOutlinedIcon}
          iconPosition="left"
        />
      )}
      {showContinueButton && (
        <CardFooterButton
          onClick={onContinue}
          disabled={continueDisabled}
          text={continueText || "Continue"}
          IconComponent={KeyboardArrowRightOutlinedIcon}
          iconPosition="right"
        />
      )}
    </>
  );
};

export default CardFooter;
