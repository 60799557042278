import { ButtonBase, Typography } from "@mui/material";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AssetDetailDrawerContainer, DrawerContent } from "./styled";
import { useAssetInfo } from "../../hooks/useAssetInfo";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { AssetDetailView } from "./AssetDetailView";
import { LoadingSection } from "./EmptySections";

const AssetDetailDrawer = ({ open, toggleDrawer, data }) => {
  const {
    data: assetData,
    error,
    loading,
  } = useAssetInfo(data?.pando_id, open);

  return (
    <AssetDetailDrawerContainer
      anchor='right'
      open={open}
      onClose={toggleDrawer}
    >
      <header className={styles.header}>
        <Typography variant='subtitle2'>Asset Info</Typography>
        <ButtonBase className={styles["close-btn"]}>
          <FontAwesomeIcon icon={faXmark} onClick={toggleDrawer} />
        </ButtonBase>
      </header>
      <DrawerContent>
        <ConditionComponent condition={loading}>
          <LoadingSection />
        </ConditionComponent>
        <ConditionComponent condition={!loading && error}>
          There was an error
        </ConditionComponent>
        <ConditionComponent condition={!loading && !error && assetData}>
          <AssetDetailView key={data?.pando_id} data={assetData} />
        </ConditionComponent>
      </DrawerContent>
    </AssetDetailDrawerContainer>
  );
};

export default AssetDetailDrawer;
