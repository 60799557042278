import githubLogo from "../../../assets/images/githubDark.svg";
import React from "react";
import {Box, Stack, Typography} from "@mui/material";

export const GithubIntegrationCompleteContent = () => {
    return (
        <Box>
            <Stack
                direction="row"
                sx={{ alignItems: "flex-end", gap: "18.9px" }}
            >
                <Typography
                    variant="h6"
                >
                    Integration Complete!
                </Typography>
            </Stack>
        </Box>
    );
};

export const GithubIntegrationCompleteConfig = {
    logo: githubLogo,
    headerTitle:  "Github integration complete",
};