import { CircularProgress } from "@mui/material";
import {
  EmptyDataSectionLabel,
  EmptyDataSection as StyledContainer,
} from "./styled";

export const EmptyDataSection = ({ label }) => {
  return (
    <StyledContainer>
      <EmptyDataSectionLabel>{label}</EmptyDataSectionLabel>
    </StyledContainer>
  );
};

export const LoadingSection = ({ children }) => {
  return (
    <StyledContainer>
      <CircularProgress size={"24px"} />
      {children}
    </StyledContainer>
  );
};
