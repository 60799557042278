import { useAssetTableState } from "./useAssetTableState";
import { useTablePagination } from "./usePagination";
import { useAssetsTableMethods } from "./useTableMethods";

export function useFetchPrimaryTableData() {
  const { fetchPrimaryTableData } = useAssetsTableMethods();
  const { searchKeyword, filters, defaultPayload = {} } = useAssetTableState();
  const pagination = useTablePagination();

  const func = (_payload, options = {}) => {
    const payload = {
      ...defaultPayload,
      search: searchKeyword,
      filters,
      pagination,
      ..._payload,
    };

    fetchPrimaryTableData(payload, { saveView: true, ...options });
  };
  return func;
}
