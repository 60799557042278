import { AppEnv } from "../../../utils/AppEnv";
import { isPrimitiveValue } from "../../../utils/helperMethods/type-guards";
import { defaultGraphCategories } from "../constants";

const useDerivedFilterOptions = (data, tagKeys) => {
  if (!data) {
    return { categories: [], categoryValuesSet: {} };
  }
  const categories = createCategoryOptions(defaultGraphCategories, tagKeys);
  const categoryValuesSet = createCategoryValuesSet(data, categories);
  syncCategoryOptionsAnvValueLength(categories, categoryValuesSet);
  return { categories, categoryValuesSet };
};

export default useDerivedFilterOptions;

function syncCategoryOptionsAnvValueLength(categories, categoryValuesSet) {
  categories.forEach((category) => {
    const { label } = category;
    const valueLength = categoryValuesSet[label]?.size || 0;
    category.label = `${label} (${valueLength})`;
  });
}

function getCategories() {
  return defaultGraphCategories;
}

/**
 *
 * @param {*} categories
 * @param {*} tagKeys
 * @returns
 *
 * WARN!!
 * Do not filter category options that should not affect the value set; This options
 * is what is used to create value set
 */
function createCategoryOptions(categories, tagKeys) {
  const defaultTransformedCategories = categories.map((category) => {
    return {
      label: category,
      value: category,
    };
  });

  const transformedUserTags = tagKeys.map((tag) => {
    return {
      label: tag,
      value: tag,
      source: "tag",
    };
  });

  return defaultTransformedCategories.concat(transformedUserTags);
}

function createCategoryValuesSet(nodesData, categoryOptions) {
  const categoryOptionsValueSet = createOptionsMap(categoryOptions);
  createCategoryValuesFromData(nodesData, categoryOptionsValueSet);
  return categoryOptionsValueSet;
}

function createOptionsMap(data) {
  return data.reduce((store, { label }) => {
    if (!store[label]) store[label] = new Set();
    return store;
  }, {});
}

/**
 *
 * @nonPure
 * @param {*} data
 * @param {*} categoryStore
 */
function createCategoryValuesFromData(data, categoryStore) {
  data.forEach((node) => syncNodeDataInCategoryStore(node, categoryStore));
}

function syncNodeDataInCategoryStore(node, optionsStore) {
  for (const field in node) {
    const nodeValue = node[field];
    const nodeValueIsPrimitive = isPrimitiveValue(nodeValue);
    if (field === "tags") {
      syncNodeTagsWithCategoryOptions(nodeValue, optionsStore);
    } else {
      const categoryOption = optionsStore[field];
      if (
        categoryOption &&
        categoryOption instanceof Set &&
        nodeValue &&
        nodeValueIsPrimitive
      ) {
        categoryOption.add(nodeValue);
      }
    }
  }
}

function syncNodeTagsWithCategoryOptions(tags, optionsStore) {
  for (const key in tags) {
    const tagValue = tags[key];
    const valuesSet = optionsStore[key];
    if (valuesSet instanceof Set) {
      valuesSet.add(tagValue);
    }
  }
}

if (AppEnv.env === "test") {
  module.exports = {
    getCategories,
    createCategoryOptions,
    createOptionsMap,
    createCategoryValuesFromData,
    syncNodeDataInCategoryStore,
    syncNodeTagsWithCategoryOptions,
    useDerivedFilterOptions,
    createCategoryValuesSet,
  };
}
