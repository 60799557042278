import {
  Header,
  Heading,
  ListRoot,
  Root,
  SectionIcon,
  SubHeading,
  TextSection,
} from "./styled";
import {
  InventorySearchComponentRef,
  SearchResultsColumnsSelector,
} from "../InventoryActions/refs";
import { ResultColumn } from "./ResultColumn";
import { Fragment, useMemo } from "react";
import { InventoryTableActionButton } from "../../../../../ui/pages/inventory/components/ActionButton";
import { SearchResultsColumnsButton } from "../../../../../ui/pages/inventory/components/SearchColumnsButton";
import { useSearchResultsFieldColumns } from "../../hooks/useSearchingResults";
import { usePrimaryTableColumnState } from "../../hooks/usePrimaryTableColumnState";
import { AssetInventoryValidators } from "../../../../../core/asset-inventory/utils/AssetInventoryDataValidator";

export const SearchResultColumnSelector = ({ onClick, onClose, ...props }) => {
  const cols = useSearchResultsFieldColumns();
  const [primaryTableCols, setTableColumns] = usePrimaryTableColumnState();

  const checkedColumnCount = useMemo(() => {
    return cols.filter((col) => col.checked).length;
  }, [cols]);

  const removeFromCheckedCols = (data) => {
    const newCols = primaryTableCols.filter((col) => col.id !== data.id);
    setTableColumns(newCols);
  };

  const addToCheckedCols = (col) => {
    setTableColumns([...primaryTableCols, col]);
  };

  const handleOnChangeColumn = (_, data) => {
    const isWithinLimit =
      AssetInventoryValidators.validateSelectedColumnsAgainstMaxLimit(
        primaryTableCols.length + 1
      );
    if (data.checked) {
      removeFromCheckedCols(data);
    } else if (isWithinLimit) {
      addToCheckedCols(data);
    }
  };

  const columnNodes = useMemo(() => {
    return cols.map((col) => (
      <ResultColumn onChange={handleOnChangeColumn} data={col} key={col.id} />
    ));
  }, [cols]);

  return (
    <Fragment>
      <InventoryTableActionButton
        ref={SearchResultsColumnsSelector}
        actionCount={checkedColumnCount}
        sx={{ width: "auto" }}
        onClick={onClick}
        tooltipProps={{
          show: true,
          title: "Search results columns",
        }}
        icon={<SearchResultsColumnsButton />}
      />
      <Root
        onClose={onClose}
        anchorEl={InventorySearchComponentRef.current}
        {...props}
      >
        <Header>
          <SectionIcon />
          <TextSection>
            <Heading>Fields containing search keyword</Heading>
            <SubHeading>Select to add to table column</SubHeading>
          </TextSection>
        </Header>
        <ListRoot className='scrollable'>{columnNodes}</ListRoot>
      </Root>
    </Fragment>
  );
};
