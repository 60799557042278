import { useGlobalState } from "./useGlobalState";
import { useDispatch } from "./useDispatch";
import { Actions } from "../stateManagement/actions";
import { useFetchData } from "./useFetchdata";
import { InventoryService } from "../../../../services/inventoryService";

export function useDeleteAction() {
  const { showDeleteActionView, deleteActionPayload } = useGlobalState();
  const dispatch = useDispatch();
  const fetchData = useFetchData();

  const close = () => {
    dispatch({
      type: Actions.CLOSE_DELETE_ACTION,
    });
  };

  const open = (payload) => {
    dispatch({
      type: Actions.OPEN_DELETE_ACTION,
      payload,
    });
  };

  const deleteAction = async () => {
    try {
      dispatch({
        type: Actions.DELETE_ACTION_PENDING,
      });

      await InventoryService.getInstance().deleteAction({
        ruleUuid: deleteActionPayload.data.rule_uuid,
      });
      dispatch({
        type: Actions.DELETE_ACTION_SUCCESS,
      });
      await fetchData();
    } catch (error) {
      dispatch({
        type: Actions.DELETE_ACTION_ERROR,
        payload: error.message,
      });
    }
  };

  return {
    openState: showDeleteActionView,
    payload: deleteActionPayload,
    close,
    open,
    deleteAction,
  };
}
