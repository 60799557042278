// FeatureService.js
const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const organization_id = localStorage.getItem("organization_id") || "";

const FeatureService = {
  async getFeatures(token) {
    const orgId = localStorage.getItem("organization_id");
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-ORG-ID": organization_id,
      };

      if (orgId) headers["X-ORG-ID"] = orgId;

      const response = await fetch(`${BASE_URL}/features/list`, {
        headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation:",
        error.message
      );
      throw error;
    }
  },
};

export default FeatureService;
