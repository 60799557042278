import { createAsyncThunk } from "@reduxjs/toolkit";
import { CMSTService } from "../../../services/CMSTService";

export const getProjectsSummary = createAsyncThunk(
  "projects-summary",
  async (_, thunkApi) => {
    try {
      //
      const res = await CMSTService.getInstance().getProjectsSummary();

      return thunkApi.fulfillWithValue(res);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
