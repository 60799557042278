import {
  Box,
  Checkbox,
  InputBase,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import { MenuFooterContainer } from "../../../views/G6Graph/components/FilterSelector/styled";
import { forwardRef } from "react";

export const MenuContainer = styled(Popover)`
  & .MuiPopover-paper {
    width: 700px;
    min-height: 350px;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

export const MenuFooter = styled(MenuFooterContainer)`
  justify-content: space-between;
  margin-top: auto;
`;

export const RightSectionActionContainer = styled("div")`
  display: flex;
  column-gap: 12px;
`;

export const MenuContentContainer = styled(Box)`
  && {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
`;

export const ContentDivider = styled(Box)`
  height: 100%;
  width: 1px;
  margin: 0 12px;
  background-color: #ccc;
`;

export const SelectedTagsSection = styled("div")`
  background-color: #cccccc30;
  padding: 4px 8px;
  border-radius: 8px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 0;
  }
`;

export const SelectionsSection = styled(Box)`
  && {
    flex: 1;
    display: flex;
    max-height: 400px;
    margin-top: 12px;
    flex-direction: column;
  }
`;

export const StyledInput = styled(InputBase)`
  width: 350px;
  max-width: 500px;
  height: 30px;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px !important;
  border: 1px solid #ccc;
  font-family: Inter, serif;
`;

export const TagListsSection = styled(Box)`
  && {
    flex: 1;
    display: grid;
    grid-template-columns: 250px 25px 1fr;
    margin-top: 24px;
    box-sizing: border-box;
  }
`;

export const FixedListSectionContainer = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const DynamicTagsSectionContainer = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: hidden;
  }
`;

export const ListSectionLabel = styled(
  forwardRef((props, ref) => (
    <Typography ref={ref} {...props} variant='caption' />
  ))
)`
  && {
    font-weight: 500;
  }
`;

export const TagsListContainer = styled(Box)`
  flex: 1;
`;

export const CurrentGroupingsListContainer = styled(Box)`
  overflow-y: auto;
  max-height: 200px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: #cccccc50;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
  }
`;

export const DynamicTagsListContainer = styled(Box)`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: #cccccc50;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
  }
`;

export const ListItemText = styled((props) => (
  <Typography {...props} component={"label"} variant='body2' />
))`
  text-align: left;
  height: auto;
`;

export const ListItem = styled("li")`
  display: flex;
  height: auto;
  align-items: flex-start;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: #cccccc40;
  }
  & :hover {
    cursor: pointer;
  }
  &:not(:first-child) {
    margin-top: 2px;
  }
  &:first-child {
    margin-top: 6px;
  }
`;

export const ListContainer = styled("ul")`
  list-style-type: none;
  padding-inline-start: 4px;
`;

export const ListItemCheckbox = styled((props) => (
  <Checkbox {...props} size='small' />
))`
  height: 24px;
  width: 24px;
  position: relative;
`;
