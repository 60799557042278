import * as React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./Menu.module.css";
import { ChatMenuContainer } from "./styled";

export default function ChatMenu({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ChatMenuContainer>
      <ButtonBase
        id="menu-button"
        aria-controls={open ? "chat-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={styles.menuBtn}
        onClick={handleClick}
      >
        Scopes
        <FontAwesomeIcon icon={faChevronDown} className={styles.chevronIcon} />
      </ButtonBase>
      <Menu
        id="chat-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
      >
        <MenuItem onClick={() => {}}></MenuItem>
      </Menu>

      {children}
    </ChatMenuContainer>
  );
}
