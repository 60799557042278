import jiraLogo from "../../../assets/images/jira.svg";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export const JiraIntegrationCompleteContent = () => {
  return (
    <Box>
      <Stack direction="row" sx={{ alignItems: "flex-end", gap: "18.9px" }}>
        <Typography variant="h6">Integration Complete!</Typography>
      </Stack>
    </Box>
  );
};

export const JiraIntegrationCompleteConfig = {
  logo: jiraLogo,
  headerTitle: "Jira Integration Complete",
};
