const getTypesStore = () => {
  return {
    int: "number",
    float: "number",
  };
};

const castToJsType = (type) => {
  const typesStore = getTypesStore();
  return typesStore[type];
};

export const TypeAdapter = Object.freeze({
  castToJsType,
});
