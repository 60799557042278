import { WizIntegrationInitialState } from "./state";

export const WizIntegrationActions = Object.freeze({
  setWizUrl: "setWizUrl",
  setClientKey: "setClientKey",
  setLegacyConnection: "setLegacyConnection",
  setProjectIdMapping: "setProjectIdMapping",
  setWizAuthUrl: "setWizAuthUrl",
  setClientSecret: "setClientSecret",
  setHttpsProxy: "setHttpsProxy",
  setProjectUUID: "setProjectUUID",
  setIntegrationStep: "setIntegrationStep",
  startIntegration: "startIntegration",
  integrationSuccess: "integrationSuccess",
  integrationFailed: "integrationFailed",
  reset: "reset",
  setName: "setName",
});

export const WizIntegrationReducer = (oldState, action) => {
  const state = { ...oldState };
  if (action.type === WizIntegrationActions.reset) {
    return WizIntegrationInitialState;
  }
  if (action.type === WizIntegrationActions.startIntegration) {
    state.loading = true;
    state.error = null;
    return state;
  }
  if (action.type === WizIntegrationActions.integrationSuccess) {
    state.loading = false;
    state.error = null;
    state.step = 2;
    return state;
  }

  if (action.type === WizIntegrationActions.setName) {
    state.name = action.payload;
    return state;
  }

  if (action.type === WizIntegrationActions.integrationFailed) {
    state.loading = false;
    state.error = action.payload?.error || "Something went wrong";
    state.step = 1;
    return state;
  }

  if (action.type === WizIntegrationActions.setWizUrl) {
    state.wizUrl = action.payload;
    return state;
  }

  if (action.type === WizIntegrationActions.setClientKey) {
    state.clientKey = action.payload;
    return state;
  }

  if (action.type === WizIntegrationActions.setLegacyConnection) {
    state.legacyConnection = action.payload;
    return state;
  }
  if (action.type === WizIntegrationActions.setProjectIdMapping) {
    state.projectIdMapping = action.payload;
    return state;
  }
  if (action.type === WizIntegrationActions.setWizAuthUrl) {
    state.wizAuthUrl = action.payload;
    return state;
  }
  if (action.type === WizIntegrationActions.setClientSecret) {
    state.clientSecret = action.payload;
    return state;
  }
  if (action.type === WizIntegrationActions.setHttpsProxy) {
    state.httpsProxy = action.payload;
    return state;
  }
  if (action.type === WizIntegrationActions.setProjectUUID) {
    state.projectUUID = action.payload;
    return state;
  }

  if (action.type === WizIntegrationActions.setIntegrationStep) {
    state.step = action.payload;
    return state;
  }

  return state;
};
