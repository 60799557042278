import { JSONUtils } from "./JSONUtils";

export class StringUtils {
  static #nativeJSON = JSONUtils.native;

  static capitalize(str) {
    if (!str) return "";
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static castToString(val) {
    return `${val}`;
  }
}
