import { Typography } from "@mui/material";
import { CloseButton, DrawerHeader as Header } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const DrawerHeader = ({ actions }) => {
  return (
    <Header>
      <Typography variant='subtitle2'>Asset Info</Typography>
      <CloseButton>
        <FontAwesomeIcon icon={faXmark} onClick={actions?.toggleDrawer} />
      </CloseButton>
    </Header>
  );
};
