import { useMemo } from "react";
import { useInventoryPageData } from "./useInventoryPageData";
import { usePageData } from "./usePageData";
import { getSearchResultsColumns } from "../utils/getSearchResultsColumns";
import { usePrimaryTableColumnState } from "./usePrimaryTableColumnState";

export function useSearchResultsFieldColumns() {
  const { searchKeyword } = usePageData();
  const tableData = useInventoryPageData();
  const primaryTableCols = usePrimaryTableColumnState()[0];

  return useMemo(() => {
    if (!searchKeyword) return [];
    if (!tableData.length) return [];
    return getSearchResultsColumns(tableData, searchKeyword, primaryTableCols);
  }, [searchKeyword, tableData, primaryTableCols]);
}
