import { getInitialGroupingValueFromAssetOptions } from "../utils/getInitialGroupingValueFromAssetOptions";
import { ActionType } from "./actions";
import { InitialState } from "./initialState";

export const Reducer = (_state, action) => {
  if (action.type === ActionType.RESET) {
    return InitialState;
  }

  if (action.type === ActionType.RESET_GROUPING_TABLE_STATE) {
    return InitialState.groupingTableMeta;
  }
  if (action.type === ActionType.RESET_PRIMARY_TABLE_STATE) {
    return InitialState.primaryTableMeta;
  }

  if (action.type === ActionType.INIT_PAGE) {
    const state = {
      ..._state,
    };
    const pageMeta = {
      isLoading: true,
      error: false,
      errorPayload: null,
    };
    state.pageMeta = pageMeta;
    return state;
  }

  if (action.type === ActionType.OPEN_ASSET_DETAIL_VISIBLE) {
    const state = { ..._state };
    state.assetDetailVisible = true;
    state.selectedAssetForDetails = action.payload;
    return state;
  }

  if (action.type === ActionType.CLOSE_ASSET_DETAIL_VISIBLE) {
    const state = { ..._state };
    state.assetDetailVisible = false;
    state.selectedAssetForDetails = null;
    return state;
  }

  if (action.type === ActionType.INIT_PAGE_SUCCESS) {
    const { payload } = action;
    const state = { ..._state };
    const pageMeta = {
      isLoading: false,
      error: false,
      errorPayload: null,
    };
    state.pageMeta = pageMeta;
    state.assetOptionsMap = payload.assetOptionsMap;

    if (payload.filters) {
      state.filters = payload.filters;
    }
    if (payload.primaryTableColumns) {
      state.primaryTableColumns = payload.primaryTableColumns;
    }
    if (payload.grouping) {
      state.grouping = payload.grouping;
      //
    }
    state.searchKeyword = payload.searchKeyword ?? "";
    if (payload.currentGroupingValue) {
      state.currentGroupingValue = payload.currentGroupingValue;
    }
    return state;
  }
  if (action.type === ActionType.FETCH_GROUPING_TABLE_DATA) {
    const state = { ..._state };
    const groupingTableMeta = {
      ..._state.groupingTableMeta,
      isLoading: true,
      error: false,
      errorPayload: null,
    };
    state.groupingTableMeta = groupingTableMeta;
    return state;
  }

  if (action.type === ActionType.FETCH_GROUPING_DATA_SUCCESSFUL) {
    const state = { ..._state };
    const groupingTableMeta = {
      ..._state.groupingTableMeta,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: action.payload.data,
      meta: action.payload.meta,
    };
    state.groupingTableMeta = groupingTableMeta;
    return state;
  }

  if (action.type === ActionType.FETCH_GROUPING_DATA_FAILED) {
    const state = { ..._state };
    const groupingTableMeta = {
      ..._state.groupingTableMeta,
      isLoading: false,
      error: true,
      errorPayload: action.payload.error ?? "Something Went wrong",
      data: [],
      meta: {},
    };
    state.groupingTableMeta = groupingTableMeta;
    return state;
  }

  if (action.type === ActionType.FETCH_PRIMARY_TABLE_DATA) {
    const state = { ..._state };
    const primaryTableMeta = {
      ..._state.primaryTableMeta,
      isLoading: true,
      error: false,
      errorPayload: null,
    };
    state.primaryTableMeta = primaryTableMeta;
    return state;
  }

  if (action.type === ActionType.FETCH_PRIMARY_TABLE_DATA_SUCCESS) {
    const state = { ..._state };
    const primaryTableMeta = {
      ..._state.primaryTableMeta,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: action.payload.data,
      meta: action.payload.meta,
    };
    state.primaryTableMeta = primaryTableMeta;
    return state;
  }

  if (action.type === ActionType.FETCH_PRIMARY_TABLE_DATA_FAILED) {
    const state = { ..._state };
    const primaryTableMeta = {
      ..._state.primaryTableMeta,
      isLoading: false,
      error: true,
      errorPayload: action.payload.error ?? "Something Went wrong",
      data: [],
      meta: {},
    };
    state.primaryTableMeta = primaryTableMeta;
    return state;
  }

  if (action.type === ActionType.SET_SEARCH_VALUE) {
    const state = { ..._state };
    state.searchKeyword = action.payload.value;
    return state;
  }

  if (action.type === ActionType.SET_PRIMARY_TABLE_COLUMNS) {
    const state = { ..._state };
    state.primaryTableColumns = action.payload;
    return state;
  }
  if (action.type === ActionType.SET_GROUPING) {
    const state = { ..._state };
    // if no grouping, reset current grouping value
    state.grouping = action.payload;
    //
    const isEmpty = !action.payload.length;
    if (isEmpty) {
      // reset primary table pagination pageIndex
      const paginationConfig = {
        ..._state.primaryTableMeta.paginationConfig,
        pageIndex: 0,
      };
      state.primaryTableMeta = {
        ..._state.primaryTableMeta,
        paginationConfig,
      };
      state.currentGroupingValue = null;

      return state;
    }
    const group = action.payload[0];
    const initialCurrentGroupingValue = getInitialGroupingValueFromAssetOptions(
      group,
      state.assetOptionsMap
    );

    if (initialCurrentGroupingValue) {
      state.currentGroupingValue = initialCurrentGroupingValue;
    }

    return state;
  }

  if (action.type === ActionType.SET_FILTERS) {
    const state = { ..._state };
    // if no grouping, reset current grouping value
    state.filters = [...action.payload];
    // reset current table pagination config
    const isGrouping = !!state.grouping.length;
    if (isGrouping) {
      state.primaryTableMeta = {
        ...state.primaryTableMeta,
        paginationConfig: {
          ...state.primaryTableMeta.paginationConfig,
          pageIndex: 0,
        },
      };
    } else {
      state.groupingTableMeta = {
        ...state.groupingTableMeta,
        paginationConfig: {
          ...state.groupingTableMeta.paginationConfig,
          pageIndex: 0,
        },
      };
    }
    return state;
  }

  if (action.type === ActionType.SET_PRIMARY_TABLE_PAGINATION_CONFIG) {
    const state = { ..._state };
    const paginationConfig = action.payload;
    state.primaryTableMeta = { ..._state.primaryTableMeta, paginationConfig };
    return state;
  }

  if (action.type === ActionType.SET_GROUPING_TABLE_PAGINATION_CONFIG) {
    const state = { ..._state };
    const paginationConfig = {
      ..._state.groupingTableMeta.paginationConfig,
      ...action.payload,
    };
    state.groupingTableMeta = { ..._state.groupingTableMeta, paginationConfig };
    return state;
  }

  if (action.type === ActionType.SET_CURRENT_GROUPING_VALUE) {
    const state = { ..._state };
    const paginationConfig = {
      ..._state.groupingTableMeta.paginationConfig,
      pageIndex: 0,
    };
    state.groupingTableMeta = { ..._state.groupingTableMeta, paginationConfig };

    state.currentGroupingValue = action.payload;
    return state;
  }

  if (action.type === ActionType.CLEAR_GROUPING) {
    const state = { ..._state };
    const paginationConfig = {
      ..._state.primaryTableMeta.paginationConfig,
      pageIndex: 0,
    };
    state.currentGroupingValue = null;
    state.grouping = [];
    state.primaryTableMeta = { ..._state.primaryTableMeta, paginationConfig };
    return state;
  }

  return _state;
};
