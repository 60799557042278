import { CheckBox, ResultColumnLabel, ResultColumnRoot } from "./styled";

export const ResultColumn = ({ data, ...props }) => {
  const label = `${data.source}.${data.label}`;
  return (
    <ResultColumnRoot
      {...props}
      onChange={(ev) => props.onChange(ev, data)}
      control={<CheckBox checked={data.checked} size='small' />}
      label={<ResultColumnLabel>{label}</ResultColumnLabel>}
    />
  );
};
