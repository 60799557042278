import { Button, styled } from "@mui/material";
import { PageHeader, PageLayout } from "../../../shared-component/PageLayout";

export const PageRoot = styled(PageLayout)`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(PageHeader)`
  display: flex;
`;

export const ActionsRoot = styled("div")`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const ActionButton = styled(Button)(() => {
  return `
  min-width: 0;
  padding: 6px;
  border-radius: 4px;
  `;
});

export const TableRoot = styled("div")(() => {
  return `
  margin-top: 24px;
  flex: 1;
  height: 100%;
  `;
});
