import React from "react";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
const OnBoardingLayout = ({ cardContent, cardFooter, onlyIntegrations }) => {
  if (onlyIntegrations) {
    styles.card["maxWidth"] = "70vw";
  }
  return (
    <Box className={styles.card}>
      <Box className={styles.cardContent}>{cardContent}</Box>
      <Box className={styles.cardFooter}>{cardFooter}</Box>
    </Box>
  );
};

export default OnBoardingLayout;
