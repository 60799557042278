const colors = [
  "#FF5733",
  "#33FF57",
  "#5733FF",
  "#C70039",
  "#900C3F",
  "#581845",
  "#1F618D",
  "#2ECC71",
  "#E74C3C",
  "#9B59B6",
  "#8E44AD",
  "#3498DB",
  "#16A085",
  "#D35400",
  "#2E4053",
  "#2980B9",
  "#1ABC9C",
  "#E67E22",
  "#7D3C98",
  "#196F3D",
  "#2874A6",
  "#B03A2E",
  "#873600",
  "#C0392B",
  "#A569BD",
  "#5B2C6F",
  "#34495E",
  "#DC7633",
  "#7D6608",
  "#76448A",
  "#52BE80",
  "#AF7AC5",
  "#48C9B0",
  "#CD6155",
  "#5499C7",
  "#922B21",
  "#1A5276",
  "#117A65",
  "#16A085",
  "#D68910",
  "#B9770E",
  "#9A7D0A",
  "#641E16",
  "#76448A",
  "#FF4500",
  "#4682B4",
  "#32CD32",
  "#FF1493",
  "#20B2AA",
  "#FF6347",
  "#8B008B",
  "#FF00FF",
  "#FF4500",
  "#EE82EE",
  "#BA55D3",
];

const COLOR_MAP_KEY = "typeColorMap";

const getFromPersistence = (key) => {
  try {
    const result = localStorage.getItem(key);
    return JSON.parse(result) ?? {};
  } catch (error) {
    return {};
  }
};

const getOptionColorFromConfig = (color) => {
  const config = getFromPersistence(COLOR_MAP_KEY);
  if (!config) return null;
  return config?.[color];
};

const saveOptionColorToConfig = (option, color) => {
  const colorMap = getFromPersistence(COLOR_MAP_KEY);
  colorMap[option] = color;
  const asStr = JSON.stringify(colorMap);
  localStorage.setItem(COLOR_MAP_KEY, asStr);
};

export function generateOptionColor(option) {
  const colorFromConfig = getOptionColorFromConfig(option);
  if (colorFromConfig) return colorFromConfig;
  const min = 0;
  const max = colors.length - 1;
  const index = Math.floor(Math.random() * (max - min + 1)) + min;
  const selectedColor = colors[index];
  saveOptionColorToConfig(option, selectedColor);
  return selectedColor;
}
