export const ActionType = Object.freeze({
  INIT_PAGE: "init",
  INIT_PAGE_SUCCESS: "init-page-success",
  INIT_PAGE_FAILURE: "init-page-failure",
  FETCH_GROUPING_TABLE_DATA: "get-grouping-table-data",
  FETCH_GROUPING_DATA_FAILED: "fetch-grouping-table-data-failed",
  FETCH_GROUPING_DATA_SUCCESSFUL: "fetch-grouping-table-data-success",
  FETCH_PRIMARY_TABLE_DATA: "fetch-primary-table-data",
  FETCH_PRIMARY_TABLE_DATA_FAILED: "fetch-primary-table-data-failed",
  FETCH_PRIMARY_TABLE_DATA_SUCCESS: "fetch-primary-table-data-success",
  RESET: "reset",
  RESET_GROUPING_TABLE_STATE: "reset-grouping-table-states",
  RESET_PRIMARY_TABLE_STATE: "reset-primary-table-states",
  SET_GROUPING: "set-grouping",
  SET_FILTERS: "set-filters",
  SET_CURRENT_GROUPING_VALUE: "set-current-grouping-value",
  SET_SEARCH_VALUE: "set-search-value",
  SET_PRIMARY_TABLE_COLUMNS: "set-primary-table-columns",
  SET_PRIMARY_TABLE_PAGINATION_CONFIG: "set-primary-table-config",
  CLEAR_GROUPING: "clear-grouping",
  SET_GROUPING_TABLE_PAGINATION_CONFIG: "set-grouping-table-config",
  OPEN_ASSET_DETAIL_VISIBLE: "open-asset-detail-visible",
  CLOSE_ASSET_DETAIL_VISIBLE: "close-asset-detail-visible",
});
