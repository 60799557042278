import {
  Box,
  Table,
  TableCell,
  TableHead,
  Typography,
  styled,
} from "@mui/material";

export const MarkdownPreComponent = styled(Box)(
  () => `
width: inherit;
overflow-wrap: break-word;
`
);

export const MarkdownListItem = styled((props) => (
  <Typography {...props} variant='body2' />
))(() => {
  return `
    display: list-item;
    list-item-style: disc;
    margin-top: 8px;
    list-style-position: inside;
  `;
});

export const MarkdownTable = styled(Table)``;

export const MarkdownTableCell = styled(TableCell)``;

export const MarkdownTableHead = styled(TableHead)``;

export const MarkdownTableHeading = styled((props) => {
  return (
    <MarkdownTableCell>
      <th>
        <Typography {...props} variant='h6'></Typography>
      </th>
    </MarkdownTableCell>
  );
})``;
