import { useMemo } from "react";
import { useGetAssetDetailState } from "./useStateManager";
import { useMaterialReactTable } from "material-react-table";
import { TruncatedCell } from "../../../../../containers/inventory/AssetsTable/styled";
import { ValueText } from "../styled";
import { EMPTY_VALUE } from "../subPages/utils/assetDetailsSelector";

function getFirstValue(val) {
  if (Array.isArray(val)) {
    const value = val[0];

    return value ?? EMPTY_VALUE;
  }
  return EMPTY_VALUE;
}

export const useRelationTable = () => {
  const { data } = useGetAssetDetailState();
  const { related_assets } = data;

  const tableData = useMemo(() => {
    return related_assets;
  }, [related_assets]);

  const table = useMaterialReactTable({
    data: tableData,
    columns: [
      {
        accessorKey: "asset",
        header: "Asset Name",
        Cell({ cell }) {
          const assetData = cell.getValue();
          const value = assetData.common_fields.name?.[0] ?? EMPTY_VALUE;

          return (
            <TruncatedCell>
              <ValueText isna={value === EMPTY_VALUE}>{value}</ValueText>
            </TruncatedCell>
          );
        },
      },

      {
        accessorKey: "asset",
        header: "Provider Id",
        Cell({ cell }) {
          const assetData = cell.getValue();
          const fieldValue = assetData.common_fields?.unique_provider_id;
          const firstValidValue = getFirstValue(fieldValue);

          return (
            <TruncatedCell>
              <ValueText isna={firstValidValue === EMPTY_VALUE}>
                {firstValidValue}
              </ValueText>
            </TruncatedCell>
          );
        },
      },

      {
        accessorKey: "asset",
        header: "Asset Type",
        Cell({ cell }) {
          const assetData = cell.getValue();
          const value = assetData.types?.[0] ?? EMPTY_VALUE;
          return (
            <TruncatedCell>
              <ValueText isna={value === EMPTY_VALUE}>{value}</ValueText>
            </TruncatedCell>
          );
        },
      },

      {
        accessorKey: "label",
        header: "Relation",
        Cell({ cell }) {
          const value = cell.getValue() || EMPTY_VALUE;
          return (
            <TruncatedCell>
              <ValueText isna={value === EMPTY_VALUE}>{value}</ValueText>
            </TruncatedCell>
          );
        },
      },
    ],
    manualPagination: false,
    manualFiltering: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    muiTopToolbarProps: {
      hidden: true,
    },
  });

  return table;
};
/*
name id, type relation
*/
