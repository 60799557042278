import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { FieldInput, InputGroup, ViewContainer } from "../styled";
import { MuiStyles } from "../../awsCloudformation/stepOne/styles";
import { IntegrationActions } from "../context/reducer";
import {
  useGetIntegrationState,
  useSetIntegrationState,
} from "../hooks/useIntegrationState";

export const IntegrationForm = () => {
  const {
    hostname,
    userServiceId,
    apiToken,
    verifySSL,
    httpsProxy,
    httpsProxyUsername,
    httpsProxyPassword,
    name,
  } = useGetIntegrationState();
  const setValue = useSetIntegrationState();

  const onChangeWithType = (ev, type) => {
    const payload = ev.target.value;
    setValue({
      payload,
      type,
    });
  };

  const handleOnChangeUserServiceId = (ev) => {
    onChangeWithType(ev, IntegrationActions.setUserServiceId);
  };

  const handleOnChangeHostname = (ev) => {
    onChangeWithType(ev, IntegrationActions.setHostname);
  };

  const handleOnChangeApiToken = (ev) => {
    onChangeWithType(ev, IntegrationActions.setApiToken);
  };

  const handleOnChangeHttpsProxy = (ev) => {
    onChangeWithType(ev, IntegrationActions.setHttpsProxy);
  };

  const handleOnChangeHttpsProxyUsername = (ev) => {
    onChangeWithType(ev, IntegrationActions.setHttpsProxyUserName);
  };

  const handleOnChangeHttpsProxyPassword = (ev) => {
    onChangeWithType(ev, IntegrationActions.setHttpsProxyPassword);
  };

  const handleOnChangeName = (ev) => {
    onChangeWithType(ev, IntegrationActions.setName);
  };

  const handleOnChangeSSL = (ev) => {
    setValue({
      payload: !verifySSL,
      type: IntegrationActions.setVerifySSL,
    });
  };

  return (
    <ViewContainer>
      <InputGroup>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Integration Name</Typography>
          </Box>
          <FieldInput value={name} onChange={handleOnChangeName} />
        </Box>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>User Service ID</Typography>
          </Box>
          <FieldInput value={userServiceId} onChange={handleOnChangeUserServiceId} />
        </Box>
        
      </InputGroup>
      <InputGroup>
     
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Token</Typography>
          </Box>
          <FieldInput value={apiToken} onChange={handleOnChangeApiToken} />
        </Box>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Host Name or IP Address (optional)</Typography>
          </Box>
          <FieldInput value={hostname} onChange={handleOnChangeHostname} />
        </Box>
      </InputGroup>
      <InputGroup>
      <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>HTTPS Proxy (optional)</Typography>
          </Box>
          <FieldInput value={httpsProxy} onChange={handleOnChangeHttpsProxy} />
        </Box>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>
              HTTPS Proxy Username (optional)
            </Typography>
          </Box>
          <FieldInput
            value={httpsProxyUsername}
            onChange={handleOnChangeHttpsProxyUsername}
          />
        </Box>

        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>
              HTTPS Proxy Password (optional)
            </Typography>
          </Box>
          <FieldInput
            value={httpsProxyPassword}
            onChange={handleOnChangeHttpsProxyPassword}
          />
        </Box>
      </InputGroup>

      <InputGroup>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%", marginTop: "auto" }}>
          <FormControlLabel
            onChange={handleOnChangeSSL}
            control={<Checkbox checked={verifySSL} />}
            label={<Typography variant='caption'>Verify SSL</Typography>}
          />
        </Box>
      </InputGroup>
    </ViewContainer>
  );
};
