import { useMemo } from "react";
import { CommandJson } from "../CommandJson";
import { useGetAssetDetailState } from "../hooks/useStateManager";
import {
  DataSection,
  FieldLabel,
  MarkdownContainer,
  RowContainer,
  SubPageContainer,
  SubPageContentContainer,
  SubPageHeading,
} from "../styled";
import { InfoValue } from "../Value";
import { selectGeneralAssetDetails } from "./utils/selectGeneralAssetInfo";
import { EMPTY_VALUE } from "./utils/assetDetailsSelector";
import ReactMarkdown from "react-markdown";
import { Typography } from "@mui/material";
import {
  MarkdownListItem,
  MarkdownPreComponent,
  MarkdownTable,
  MarkdownTableCell,
  MarkdownTableHead,
  MarkdownTableHeading,
} from "../../../../../components/botgpt/messageList/styled-components";
import remarkGfm from "remark-gfm";

export const GeneralPage = () => {
  const { data: assetDetails } = useGetAssetDetailState();

  const details = useMemo(() => {
    return selectGeneralAssetDetails(assetDetails);
  }, [assetDetails]);

  const accountIdNodes = useMemo(() => {
    const data = details.accountId;
    const { sources } = details;
    let styles = {
      flex: 1,
    };
    const isMultiple = data.length > 1;

    const getValueSource = (index) => {
      if (!isMultiple) return undefined;
      return sources[index];
    };

    if (isMultiple) styles.maxWidth = "30%";

    return data.map((value, index) => {
      let source = getValueSource(index);

      return (
        <InfoValue
          containerProps={{ sx: styles }}
          key={value}
          options={{ copy: true }}
          data={{ value, source }}
        />
      );
    });
  }, [details.accountId, details.sources]);

  const typeNodes = useMemo(() => {
    const { sources, type: data } = details;
    let styles = {
      flex: 1,
    };
    const isMultiple = data.length > 1;

    const getValueSource = (index) => {
      if (!isMultiple) return undefined;
      return sources[index];
    };

    return data.map((value, index) => {
      let source = getValueSource(index);

      return (
        <InfoValue
          containerProps={{ sx: styles }}
          key={value}
          options={{ copy: true }}
          data={{ value, source }}
        />
      );
    });
  }, [details.type, details.sources]);

  const regionNodes = useMemo(() => {
    const { sources, region: data } = details;
    let styles = {
      flex: 1,
    };
    const isMultiple = data.length > 1;

    const getValueSource = (index) => {
      if (!isMultiple) return undefined;
      return sources[index];
    };

    return data.map((value, index) => {
      let source = getValueSource(index);

      return (
        <InfoValue
          containerProps={{ sx: styles }}
          key={value}
          options={{ copy: true }}
          data={{ value, source }}
        />
      );
    });
  }, [details.region, details.sources]);

  const statusNodes = useMemo(() => {
    const { sources, status: data } = details;
    let styles = {
      flex: 1,
    };
    const isMultiple = data.length > 1;
    const isEmpty = data.length === 0;

    const getValueSource = (index) => {
      if (!isMultiple) return undefined;
      return sources[index];
    };

    if (isEmpty)
      return (
        <InfoValue options={{ copy: false }} data={{ value: EMPTY_VALUE }} />
      );

    return data.map((value, index) => {
      let source = getValueSource(index);

      return (
        <InfoValue
          containerProps={{ sx: styles }}
          key={value}
          options={{ copy: true }}
          data={{ value, source }}
        />
      );
    });
  }, [details.region, details.sources]);

  return (
    <SubPageContainer>
      <SubPageHeading>General</SubPageHeading>
      <SubPageContentContainer className='scrollable'>
        <RowContainer>
          <DataSection sx={{ flex: 1 }}>
            <FieldLabel>Account ID</FieldLabel>
            <RowContainer>{accountIdNodes}</RowContainer>
          </DataSection>
          <DataSection sx={{ flex: 1 }}>
            <FieldLabel>Asset Type</FieldLabel>
            <RowContainer>{typeNodes}</RowContainer>
          </DataSection>
        </RowContainer>

        <RowContainer>
          <DataSection sx={{ flex: 1 }}>
            <FieldLabel>Region</FieldLabel>
            <RowContainer>{regionNodes}</RowContainer>
          </DataSection>
          <DataSection sx={{ flex: 1 }}>
            <FieldLabel>Status</FieldLabel>
            <RowContainer>{statusNodes}</RowContainer>
          </DataSection>
        </RowContainer>

        <DataSection>
          <FieldLabel>Technical Summary</FieldLabel>
          <MarkdownContainer className='scrollable'>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              children={details.description}
              components={{
                p({ children }) {
                  return <Typography variant='body2'>{children}</Typography>;
                },
                em({ children }) {
                  return <em className='important-text'>{children}</em>;
                },
                pre({ children }) {
                  return (
                    <MarkdownPreComponent>{children}</MarkdownPreComponent>
                  );
                },
                li({ children }) {
                  return <MarkdownListItem>{children}</MarkdownListItem>;
                },
                table: ({ children }) => {
                  return <MarkdownTable>{children}</MarkdownTable>;
                },

                td: ({ children }) => (
                  <MarkdownTableCell>{children}</MarkdownTableCell>
                ),
                thead: ({ children }) => (
                  <MarkdownTableHead>{children}</MarkdownTableHead>
                ),
                th: ({ children }) => {
                  return (
                    <MarkdownTableHeading>{children}</MarkdownTableHeading>
                  );
                },
              }}
            />
          </MarkdownContainer>
        </DataSection>
        <DataSection>
          <CommandJson />
        </DataSection>
      </SubPageContentContainer>
    </SubPageContainer>
  );
};
