import { Fragment } from "react";
import { MaterialReactTable } from "material-react-table";
import { TableContent, TableWrapper } from "./styled";
import { usePrimaryTable } from "../../../../hooks/usePrimaryTable";

export const RootTable = () => {
  const table = usePrimaryTable();

  return (
    <Fragment>
      <TableWrapper>
        <TableContent>
          <MaterialReactTable table={table} />
        </TableContent>
      </TableWrapper>
    </Fragment>
  );
};
