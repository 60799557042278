export const InitialState = Object.freeze({
  meta: {},
  data: [],
  isLoading: false,
  error: false,
  errorPayload: null,
  paginationConfig: {
    pageIndex: 0,
    pageSize: 20,
  },
  showNewAlertView: false,
  newAlertPayload: {
    data: null,
    isLoading: false,
    error: false,
    errorPayload: null,
  },
  deleteQueryPayload: {
    open: false,
    data: null,
    isLoading: false,
    error: false,
    errorPayload: null,
  },
  editQueryPayload: {
    open: false,
    data: null,
    isLoading: false,
    error: false,
    errorPayload: null,
  },
});
