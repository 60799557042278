import { createContext } from "react";
import { InitialState } from "../state-management/initialState";

export const AssetsTableMethodsContext = createContext();

export const AssetInventoryPageMetaContext = createContext(
  InitialState.pageMeta
);

export const AssetInventoryPrimaryTableStateContext = createContext(
  InitialState.primaryTableMeta
);

export const AssetInventoryGroupingTableStateContext = createContext(
  InitialState.groupingTableMeta
);

export const AssetInventoryDataContext = createContext({
  filters: [],
  primaryTableColumns: [],
  grouping: [],
  currentGroupingValue: null,
  assetOptionsMap: {},
  searchKeyword: "",
  assetDetailVisible: false,
});

export const AssetInventoryDispatchContext = createContext();
