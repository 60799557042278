export const InitialState = {
  savedQueriesTableState: {
    isLoading: false,
    pagination: {
      pageIndex: 0,
      pageSize: 20,
    },
    data: [],
    meta: {},
    error: null,
  },
};
