export const DataFilteringOperators = Object.freeze({
  IS: {
    value: "is",
    label: "Is",
    types: new Set(["any", "number"]),
  },
  IS_NOT: {
    value: "is-not",
    label: "Is not",
    types: new Set(["any"]),
  },
  CONTAINS: {
    value: "contains",
    label: "Contains",
    types: new Set(["any"]),
  },
  DOES_NOT_CONTAIN: {
    value: "does-not-contain",
    label: "Does not contain",
    types: new Set(["any"]),
  },
  STARTS_WITH: {
    value: "starts-with",
    label: "Starts with",
    types: new Set(["any"]),
  },
  ENDS_WITH: {
    value: "ends-with",
    label: "Ends with",
    types: new Set(["any"]),
  },
  IS_EMPTY: {
    value: "is-empty",
    label: "Is empty",
    types: new Set(["any"]),
  },
  IS_NOT_EMPTY: {
    value: "is-not-empty",
    label: "Is not empty",
    types: new Set(["any"]),
  },
  IS_VALUE_OF: {
    value: "is-value-of",
    label: "Is value of",
    types: new Set(["any"]),
  },
  IS_ONE_OF: {
    value: "is-one-of",
    label: "Is one of",
    types: new Set(["any"]),
  },
  IS_NOT_ONE_OF: {
    value: "is-not-one-of",
    label: "Is not one of",
    types: new Set(["any"]),
  },
  EXISTS: {
    value: "exists",
    label: "Exists",
    types: new Set(["any"]),
  },
  DOES_NOT_EXIST: {
    value: "not-exists",
    label: "Does not Exist",
    types: new Set(["any"]),
  },

  LESS_THAN_OR_EQ: {
    value: "less-than-or-equal",
    label: "≤",
    types: new Set(["number"]),
  },

  GREATER_THAN_OR_EQ: {
    value: "greater-than-or-equal",
    label: "≥",
    types: new Set(["number"]),
  },

  SMALLER_THAN: {
    value: "smaller-than",
    label: "<",
    types: new Set(["number"]),
  },

  BIGGER_THAN: {
    value: "bigger-than",
    label: ">",
    types: new Set(["number"]),
  },
});

export const EmptyValueOperatorsMap = Object.freeze({
  [DataFilteringOperators.IS_EMPTY.value]: true,
  [DataFilteringOperators.IS_NOT_EMPTY.value]: true,
  [DataFilteringOperators.EXISTS.value]: true,
  [DataFilteringOperators.DOES_NOT_EXIST.value]: true,
});

export const MultipleValuesFilterOperators = Object.freeze({
  [DataFilteringOperators.IS_NOT_ONE_OF.value]: true,
  [DataFilteringOperators.IS_ONE_OF.value]: true,
  [DataFilteringOperators.IS_VALUE_OF.value]: true,
});
