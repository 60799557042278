import { styled } from "@mui/material";

export const Header = styled("header")(({ theme }) => {
  return `
  border-bottom: ${theme.border.main};
  `;
});

export const PageBody = styled("main")`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;
