import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistedStore, store } from "./store";

const StateManagementProvider = ({
  children,
  loadingFallbackComponent = null,
}) => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={loadingFallbackComponent}
        persistor={persistedStore}
      >
        {children}
      </PersistGate>
    </Provider>
  );
};

export default StateManagementProvider;
