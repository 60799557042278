import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputBase,
  Typography,
} from "@mui/material";
import styles from "../styles.module.css";
import { MuiStyles } from "../styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { generateUid } from "../generateUID";
import { defaultArnRole } from "../../data";
import { useAwsCloudFormationIntegrationValueMutators } from "../../hooks/useAwsCloudFormationIntegrationValuesMutators";
import { useAwsCloudFormationIntegrationValues } from "../../hooks/useAwsCloudFormationIntegrationValues";
import ConditionComponent from "../../../../utils/ConditionComponent";

function AccountInput({
  account,
  isGenerated,
  externalId,
  role,
  selfRole,
  handleAccountChange,
  handleExternalIdChange,
  handleSelfRoleChange,
}) {
  const { setIntegrationName, setArnRole } =
    useAwsCloudFormationIntegrationValueMutators();
  const { integrationName, isManual, arnRole } =
    useAwsCloudFormationIntegrationValues();
  const handleOnClickRegenerate = () => {
    handleExternalIdChange(generateUid());
  };

  const onChangeIntegrationName = (ev) => {
    const value = ev.target.value;
    setIntegrationName(value);
  };

  const onChangeArnRole = (ev) => {
    const value = ev.target.value;
    setArnRole(value);
  };

  return (
    <Box sx={MuiStyles.inputsSection}>
      <Box sx={MuiStyles.inputGroup}>
        <Box sx={MuiStyles.fieldBlock}>
          <Box sx={MuiStyles.label}>
            <Typography variant="caption" className={styles.awsAccIdText}>
              Integration Name *
            </Typography>
          </Box>
          <InputBase
            id="integration-name"
            label="Integration Name"
            value={integrationName}
            onChange={onChangeIntegrationName}
            placeholder="AWS Adapter"
            className={styles.accountIdInput}
          />
        </Box>
        <ConditionComponent condition={isManual}>
          <Box sx={{ ...MuiStyles.fieldBlock, flex: 1 }}>
            <Box sx={MuiStyles.label}>
              <Typography variant="caption" className={styles.awsAccIdText}>
                ARN Role *
              </Typography>
            </Box>
            <InputBase
              label="ARN Role"
              value={arnRole}
              onChange={onChangeArnRole}
              placeholder="ARN Role"
              fullWidth
              className={styles.accountIdInput}
            />
          </Box>
        </ConditionComponent>
      </Box>
      <Box sx={MuiStyles.inputGroup}>
        <ConditionComponent condition={!isManual}>
          <Box sx={MuiStyles.fieldBlock}>
            <Typography variant="caption" className={styles.awsAccIdText}>
              ARN Role
            </Typography>

            <Typography variant="body1">{role || defaultArnRole}</Typography>
          </Box>
        </ConditionComponent>
      </Box>
      <Box sx={MuiStyles.inputGroup}>
        <Box sx={MuiStyles.fieldBlock}>
          <Box sx={MuiStyles.label}>
            <Typography variant="caption" className={styles.awsAccIdText}>
              Account ID *
            </Typography>
          </Box>
          <InputBase
            id="accountId"
            label="Account ID *"
            type="number"
            value={account}
            onChange={handleAccountChange}
            placeholder="123456789012"
            readOnly={isGenerated}
            inputProps={{ inputMode: "numeric" }}
            className={styles.accountIdInput}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={MuiStyles.label}>
            <Typography variant="caption" className={styles.awsAccIdText}>
              External ID *
            </Typography>
          </Box>

          <InputBase
            id="externalId"
            className={styles.accountIdInput}
            value={externalId}
            onChange={handleExternalIdChange}
            required
            fullWidth
            placeholder="5e9140cf-1ee7-4351-a3ec-28cf1e7e69b"
            inputProps={{ maxLength: 36 }}
          />
          <Button
            onClick={handleOnClickRegenerate}
            sx={MuiStyles.regenerateBtn}
            variant="contained"
          >
            Regenerate
          </Button>
        </Box>
      </Box>
      <Box sx={MuiStyles.advancedSection}>
        <Accordion disableGutters sx={MuiStyles.advancedAccordion}>
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon sx={MuiStyles.accordionSummaryIcon} />
            }
            sx={MuiStyles.advancedAccordionSummary}
          >
            Advanced
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={MuiStyles.fieldBlock}>
              <Typography variant="caption" className={styles.awsAccIdText}>
                Self Role
              </Typography>
              <InputBase
                id="selfRole"
                label="Self Role"
                value={selfRole}
                className={styles.accountIdInput}
                onChange={handleSelfRoleChange}
                placeholder="arn:aws:iam::111111111111:role/company-assume-role"
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default AccountInput;
