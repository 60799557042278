import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./initialState";
import { getAssetOptions } from "./thunks/getAssetOptions";

const slice = createSlice({
  initialState: InitialState,
  name: "inventory",
  extraReducers(builder) {
    builder.addCase(getAssetOptions.pending, (state, action) => {
      state.pageMeta.isLoading = true;
      state.pageMeta.error = false;
      state.pageMeta.errorPayload = null;
    });

    builder.addCase(getAssetOptions.fulfilled, (state, action) => {
      const { payload } = action;
      state.assetOptions.data = payload.data;
      state.assetOptions.apiConfig.lastQueryTime = payload.lastQueryTime;
      state.pageMeta.isLoading = false;
      state.pageMeta.error = false;
      state.pageMeta.errorPayload = null;
    });

    builder.addCase(getAssetOptions.rejected, (state, { payload }) => {
      state.pageMeta.isLoading = false;
      state.pageMeta.error = true;
      state.pageMeta.errorPayload = payload.error;
    });
  },
});

const reducer = slice.reducer;

export { reducer };
