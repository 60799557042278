const migrations = {
  0(state) {
    return {
      onboarding: state.onboarding,
      integration: state.integration,
      user: {},
    };
  },
};

export { migrations };
