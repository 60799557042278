import { defaultColumns } from "../defaultColumns";

export function createTagValuesMapFromGraphData(graphData) {
  const defaultTagsSet = new Set(defaultColumns.map((col) => col.name));
  const tagValuesMap = new Map();

  const checkFieldIsDefaultTag = (field) => defaultTagsSet.has(field);

  graphData.nodes.forEach((node) => {
    for (const field in node) {
      if (checkFieldIsDefaultTag(field)) {
        if (tagValuesMap.has(field)) {
          const fieldValue = node[field];
          if (!fieldValue) continue;
          const tagValues = tagValuesMap.get(field);
          tagValues.add(fieldValue);
        } else {
          const fieldValue = node[field];
          if (!fieldValue) continue;
          tagValuesMap.set(field, new Set([fieldValue]));
        }
      } else if (field === "tags") {
        const nodeHasTags = Object.keys(node.tags ?? {}).length > 0;
        if (node.tags && nodeHasTags) {
          for (const tag in node.tags) {
            if (tagValuesMap.has(tag)) {
              const tagValues = tagValuesMap.get(tag);
              const tagValue = node.tags[tag];
              tagValues.add(tagValue);
            } else {
              const tagValue = node.tags[tag];
              tagValuesMap.set(tag, new Set([tagValue]));
            }
          }
        }
      }
    }
  });

  tagValuesMap.forEach((value, key, map) => {
    const numberOfItems = value.size;
    map.set(key, numberOfItems);
  });

  return tagValuesMap;
}
