import { Button, InputBase, Popover, styled, Typography } from "@mui/material";
import MuiCloseIcon from "@mui/icons-material/Close";
import { forwardRef } from "react";

export const MenuContainer = styled(Popover)(({ theme }) => {
  return `
  & .MuiPopover-paper {
    width: 600px;
    height: 600px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
     border:1px solid ${theme.palette.grey[400]};
    box-shadow: ${theme.shadows[2]};
    background-color: ${theme.palette.background.default};
  }
`;
});

export const MenuHeaderContainer = styled("div")(
  () => `
display: flex;
height: 48px;
box-sizing: border-box;
align-items:center;
padding: 8px 12px;
border-bottom: 1px solid #ccc;
`
);

export const CloseIcon = styled(MuiCloseIcon)(
  () => `
margin-left: auto;
cursor: pointer;
`
);

export const MenuContentContainer = styled("div")`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  padding: 12px;
  flex: 1;
  overflow-y: hidden;
`;

export const SearchSectionContainer = styled("div")`
  min-height: 80px;
  max-height: 100px;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
`;

export const ListSectionLabel = styled(
  forwardRef((props, ref) => (
    <Typography ref={ref} {...props} variant='caption' />
  ))
)`
  && {
    font-weight: 500;
  }
`;

export const SelectedGroupingContentContainer = styled("div")`
  height: calc(100% - 24px);
`;

export const StyledInput = styled(InputBase)`
  width: 350px;
  max-width: 500px;
  height: 30px;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px !important;
  border: 1px solid #ccc;
  font-family: Inter, serif;
`;

export const SelectionsContainer = styled("div")`
  min-height: 100px;
  max-height: 200px;
  grid-row: 2 / 3;
  grid-column: 1 / -1;
`;

export const UserDataSection = styled("div")(() => {
  return `
  grid-row: 3/4;
  grid-column: 1 / 3;
  overflow-y: hidden;
  padding-bottom: 12px;
  `;
});

export const CustomFieldsSection = styled("div")`
  overflow-y: auto;
  height: calc(100% - 14px);
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const MenuFooter = styled("div")`
  flex-shrink: 0;
  display: flex;
  height: 56px;
  border-top: 1px solid #ccc;
  justify-content: space-between;
  padding: 6px 12px;
  box-sizing: border-box;
  margin-top: auto;
`;

const FilterMenuBottomButton = styled(Button)`
  && {
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    text-transform: none;
    border-radius: 8px;
    column-gap: 4px;
    padding: 6px;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
`;

export const FilterGroupButton = styled(FilterMenuBottomButton)(
  ({ textColor, bgColor, border }) => `
 &&& {
   color: ${textColor ?? "#000033"};
  background-color: ${bgColor ?? "transparent"};
  display: flex;
  border: ${border};
  margin-left: auto;
  &:hover {
  background-color: ${bgColor ?? "transparent"};
    }
  }
`
);

export const ButtonLabel = styled((props) => (
  <Typography {...props} variant='body2' />
))`
  color: #000033;
`;
