import { styled } from "@mui/material";
import { PageLayout } from "../../../../shared-component/PageLayout";

export const InsightsBlocksRoot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 24px;
`;

export const PageRoot = styled(PageLayout)`
  display: flex;
  flex-direction: column;
`;
