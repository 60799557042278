import { showAlert } from "../../../../app/alert/showAlert";
import { InventoryService } from "../../../../services/inventoryService";
import { Actions } from "../stateManagement/actions";
import { useDispatch } from "./useDispatch";
import { useFetchData } from "./useFetchdata";
import { useGlobalState } from "./useGlobalState";

export function useEditQuery() {
  const { editQueryPayload } = useGlobalState();
  const dispatch = useDispatch();
  const fetchData = useFetchData();

  const openView = (payload) => {
    dispatch({
      type: Actions.SHOW_EDIT_QUERY_VIEW,
      payload,
    });
  };

  const closeView = () => {
    dispatch({
      type: Actions.CLOSE_EDIT_QUERY_VIEW,
    });
  };

  const editQuery = async (payload) => {
    try {
      dispatch({
        type: Actions.EDIT_QUERY_PENDING,
      });
      await InventoryService.getInstance().editQuery(payload);
      dispatch({
        type: Actions.EDIT_QUERY_SUCCESS,
        payload,
      });
      await fetchData();
    } catch (error) {
      const errorMessage = error.message;
      showAlert(errorMessage, { type: "error" });
      dispatch({
        type: Actions.EDIT_QUERY_ERROR,
        payload: error.message,
      });
      throw error;
    }
  };

  return {
    ...editQueryPayload,
    editQuery,
    openView,
    closeView,
  };
}
