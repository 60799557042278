import React, { useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const PandoTable = ({ title, data, onSelectionChange }) => {
  const [rowSelection, setRowSelection] = useState({}); //ts type available

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 10, //small column
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 20, //small column
      },
      {
        accessorKey: "deployment" || "category",
        header: "Asset Type",
        size: 20,
        enableFiltering: true,
      },
      {
        accessorKey: "operational_owner",
        header: "Owner",
        size: 20, //small column
      },
      {
        accessorKey: "purpose",
        header: "Purpose",
        Cell: ({ cell }) => <div> {cell.getValue()} </div>,
        size: 60, // adjust the size accordingly
      },
    ],
    [],
  );

  useEffect(() => {
    const selectedRowsData = data.filter((row, index) => rowSelection[index]);

    onSelectionChange(selectedRowsData);
  }, [rowSelection, data]);

  return (
    <MaterialReactTable
      columns={columns}
      state={{ rowSelection }}
      data={data}
      enableColumnFilters={true}
      enableSorting={false}
      enableStickyHeader={true}
      enableRowSelection
      enableFiltering
      onRowSelectionChange={setRowSelection}
      muiTableContainerProps={{
        sx: { height: "350px" },
      }}
      muiTableBodyRowProps={{
        sx: {
          height: "45px",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
        },
      }}
    />
  );
};

export default PandoTable;
