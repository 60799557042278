import { Button, InputBase, styled, Typography } from "@mui/material";
import Selector from "react-select";
import { Chip } from "../../../../../shared-component/Chip";
import { CMSTChip } from "../Chip";

export const SecurityTasksRoot = styled("div")(({ theme }) => {
  const bg = theme.palette.background.paper;

  return `
  background-color: ${bg};
  display: flex;
  flex-direction:column;
  row-gap: 16px;
  padding: 18px;
  border-radius: 8px;
  max-height: 600px;
  `;
});

export const SecurityTasksListRoot = styled("div")(() => {
  return `
  display: flex;
  flex-direction:column;
  row-gap: 12px;
  `;
});

export const TaskRoot = styled("div")(() => {
  return `
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;
  min-width: 600px;
  `;
});

export const TaskLabel = styled(({ children, ...props }) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))`
  flex: 1;
  line-break: anywhere;
`;

export const TaskActionsRoot = styled("div")(() => {
  return `
  display: flex;
  column-gap: 8px;
  `;
});

export const SelectValueContainer = styled("div")(({ bgColor, textColor }) => {
  return `
  display: flex;
  height: 24px;
  border-radius: 12px;
  background-color: ${bgColor};
  `;
});

export const SelectValueContainerRoot = styled("div")(() => {
  return `
  display: flex;
  height: 24px;
  align-items:center;
  `;
});

export const SingleValueRoot = styled((props) => (
  <Typography variant='caption' {...props} />
))(({ textColor }) => {
  return `
  color: ${textColor};
  position: relative;
  padding-left: 8px;
  `;
});

export const Select = styled((props) => <Selector isDisabled {...props} />)(
  () => {
    return `
  font-size: 12px;
  max-width: 120px;
  font-size: 12px;
  margin-top: 8px;
  `;
  }
);

export const DeleteButton = styled(Button)(({ theme }) => {
  return `
  flex-shrink: 0;
  min-width: 0;
  background-color: ${theme.palette.error.main};
  color: #fff !important;
  width: 30px;

 &:disabled { 
    background-color: ${theme.palette.grey[500]};
  }

  & > svg {
  height: 20px;
  width: 20px;
  }
  `;
});

export const DoneButton = styled(DeleteButton)(({ theme }) => {
  return `
  background-color: ${theme.palette.success.main} !important;
  `;
});

export const TaskChip = styled(CMSTChip)(() => {
  return `
  margin-top: 8px;
  `;
});

export const TasksNewActionRoot = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const NewButton = styled(Button)(() => {
  return `
  min-width: 0;

  `;
});

export const Input = styled(InputBase)(({ theme }) => {
  const borderColor = theme.palette.grey[500];
  return `
  border: 1px solid ${borderColor};
  border-radius: 6px;
  padding: 0 6px;
  font-size: 14px;
  `;
});

export const NewTaskStatusSelector = styled(Selector)(({ theme }) => {
  return `
  `;
});

export const NewTaskRoot = styled("div")(() => {
  return `
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  `;
});

export const NewTaskContentRoot = styled("div")(() => {
  return `
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  column-gap: 12px;
  `;
});

export const AddTaskButton = styled(Button)(({ theme }) => {
  return `
  min-width: 0;
  min-height: 0;
  padding: 4px;
  display: flex;
  align-items:center;
  width: fit-content;
  background-color: #1E40AF;
  color: #fff;
  font-size: 12px;

  & > svg {
  height: 14px;
  width: 14px;
  }

  &:disabled { 
    background-color: ${theme.palette.grey[500]};
  }
  `;
});
