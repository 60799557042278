import { checkValueIsPrimitive } from "../typeUtils/valueTypeCheckerUtils";

const getItem = (key) => {
  try {
    const data = localStorage.getItem(key);
    if (!data) return null;
    let valueToReturn = data;
    if (!checkValueIsPrimitive(data)) {
      valueToReturn = JSON.parse(data);
    }
    return valueToReturn;
  } catch (error) {
    return null;
  }
};

const setItem = (key, value) => {
  try {
    if (!value || !key) return;
    let valueToSet = value;
    if (!checkValueIsPrimitive(value)) valueToSet = JSON.stringify(value);
    localStorage.setItem(key, valueToSet);
  } catch (error) {
    console.warn("There was an error setting a local storage value");
  }
};

export const LocalStorage = Object.freeze({
  getItem,
  setItem,
  clear: localStorage.clear,
});
