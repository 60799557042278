import { CircularProgress, Dialog, Typography } from "@mui/material";
import {
  CancelButton,
  DeleteButton,
  DeleteDialogTitle,
  DeleteDialogueContent,
  DialogFooter,
  DialogIntegrationContainer,
  ErrorMessage,
  IntegrationLogo,
} from "./styled";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { useDeleteIntegration } from "./hooks/useDeleteIntegration";

const Content = ({ data, onCloseDialog, onDeleteSuccess }) => {
  const onSuccess = () => {
    onDeleteSuccess(data);
    onCloseDialog();
  };
  const { error, handler, loading } = useDeleteIntegration(data, onSuccess);

  const onClickDelete = async () => {
    await handler();
  };

  return (
    <DeleteDialogueContent>
      <DeleteDialogTitle>
        Are you sure you want to delete this integration?
      </DeleteDialogTitle>
      <DialogIntegrationContainer>
        <IntegrationLogo src={data.logo} alt={`${data.name} logo`} />
        <Typography variant='body1'>{data.name}</Typography>
      </DialogIntegrationContainer>
      <ConditionComponent condition={!!error}>
        <ErrorMessage variant='caption'>
          Failed to delete integration. Try again
        </ErrorMessage>
      </ConditionComponent>
      <DialogFooter>
        <CancelButton onClick={onCloseDialog} disableRipple>
          Cancel
        </CancelButton>
        <DeleteButton disableRipple onClick={onClickDelete}>
          <ConditionComponent condition={loading} falsyNode={"Delete"}>
            <CircularProgress size={24} sx={{ color: "#fff" }} />
          </ConditionComponent>
        </DeleteButton>
      </DialogFooter>
    </DeleteDialogueContent>
  );
};

export const DeleteConnectorDialog = ({ data, ...props }) => {
  return (
    <Dialog {...props}>
      <ConditionComponent condition={!!data}>
        <Content
          data={data}
          onDeleteSuccess={props.onDeleteSuccess}
          onCloseDialog={props.onClose}
        />
      </ConditionComponent>
    </Dialog>
  );
};
