export const InitialState = {
  projectsTableState: {
    isLoading: false,
    pagination: {
      pageIndex: 0,
      pageSize: 20,
    },
    data: {},
    meta: {},
    error: false,
    errorPayload: null,
  },
};
