import { createOptionId } from "../../../../components/Grouping/utils/createOptionId";

function filterToColumnAdapter(filter) {
  const { value, label, source } = filter.field;
  const id = createOptionId(filter.field, "options");

  return {
    value,
    label,
    source,
    id,
    checked: false,
  };
}

function checkFilterFieldInColumns(fieldColumnInstance, cols) {
  const fieldInCols = cols.find((col) => col.id === fieldColumnInstance.id);
  return !!fieldInCols;
}

export function filtersToColumnAdapter(filters, cols) {
  // remove already selected fields from cols
  let updatedColumns = [...cols];

  filters.forEach((filter) => {
    const filterColumnObject = filterToColumnAdapter(filter);
    if (checkFilterFieldInColumns(filterColumnObject, updatedColumns)) {
      if (!filter.addToColumns) {
        const filteredCols = updatedColumns.filter(
          (col) => col.id !== filterColumnObject.id
        );
        updatedColumns = filteredCols;
      }
    } else if (filter.addToColumns) {
      updatedColumns.push(filterColumnObject);
    }
  });

  return updatedColumns;
}
