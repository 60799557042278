import { Button, IconButton, Typography, styled } from "@mui/material";
import { Chip } from "../../../pages/inventory/components/AssetDetailDrawer/Chip";

export const CardContainer = styled("div")`
  position: relative !important;
  display: flex !important;
  min-width: 250px;
  flex-direction: column;
  justify-content: center !important;
  gap: 10px !important;
  box-sizing: border-box !important;
  background-color: #5c757d20;
  border: 1px solid #5c757d50;
  padding: 12px 8px;
  border-radius: 8px;
`;

export const ImageContainer = styled("div")`
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardTopSection = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const ConnectButton = styled(Button)`
  &&& {
    background-color: #002733;
    color: #00ffb2;
    border-radius: 8px;
    text-transform: none;
    font-size: 12px;
    padding: 4px;
    height: 32px;
  }
`;

export const DeleteButton = styled(IconButton)`
  &&& {
    background-color: #f91c5110;
    border-radius: 8px;
    padding: 4px !important;
    height: 30px !important;
    color: #f91c5190 !important;
    border: 1px solid #f91c5160 !important;
    :focus {
      outline: none;
    }
  }
`;

export const Footer = styled(CardTopSection)`
  margin-top: 6px;
  justify-content: space-between;
`;

export const ComingSoon = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 600;
    color: #ff7596;
  }
`;

export const ConnectedChipContainer = styled(Chip)`
  && {
    background-color: #00a37220;
    color: #00a372;
    border: 1px solid #00a372 !important;
    font-size: 12px !important;
  }
`;
