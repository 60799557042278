import { useMaterialReactTable } from "material-react-table";
import { useColumns } from "./useColumns";
import { useGlobalState } from "./useGlobalState";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useDeleteAction } from "./useDeleteAction";
import {
  DeleteButton,
  EditButton,
  TableActionsRoot,
} from "../../../shared-component/TableActionButtons/styled";
import { useEditAction } from "./useEditAction";

export function useActionsTable() {
  const columns = useColumns();
  const {
    isLoading,
    paginationConfig: pagination,
    data = [],
  } = useGlobalState();
  const meta = { total: 0 };

  const { open } = useDeleteAction();
  const { open: openEditAction } = useEditAction();

  const handleOnPaginationChange = () => {};

  const onClickRow = () => {};

  return useMaterialReactTable({
    columns,
    state: {
      isLoading,
      pagination,
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 100,
      },
    },
    renderRowActions({ row }) {
      const openDeleteAction = () => {
        open(row.original);
      };

      const openEdit = () => {
        openEditAction(row.original);
      };

      return (
        <TableActionsRoot>
          <EditButton onClick={openEdit}>Edit</EditButton>
          <DeleteButton onClick={openDeleteAction}>
            <DeleteRoundedIcon sx={{ fontSize: "18px", color: "#fff" }} />
          </DeleteButton>
        </TableActionsRoot>
      );
    },
    data,
    positionActionsColumn: "last",
    onPaginationChange: handleOnPaginationChange,
    rowCount: meta?.total ?? 0,
    enableRowActions: true,
    paginationDisplayMode: "pages",
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePagination: true,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    layoutMode: "grid",
    manualPagination: true,
    muiTableBodyRowProps({ row }) {
      return {
        onClick: (ev) => onClickRow(ev, row),
        sx: {
          cursor: "pointer",
        },
      };
    },
    muiTableContainerProps: {
      sx: {
        height: "calc(100% - 72px)",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        height: "40px",
      },
    },
  });
}
