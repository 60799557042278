import { useState } from "react";
import { copyToClipboard } from "../utils/browserUtils/copyToClipboard";
import { debounce } from "@mui/material";

export function useCopyToClipboard({ value, resetDelayDuration = 2500 }) {
  const [copied, setCopied] = useState(false);

  const resetCopiedState = () => setCopied(false);

  const onClickCopy = () => {
    if (!value || copied) return;
    copyToClipboard(value);
    setCopied(true);
    debounce(resetCopiedState, resetDelayDuration)();
  };

  return {
    onClickCopy,
    copied,
    setCopied,
  };
}
