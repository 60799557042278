import { useCallback, useImperativeHandle, useMemo, useState } from "react";
import { FilterGroupButton } from "../../../views/G6Graph/components/FilterSelector/styled";
import { GroupByTriggerButtonRef } from "../../../views/G6Graph/components/FilterSelector/globalRefs";
import { MenuHeader } from "../../../views/G6Graph/components/FilterSelector/MenuHeader";
import {
  CustomFieldsSection,
  Label,
  MenuContainer,
  MenuContentContainer,
  MenuFooter,
  PlatformDataSection,
  SearchSectionContainer,
  SectionContent,
  SelectedGroupingContentContainer,
  SelectionsContainer,
  UserDataSection,
} from "../styled";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import {
  ListSectionLabel,
  StyledInput,
} from "../../G6Graph/GraphGrouping/styled";
import { EmptyDataSection } from "../../EmptyData";
import { DataGroupsSources } from "../data";
import ConditionComponent from "../../../utils/ConditionComponent";
import { useTransition } from "react";
import { LoadingSection } from "../../LayoutFillers";
import { Typography } from "@mui/material";
import { GroupOption } from "./GroupOptionValue";
import { createOptionId } from "../utils/createOptionId";
import { useCustomFieldOptions } from "../hooks/useCustomFieldOptions";

function getDataOptions(data, key) {
  const results = { options: [], valueHash: {} };
  if (!data || !key || !data?.[key]) return results;
  results.valueHash = data[key];
  results.options = Object.keys(data[key]);

  return results;
}

/**
 *
 * @param {*} param0
 * @returns
 * @emits effect used in DataTable component to update grouped data results
 */
export const DataGroupingMenu = ({
  menuContainerProps = {},
  onClearGrouping,
  data,
  handleCloseMenu,
  menuOpened,
  onSelectValue,
  viewConfig = {},
  bindings,
  value: values = [],
  setValue,
  optionFilter,
  title,
  checkOptionIsSelected,
  anchorEl,
  noDataPlaceholderText,
  isMulti = true,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showContent, setShowContent] = useState();
  const startSearchTransition = useTransition()[1];
  const [renderingModalContent, startModalContentTransition] = useTransition();

  const noDataPlaceholder = useMemo(() => {
    if (noDataPlaceholderText) return noDataPlaceholderText;
    if (title) return `No selected ${title} data`;

    return "No data";
  }, [noDataPlaceholderText, title]);

  const removeOptionFromValues = (option) => {
    const newValues = values.filter(({ id }) => id !== option.id);
    setValue(newValues);
  };

  const addOptionToValues = (option) => {
    let newValues;
    if (isMulti) {
      newValues = [...values];
      newValues.push(option);
    } else {
      newValues = [option];
    }
    setValue(newValues);
  };

  const checkOptionIsChecked = (option) => {
    if (checkOptionIsSelected) return checkOptionIsSelected(option);
    return values.some((val) => {
      let id = val.id;
      if (!id) {
        id = createOptionId(val, "options");
      }
      return id === option.id;
    });
  };

  const handleOnClickGroupValue = (ev, option) => {
    const isSelected = checkOptionIsChecked(option);
    if (isSelected) {
      removeOptionFromValues(option);
    } else {
      addOptionToValues(option);
    }
  };

  /**
   *
   * @param {*} option
   * @param {*} searchQuery
   * @returns
   * empty, wildcard, str
   * str, wildcard, empty
   * vpc*
   */
  const execWildcardSearch = (option, searchQuery) => {
    const searchParts = searchQuery.split("*");
    let matches = true;
    searchParts.forEach((part) => {
      if (!part) {
        const valueMatchesSearch = option.value?.toLowerCase()?.includes(part);
        const typeMatchesSearch = option.originationTypes?.some((type) =>
          type?.toLowerCase().includes(part)
        );
        matches = matches && (valueMatchesSearch || typeMatchesSearch);
      } else {
        const valueMatchesSearch = option.value?.toLowerCase()?.includes(part);
        const typeMatchesSearch = option.originationTypes?.some((type) =>
          type?.toLowerCase().includes(part)
        );
        matches = matches && (valueMatchesSearch || typeMatchesSearch);
      }
    });
    return matches;
  };

  const execDefaultSearch = (option, searchQuery) => {
    const valueMatchesSearch = option.value
      ?.toLowerCase()
      ?.includes(searchQuery);
    const typeMatchesSearch = option.originationTypes?.some((type) =>
      type?.toLowerCase().includes(searchQuery)
    );
    return valueMatchesSearch || !!typeMatchesSearch;
  };

  const checkOptionMatchesSearchQuery = useCallback(
    (option) => {
      const areValidTypes =
        typeof searchValue === "string" || typeof option === "string";
      if (!searchValue) return true;
      if (!option || !areValidTypes) return false;

      const searchIsWildCard = searchValue.includes("*");
      const searchQueryStr = searchValue.toLowerCase();
      if (searchIsWildCard) {
        return execWildcardSearch(option, searchQueryStr);
      } else return execDefaultSearch(option, searchQueryStr);
    },
    [searchValue]
  );

  const platformOptions = useMemo(() => {
    if (!menuOpened)
      return {
        options: [],
        valueHash: {},
      };

    return getDataOptions(data, "platform");
  }, [data, menuOpened]);

  const customFieldOptionNodes = useCustomFieldOptions({
    data,
    getOptionCheckedState: checkOptionIsChecked,
    handleOnClickGroupValue,
    shouldReturnEmpty: !menuOpened,
    checkOptionMatchesSearchQuery,
    searchValue,
    optionFilter,
  });

  /**
   * Optimize;
   * filter nodes for only valid values before saving
   */
  const platformFieldsNodes = useMemo(() => {
    const nodes = [];
    const { options, valueHash } = platformOptions;
    options.forEach((option) => {
      const optionValues = valueHash[option].values;
      const originationTypes = valueHash[option].types ?? [];
      const optionValueCount = optionValues.length;
      if (!optionValueCount) return;

      const label = `${option} (${optionValueCount})`;
      const searchOptionPayload = {
        value: option,
        originationTypes,
      };
      const matchesSearchQuery =
        checkOptionMatchesSearchQuery(searchOptionPayload);
      if (!matchesSearchQuery) {
        return;
      }
      const componentData = {
        label,
        value: option,
        source: DataGroupsSources.PLATFORM,
      };

      if (optionFilter && !optionFilter?.(componentData)) return;
      const id = createOptionId(componentData, "options");
      componentData.id = id;
      const checked = checkOptionIsChecked(componentData);
      componentData.checked = checked;

      nodes.push(
        <GroupOption
          key={id}
          data={componentData}
          handleOnClickGroupValue={handleOnClickGroupValue}
        />
      );
    });

    return nodes;
  }, [platformOptions, values, searchValue]);

  const handleClearSelection = () => {
    onSelectValue([]);
    onClearGrouping();
  };

  const handleOnChangeSearch = (ev) => {
    const value = ev.target.value;
    startSearchTransition(() => {
      setSearchValue(value);
    });
  };

  useImperativeHandle(bindings, () => {
    return {
      onOpenMenu() {
        startModalContentTransition(function () {
          setShowContent(true);
        });
      },
      onClose() {
        setShowContent(false);
      },
    };
  });

  return (
    <MenuContainer
      anchorEl={anchorEl}
      open={menuOpened}
      anchorOrigin={{
        horizontal: "left",
        vertical: 40,
      }}
      onClose={handleCloseMenu}
      {...menuContainerProps}
    >
      <MenuHeader handleOnClose={handleCloseMenu} />

      <ConditionComponent condition={renderingModalContent}>
        <LoadingSection>
          <Typography variant='caption'>One moment...</Typography>
        </LoadingSection>
      </ConditionComponent>

      <ConditionComponent condition={!renderingModalContent && showContent}>
        <MenuContentContainer>
          <SearchSectionContainer>
            <ListSectionLabel>Search</ListSectionLabel>
            <SelectedGroupingContentContainer>
              <StyledInput
                onChange={handleOnChangeSearch}
                placeholder='Search...'
              />
            </SelectedGroupingContentContainer>
          </SearchSectionContainer>

          <ConditionComponent condition={!!viewConfig?.showCurrentGroupings}>
            <SelectionsContainer>
              <ListSectionLabel>Selected Values</ListSectionLabel>
              <SelectedGroupingContentContainer className='scrollable'>
                <ConditionComponent
                  condition={!!values.length}
                  falsyNode={<EmptyDataSection label={noDataPlaceholder} />}
                >
                  {values.map((selectedValue) => {
                    const key = createOptionId(
                      selectedValue,
                      "current grouping"
                    );
                    const label = `${selectedValue.source}.${selectedValue.label}`;
                    const data = {
                      ...selectedValue,
                      label,
                      checked: true,
                    };
                    return (
                      <GroupOption
                        key={key}
                        data={data}
                        handleOnClickGroupValue={handleOnClickGroupValue}
                      />
                    );
                  })}
                </ConditionComponent>
              </SelectedGroupingContentContainer>
            </SelectionsContainer>
          </ConditionComponent>
          <PlatformDataSection>
            <ListSectionLabel>Platform Fields</ListSectionLabel>
            <SectionContent className='scrollable'>
              {platformFieldsNodes}
            </SectionContent>
          </PlatformDataSection>
          <UserDataSection>
            <ListSectionLabel>Custom Fields</ListSectionLabel>
            <CustomFieldsSection className='scrollable'>
              {customFieldOptionNodes}
            </CustomFieldsSection>
          </UserDataSection>
        </MenuContentContainer>
      </ConditionComponent>

      <ConditionComponent condition={viewConfig?.showFooter}>
        <MenuFooter>
          <FilterGroupButton
            sx={{ marginLeft: "0 !important" }}
            variant='text'
            border='1px solid #dee0e4'
            disableRipple
            bgColor='#F7F9FE'
            onClick={handleClearSelection}
          >
            <DeleteSweepOutlinedIcon />
            <Label>Clear All Groupings</Label>
          </FilterGroupButton>
        </MenuFooter>
      </ConditionComponent>
    </MenuContainer>
  );
};
