import { AppEnv } from "../../utils/AppEnv";
import { FilterConditions } from "../../views/G6Graph/components/FilterSelector/constants";

export function applyGraphDataFiltering(data, filters, showRelatedNodes) {
  if (!filters.filterGroups.length) return data;
  const filteredNodes = data.nodes.filter((node) =>
    nodeFilteringStrategy(node, filters)
  );
  let nodes = filteredNodes;
  if (showRelatedNodes) {
    const withConnectedNodes = getAllRelatedNodes(data, filteredNodes);
    nodes = withConnectedNodes;
  }
  const nodesIdMap = createNodesIdMap(nodes);
  const edges = data.edges.filter((edge) =>
    edgesFilteringStrategy(edge, nodesIdMap)
  );
  return { nodes, edges };
}

function getAllRelatedNodes(data, filteredNodes) {
  const { nodes, edges } = data;
  const adjacencyList = new Map();

  // Building adjacency list
  nodes.forEach((node) => adjacencyList.set(node.id, []));
  edges.forEach((edge) => {
    adjacencyList.get(edge.source)?.push(edge.target);
    adjacencyList.get(edge.target)?.push(edge.source);
  });

  const sources = [...filteredNodes];
  const visited = new Set();
  const allConnectedNodes = [];

  while (sources.length) {
    const currentNode = sources.shift();
    if (!visited.has(currentNode.id)) {
      allConnectedNodes.push(currentNode);
      visited.add(currentNode.id);
      adjacencyList.get(currentNode.id).forEach((neighborId) => {
        const neighborNode = nodes.find((node) => node.id === neighborId);
        if (neighborNode && !visited.has(neighborId)) {
          sources.push(neighborNode);
        }
      });
    }
  }

  return allConnectedNodes;
}

function nodeFilteringStrategy(node, filters) {
  const { filterGroups } = filters;

  return applyFiltersToNode(node, filterGroups);
}

function edgesFilteringStrategy(edge, nodesIdMap) {
  const hasSource = nodesIdMap[edge.source];
  const hasTarget = nodesIdMap[edge.target];
  return hasSource && hasTarget;
}

function applyFiltersToNode(node, filters) {
  const passes = filters.some((filter) => {
    const passes = checkNodePassesFilter(node, filter);
    return passes;
  });
  return passes;
}

function checkNodePassesFilter(node, filter) {
  const category = filter.category.value;
  const categorySource = filter.category.source;
  const condition = filter.condition.value;

  let nodeValue = node[category];

  if (categorySource === "tag") {
    nodeValue = node.tags?.[category];
  }

  if (condition === FilterConditions.IS) {
    return filter.values.some((value) => value.value === nodeValue);
  } else if (condition === FilterConditions.IS_NOT) {
    return filter.values.every((value) => value.value !== nodeValue);
  }
}

function createNodesIdMap(nodes) {
  return nodes.reduce((store, node) => {
    store[node.id] = node;
    return store;
  }, {});
}

if (AppEnv.env === "test") {
  module.exports = {
    getAllRelatedNodes,
  };
}
