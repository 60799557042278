import { Button, styled } from "@mui/material";

export const IconButtonRoot = styled(Button)(() => {
  return `
  border-radius: 6px;
  padding: 0;
  min-height: 0;
  min-width: 0;
  width: 30px;
  height: 30px;
  align-self: start;
  background-color: #F6F6F6;
  border: 1px solid #d5d5d5;
  box-shadow: none;
  
&:hover {
background-color: #e2e2e2;
border: initial;
}

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  & svg {
    width: 20px;
    height: 20px;
    color: #002733;
  }
`;
});

export const CountRoot = styled("div")`
  position: absolute;
  top: -12px;
  right: -2px;
  background-color: #3399ff;
  height: 16px;
  min-width: 16px;
  border-radius: 12px;
  text-align: center;
  font-size: 11px;
  color: #fff;
  font-weight: 600;
  z-index: 4;
  padding: 0 2px;
`;

export const ActionButtonRoot = styled("div")`
  position: relative;
`;
