import {
  CellContainer,
  TruncatedCell,
} from "../../../../containers/inventory/AssetsTable/styled";

export const useColumns = () => {
  return [
    {
      header: "Action Name",
      accessorKey: "name",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Description",
      accessorKey: "description",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Associated Queries",
      accessorKey: "associated_queries",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
    {
      header: "Last Triggered",
      accessorKey: "last_triggered",
      Cell({ cell }) {
        const value = cell.getValue();

        return (
          <CellContainer>
            <TruncatedCell>{value}</TruncatedCell>
          </CellContainer>
        );
      },
    },
  ];
};
