import React from "react";
import { Handle, Position } from "reactflow";

import amazonEcr from "../../assets/icons/amazon-ecr.svg";
import amazonElasticache from "../../assets/icons/amazon-elasticache.svg";
import amazonLambda from "../../assets/icons/amazon-lambda.svg";
import amazonSqs from "../../assets/icons/amazon-sqs.svg";
import genericServer from "../../assets/icons/generic-server.svg";
import amazonECS from "../../assets/icons/amazon-ecs.svg";
import httpSvg from "../../assets/icons/http.svg";
import amazonRDS from "../../assets/icons/amazon-rds.svg";
import securityWAF from "../../assets/icons/security-waf.svg";
import amazonS3 from "../../assets/icons/amazon-s3.svg";
import amazonBinstalk from "../../assets/icons/amazon-binstalk.svg";
import rabbitmqSvg from "../../assets/icons/rabbitmq.svg";

const CustomNode = ({ data, isConnectable }) => {
  const { label, badge, icon, vulnerabilities } = data;

  const svgDict = {
    "amazon-ecr": amazonEcr,
    "amazon-elasticache": amazonElasticache,
    "amazon-lambda": amazonLambda,
    "amazon-sqs": amazonSqs,
    "generic-server": genericServer,
    "amazon-ecs": amazonECS,
    http: httpSvg,
    "amazon-rds": amazonRDS,
    "security-waf": securityWAF,
    "amazon-s3": amazonS3,
    "amazon-binstalk": amazonBinstalk,
    rabbitmq: rabbitmqSvg,
  };

  const svg = svgDict[icon];

  return (
    <div className="custom-node">
      {vulnerabilities.length > 0 && (
        <span className="badge-1">{vulnerabilities.length}</span>
      )}
      {icon && (
        <span className="badge-2">
          <img width={24} src={svg} alt={"rabbit"} />
        </span>
      )}
      {/*{badge && <span className="badge-3">{"..."}</span>}*/}
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
      <div className="node-label">{label}</div>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default CustomNode;
