import { showAlert } from "../../../../app/alert/showAlert";
import { InventoryService } from "../../../../services/inventoryService";
import { Actions } from "../stateManagement/actions";
import { useDispatch } from "./useDispatch";
import { useFetchData } from "./useFetchdata";
import { useGlobalState } from "./useGlobalState";

export function useDeleteQuery() {
  const { deleteQueryPayload } = useGlobalState();
  const dispatch = useDispatch();
  const fetchData = useFetchData();

  const openView = (payload) => {
    dispatch({
      type: Actions.SHOW_DELETE_VIEW,
      payload,
    });
  };

  const closeView = () => {
    dispatch({
      type: Actions.CLOSE_DELETE_VIEW,
    });
  };

  const deleteQuery = async () => {
    try {
      dispatch({
        type: Actions.DELETE_QUERY_PENDING,
      });

      await InventoryService.getInstance().deleteQuery({
        queryUuid: deleteQueryPayload.data.query_uuid,
      });
      dispatch({
        type: Actions.DELETE_QUERY_SUCCESS,
      });
      await fetchData();
      showAlert("Query Deleted Successfully", { type: "success" });
    } catch (error) {
      dispatch({
        type: Actions.DELETE_QUERY_ERROR,
        payload: error.message,
      });
      showAlert("Delete Query Failed", { type: "error" });
    }
  };

  return {
    ...deleteQueryPayload,
    deleteQuery,
    openView,
    closeView,
  };
}
