import React, { useMemo } from "react";
import { sendJiraOnboarding } from "../../utils/api";

import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import MultiStepSidebar from "../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import createButtonConfig from "../../shared/functions/buttons";
import { addIntegration } from "../../redux/actions/integrationActions";
import { useAuth0 } from "@auth0/auth0-react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import {
  JiraIntegrationCompleteConfig,
  JiraIntegrationCompleteContent,
} from "./jiraIntegrationComplete";
import {
  JiraIntegrationConfig,
  JiraIntegrationContent,
} from "./jiraIntegrationContent";

import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import BannerAlert from "../../components/banners/ErrorAlert";
import { IntegrationsProvider } from "./context/Provider";
import { useIntegrationValues } from "./hooks/useIntegrationValues";

const JiraIntegration = ({ cancelUrl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sidebarStep, setSidebarStep] = React.useState(1);
  const { getAccessTokenSilently } = useAuth0();
  const [errors, setErrors] = React.useState([]);

  const { apiToken, domainUrl, email, integrationName } =
    useIntegrationValues();

  const canContinue = useMemo(() => {
    return !!integrationName && !!apiToken && domainUrl;
  }, [apiToken, domainUrl, email, integrationName]);

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  const sidebarSteps = [
    { title: "Setup Integration", description: "Setup Jira Integration" },
    { title: "Completion", description: "" },
  ];

  const multiSteps = (
    <MultiStepSidebar steps={sidebarSteps} currentStep={sidebarStep} />
  );

  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
  };

  const onContinue = async () => {
    setErrors([]);

    if (sidebarStep === 1) {
      try {
        const auth0Token = await getAccessTokenSilently();
        await sendJiraOnboarding(
          apiToken,
          domainUrl,
          email,
          integrationName,
          auth0Token,
        );
        setSidebarStep(2);
        return;
      } catch (error) {
        let newErrors = [];
        if (typeof error.response?.data?.detail === "string") {
          newErrors.push(`${error.response.data.detail}`);
        } else if (error.response?.data?.detail) {
          newErrors = error.response.data.detail.map((detail) => {
            const errorLocation =
              detail.loc.length > 1
                ? detail.loc.slice(1).join(".")
                : "unknown location";
            return `${errorLocation}: ${detail.msg || "An error occurred"}`;
          });
        } else {
          newErrors = ["Failed to connect Jira. Please try again."];
        }

        // Update the errors state with new errors
        setErrors((currentErrors) => [...currentErrors, ...newErrors]);
        return; // Ensure function execution stops here after handling the error
      }
    } else if (sidebarStep === 2) {
      dispatch(addIntegration("jira"));
      history.push(cancelUrl);
    }
  };

  const footerButtonsStepOne = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel"),
    createButtonConfig(
      "Continue",
      "right",
      onContinue,
      <KeyboardArrowRightOutlinedIcon />,
      "right",
      !canContinue,
    ),
  ];
  const footerButtonsComplete = [
    createButtonConfig(
      "Done",
      "right",
      onContinue,
      <DoneOutlinedIcon />,
      "left",
      false,
    ),
  ];

  const pageSteps = [
    {
      headerLogo: JiraIntegrationConfig.logo,
      headerTitle: JiraIntegrationConfig.headerTitle,
      content: <JiraIntegrationContent />,
      footerButtons: footerButtonsStepOne,
    },
    {
      headerLogo: JiraIntegrationCompleteConfig.logo,
      headerTitle: JiraIntegrationCompleteConfig.headerTitle,
      content: <JiraIntegrationCompleteContent />,
      footerButtons: footerButtonsComplete,
    },
  ];
  return (
    <>
      {errors.length > 0 &&
        errors.map((error, index) => (
          <BannerAlert
            key={index}
            message={error}
            topOffset={index * 80 + 20}
          />
        ))}

      <IntegrationLayout
        multisteps={multiSteps}
        headerLogo={pageSteps[sidebarStep - 1]?.headerLogo}
        headerTitle={pageSteps[sidebarStep - 1]?.headerTitle}
        content={pageSteps[sidebarStep - 1]?.content}
        footerButtons={pageSteps[sidebarStep - 1]?.footerButtons}
      />
    </>
  );
};

const Component = (props) => {
  return (
    <IntegrationsProvider>
      <JiraIntegration {...props} />
    </IntegrationsProvider>
  );
};

export default Component;
