import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import businessProcessService from "../../services/businessProcessService";
import Snackbar from "@mui/material/Snackbar";

const IS_ON_PREMISES = process.env.REACT_APP_IS_ON_PREMISES === "true";
const LOCAL_JWT = process.env.REACT_APP_LOCAL_USER_JWT;

const BPSettingSection = () => {
  const [processes, setProcesses] = useState([]);
  const [newProcessName, setNewProcessName] = useState("");
  const [selectedProcesses, setSelectedProcesses] = useState(new Set());
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editProcessId, setEditProcessId] = useState(null);
  const [editProcessName, setEditProcessName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Function to fetch the business processes
  const fetchBusinessProcesses = async () => {
    try {
      setLoading(true);
      let token = LOCAL_JWT;

      if (!IS_ON_PREMISES) {
        token = await getAccessTokenSilently();
      }

      const processList =
        await businessProcessService.getBusinessProcesses(token);
      setProcesses(processList);
    } catch (err) {
      handleSnackbarOpen(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Call fetchBusinessProcesses on component mount
  useEffect(() => {
    fetchBusinessProcesses();
  }, []);

  // Function to add a new business process
  const handleAddProcess = async () => {
    if (newProcessName.trim() !== "") {
      try {
        let token = LOCAL_JWT;

        if (!IS_ON_PREMISES) {
          token = await getAccessTokenSilently();
        }
        await businessProcessService.addBusinessProcess(token, {
          name: newProcessName,
        });
        setNewProcessName("");
        await fetchBusinessProcesses(); // Refresh the list
      } catch (err) {
        handleSnackbarOpen(err.message);
      }
    }
  };

  const handleDeleteSelected = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await businessProcessService.deleteBusinessProcessesByIds(
        token,
        Array.from(selectedProcesses),
      );
      await fetchBusinessProcesses(); // Refresh the list
      setSelectedProcesses(new Set()); // Clear selection
    } catch (err) {
      handleSnackbarOpen(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to toggle selection of a process
  const handleToggle = (id) => {
    const newSet = new Set(selectedProcesses);
    if (selectedProcesses.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setSelectedProcesses(newSet);
  };

  const handleEditClick = (process) => {
    setEditProcessId(process.id);
    setEditProcessName(process.name);
  };

  const handleCancelEdit = () => {
    setEditProcessId(null);
    setEditProcessName("");
  };

  const handleSaveEdit = async (id) => {
    // Logic to save the edited business process
    try {
      const updatedProcess = { id, name: editProcessName };
      const token = await getAccessTokenSilently();
      // Save the updated process name using the API
      await businessProcessService.updateBusinessProcess(token, updatedProcess);

      setProcesses(
        processes.map((process) =>
          process.id === id ? { ...process, name: editProcessName } : process,
        ),
      );
      handleCancelEdit(); // Reset edit state
    } catch (error) {
      // Handle error (use snackbar or any other method)
    }
  };

  return (
    <>
      <Card sx={{ width: "100%", overflow: "visible" }}>
        <CardContent>
          <Typography variant="h6" component="h2" gutterBottom>
            Business Processes
          </Typography>
          <List>
            {processes.map((process) => (
              <ListItem
                key={process.id}
                secondaryAction={
                  editProcessId === process.id ? (
                    <>
                      <IconButton
                        edge="end"
                        aria-label="save"
                        onClick={() => handleSaveEdit(process.id)}
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="cancel"
                        onClick={handleCancelEdit}
                      >
                        <CancelIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleEditClick(process)}
                    >
                      <EditIcon />
                    </IconButton>
                  )
                }
              >
                <Checkbox
                  edge="start"
                  checked={selectedProcesses.has(process.id)}
                  tabIndex={-1}
                  disableRipple
                  onChange={() => handleToggle(process.id)}
                />
                {editProcessId === process.id ? (
                  <TextField
                    id="name"
                    label="Business Process Name"
                    type="text"
                    value={editProcessName}
                    onChange={(e) => setEditProcessName(e.target.value)}
                    fullWidth
                  />
                ) : (
                  <ListItemText primary={process.name} />
                )}
              </ListItem>
            ))}
          </List>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                label="New Process Name"
                value={newProcessName}
                onChange={(e) => setNewProcessName(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") handleAddProcess();
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={handleAddProcess}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteSelected}
            disabled={selectedProcesses.size === 0}
            sx={{ mb: 2 }}
          >
            Delete Selected
          </Button>
        </CardContent>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BPSettingSection;
