import { Button, Typography } from "@mui/material";
import { ErrorFallbackViewContainer } from "./styled";
import ReplayIcon from "@mui/icons-material/Replay";

export const ErrorFallbackView = () => {
  const handleOnClickReload = () => {
    window.location.reload();
  };

  return (
    <ErrorFallbackViewContainer>
      <Typography variant='subtitle2'>There was an error</Typography>
      <Button onClick={handleOnClickReload}>
        <ReplayIcon sx={{ fontSize: "14px" }} />
        <Typography variant='caption'>Reload page</Typography>
      </Button>
    </ErrorFallbackViewContainer>
  );
};
