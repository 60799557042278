import { useReducer } from "react";
import { IntegrationValuesStateContext } from "./contexts";
import { WizIntegrationInitialState } from "./state";
import { WizIntegrationReducer } from "./reducer";

export const WizIntegrationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    WizIntegrationReducer,
    WizIntegrationInitialState
  );

  return (
    <IntegrationValuesStateContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </IntegrationValuesStateContext.Provider>
  );
};
