import { Typography } from "@mui/material";
import { ViewContainer } from "../styled";

export const CompletionView = () => {
  return (
    <ViewContainer>
      <Typography variant='h6'>Integration Complete!</Typography>
    </ViewContainer>
  );
};
