import { createUsername } from "../user-management/createUsername";

function transformToAction(method) {
  let payload = {
    action_type: method.type,
    ...method.payload,
  };
  if (method.type === "email") {
    const emails = method.payload.to.split(",").map((email) => email.trim());
    payload.to = emails;
  }
  return payload;
}

export function createActionPayloadTransformer(_payload, queryPayload, user) {
  const { name, description, threshold, condition, notificationMethods } =
    _payload;
  const created_by = createUsername(user);
  const payload = {
    name,
    description,
    created_by,
    tags: [],
    query_uuid: queryPayload.query_uuid,
    actions: notificationMethods.map((method) => transformToAction(method)),
    threshold,
    condition,
    delta_count: 0,
    rule_type: "fixed",
    status: "enabled",
    run_once: false,
  };

  return payload;
}
