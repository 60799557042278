import githubLogo from "../../../assets/images/githubDark.svg";
import React from "react";
import {Box, Typography} from "@mui/material";
import GitHubLoginUsingHook from "../../../components/github-oauth/GitHubLoginUsingHook";
import IntegrationMethod from "../../../components/onboardingV2/awsIntegration/integrationMethod";
import oauthLogo from "../../../assets/images/oauth.svg";
import keyLogo from "../../../assets/images/key.svg";
import { useHistory } from "react-router-dom";

export const GithubIntegrationContent = ({githubLoginRef, GITHUB_CLIENT_ID, GITHUB_REDIRECT_URI,handleSuccessGithub, handleFailureGithub, handleOAuthClick}) => {
    const history = useHistory();

    return (
        <Box sx={{ display: "flex", gap: "50px", paddingLeft: "20px"}}>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Typography variant="body1" className="integrationMethodDesc" sx={{ mt: "21.5px" }}>
                    In this step, we're requesting read-only access to your GitHub account.This means we'll be able to view your repositories, but we won't be able to make any changes.
                    We prioritize your privacy and security, so you can rest assured that your data will be handled safely and securely.
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: "21px", width: "1px", bgcolor: "#D9D9D9" }} />

            <Box className="integrationMethodWrapper">
                <GitHubLoginUsingHook
                    ref={githubLoginRef}
                    className="custom-github-btn"
                    clientId={GITHUB_CLIENT_ID}
                    redirectUri={GITHUB_REDIRECT_URI}
                    onSuccess={handleSuccessGithub}
                    onFailure={handleFailureGithub}
                    scope="repo, read:org"
                />
                <IntegrationMethod
                    logo={oauthLogo}
                    name="OAuth"
                    onClick={handleOAuthClick}
                />
                <IntegrationMethod
                    logo={keyLogo}
                    name="API Key"
                    onClick={() => {history.push("/integrations/github/key")} }

                />
            </Box>
        </Box>
    );
};

export const GithubIntegrationConfig = {
    logo: githubLogo,
    headerTitle:  "Choose preferred integration method",

};