import React from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const IntegrationsSection = () => {
  const history = useHistory();
  return (
    <>
      <Card sx={{ width: "100%", overflow: "visible" }}>
        <CardContent>
          <Typography variant="h6" component="h2" gutterBottom>
            Intergrations
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Extensions are additional features that can be added to the system
            to enhance its functionality.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => history.push("/integrations")}
          >
            View Integrations
          </Button>
          {/* <Onboarding /> */}
        </CardContent>
      </Card>
    </>
  );
};

export default IntegrationsSection;
