import { Box } from "@mui/material";
import styles from "./chatfooter.module.css";

const ChatFooter = () => (
  <Box
    component="footer"
    className={styles.chatFooter}
    sx={{ mb: { xs: 0, sm: "10px" } }}
  >
    PandoGPT can make mistakes. Consider checking important information.
  </Box>
);

export default ChatFooter;
