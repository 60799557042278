import { Typography } from "@mui/material";
import { PageHeader } from "../../../shared-component/PageLayout";
import { PageRoot } from "./component/styled";
import { PageNavigationButton } from "../../../shared-component/Navigation";
import { useAppNavigator } from "../../../../hooks/useAppNavigator";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getIssueDetail } from "../../../../state-management/CMST/thunks/getIssueDetaiil";
import { useIssueDetail } from "../hooks/useIssueDetail";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { Content } from "./component/Content";
import { LoadingSection } from "../../../../components/LayoutFillers";
import { createAssetInfo } from "../utils/createAssetInfo";

const PageEntry = (props) => {
  const { goBack } = useAppNavigator();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data, isLoading, error } = useIssueDetail();

  const profile = useMemo(() => {
    const issueProfile = createAssetInfo(data ?? {});
    if (location.state.data) {
      issueProfile.projectName = location.state.data.project?.id;
    }
    return issueProfile;
  }, [data]);

  const showContent = !isLoading && !error;

  const fetchIssue = () => {
    dispatch(getIssueDetail({ id }));
  };

  const heading = useMemo(() => {
    let prefix = "";
    if (location.state) {
      prefix = location.state.data?.project?.id;
    }
    if (prefix) {
      return `${prefix}/${id}`;
    }
    return "";
  }, [location, id]);

  useEffect(() => {
    fetchIssue();
  }, [id]);

  return (
    <PageRoot>
      <PageHeader>
        <PageNavigationButton onClick={goBack} label={"Back to Issues"} />
        <Typography variant='h1'>{heading}</Typography>
      </PageHeader>
      <ConditionComponent condition={isLoading}>
        <LoadingSection />
      </ConditionComponent>
      <ConditionComponent condition={showContent}>
        <Content data={profile} />
      </ConditionComponent>
    </PageRoot>
  );
};

export default PageEntry;
