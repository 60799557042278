import React from "react";
import { Box, InputBase, Typography } from "@mui/material";
import styles from "./index.module.css";
import bitbucketLogo from "../../../../assets/images/bitbucket.svg";
import { IntegrationLabel } from "../../../../components/integrations-components/styled";
import {
  InputSectionContainer,
  InputsSection,
  RowInputsContainer,
} from "./styled";
import { useIntegrationValues } from "../../hooks/useIntegrationValues";
import { useIntegrationValueMutators } from "../../hooks/useIntegrationValueMutators";

export const GithubKeyIntegrationContent = () => {
  const { accessToken, integrationName } = useIntegrationValues();
  const { setAccessToken, setIntegrationName } = useIntegrationValueMutators();

  const handleOnChangeAccessToken = (ev) => {
    setAccessToken(ev.target.value);
  };
  const handleOnChangeIntegrationName = (ev) => {
    setIntegrationName(ev.target.value);
  };

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Box>
          <Typography variant='body1'>
            To create a read-only access token in GitHub, follow these steps:
          </Typography>
          <ol>
            <li className={styles["list-item"]}>
              Log into your <strong>GitHub account</strong>.
            </li>
            <li className={styles["list-item"]}>
              Click on your profile picture in the top right corner, then select{" "}
              <strong>'Settings'</strong>.
            </li>
            <li className={styles["list-item"]}>
              In the left sidebar, click on{" "}
              <strong>'Developer settings'</strong>.
            </li>
            <li className={styles["list-item"]}>
              Click on <strong>'Personal access tokens'</strong> under the
              'Developer settings'.
            </li>
            <li className={styles["list-item"]}>
              Click on the <strong>'Generate new token'</strong> button under
              'Fine-Grained Tokens'.
            </li>
            <li className={styles["list-item"]}>
              Give your token a <strong>description</strong> for easy
              identification.
            </li>
            <li className={styles["list-item"]}>
              Set <b>expiration date</b> for three months.
            </li>
            <li className={styles["list-item"]}>
              Change <strong>Resource Owner</strong> to the organization.
            </li>
            <li className={styles["list-item"]}>
              Under the <strong>'Repository Access'</strong> section, change to
              all repositories.
            </li>
            <li className={styles["list-item"]}>
              In the <strong>Permissions section</strong>, select the following
              permissions with read only access:
              <br />
              <ul className={styles["inner-list"]}>
                <li className={styles["inner-list-item"]}>
                  <b>Repository - </b> Actions, Administration, Code scanning
                  alerts, Commit statuses , Contents, Custom, Custom properties,
                  Dependabot alerts, Deployments, Discussions, Issues, Metadata,
                  Pages, Pull requests, Repository security advisories, Secret
                  scanning alerts, Variables, Webhooks.
                  <br />
                </li>
                <li className={styles["inner-list-item"]}>
                  <b>Organization - </b> Administration, Blocking users, Custom
                  properties, Custom repository roles, Events, Members,
                  Organization announcement banners, Plan, Projects, Team
                  discussions, Variables, Webhooks.
                </li>
              </ul>
            </li>
            <li className={styles["list-item"]}>
              Click <strong>'Generate token'</strong> at the bottom of the page
              to create your new token.
            </li>
            <li className={styles["list-item"]}>
              Copy the generated token and paste it wherever it's required.
            </li>
          </ol>
        </Box>
        <InputsSection>
          <RowInputsContainer>
            <InputSectionContainer>
              <IntegrationLabel>Integration Name *</IntegrationLabel>
              <InputBase
                value={integrationName}
                onChange={handleOnChangeIntegrationName}
                placeholder=''
                classes={{ root: styles["input"] }}
              />
            </InputSectionContainer>
            <InputSectionContainer>
              <IntegrationLabel>Access Token *</IntegrationLabel>
              <InputBase
                value={accessToken}
                onChange={handleOnChangeAccessToken}
                placeholder=''
                classes={{ root: styles["input"] }}
              />
            </InputSectionContainer>
          </RowInputsContainer>
        </InputsSection>
      </Box>
    </Box>
  );
};

export const BitbucketIntegrationConfig = {
  logo: bitbucketLogo,
  headerTitle: "Setup Bitbucket Integration",
};
