export const DefaultCMSTProjectsTableColumns = Object.freeze([
  {
    label: "data.project.name",
    value: "data.project.name",
    source: "platform",
    valueTypes: ["str"],
    id: "options-platform.data.project.name",
    checked: false,
  },
  {
    label: "data.project_owner.name",
    value: "data.project_owner.name",
    source: "platform",
    valueTypes: ["str"],
    id: "options-platform.data.project_owner.name",
    checked: false,
  },
  {
    label: "data.related_applications",
    value: "data.related_applications",
    source: "platform",
    valueTypes: ["str"],
    id: "options-platform.data.related_applications",
    checked: false,
  },
  {
    label: "data.issues",
    value: "data.issues",
    source: "platform",
    valueTypes: ["int"],
    id: "options-platform.data.issues",
    checked: false,
  },
  {
    label: "data.severity.score",
    value: "data.severity.score",
    source: "platform",
    valueTypes: ["int"],
    id: "options-platform.data.severity.score",
    checked: false,
  },
]);
