// src/FAIRRiskForm.js
import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { calculateFairRisk } from "../../utils/api";
import FairRiskResults from "./FairRiskResults";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  calculateButton: {
    display: "inline-flex",
    padding: "5.5px 20px",
    alignItems: "center",
    gap: "4px",
    borderRadius: "4px",
    background: "#002733", // You can also use 'var(--dark-blue, #002733)' if you've CSS variables set up
    color: "#00FFB2",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "19px", // 135.714%
    "&:hover": {
      background: "#004855", // A slightly lighter shade for hover
    },
    infoBox: {
      padding: "15px",
      backgroundColor: "#F5F9FF", // a gentle shade of blue for the box
      borderRadius: "5px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // subtle shadow for depth
      marginBottom: "20px",
    },
    infoTitle: {
      fontWeight: "bold",
      marginBottom: "10px",
    },
  },
});

const FAIRRiskForm = () => {
  const [numEmployees, setNumEmployees] = useState("");
  const [actorType, setActorType] = useState("");
  const [attackVector, setAttackVectors] = useState("");
  const [controlKeys, setControlKeys] = useState([]);
  const [totalDatabaseRecords, setTotalDatabaseRecords] = useState("");
  const [wcl, setWCL] = useState("");
  const [tl, setTL] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const [apiResponse, setApiResponse] = useState(null); // State to store API response
  const classes = useStyles();
  const [availableControls, setAvailableControls] = useState([]);

  const controlMapping = {
    malicious_insider: [
      "background_checks",
      "least_privilege_access",
      "user_behavior_analytics",
      "two_factor_authentication",
    ],
    accidental_data_loss: [
      "data_loss_prevention",
      "regular_backups",
      "access_controls",
      "encryption",
    ],
    // ... rest of your controls
  };

  useEffect(() => {
    if (attackVector) {
      setAvailableControls(controlMapping[attackVector] || []);
    } else {
      setAvailableControls([]);
    }
  }, [attackVector]);

  const handleSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await calculateFairRisk(
        {
          num_employees: numEmployees,
          actor_type: actorType,
          attack_vector: attackVector,
          control_keys: controlKeys,
          // wcl,
          // tl,
          total_database_records: totalDatabaseRecords, // Include the new parameter here
        },
        token,
      );
      console.log(response);
      setApiResponse(response); // Store the response data
      console.log(apiResponse);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const resetForm = () => {
    setNumEmployees("");
    setActorType("");
    setAttackVectors("");
    setControlKeys([]);
    setTotalDatabaseRecords("");
    // Reset other form fields if any...
  };

  return (
    <Container className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Number of Employees"
            value={numEmployees}
            onChange={(e) => setNumEmployees(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel>Actor Type</InputLabel>
            <Select
              value={actorType}
              onChange={(e) => setActorType(e.target.value)}
            >
              <MenuItem value="state">Nation State</MenuItem>
              <MenuItem value="group">Group</MenuItem>
              <MenuItem value="bot">Bot</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel>Attack Vectors</InputLabel>
            <Select
              value={attackVector}
              onChange={(e) => setAttackVectors(e.target.value)}
            >
              <MenuItem value="malicious_insider">Malicious Insider</MenuItem>
              <MenuItem value="accidental_data_loss">
                Accidental Data Loss
              </MenuItem>
              <MenuItem value="known_unpatched_vulnerability">
                Known Unpatched Vulnerability
              </MenuItem>
              <MenuItem value="system_error">System Error</MenuItem>
              <MenuItem value="social_engineering">Social Engineering</MenuItem>
              <MenuItem value="physical_security_compromise">
                Physical Security Compromise
              </MenuItem>
              <MenuItem value="business_email_compromise">
                Email Compromise
              </MenuItem>
              <MenuItem value="physical_security_compromise">
                Physical Security Compromise
              </MenuItem>
              <MenuItem value="zero-day_vulnerability">
                0 Days Vulnerability
              </MenuItem>
              <MenuItem value="cloud_misconfiguration">
                Cloud Misconfiguration
              </MenuItem>
              <MenuItem value="stolen_credentials">Stolen Credentials</MenuItem>
              <MenuItem value="phishing">Phishing</MenuItem>
              <MenuItem value="ransomware_attack">Ransomware Attack</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Tooltip
            title={!attackVector ? "You need to choose an Attack Vector" : ""}
          >
            <span>
              {" "}
              {/* Wrap the disabled FormControl in a span so that Tooltip works properly */}
              <FormControl fullWidth disabled={!attackVector}>
                <InputLabel>Control Keys</InputLabel>
                <Select
                  multiple
                  value={controlKeys}
                  onChange={(e) => setControlKeys(e.target.value)}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {availableControls.map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>
          </Tooltip>
        </Grid>

        {/*<Grid item xs={6}>*/}
        {/*    <TextField*/}
        {/*        label="Worse Case Loss ($)"*/}
        {/*        value={wcl}*/}
        {/*        onChange={(e) => setWCL(e.target.value)}*/}
        {/*        fullWidth*/}
        {/*        required*/}
        {/*    />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={6}>*/}
        {/*    <TextField*/}
        {/*        label="Typical loss ($)"*/}
        {/*        value={tl}*/}
        {/*        onChange={(e) => setTL(e.target.value)}*/}
        {/*        fullWidth*/}
        {/*        required*/}
        {/*    />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*    <TextField*/}
        {/*        label="Total Database Records"*/}
        {/*        value={totalDatabaseRecords}*/}
        {/*        onChange={(e) => setTotalDatabaseRecords(e.target.value)}*/}
        {/*        fullWidth*/}
        {/*        optional // This marks the field as optional*/}
        {/*    />*/}
        {/*</Grid>*/}

        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.calculateButton}
            onClick={handleSubmit}
          >
            Calculate Risk
          </Button>
          <Button onClick={resetForm}>Reset</Button>
        </Grid>
      </Grid>
      <Box>
        {apiResponse && <FairRiskResults data={apiResponse} />}{" "}
        {/* Conditionally render results */}
      </Box>
    </Container>
  );
};

export default FAIRRiskForm;
