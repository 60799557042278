import { useState } from "react";
import {
  DoneButton,
  Input,
  NewTaskContentRoot,
  NewTaskRoot,
  NewTaskStatusSelector,
} from "./styled";
import CheckIcon from "@mui/icons-material/Check";
import { SecurityTaskStatus } from "../../../../../../core/cmst/issues-page/data";
import ConditionComponent from "../../../../../../utils/ConditionComponent";
import { Typography } from "@mui/material";

const statusOptions = [
  {
    label: "Completed",
    value: SecurityTaskStatus.COMPLETED,
  },
  {
    label: "Pending",
    value: SecurityTaskStatus.PENDING,
  },
];

function validateNewTask(task) {
  const missingFields = [];
  if (!task.label) {
    missingFields.push("name");
  }
  if (!task.team) {
    missingFields.push("team");
  }
  let errorMessage = "";
  if (missingFields.length) {
    const formattedMissingVals = missingFields.join(" and ");
    errorMessage = `Select ${formattedMissingVals} value(s) to proceed`;
  }

  return errorMessage;
}

export const NewTask = ({ onSubmit }) => {
  const [task, setTask] = useState({
    label: "",
    team: "",
    status: SecurityTaskStatus.PENDING,
    error: "",
  });

  const taskStatus = statusOptions.filter(
    (status) => status.value === task.status
  );

  const handleOnSubmit = () => {
    const error = validateNewTask(task);
    if (!!error) {
      return setTask((task) => ({ ...task, error }));
    }
    onSubmit(task);
  };

  const handleOnInputChange = (ev, field) => {
    const value = ev.target.value;
    setTask((task) => ({ ...task, [field]: value }));
  };

  const handleOnChangeStatus = (newStatus) => {
    setTask((prevTask) => ({ ...prevTask, status: newStatus.value }));
  };

  return (
    <NewTaskRoot>
      <NewTaskContentRoot>
        <Input
          value={task.label}
          placeholder='Enter new task'
          onChange={(ev) => handleOnInputChange(ev, "label")}
        />
        <Input
          value={task.team}
          placeholder='Team name'
          onChange={(ev) => handleOnInputChange(ev, "team")}
        />
        <NewTaskStatusSelector
          onChange={handleOnChangeStatus}
          value={taskStatus}
          options={statusOptions}
        />
        <DoneButton onClick={handleOnSubmit}>
          <CheckIcon />
        </DoneButton>
      </NewTaskContentRoot>
      <ConditionComponent condition={!!task.error}>
        <Typography variant='caption' color='error.main'>
          {task.error}
        </Typography>
      </ConditionComponent>
    </NewTaskRoot>
  );
};
