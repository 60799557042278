import { styled, Typography } from "@mui/material";

export const Container = styled("div")`
  height: calc(100vh - 150px);
  display: grid;
  grid-template-columns: 320px 1fr;
  column-gap: 12px;
  overflow-y: hidden;
  margin-top: 24px;
`;

export const GroupingSectionContainer = styled("section")`
  border: 0.5px solid #c7c7c7;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const SectionHeading = styled((props) => (
  <Typography variant='subtitle2' {...props} />
))`
  height: 32px;
  border-bottom: 0.5px solid #c7c7c7;
  background-color: inherit;
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-weight: 500;
  min-height: 28px;
`;

const SectionBase = styled("section")`
  border: 0.5px solid #c7c7c7;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 8px;
`;

export const GroupsSectionContent = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px;
  overflow-y: auto;
`;

export const GroupDataTableSectionContainer = styled(SectionBase)`
  background-color: #f3f6fd;
`;
