import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { deleteAdapter } from "../../../../../utils/api";
import { useUserInfo } from "../../../../../hooks/useAppUser";

export const useDeleteIntegration = (data, onSuccess) => {
  const { organizationId } = useUserInfo();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(false);

  const handler = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await deleteAdapter({
        id: data.uuid,
        organization_id: organizationId,
        token,
      });
      onSuccess?.();
    } catch (error) {
      setError(error.message);
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    handler,
    loading,
    error,
  };
};
