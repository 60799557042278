export const CoreDataTypes = Object.freeze({
  N_A: "n/a",
});

export const SeverityVariants = Object.freeze({
  CRITICAL: "critical",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  UNKNOWN: CoreDataTypes.N_A,
});
