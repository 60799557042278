import { Typography } from "@mui/material";
import {
  Circle,
  DateLabel,
  DateSection,
  TicketDetailsSection,
  TicketSummaryContainer,
  ViewFullDescriptionLink,
} from "../../styled";
import { TruncatedCell } from "../../../../../../containers/inventory/AssetsTable/styled";
import { useEffect, useRef, useState } from "react";
import ConditionComponent from "../../../../../../utils/ConditionComponent";

function detectNodeContentOverflows(node) {
  if (!node) return false;
  const { scrollHeight, clientHeight } = node;
  return scrollHeight > clientHeight;
}

export const TicketSummary = ({ data }) => {
  const [textOverflows, setTextOverflows] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const descriptionNode = useRef(null);

  const showAllButtonLabel = showAll
    ? "Hide description"
    : "Show Full description";

  const toggleShowAll = (ev) => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (descriptionNode.current) {
      const hasHiddenText = detectNodeContentOverflows(descriptionNode.current);
      setTextOverflows(hasHiddenText);
    }
  }, []);

  return (
    <TicketSummaryContainer>
      <DateSection>
        <Circle size='8px' bgColor='#d9d9d9' />
        <DateLabel>{data.date}</DateLabel>-
        <Typography variant='body2'>{data.key}</Typography>
      </DateSection>
      <TicketDetailsSection>
        <Typography variant='body2'>{data.summary}</Typography>
        <TruncatedCell showAll={showAll} ref={descriptionNode}>
          <Typography variant='caption'>{data.description}</Typography>
        </TruncatedCell>
        <ConditionComponent condition={textOverflows}>
          <ViewFullDescriptionLink variant='text' onClick={toggleShowAll}>
            {showAllButtonLabel}
          </ViewFullDescriptionLink>
        </ConditionComponent>
      </TicketDetailsSection>
    </TicketSummaryContainer>
  );
};
