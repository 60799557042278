import { useContext } from "react";
import { AssetsTableMethodsContext } from "../context/contexts";
import { usePageData } from "./usePageData";

export const useAssetsTableMethods = () => {
  const methods = useContext(AssetsTableMethodsContext);
  const { primaryTableColumns } = usePageData();

  const fetchGroupingTableData = async (...args) => {
    const payload = args[1];
    if (payload) {
      payload.primaryTableColumns = primaryTableColumns;
    }
    await methods.fetchGroupingTableData(...args);
  };

  const fetchPrimaryTableData = async (...args) => {
    const payload = args[1];
    if (payload) {
      payload.primaryTableColumns = primaryTableColumns;
      args[1] = payload;
    }
    await methods.fetchPrimaryTableData(...args);
  };

  return { ...methods, fetchPrimaryTableData, fetchGroupingTableData };
};
