import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { createUsername } from "../app/user-management/createUsername";

export const useUserInfo = () => {
  const { user } = useAuth0();
  const data = useSelector((state) => state.user);
  const username = createUsername(user);
  return { ...data, username };
};
