// GitHubLogin.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import PopupWindow from "./PopupWindow"; // Ensure this path is correct
import { toQuery } from "./utils"; // Ensure this path is correct

class GitHubLoginUsingHook extends Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
    redirectUri: PropTypes.string,
    scope: PropTypes.string,
  };

  static defaultProps = {
    redirectUri: "",
    scope: "user:email",
    onSuccess: () => {},
    onFailure: () => {},
  };

  initiateLogin = () => {
    const { clientId, scope, redirectUri } = this.props;
    const search = toQuery({
      client_id: clientId,
      scope,
      redirect_uri: redirectUri,
    });

    const popup = (this.popup = PopupWindow.open(
      "github-oauth-authorize",
      `https://github.com/login/oauth/authorize?${search}`,
      { height: 1000, width: 600 },
    ));

    this.onRequest();
    popup.then(
      (data) => this.onSuccess(data),
      (error) => this.onFailure(error),
    );
  };

  onRequest = () => {
    // Custom logic for request (optional)
  };

  onSuccess = (data) => {
    if (!data.code) {
      return this.onFailure(new Error("'code' not found"));
    }

    this.props.onSuccess(data);
  };

  onFailure = (error) => {
    this.props.onFailure(error);
  };

  render() {
    return null; // No UI, control from parent component
  }
}

export default GitHubLoginUsingHook;
