import { MaterialReactTable } from "material-react-table";
import { OwnerType, useOwnersTable } from "../hooks/useBusinessOwnersTable";
import {
  BusinessPurposeSection,
  MarkdownContainer,
  SubPageContainer,
  SubPageContentContainer,
  SubPageHeading,
  SubPageSubHeading,
  ValueText,
} from "../styled";
import { Typography } from "@mui/material";
import { useGetAssetDetailState } from "../hooks/useStateManager";
import ReactMarkdown from "react-markdown";
import {
  MarkdownListItem,
  MarkdownPreComponent,
  MarkdownTable,
  MarkdownTableCell,
  MarkdownTableHead,
  MarkdownTableHeading,
} from "../../../../../components/botgpt/messageList/styled-components";
import ConditionComponent from "../../../../../utils/ConditionComponent";

export const BusinessPage = () => {
  const { data } = useGetAssetDetailState();

  const businessOwnersTable = useOwnersTable(OwnerType.BUSINESS);
  const technicalOwnersTable = useOwnersTable(OwnerType.TECHNICAL);
  const businessPurpose = data?.asset?.business_purpose;

  return (
    <SubPageContainer>
      <SubPageHeading>Business Profile</SubPageHeading>
      <SubPageContentContainer className='scrollable'>
        <SubPageSubHeading>Business Purpose</SubPageSubHeading>
        <ConditionComponent
          condition={!!businessPurpose}
          falsyNode={<ValueText isna>N/A</ValueText>}
        >
          <MarkdownContainer className='scrollable'>
            <ReactMarkdown
              children={businessPurpose}
              components={{
                p({ children }) {
                  return <Typography variant='body2'>{children}</Typography>;
                },
                em({ children }) {
                  return <em className='important-text'>{children}</em>;
                },
                pre({ children }) {
                  return (
                    <MarkdownPreComponent>{children}</MarkdownPreComponent>
                  );
                },
                li({ children }) {
                  return <MarkdownListItem>{children}</MarkdownListItem>;
                },
                table: ({ children }) => {
                  return <MarkdownTable>{children}</MarkdownTable>;
                },

                td: ({ children }) => (
                  <MarkdownTableCell>{children}</MarkdownTableCell>
                ),
                thead: ({ children }) => (
                  <MarkdownTableHead>{children}</MarkdownTableHead>
                ),
                th: ({ children }) => {
                  return (
                    <MarkdownTableHeading>{children}</MarkdownTableHeading>
                  );
                },
              }}
            />
          </MarkdownContainer>
        </ConditionComponent>
        <SubPageSubHeading>Business Owners</SubPageSubHeading>
        <MaterialReactTable table={businessOwnersTable} />
        <SubPageSubHeading>Technical Owners</SubPageSubHeading>
        <MaterialReactTable table={technicalOwnersTable} />
      </SubPageContentContainer>
    </SubPageContainer>
  );
};
