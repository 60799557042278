import { styled, Typography } from "@mui/material";

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const Label = styled(Typography)`
  color: #cccccc !important;
  font-weight: 14px !important;
`;

export const EmptyDataSection = ({ label }) => {
  return (
    <Section>
      <Label>{label}</Label>
    </Section>
  );
};
