import { useState } from "react";
import {
  IntegrationValueMutatorsContext,
  IntegrationValuesStateContext,
} from "./contexts";
import { generateUid } from "../stepOne/generateUID";

export const IntegrationsProvider = ({ children, manual }) => {
  const [account, setAccount] = useState("");
  const [integrationName, setIntegrationName] = useState("");
  const [role, setRole] = useState("");
  const [externalId, setExternalId] = useState(() => generateUid());
  const [selfRole, setSelfRole] = useState("");
  const [arnRole, setArnRole] = useState("");

  return (
    <IntegrationValueMutatorsContext.Provider
      value={{
        setAccount,
        setIntegrationName,
        setRole,
        setExternalId,
        setSelfRole,
        setArnRole,
      }}
    >
      <IntegrationValuesStateContext.Provider
        value={{
          account,
          integrationName,
          role,
          externalId,
          selfRole,
          isManual: manual,
          arnRole,
        }}
      >
        {children}
      </IntegrationValuesStateContext.Provider>
    </IntegrationValueMutatorsContext.Provider>
  );
};
