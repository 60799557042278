import {
  CommandJsonContainer,
  CommandJsonContent,
  CommandJsonSummary,
} from "./styled";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useGetAssetDetailState } from "./hooks/useStateManager";
import { JsonViewer } from "./components/JsonViewer";

export const CommandJson = () => {
  const { data } = useGetAssetDetailState();

  return (
    <CommandJsonContainer>
      <CommandJsonSummary expandIcon={<KeyboardArrowDownRoundedIcon />}>
        Asset Json
      </CommandJsonSummary>
      <JsonViewer data={data} />
    </CommandJsonContainer>
  );
};
