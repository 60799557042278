import React, { useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import InitiativeService from "../services/initiativeService";
import { Button } from "reactstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { format, differenceInCalendarDays } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import { Image } from "@material-ui/icons";

const InitiativesPage = () => {
  const [initiatives, setInitiatives] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null); // State for tracking delete candidate
  const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
  const { getAccessTokenSilently } = useAuth0();

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const deleteInitiative = async () => {
    if (deleteCandidate) {
      const token = await getAccessTokenSilently();
      await InitiativeService.deleteInitiative(token, deleteCandidate.id);

      const updatedInitiatives = initiatives.filter(
        (initiative) => initiative.id !== deleteCandidate.id,
      );
      setInitiatives(updatedInitiatives);
      setDeleteCandidate(null); // Reset delete candidate
    }
    handleDialogClose();
  };

  // Define actions
  const actions = [
    {
      icon: "delete", // Material Icon Name
      tooltip: "Delete Initiative",
      onClick: (event, rowData) => deleteInitiative(rowData),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      const data = await InitiativeService.listInitiatives(token);

      console.log(data);

      const initiatives = data.map((initiative) => {
        return {
          ...initiative,
        };
      });

      setInitiatives(initiatives);
    };
    fetchData();
  }, []);

  // Define columns
  const columns = [
    { accessorKey: "name", header: "Name", width: 100 },
    { accessorKey: "description", header: "Description" },
    {
      accessorKey: "owner",
      header: "Security Owner",
      Cell: ({ cell }) => {
        console.log(cell.row.original.ownerObj);

        if (cell.row.original.ownerObj) {
          const owner = JSON.parse(cell.row.original.ownerObj);
          return (
            <>
              <img
                src={owner.picture}
                alt={"owner"}
                style={{ width: "30px", borderRadius: "50%" }}
              />
              {owner.name}
            </>
          );
        } else {
          return cell.getValue()?.name;
        }
      },
    },
    { accessorKey: "startDate", header: "Creation Date" },
    {
      accessorKey: "selectedEntities",
      header: "Related Entities",
      Cell: ({ cell }) => {
        const selectedEntities = cell.getValue();
        const maxIdLength = 15;

        if (selectedEntities.length <= 3) {
          return (
            <div style={{ fontSize: "12px" }}>
              {selectedEntities.map((entity) => (
                <Button
                  key={entity.id}
                  style={{
                    fontSize: "12px",
                    padding: "2px 6px",
                    margin: "2px",
                  }}
                  variant="outlined"
                  size="small"
                >
                  {entity.name.slice(0, maxIdLength)}
                </Button>
              ))}
            </div>
          );
        } else {
          const firstThreeEntities = selectedEntities
            .slice(0, 3)
            .map((entity) => (
              <Button
                key={entity.id}
                style={{ fontSize: "12px", padding: "2px 6px", margin: "2px" }}
                variant="outlined"
                size="small"
              >
                {entity.name.slice(0, maxIdLength)}
              </Button>
            ));
          return (
            <div style={{ fontSize: "12px" }}>
              {firstThreeEntities}
              <Button
                style={{ fontSize: "12px", padding: "2px 6px", margin: "2px" }}
                variant="outlined"
                size="small"
                onClick={handleModalOpen}
              >
                ...
              </Button>
              <Dialog open={openModal} onClose={handleModalClose}>
                <DialogTitle>All Entities</DialogTitle>
                <DialogContent
                  style={{
                    minWidth: "300px",
                    maxHeight: "400px",
                    padding: "16px",
                    overflow: "true",
                  }}
                >
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {selectedEntities.map((entity) => (
                      <li
                        key={entity.id}
                        style={{ marginBottom: "12px", padding: "8px 0" }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            marginRight: "15px",
                          }}
                        >
                          Entity:
                        </span>{" "}
                        {entity.id}
                      </li>
                    ))}
                  </ul>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleModalClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          );
        }
      },
    },
    {
      accessorKey: "relatedBusinessProcesses",
      header: "Related Business Processes",
      Cell: ({ cell }) => cell.getValue()?.join(", "),
    },
    {
      accessorKey: "sla",
      header: "SLA",
      Cell: ({ cell }) => {
        const today = new Date();
        const endDate = new Date(cell.row.original.endDate);
        const sla = differenceInCalendarDays(endDate, today);
        return <span>{sla} days</span>;
      },
    },
    { accessorKey: "status", header: "Status" },
  ];

  const initiateDelete = (initiative) => {
    setDeleteCandidate(initiative); // Set delete candidate
    handleDialogOpen(); // Open confirmation dialog
  };

  const renderRowActions = (table) => (
    <div style={{ display: "flex", gap: "10px" }}>
      <IconButton
        onClick={() => initiateDelete(table.row.original)}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );

  const ConfirmationDialog = () => (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">
        Confirm Initiative Deletion
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          You are about to delete the initiative{" "}
          <strong>{deleteCandidate?.name}</strong>. This action cannot be
          undone. Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={deleteInitiative} color="secondary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      <h1>Initiatives</h1>
      <MaterialReactTable
        enableRowActions
        columns={columns}
        data={initiatives}
        renderRowActions={renderRowActions}
      />

      <ConfirmationDialog />
    </div>
  );
};

export default InitiativesPage;
