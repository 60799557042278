import { useEffect, useReducer } from "react";
import {
  AssetInventoryDataContext,
  AssetInventoryDispatchContext,
  AssetInventoryGroupingTableStateContext,
  AssetInventoryPageMetaContext,
  AssetInventoryPrimaryTableStateContext,
  AssetsTableMethodsContext,
} from "./contexts";
import { useAuth0 } from "@auth0/auth0-react";
import { getInventoryAssetsByFilters } from "../../../../utils/api";
import { InventoryFilterUrlParser } from "../../../../ui/pages/inventory/utils/filterToUrlModule";
import { useHistory } from "react-router-dom";
import { getPageInitialEncodedViewConfig } from "../utils/getPageInitialEncodedViewConfig";
import { decodeEncodedFiltersFromUrl } from "../utils/decodeEncodedFiltersFromUrl";
import { Reducer } from "../state-management/reducer";
import { InitialState } from "../state-management/initialState";
import { ActionType } from "../state-management/actions";
import { createAssetInfoSubPageUrl } from "../../../../utils/routing/createAssetInfoUrl";
import { AppRoutingConfig } from "../../../../utils/routing/RouteConfig";
import { useDispatch } from "react-redux";
import { getAssetOptions } from "../../../../state-management/Inventory/thunks/getAssetOptions";

export const AssetsTableContextProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  //
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const globalStateDispatch = useDispatch();

  //

  const history = useHistory();

  const fetchGroupingTableData = async (_token, payload, options) => {
    try {
      //
      const syncPayload = options?.syncPayload ?? true;
      if (syncPayload) {
        syncPayloadWithUrl(payload);
      }
      dispatch({
        type: ActionType.FETCH_GROUPING_TABLE_DATA,
        payload: {},
      });
      let token = _token;
      if (!token) {
        token = await getAccessTokenSilently();
      }
      const data = await getInventoryAssetsByFilters(token, payload);
      dispatch({
        type: ActionType.FETCH_GROUPING_DATA_SUCCESSFUL,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionType.FETCH_GROUPING_DATA_FAILED,
        payload: error,
      });
    }
  };

  const fetchPrimaryTableData = async (_token, payload, options) => {
    try {
      const syncPayload = options?.syncPayload ?? true;
      if (syncPayload) {
        syncPayloadWithUrl(payload);
      }
      dispatch({
        type: ActionType.FETCH_PRIMARY_TABLE_DATA,
        payload: {},
      });
      let token = _token;
      if (!token) {
        token = await getAccessTokenSilently();
      }
      const data = await getInventoryAssetsByFilters(token, payload);
      dispatch({
        type: ActionType.FETCH_PRIMARY_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: ActionType.FETCH_PRIMARY_TABLE_DATA_FAILED,
        payload: {
          error,
        },
      });
    }
  };

  const fetchInitialInventoryData = async () => {
    try {
      dispatch({
        type: ActionType.INIT_PAGE,
        payload: {},
      });

      const initialEncodedConfig = getPageInitialEncodedViewConfig();
      const token = await getAccessTokenSilently();
      const response = await globalStateDispatch(getAssetOptions());
      const assetOptionsMap = response.payload.data;
      if (initialEncodedConfig) {
        const config =
          InventoryFilterUrlParser.decodeUrlToFilters(initialEncodedConfig);
        const filters = decodeEncodedFiltersFromUrl(config.filters);
        const currentGroupingValue = config?.group;
        const grouping = [];
        if (currentGroupingValue) {
          grouping.push(currentGroupingValue.group);
        }

        dispatch({
          type: ActionType.INIT_PAGE_SUCCESS,
          payload: {
            filters,
            assetOptionsMap,
            grouping,
            currentGroupingValue,
            searchKeyword: config.searchKeyword,
            primaryTableColumns: config.primaryTableColumns,
            paginationConfig: {
              pageIndex: config?.page,
              pageSize: config?.limit,
            },
          },
        });
        const isGrouping = !!grouping.length;
        if (isGrouping) {
          // fetch initial grouping data
          const payload = {
            page: config.page,
            limit: config?.limit,
            filters: filters,
            group: currentGroupingValue,
            searchKeyword: config.searchKeyword ?? "",
          };
          await fetchGroupingTableData(token, payload, { syncPayload: false });
        } else {
          // fetch initial primary table data
          const payload = {
            page: config.page,
            limit: config?.limit,
            filters: filters,
            group: [],
            searchKeyword: config.searchKeyword ?? "",
          };
          await fetchPrimaryTableData(token, payload, { syncPayload: false });
        }
      } else {
        dispatch({
          type: ActionType.INIT_PAGE_SUCCESS,
          payload: {
            assetOptionsMap,
          },
        });
        const payload = {
          page: state.primaryTableMeta.paginationConfig.pageIndex,
          limit: state.primaryTableMeta.paginationConfig.pageSize,
          filters: state.filters,
          group: state.currentGroupingValue,
          searchKeyword: state.searchKeyword.new,
        };
        await fetchPrimaryTableData(token, payload, { syncPayload: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const syncPayloadWithUrl = (payload) => {
    const encodedViewState =
      InventoryFilterUrlParser.encodeFiltersToUrl(payload);
    history.push(`?currentViewPayload=${encodedViewState}`);
  };

  const redirectSubPage = (data) => {
    const path = createAssetInfoSubPageUrl(
      data.id,
      AppRoutingConfig.inventory.AssetInfo.GENERAL
    );
    const previousUrl = window.location.pathname;
    history.push(path, { previousUrl });
  };

  const openAssetDrawer = (payload) => {
    dispatch({
      type: ActionType.OPEN_ASSET_DETAIL_VISIBLE,
      payload: payload,
    });
    redirectSubPage(payload);
  };

  const closeAssetDrawer = () => {
    dispatch({
      type: ActionType.CLOSE_ASSET_DETAIL_VISIBLE,
      payload: null,
    });
    history.push("/inventory");
  };

  const pageData = {
    filters: state.filters,
    primaryTableColumns: state.primaryTableColumns,
    grouping: state.grouping,
    currentGroupingValue: state.currentGroupingValue,
    assetOptionsMap: state.assetOptionsMap,
    searchKeyword: state.searchKeyword,
    assetDetailVisible: state.assetDetailVisible,
    selectedAssetForDetails: state.selectedAssetForDetails,
  };

  useEffect(() => {
    fetchInitialInventoryData();
  }, []);

  return (
    <AssetsTableMethodsContext.Provider
      value={{
        openAssetDrawer,
        closeAssetDrawer,
        syncPayloadWithUrl,
        //
        fetchPrimaryTableData,
        fetchGroupingTableData,
      }}
    >
      <AssetInventoryDispatchContext.Provider value={dispatch}>
        <AssetInventoryPageMetaContext.Provider value={state.pageMeta}>
          <AssetInventoryPrimaryTableStateContext.Provider
            value={state.primaryTableMeta}
          >
            <AssetInventoryGroupingTableStateContext.Provider
              value={state.groupingTableMeta}
            >
              <AssetInventoryDataContext.Provider value={pageData}>
                {children}
              </AssetInventoryDataContext.Provider>
            </AssetInventoryGroupingTableStateContext.Provider>
          </AssetInventoryPrimaryTableStateContext.Provider>
        </AssetInventoryPageMetaContext.Provider>
      </AssetInventoryDispatchContext.Provider>
    </AssetsTableMethodsContext.Provider>
  );
};
