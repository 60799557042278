import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { InventoryFilterUrlParser } from "../../../pages/inventory/utils/filterToUrlModule";

const VIEW_PARAM = "tableView";
export function useSaveTableView() {
  const history = useHistory();

  const saveView = (payload) => {
    const encodedViewState =
      InventoryFilterUrlParser.encodeFiltersToUrl(payload);
    history.push(`?${VIEW_PARAM}=${encodedViewState}`);
  };

  const getView = () => {
    const url = window.location.search;
    const params = new URLSearchParams(url);
    return params.get(VIEW_PARAM);
  };

  return { saveView, getView };
}
