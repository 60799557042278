import { combineReducers } from "redux";
import { reducer as UserStateReducer } from "./UserState/index";
import { reducer as CMSTReducer } from "./CMST/index";
import { reducer as InventoryReducer } from "./Inventory/index";
import { reducer as AppReducer } from "./app/index";
import integrationReducer from "../redux/reducers/integrationReducer";
import onboardingReducer from "../redux/reducers/onboardingReducer";

export default combineReducers({
  onboarding: onboardingReducer,
  integration: integrationReducer,
  user: UserStateReducer,
  cmst: CMSTReducer,
  inventory: InventoryReducer,
  app: AppReducer,
});
