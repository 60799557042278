import React from "react";
import { Box, Typography } from "@mui/material";
import pandoSmallLogo from "../../../../assets/images/pando-small-logo.svg";
import "./module.css";

function Completed() {
  return (
    <Box sx={{ position: "relative", maxWidth: "687px", paddingTop: "200px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "#002733",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "normal",
          }}
        >
          Onboarding Completed
        </Typography>
        <img
          src={pandoSmallLogo}
          alt="pando small logo"
          style={{
            position: "absolute",
            top: -8,
            right: "70px", // Adjust as needed for correct positioning
            width: "25px", // Adjust the size as necessary
          }}
        />
      </Box>
      <Typography
        sx={{
          color: "#676767",
          textAlign: "center",
          fontFamily: "Roboto",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          paddingTop: "20px",
        }}
      >
        Congratulations! <br /> You have completed the onboarding process.{" "}
        <br /> We will contact you shortly to provide further information and
        assistance.
      </Typography>
    </Box>
  );
}

export default Completed;
