import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { FieldInput, InputGroup, ViewContainer } from "../styled";
import { MuiStyles } from "../../awsCloudformation/stepOne/styles";
import { WizIntegrationActions } from "../context/reducer";
import {
  useGetWizIntegrationState,
  useSetWizIntegrationState,
} from "../hooks/useIntegrationState";

export const IntegrationForm = () => {
  const {
    wizUrl,
    legacyConnection,
    wizAuthUrl,
    clientSecret,
    projectUUID,
    httpsProxy,
    projectIdMapping,
    clientKey,
    name,
  } = useGetWizIntegrationState();
  const setValue = useSetWizIntegrationState();

  const onChangeWithType = (ev, type) => {
    const payload = ev.target.value;
    setValue({
      payload,
      type,
    });
  };

  const handleOnChangeWizUrl = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setWizUrl);
  };

  const handleOnChangeWizAuthenticationUrl = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setWizAuthUrl);
  };

  const handleOnChangeClientKey = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setClientKey);
  };

  const handleOnChangeLegacyConnection = () => {
    setValue({
      type: WizIntegrationActions.setLegacyConnection,
      payload: !legacyConnection,
    });
  };

  const handleOnChangeClientSecret = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setClientSecret);
  };

  const handleOnChangeProjectUUID = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setProjectUUID);
  };

  const handleOnChangeHttpsProxy = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setHttpsProxy);
  };

  const handleOnChangeProjectIdMapping = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setProjectIdMapping);
  };

  const handleOnChangeName = (ev) => {
    onChangeWithType(ev, WizIntegrationActions.setName);
  };

  return (
    <ViewContainer>
      <InputGroup>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Integration Name</Typography>
          </Box>
          <FieldInput value={name} onChange={handleOnChangeName} />
        </Box>

        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Wiz URL</Typography>
          </Box>
          <FieldInput value={wizUrl} onChange={handleOnChangeWizUrl} />
        </Box>
      </InputGroup>
      <InputGroup>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Wiz Authentication Url</Typography>
          </Box>
          <FieldInput
            value={wizAuthUrl}
            onChange={handleOnChangeWizAuthenticationUrl}
          />
        </Box>

        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Client Key</Typography>
          </Box>
          <FieldInput value={clientKey} onChange={handleOnChangeClientKey} />
        </Box>
      </InputGroup>
      <InputGroup>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Client Secret</Typography>
          </Box>
          <FieldInput
            value={clientSecret}
            onChange={handleOnChangeClientSecret}
          />
        </Box>

        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>Project UUID</Typography>
          </Box>
          <FieldInput
            value={projectUUID}
            onChange={handleOnChangeProjectUUID}
          />
        </Box>
      </InputGroup>
      <InputGroup>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>HTTPS Proxy (optional)</Typography>
          </Box>
          <FieldInput value={httpsProxy} onChange={handleOnChangeHttpsProxy} />
        </Box>

        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%" }}>
          <Box sx={MuiStyles.label}>
            <Typography variant='caption'>
              Project ID Mapping - Legacy Only (optional)
            </Typography>
          </Box>
          <FieldInput
            value={projectIdMapping}
            onChange={handleOnChangeProjectIdMapping}
          />
        </Box>
      </InputGroup>

      <InputGroup>
        <Box sx={{ ...MuiStyles.fieldBlock, width: "46%", marginTop: "auto" }}>
          <FormControlLabel
            onChange={handleOnChangeLegacyConnection}
            control={<Checkbox checked={legacyConnection} />}
            label={
              <Typography variant='caption'>Use legacy connection</Typography>
            }
          />
        </Box>
      </InputGroup>
    </ViewContainer>
  );
};
