import { DataGroupsSources } from "../../../../components/Grouping/data";
import { createOptionId } from "../../../../components/Grouping/utils/createOptionId";

function checkIncludes(subject, query) {
  let includes = false;

  try {
    if (Array.isArray(subject)) {
      includes = subject.some((val) => {
        const valueAsString = JSON.stringify(val);
        return valueAsString.includes(query);
      });
    }
    includes = subject.includes?.(query);
  } catch (error) {
    includes = false;
  }

  return includes;
}

function createPath(root, path) {
  if (!root) return path;
  return `${root}.${path}`;
}

function sanitizePath(path) {
  if (!path || typeof path !== "string") return null;
  return path.trim();
}

function traverse(asset, keyword, addToResults, path) {
  for (const key in asset) {
    const value = asset[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      const nestedPath = createPath(path, key);
      traverse(value, keyword, addToResults, nestedPath);
    } else {
      const includes = checkIncludes(value, keyword);
      if (includes) {
        const fullPath = createPath(path, key);
        addToResults(fullPath);
      }
    }
  }
}

function getFieldsFromTableResults(tableResults, keyword) {
  return tableResults.reduce((fields, asset) => {
    const addToResults = (value) => {
      const sanitizedPath = sanitizePath(value);
      fields.add(sanitizedPath);
    };
    traverse(asset, keyword, addToResults, "");
    return fields;
  }, new Set());
}

function getSourceFromField(field) {
  if (field.startsWith("adapters")) {
    return field.split(".")[1];
  }
  return DataGroupsSources.PLATFORM;
}

function getValueFromField(field, source) {
  if (source === DataGroupsSources.PLATFORM) return field;
  return field.split(".")?.[2];
}

function getOptionId(option, source) {
  if (source === DataGroupsSources.PLATFORM)
    return createOptionId(option, "options");
  return createOptionId(option, `${source}-option`);
}

function getFieldCheckedState(field, selectedCols) {
  return selectedCols.some((col) => col.id === field.id);
}

function createColumns(fields, selectedColumns) {
  function applyTransformer(field) {
    const source = getSourceFromField(field);
    const value = getValueFromField(field, source);
    const option = {
      label: value,
      value,
      source,
    };
    const id = getOptionId(option, source);
    option.id = id;
    const checked = getFieldCheckedState(option, selectedColumns);
    option.checked = checked;
    return option;
  }

  return fields.map(applyTransformer);
}

export function getSearchResultsColumns(
  tableResults,
  searchKeyword,
  selectedCols
) {
  const fields = getFieldsFromTableResults(tableResults, searchKeyword);
  return createColumns(Array.from(fields.values()), selectedCols);
}
