/**
 * set method to set token
 * @returns
 */
const ContextManager = function () {
  let context = {
    getToken: null,
    isInitialized: false,
  };

  this.setGetAuthToken = (getAuthToken) => {
    if (!context.getToken) {
      context.getToken = getAuthToken;
      context.isInitialized = true;
    }
  };

  this.getToken = async (...args) => {
    if (context.getToken) {
      return await context.getToken(...args);
    } else {
      console.warn("Method not set in Manager context");
    }
  };

  this.checkIsReady = () => {
    return context.isInitialized;
  };

  return this;
};

export const AuthManager = new ContextManager();
