import React from "react";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
const CardContent = ({ children }) => (
  <Box className={styles.cardContent}>
    <Box className={styles.cardMain}>{children}</Box>
  </Box>
);

export default CardContent;
