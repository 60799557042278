const mediaQueryRule = "@media screen and (max-width: 1024px)";

export const MuiStyles = {
  inputsSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "32px",
  },
  fieldBlock: {
    display: "flex",
    flexDirection: "column",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    columnGap: "32px",
    ":not(:first-child)": {
      marginTop: "24px",
    },
    [mediaQueryRule]: {
      flexDirection: "column",
      columnGap: 0,
      rowGap: "24px",
    },
  },
  label: {
    display: "flex",
    columnGap: "4px",
    marginBottom: "6px",
  },
  labelEditIcon: {
    color: "#999",
    fontSize: "16px",
  },
  advancedSection: {
    marginTop: "24px",
  },
  advancedAccordion: {
    border: "none",
    boxShadow: "none",
  },
  accordionSummaryIcon: {
    fontSize: "1rem !important",
    fontWeight: "600 !important",
  },
  advancedAccordionSummary: {
    "&.MuiAccordionSummary-root": {
      padding: "0",
      display: "flex",
      columnGap: "6px",
      minHeight: "24px",
      height: "24px",
      width: "min-content",
      boxSizing: "border-box",
      boxShadow: "none",
      border: "none",

      "&::before": {
        display: "none",
      },
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(0deg)",
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        transform: "rotate(90deg)",
      },
      "& > *": {
        color: "#023e8a !important",
        fontWeight: 600,
      },
    },
  },
  blockLabelSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  regenerateBtn: {
    textTransform: "none",
    marginTop: "8px",
    fontSize: "12px",
    borderRadius: "6px",
    backgroundColor: "#000",
    boxShadow: "none",
    left: "calc(100% - 100px)",

    ":focus": {
      outline: "none",
    },
    ":hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
};
