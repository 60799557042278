import { InputBase, styled } from "@mui/material";

export const ViewContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const FieldInput = styled(InputBase)`
  height: 32px;
  border-radius: 4px;
  border: 1px solid #eae9e9;
  padding: 0 6px;
`;

export const InputGroup = styled("div")`
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  :not(:first-child) {
    margin-top: 24px;
  }
`;
