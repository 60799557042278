import { useCallback, useMemo } from "react";
import { getCustomFieldsFromData } from "../utils/getCustomFields";
import { getOptionsFromData } from "../utils/getOptionsFromData";
import { createOptionId } from "../utils/createOptionId";
import { GroupOption } from "../components/GroupOptionValue";
import { AdapterOption } from "../components/AdapterOption";

export const useCustomFieldOptions = ({
  data,
  shouldReturnEmpty,
  getOptionCheckedState,
  checkOptionMatchesSearchQuery,
  handleOnClickGroupValue,
  searchValue,
  optionFilter,
}) => {
  const adapters = useMemo(() => {
    if (shouldReturnEmpty || !data) return [];
    return getCustomFieldsFromData(data);
  }, [data, shouldReturnEmpty]);

  const checkIsValidOption = (option, valueHash) => {
    return !!valueHash[option].values.length;
  };

  const createOptionNode = ({ option, valueHash, adapter }) => {
    if (!option) return null;
    const optionValue = valueHash[option];
    const originationTypes = optionValue.types;
    const values = optionValue.values;
    const label = `${option} (${values.length})`;
    const optionContext = `${adapter}-option`;
    const payload = {
      label,
      value: option,
      originationTypes,
      source: adapter,
    };
    if (optionFilter && !optionFilter?.(option)) return;
    const id = createOptionId(payload, optionContext);
    payload.id = id;
    const isChecked = getOptionCheckedState(payload);
    payload.checked = isChecked;
    if (!checkOptionMatchesSearchQuery(payload)) return null;
    return (
      <GroupOption
        key={id}
        data={payload}
        handleOnClickGroupValue={handleOnClickGroupValue}
      />
    );
  };

  const getAdapterOptions = useCallback(
    (adapter) => {
      const { options, valueHash } = getOptionsFromData(data, adapter);
      const adapterOptionNodes = [];
      options.forEach((adapterOption) => {
        if (checkIsValidOption(adapterOption, valueHash)) {
          const optionNode = createOptionNode({
            valueHash,
            adapter,
            option: adapterOption,
          });
          if (optionNode) adapterOptionNodes.push(optionNode);
        }
      });
      return adapterOptionNodes;
    },
    [data, getOptionCheckedState]
  );

  return useMemo(() => {
    return adapters.map((adapter, index) => {
      const key = `${adapter}-options`;
      const data = {
        key,
        label: adapter,
        value: adapter,
      };
      return (
        <AdapterOption
          key={key}
          isExpanded={!!searchValue}
          data={data}
          getGroupOptions={getAdapterOptions}
        />
      );
    });
  }, [
    searchValue,
    data,
    getOptionCheckedState,
    getAdapterOptions,
    checkOptionMatchesSearchQuery,
  ]);
};
