import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import BPSettingSection from "../components/settings/BPSection";
import UserManagementSettingSection from "../components/settings/UserManagement";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FeatureService from "../services/feturesService";
import IntegrationsSection from "../components/settings/IntegrationsSection";

function SettingsPage() {
  const [features, setFeatures] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await FeatureService.getFeatures(token);
        setFeatures(data);
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation:",
          error.message,
        );
      }
    };

    fetchFeatures();
  }, [getAccessTokenSilently]);

  // Render the rest of your component with the fetched processes
  return (
    <>
      <Container maxWidth={false}>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          {features["BP_SETTINGS"] && (
            <Grid item xs={12}>
              <BPSettingSection />
            </Grid>
          )}
          {features["USER_MGMT"] && (
            <Grid item xs={12}>
              <UserManagementSettingSection />
            </Grid>
          )}
          <Grid item xs={12}>
            <IntegrationsSection />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default SettingsPage;
