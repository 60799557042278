import { ActionType } from "../state-management/actions";
import { useAssetInventoryActionDispatch } from "./useAssetInventoryActionDispatch";
import { usePageData } from "./usePageData";

export function useGroupingState() {
  const { grouping } = usePageData();
  const dispatch = useAssetInventoryActionDispatch();
  const setState = (data) => {
    dispatch({
      type: ActionType.SET_GROUPING,
      payload: data,
    });
  };

  return [grouping, setState];
}
