import { DataFilterConjunctions } from "../../../../core/asset-inventory/filterConjunctions";
import { DataFilterOperators } from "../../../../core/asset-inventory/filterOperators";

function createOptionPayload(field, source) {
  return {
    value: field,
    label: field,
    source,
  };
}

function getOptionByValue(value, sourceOptions) {
  return Object.values(sourceOptions).find((val) => val.value === value);
}

function decodeFilter(
  { operator, conjunction, field, source, values, id },
  index
) {
  const fieldPayload = createOptionPayload(field, source);
  const valuesPayload = values.map((val) => createOptionPayload(val, source));
  const filter = {
    operator: getOptionByValue(operator, DataFilterOperators),
    field: fieldPayload,
    values: valuesPayload,
    id,
  };
  if (index > 0 && conjunction) {
    filter.conjunction = getOptionByValue(conjunction, DataFilterConjunctions);
  }
  return filter;
}

export function decodeEncodedFiltersFromUrl(filters) {
  return filters.map(decodeFilter);
}
