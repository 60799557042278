import { Typography } from "@mui/material";
import { Container, Icon } from "./styled";

const Component = () => {
  return (
    <Container>
      <Icon />
      <Typography variant='h6'>Ooops! You cannot access this page.</Typography>
    </Container>
  );
};

export default Component;
