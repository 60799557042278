import { useMemo } from "react";
import { useGetWizIntegrationState } from "./useIntegrationState";
import { CompletionView } from "../components/CompletionView";
import { IntegrationForm } from "../components/IntegrationForm";

export const useIntegrationView = () => {
  const { step } = useGetWizIntegrationState();

  return useMemo(() => {
    if (step === 1) return <IntegrationForm />;
    return <CompletionView />;
  }, [step]);
};
