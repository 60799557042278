import {
  EmptyValueOperatorsMap,
  MultipleValuesFilterOperators,
} from "../../../../core/data-filtering/operators";

function createFieldQueryStr(field) {
  if (!field) return "";
  const { source, value } = field;

  const queryStr = `${source}.${value}`;
  return queryStr;
}

const createQueryStrForEmptyValueFilter = (filter) => {
  const { field, operator } = filter;
  const fieldQueryStr = createFieldQueryStr(field);
  return `filter=${fieldQueryStr},${operator.value}`;
};

const checkIsMultiValueEncodedFilter = (operator) => {
  return !!MultipleValuesFilterOperators[operator];
};

const createUniqueOperatorQueryStr = (fieldQueryStr, operatorValue, values) => {
  const encodedValues = values.map((val) => val.value).join("|");
  return `filter=${fieldQueryStr},${operatorValue},${encodedValues}`;
};

const createQueryStrForFilterWithValue = (filter) => {
  let queryStr = "";

  const { field, operator, values: _values } = filter;
  let values = _values;
  if (_values && !Array.isArray(_values)) {
    values = [_values];
  }

  const fieldQueryStr = createFieldQueryStr(field);
  const isMultiValueEncodedFilter = checkIsMultiValueEncodedFilter(
    operator.value
  );
  if (isMultiValueEncodedFilter) {
    queryStr = createUniqueOperatorQueryStr(
      fieldQueryStr,
      operator.value,
      values
    );
  } else {
    values.forEach(({ value }, index) => {
      let valStr = "";
      if (index > 0) valStr += "&";
      valStr += `filter=${fieldQueryStr},${operator.value},${value}`;
      queryStr += valStr;
    });
  }
  return queryStr;
};

function checkIsValidEmptyValueFilter(filter) {
  return !!EmptyValueOperatorsMap[filter.operator.value];
}

export function filtersToQueryParamTransformer(filters) {
  let query = "";
  if (!filters) return query;

  try {
    const createFilterRequestQuery = (filter, index) => {
      const isEmptyValueFilter = checkIsValidEmptyValueFilter(filter);
      let queryStr = "";
      if (isEmptyValueFilter) {
        queryStr = createQueryStrForEmptyValueFilter(filter);
      } else {
        queryStr = createQueryStrForFilterWithValue(filter);
      }

      if (index > 0) query += "&";
      query += `${queryStr}`;
    };

    filters.forEach(createFilterRequestQuery);
  } catch (error) {
    console.log(error);
  }
  return query;
}
