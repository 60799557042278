import { styled } from "@mui/material";

export const TableActionsRoot = styled("div")(({ theme }) => {
  return `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
  height: 45px;
  width: 100%;
  padding-right: 4px;
  padding: 24px 12px;
  padding-top: 32px;
  border-radius: 2px;
  margin-top: 6px;
  background-color: ${theme.palette.grey[100]};
  position: relative;
`;
});
