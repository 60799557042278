import { forwardRef, useMemo } from "react";
import { ActionButtonRoot, CountRoot, IconButtonRoot } from "./styled";
import { Tooltip } from "@mui/material";
import ConditionComponent from "../../../../../../../utils/ConditionComponent";

const Button = forwardRef(({ icon = null, ...props }, ref) => {
  return (
    <IconButtonRoot ref={ref} {...props}>
      {icon}
    </IconButtonRoot>
  );
});

export const AssetTableAction = forwardRef(
  (
    {
      tooltipProps = {},
      actionCount,
      showActionCount = true,
      children,
      ...props
    },
    ref
  ) => {
    const content = useMemo(() => {
      if (tooltipProps.show && tooltipProps.title) {
        return (
          <Tooltip {...tooltipProps}>
            <div>
              <ConditionComponent
                condition={!!children}
                falsyNode={<Button {...props} />}
              >
                {children}
              </ConditionComponent>
            </div>
          </Tooltip>
        );
      }
      return <Button {...props} />;
    }, [tooltipProps, props, children]);

    return (
      <ActionButtonRoot ref={ref}>
        {content}
        <ConditionComponent condition={showActionCount && !!actionCount}>
          <CountRoot>{actionCount}</CountRoot>
        </ConditionComponent>
      </ActionButtonRoot>
    );
  }
);
