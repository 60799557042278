import { Actions } from "./actions";

export const Reducer = (_state, action) => {
  if (action.type === Actions.FETCH_QUERIES_PENDING) {
    const state = { ..._state };
    state.isLoading = true;
    state.error = false;
    state.errorPayload = null;
    state.data = [];
    return state;
  }
  if (action.type === Actions.FETCH_QUERIES_SUCCESS) {
    const state = { ..._state };
    state.isLoading = false;
    state.error = false;
    state.errorPayload = null;
    state.data = action.payload.data;
    state.meta = action.payload.meta;
    return state;
  }
  if (action.type === Actions.FETCH_QUERIES_ERROR) {
    const state = { ..._state };
    state.isLoading = false;
    state.error = true;
    state.errorPayload = action.payload;
    state.data = [];
    return state;
  }
  if (action.type === Actions.SET_PAGINATION) {
    const state = { ..._state };
    state.paginationConfig = action.payload;
    return state;
  }

  if (action.type === Actions.DELETE_ACTION_PENDING) {
    const state = { ..._state };
    const deleteActionPayload = {
      ...state.deleteActionPayload,
      isLoading: true,
    };

    state.deleteActionPayload = deleteActionPayload;
    return state;
  }

  if (action.type === Actions.DELETE_ACTION_ERROR) {
    const state = { ..._state };
    const deleteActionPayload = {
      ...state.deleteActionPayload,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
    };

    state.deleteActionPayload = deleteActionPayload;
    return state;
  }

  if (action.type === Actions.DELETE_ACTION_SUCCESS) {
    const state = { ..._state };
    const deleteActionPayload = {
      ...state.deleteActionPayload,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: null,
    };

    state.showDeleteActionView = false;
    state.deleteActionPayload = deleteActionPayload;
    return state;
  }

  if (action.type === Actions.OPEN_DELETE_ACTION) {
    const state = { ..._state };
    const deleteActionPayload = {
      ...state.deleteActionPayload,
      data: action.payload,
    };

    state.deleteActionPayload = deleteActionPayload;
    state.showDeleteActionView = true;

    return state;
  }

  if (action.type === Actions.CLOSE_DELETE_ACTION) {
    const state = { ..._state };
    const deleteActionPayload = {
      ...state.deleteActionPayload,
      data: null,
    };

    state.deleteActionPayload = deleteActionPayload;
    state.showDeleteActionView = false;

    return state;
  }

  if (action.type === Actions.OPEN_EDIT_ACTION_MODAL) {
    const state = { ..._state };

    state.showEditActionView = true;
    const editActionModalPayload = {
      ...state.editActionModalPayload,
      data: {
        ...state.editActionModalPayload.data,
        ...action.payload,
      },
    };
    state.editActionModalPayload = editActionModalPayload;
    return state;
  }

  if (action.type === Actions.CLOSE_EDIT_ACTION_MODAL) {
    const state = { ..._state };

    state.showEditActionView = false;
    const editActionModalPayload = {
      ...state.editActionModalPayload,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: null,
    };
    state.editActionModalPayload = editActionModalPayload;

    return state;
  }

  if (action.type === Actions.SET_ACTION_MODAL_PAYLOAD) {
    const state = { ..._state };
    const editActionModalPayload = {
      ...state.editActionModalPayload,
      data: {
        ...state.editActionModalPayload.data,
        ...action.payload,
        notificationMethods: [...(action.payload.notificationMethods ?? [])],
      },
    };

    state.editActionModalPayload = editActionModalPayload;

    return state;
  }

  if (action.type === Actions.EDIT_ACTION_PENDING) {
    const state = { ..._state };
    const editActionModalPayload = {
      ...state.editActionModalPayload,
      isLoading: true,
      error: false,
      errorPayload: null,
    };
    state.editActionModalPayload = editActionModalPayload;
    return state;
  }

  if (action.type === Actions.EDIT_ACTION_ERROR) {
    const state = { ..._state };
    const editActionModalPayload = {
      ...state.editActionModalPayload,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
    };
    state.editActionModalPayload = editActionModalPayload;
    return state;
  }
  if (action.type === Actions.EDIT_ACTION_SUCCESS) {
    const state = { ..._state };
    const editActionModalPayload = {
      ...state.editActionModalPayload,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: {},
    };
    state.editActionModalPayload = editActionModalPayload;
    const updatedAction = action.payload;
    const updatedData = state.data.map((action) => {
      const isEditedAction = action.rule_uuid === updatedAction.ruleUuid;
      if (isEditedAction) return updatedAction;
      return action;
    });
    state.data = updatedData;
    return state;
  }

  return { ..._state };
};
