import {
  List,
  Popover,
  styled,
  Typography,
  Checkbox as MuiCheckbox,
  FormControlLabel,
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

export const Root = styled(Popover)(({ theme }) => {
  return `
  & .MuiPopover-paper {
    max-height: 300px;
    min-height: 200px;
    width: 350px;
    margin-top: 36px;
    border-radius: 2px;
    padding: 14px 0px 8px;
    box-shadow: ${theme.shadows[1]};
    background-color:#fff;
    overflow-y: hidden;
    display: flex; flex-direction: column;
  }
`;
});

export const ListRoot = styled(List)(({ theme }) => {
  return `
  margin-top: 12px;
  min-height: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  overflow-y: auto;
  border-radius: 0;
  padding: 6px 10px 8px;
  background-color: ${theme.palette.background.paper};
`;
});

export const ResultColumnRoot = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start;
  column-gap: 4px;
  margin-left: 0;
  margin-right: 0;
`;

export const CheckBox = styled(MuiCheckbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
`;

export const ResultColumnLabel = styled((props) => (
  <Typography variant='body2' {...props} />
))``;

export const Heading = styled((props) => (
  <Typography variant='h6' {...props} />
))`
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
`;

export const SubHeading = styled((props) => (
  <Typography variant='h6' {...props} />
))`
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  color: #00;
  margin-top: 2px;
`;

export const Header = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 0 10px;
`;

export const TextSection = styled("div")`
display block;
`;

export const SectionIcon = styled(ViewColumnIcon)`
  background: #a9a9a9;
  font-size: 25px;
  padding: 2px;
`;
