import { Box } from "@mui/material";
import React, { useEffect } from "react";
import "react-step-progress-bar/styles.css";
import styles from "./styles.module.css";
import OnBoardingLayout from "../../components/onboardingV2/onBoardingLayout";
import { WelcomeStep } from "../../components/onboardingV2/onBoardingSteps/welcomeStep/Welcome";
import CardFooter from "../../components/onboardingV2/cardFooter";
import ConnectorsStep from "../../components/onboardingV2/onBoardingSteps/connectorsStep";
import { useSelector, useDispatch } from "react-redux";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import Completed from "../../components/onboardingV2/onBoardingSteps/completedStep";
import { PageContainer } from "./styled";

export default function Onboarding({ onlyIntergrations }) {
  const dispatch = useDispatch();
  const stageMemory = useSelector((state) => state.onboarding.currentStep);
  const [stage, setStage] = React.useState(stageMemory);

  const [steps, setSteps] = React.useState([]);

  useEffect(() => {
    const handleBack = () => {
      if (stage > 1) {
        setStage(stage - 1);
        dispatch(setOnboardingStep(stage - 1));
      }
    };

    const handleContinue = async () => {
      if (stage < steps.length) {
        setStage(stage + 1);
        dispatch(setOnboardingStep(stage + 1));
      }
    };

    let localSteps = [];
    if (onlyIntergrations) {
      localSteps = [
        {
          content: <ConnectorsStep />,
          footer: (
            <CardFooter showBackButton={false} showContinueButton={false} />
          ),
        },
      ];
    } else {
      localSteps = [
        {
          content: <WelcomeStep />,
          footer: (
            <CardFooter
              onBack={handleBack}
              onContinue={handleContinue}
              backDisabled={true}
              continueDisabled={false}
            />
          ),
        },
        {
          content: <ConnectorsStep />,
          footer: (
            <CardFooter
              onBack={handleBack}
              onContinue={handleContinue}
              backDisabled={false}
              continueDisabled={false}
            />
          ),
        },
        {
          content: <Completed />,
          footer: (
            <CardFooter
              onBack={handleBack}
              onContinue={handleContinue}
              backDisabled={false}
              continueDisabled={false}
              showBackButton={true}
              showContinueButton={false}
            />
          ),
        },
      ];
    }
    setSteps(localSteps);
  }, [onlyIntergrations, stage, dispatch, steps.length]);

  useEffect(() => {
    if (steps.length === 0) {
      return;
    }

    if (steps.length < stage) {
      console.log(steps.length);
      setStage(1);
      dispatch(setOnboardingStep(1));
      console.log("resetting stage");
    }
  }, [steps.length, stage, dispatch]);
  return (
    <PageContainer component={"main"}>
      <OnBoardingLayout
        cardContent={steps[stage - 1]?.content}
        cardFooter={steps[stage - 1]?.footer}
        onlyIntergrations
      />
    </PageContainer>
  );
}
