import ConditionComponent from "../../../../../utils/ConditionComponent";
import { useIsGrouping } from "../../hooks/useIsGrouping";
import { GroupingView } from "./components/GroupingView";
import { RootTable } from "./components/RootTable";

export const TableRoot = () => {
  const isGrouping = useIsGrouping();

  return (
    <div>
      <ConditionComponent condition={isGrouping} falsyNode={<RootTable />}>
        <GroupingView />
      </ConditionComponent>
    </div>
  );
};
