import { useMemo } from "react";
import createButtonConfig from "../../../shared/functions/buttons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { IntegrationActions } from "../context/reducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addHibobIntegration } from "../../../utils/api";
import {
  useGetIntegrationState,
  useSetIntegrationState,
} from "./useIntegrationState";
import { u } from "@table-library/react-table-library/HeaderCell-1d879c3c";

export const useIntegrationButtons = () => {
  const state = useGetIntegrationState();
  const {
    step,
    loading,
    hostname,
    userServiceId,
    apiToken,
    verifySSL,
    httpsProxy,
    httpsProxyUsername,
    httpsProxyPassword,
    name,
  } = state;

  const setState = useSetIntegrationState();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const payloadIsValid = useMemo(() => {
    let isValid = true;
    if (!apiToken) isValid = false;
    if (!name) isValid = false;
    if (!userServiceId) isValid = false;
    return isValid;
  }, [userServiceId, apiToken, name]);

  const handleOnCancelIntegration = () => {
    history.push("/integrations");
  };

  const handleOnCompleteIntegration = () => {
    setState({ type: IntegrationActions.reset });
    history.push("/integrations");
  };

  const handleOnContinueIntegration = async () => {
    try {
      setState({
        type: IntegrationActions.startIntegration,
      });
      const token = await getAccessTokenSilently();
      const payload = {
        host_name: hostname || null,
        user_service_id: userServiceId,
        api_token: apiToken,
        verify_ssl: verifySSL,
        https_proxy: httpsProxy || null,
        https_proxy_user: httpsProxyUsername || null,
        https_proxy_password: httpsProxyPassword || null,
        name,
      };
      await addHibobIntegration(token, payload);
      setState({
        type: IntegrationActions.integrationSuccess,
      });
    } catch (error) {
      const payload = createErrorMessage(error);
      setState({
        type: IntegrationActions.integrationFailed,
        payload,
      });
    }
  };

  const step1FooterButtons = [
    createButtonConfig(
      "Cancel",
      "left",
      handleOnCancelIntegration,
      null,
      "cancel",
      false
    ),
    createButtonConfig(
      "Continue",
      "right",
      handleOnContinueIntegration,
      <DoneOutlinedIcon />,
      "cancel",
      !payloadIsValid,
      loading
    ),
  ];

  const step2FooterButtons = [
    createButtonConfig(
      "Done",
      "right",
      handleOnCompleteIntegration,
      null,
      "cancel",
      false
    ),
  ];

  const buttons = useMemo(() => {
    if (step === 2) return step2FooterButtons;
    return step1FooterButtons;
  }, [state]);

  return buttons;
};

function createErrorMessage(error) {
  let message = "Something went wrong";
  if (error.response.status === 422) message = "Invalid field value";
  return message;
}
