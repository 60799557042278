import { Actions } from "./actions";

export const Reducer = (_state, action) => {
  if (action.type === Actions.FETCH_ISSUES_TABLE) {
    const state = { ..._state };
    state.issuesTableState = {
      ..._state.issuesTableState,
      isLoading: true,
      error: false,
      errorPayload: null,
    };
    return state;
  }

  if (action.type === Actions.FETCH_ISSUES_TABLE_SUCCESS) {
    const state = { ..._state };
    state.issuesTableState = {
      ..._state.issuesTableState,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: action.payload.data,
      meta: action.payload.meta,
    };
    return state;
  }

  if (action.type === Actions.FETCH_ISSUES_TABLE_FAILED) {
    const state = { ..._state };
    state.issuesTableState = {
      ..._state.issuesTableState,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
      data: [],
      meta: {},
    };
    return state;
  }

  if (action.type === Actions.SET_ISSUES_PAGINATION) {
    const paginationConfig = action.payload;

    const state = { ..._state };
    state.issuesTableState = {
      ..._state.issuesTableState,
      paginationConfig,
    };

    return state;
  }

  return { ..._state };
};
