import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useUserInfo } from "./useAppUser";

export const useFetchApiData = ({ path }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const [unAuthorized, setUnAuthorized] = useState(false);
  const { organizationId } = useUserInfo();

  const initStates = () => {
    setLoading(true);
    setData(null);
    setError("");
    setUnAuthorized(false);
  };

  const fetchData = async () => {
    try {
      initStates();
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}${path}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-ORG-ID": organizationId,
          },
        },
      );
      const status = response.status;
      const isUnAuthorizedRequest =
        response.status === 401 || response.status === 403;
      const dataNotFound = status > 400 && status < 500;

      if (isUnAuthorizedRequest) {
        setUnAuthorized(true);
        throw new Error("user not authorized");
      } else if (dataNotFound) {
        setData("notFound");
        throw new Error("Requested Data not found");
      } else {
        const data = await response.json();
        setData(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [path]);

  return {
    data,
    loading,
    error,
    unAuthorized,
  };
};
