import { Actions } from "./actions";
import { InitialState } from "./initialState";

export const Reducer = (_state, action) => {
  if (action.type === Actions.INIT_TABLE_STATES) {
    const state = { ..._state };

    const {
      grouping,
      currentGroupingOption,
      currentGroupingOptions,
      filters,
      primaryTable,
      groupingTable,
      searchKeyword,
    } = action.payload;

    state.filters = filters;
    state.grouping = grouping;
    state.searchKeyword = searchKeyword;
    state.currentGroupingOption = currentGroupingOption;
    state.currentGroupingOptions = currentGroupingOptions;
    state.groupingTable = { ...state.groupingTable, ...groupingTable };
    state.primaryTable = { ...state.primaryTable, ...primaryTable };

    return state;
  }
  if (action.type === Actions.SET_SEARCH_INPUT) {
    const state = { ..._state };
    state.searchKeyword = action.payload;
    return state;
  }
  if (action.type === Actions.SET_COLUMNS) {
    const state = { ..._state };
    state.columns = action.payload;
    return state;
  }
  if (action.type === Actions.SET_GROUPING) {
    const state = { ..._state };
    const { options, grouping, currentGroupingOption } = action.payload;

    state.grouping = grouping;
    state.currentGroupingOptions = options;
    state.currentGroupingOption = currentGroupingOption;
    return state;
  }

  if (action.type === Actions.SET_GROUPING_OPTION) {
    const state = { ..._state };

    state.currentGroupingOption = action.payload;
    return state;
  }
  if (action.type === Actions.RESET_GROUPING_TABLE) {
    const state = { ..._state };
    state.groupingTable = InitialState.groupingTable;
    return state;
  }
  if (action.type === Actions.SET_FILTERS) {
    const state = { ..._state };
    state.filters = action.payload;
    return state;
  }
  if (action.type === Actions.SET_PRIMARY_TABLE_PAGINATION) {
    const state = { ..._state };
    const primaryTable = {
      ...state.primaryTable,
      paginationConfig: action.payload,
    };
    state.primaryTable = primaryTable;

    return state;
  }

  if (action.type === Actions.SET_GROUPING_TABLE_PAGINATION) {
    const state = { ..._state };
    const groupingTable = {
      ...state.groupingTable,
      paginationConfig: action.payload,
    };
    state.groupingTable = groupingTable;

    return state;
  }

  if (action.type === Actions.FETCH_PRIMARY_TABLE_DATA_PENDING) {
    const state = { ..._state };

    const primaryTable = {
      ...state.primaryTable,
      isLoading: true,
      error: false,
      errorPayload: null,
      data: [],
      meta: {},
    };
    state.primaryTable = primaryTable;
    return state;
  }

  if (action.type === Actions.FETCH_PRIMARY_TABLE_DATA_SUCCESS) {
    const state = { ..._state };
    const primaryTable = {
      ...state.primaryTable,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: action.payload.data,
      meta: action.payload.meta,
    };
    state.primaryTable = primaryTable;

    return state;
  }
  if (action.type === Actions.FETCH_PRIMARY_TABLE_DATA_ERROR) {
    const state = { ..._state };
    const primaryTable = {
      ...state.primaryTable,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
      data: [],
      meta: {},
    };
    state.primaryTable = primaryTable;
    return state;
  }

  if (action.type === Actions.FETCH_GROUPING_TABLE_DATA_PENDING) {
    const state = { ..._state };

    const groupingTable = {
      ...state.groupingTable,
      isLoading: true,
      error: false,
      errorPayload: null,
      data: [],
      meta: {},
    };
    state.groupingTable = groupingTable;
    return state;
  }

  if (action.type === Actions.FETCH_GROUPING_TABLE_DATA_SUCCESS) {
    const state = { ..._state };
    const groupingTable = {
      ...state.groupingTable,
      isLoading: false,
      error: false,
      errorPayload: null,
      data: action.payload.data,
      meta: action.payload.meta,
    };
    state.groupingTable = groupingTable;

    return state;
  }
  if (action.type === Actions.FETCH_GROUPING_TABLE_DATA_ERROR) {
    const state = { ..._state };
    const groupingTable = {
      ...state.groupingTable,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
      data: [],
      meta: {},
    };
    state.groupingTable = groupingTable;
    return state;
  }

  if (action.type === Actions.SHOW_CREATE_QUERY_VIEW) {
    const state = { ..._state };
    const saveQueryPayload = {
      ...state.saveQueryPayload,
      open: true,
      isLoading: false,
    };
    state.saveQueryPayload = saveQueryPayload;
    return state;
  }

  if (action.type === Actions.CLOSE_CREATE_QUERY_VIEW) {
    const state = { ..._state };
    const saveQueryPayload = {
      ...state.saveQueryPayload,
      open: false,
      data: {},
      isLoading: false,
    };
    state.saveQueryPayload = saveQueryPayload;
    return state;
  }

  if (action.type === Actions.CREATE_QUERY_PENDING) {
    const state = { ..._state };
    const saveQueryPayload = {
      ...state.saveQueryPayload,
      isLoading: true,
      error: false,
      errorPayload: null,
    };
    state.saveQueryPayload = saveQueryPayload;
    return state;
  }

  if (action.type === Actions.CREATE_QUERY_SUCCESS) {
    const state = { ..._state };
    const saveQueryPayload = {
      ...state.saveQueryPayload,
      open: false,
      isLoading: false,
      error: false,
      errorPayload: null,
    };
    state.saveQueryPayload = saveQueryPayload;
    return state;
  }

  if (action.type === Actions.CREATE_QUERY_ERROR) {
    const state = { ..._state };
    const saveQueryPayload = {
      ...state.saveQueryPayload,
      isLoading: false,
      error: true,
      errorPayload: action.payload,
    };
    state.saveQueryPayload = saveQueryPayload;
    return state;
  }

  return { ..._state };
};
