const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const organization_id = localStorage.getItem("organization_id") || "";

const InitiativeService = {
  async createInitiative(token, initiative) {
    const url = `${BASE_URL}/initiative/create`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-ORG-ID": organization_id,
      },
      body: JSON.stringify(initiative),
    });

    const data = await response.json();
    return data;
  },
  async listInitiatives(token) {
    const url = `${BASE_URL}/initiative/list`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  },
  async deleteInitiative(id) {
    const url = `${BASE_URL}/initiative/delete/${id}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  },
  async generateTicket(token, initiativeObj) {
    const url = `${BASE_URL}/initiative/generate_ticket`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        initiative_obj: initiativeObj,
      }),
    });

    return await response.json();
  },
};

export default InitiativeService;
