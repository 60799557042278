import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import authorPandogpt from "../../../assets/images/chat/author-pandogpt.svg";
import styles from "./MessageList.module.css";
import { Box, IconButton, Typography } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { MarkdownListItem, MarkdownPreComponent } from "./styled-components";

const MessageList = ({ messages, handleLike, handleDislike }) => {
  const endOfMessagesRef = useRef(null);
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
  const [likedMessages, setLikedMessages] = useState([]);
  const [dislikedMessages, setDislikedMessages] = useState([]);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    let timeout;
    if (copiedMessageIndex !== null) {
      timeout = setTimeout(() => {
        setCopiedMessageIndex(null);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [copiedMessageIndex]);

  const hasMessages = messages.length > 0;

  const messageListStyle = hasMessages ? {} : {};

  const handleCopy = (index) => {
    navigator.clipboard.writeText(messages[index].content);
    setCopiedMessageIndex(index);
  };

  const handleLikeClick = (index) => {
    if (likedMessages.includes(index)) {
      setLikedMessages(likedMessages.filter((i) => i !== index));
    } else {
      setLikedMessages([...likedMessages, index]);
      setDislikedMessages(dislikedMessages.filter((i) => i !== index));
      handleLike && handleLike(index);
    }
  };

  const handleDislikeClick = (index) => {
    if (dislikedMessages.includes(index)) {
      setDislikedMessages(dislikedMessages.filter((i) => i !== index));
    } else {
      setDislikedMessages([...dislikedMessages, index]);
      setLikedMessages(likedMessages.filter((i) => i !== index));
      handleDislike && handleDislike(index);
    }
  };

  return (
    <div className={styles.messageList} style={messageListStyle}>
      {hasMessages &&
        messages.map((message, index) => (
          <div
            key={index}
            className={`${styles.messageItem} ${styles[message.author]}`}
          >
            {message.author === "chatgpt" ? (
              <>
                <div className={styles.avatar}>
                  <img src={authorPandogpt} alt='PandoGPT' />
                </div>
                <div className={styles.messageContent}>
                  <ReactMarkdown
                    children={message.content}
                    components={{
                      p({ children }) {
                        return (
                          <Typography variant='body2'>{children}</Typography>
                        );
                      },
                      em({ children }) {
                        return <em className='important-text'>{children}</em>;
                      },
                      pre({ children }) {
                        return (
                          <MarkdownPreComponent>
                            {children}
                          </MarkdownPreComponent>
                        );
                      },
                      li({ children }) {
                        return <MarkdownListItem>{children}</MarkdownListItem>;
                      },
                    }}
                  />
                  <Box className={styles.responseActions}>
                    <IconButton
                      aria-label='like'
                      onClick={() => handleLikeClick(index)}
                      disabled={
                        likedMessages.includes(index) ||
                        dislikedMessages.includes(index)
                      }
                    >
                      {likedMessages.includes(index) ? (
                        <ThumbUpIcon />
                      ) : (
                        <ThumbUpOutlinedIcon />
                      )}
                    </IconButton>
                    <IconButton
                      aria-label='dislike'
                      onClick={() => handleDislikeClick(index)}
                      disabled={
                        likedMessages.includes(index) ||
                        dislikedMessages.includes(index)
                      }
                    >
                      {dislikedMessages.includes(index) ? (
                        <ThumbDownIcon />
                      ) : (
                        <ThumbDownOutlinedIcon />
                      )}
                    </IconButton>
                    <IconButton
                      aria-label='copy'
                      onClick={() => handleCopy(index)}
                    >
                      {copiedMessageIndex === index ? (
                        <DoneOutlinedIcon />
                      ) : (
                        <ContentCopyOutlinedIcon />
                      )}
                    </IconButton>
                  </Box>
                </div>
              </>
            ) : (
              <>
                <div className={styles.avatar}>
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className={styles.messageContent}>{message.content}</div>
              </>
            )}
          </div>
        ))}
      {/* Invisible div at the end of the message list to act as scroll target */}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default MessageList;
