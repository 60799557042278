import {
  cloneElement,
  createRef,
  Fragment,
  useMemo,
  useRef,
  useState,
} from "react";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { InventoryTableActionButton } from "../../pages/inventory/components/ActionButton/index";

const ref = createRef();

export const TableColumnSelector = ({ children, count = 0 }) => {
  const [open, setOpen] = useState(false);
  const menuTwoWayBindingRef = useRef(null);

  const handleOpenSelector = () => {
    menuTwoWayBindingRef.current.onOpenMenu?.();
    setOpen(!open);
  };

  const handleOnClose = () => {
    menuTwoWayBindingRef.current.onClose?.();
    setOpen(false);
  };

  const selector = useMemo(() => {
    return cloneElement(children, {
      title: "Columns",
      opened: open,
      anchorEl: ref.current,
      onClose: handleOnClose,
      twoWayBindingRef: menuTwoWayBindingRef,
      elements: {
        selectedValues: true,
        footer: true,
      },
    });
  }, [children, open]);

  return (
    <Fragment>
      <InventoryTableActionButton
        ref={ref}
        actionCount={count}
        tooltipProps={{
          show: true,
          title: "Select Columns",
        }}
        onClick={handleOpenSelector}
        icon={<ViewColumnIcon />}
      />
      {selector}
    </Fragment>
  );
};
