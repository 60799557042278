// @ts-check

import { useSavedQueriesState } from "./useSavedQueriesState";

/**
 * @typedef {Object} PaginationState
 * @property {number} pageIndex
 * @property {number} pageSize
 */

/**
 * @typedef {Object} TableState
 * @property {boolean} isLoading
 * @property {Array} data
 * @property {any} error
 * @property {Object } meta
 * @property {PaginationState} pagination
 */

/**
 *
 * @returns {TableState}
 */
export const useSavedQueriesTableState = () => {
  return useSavedQueriesState().savedQueriesTableState;
};
