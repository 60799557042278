import { Box, Typography, styled } from "@mui/material";
import { forwardRef } from "react";

export const PageLayoutContainer = styled(Box)(({ theme }) => {
  return `
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${theme.palette.background.paper};
  padding: 24px;
  box-sizing: border-box;
`;
});

export const PageHeader = styled(
  forwardRef((props, ref) => <Typography ref={ref} {...props} variant="h5" />),
)`
  font-family: Inter;
`;
