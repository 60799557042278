import { Typography } from "@mui/material";
import { AssetJSONSection } from "./styled";
import DataObjectIcon from "@mui/icons-material/DataObject";

export const EmptyJson = () => {
  return (
    <AssetJSONSection
      sx={{ color: "#ccc", justifyContent: "center", alignItems: "center" }}
    >
      <DataObjectIcon />
      <Typography variant="body2">No data available</Typography>
    </AssetJSONSection>
  );
};
