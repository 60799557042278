import { AccountCircle, Send } from "@mui/icons-material";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import { useMaterialReactTable } from "material-react-table";
import { useAssetsTableMethods } from "./useAssetsTableMethods";
import { CellContainer, TruncatedCell } from "../styled";
import { DataCount } from "../../../../components/DataCount";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { getShortListValue } from "../utils/getShortestListValue";
import { TableCellTooltipContent } from "../components/TableCellTooltip";
import { getCommonFieldValues } from "../utils/getCommonField";

export const useDataTable = ({
  searchKeyword,
  isLoading,
  handleSearchChange,
  rowCount,
  pagination,
  data,
  error,
  reactTableProps = {},
}) => {
  const { openAssetDrawer } = useAssetsTableMethods();

  const table = useMaterialReactTable({
    renderEmptyRowsFallback: () => {
      if (error)
        return (
          <Typography sx={{ color: "red", marginTop: "24px" }}>
            {error}
          </Typography>
        );
      return <Typography sx={{ marginTop: "24px" }}>No data</Typography>;
    },
    columns: [
      {
        accessorKey: "common_fields",
        header: "Name",
        Cell({ cell }) {
          const commonFieldsValue = cell.getValue();
          const names = getCommonFieldValues(commonFieldsValue, "name");
          const displayValue = getShortListValue(names);
          const displayLabel = displayValue || "n/a";

          const hasMultipleValues = Array.isArray(names) && names.length > 1;
          const count = names.length - 1;

          return (
            <CellContainer>
              <TruncatedCell>{displayLabel}</TruncatedCell>
              <ConditionComponent condition={hasMultipleValues}>
                <Tooltip
                  placement='top'
                  title={<TableCellTooltipContent data={names} />}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <div>
                    <DataCount data={{ count }} />
                  </div>
                </Tooltip>
              </ConditionComponent>
            </CellContainer>
          );
        },
      },
      {
        accessorKey: "types",
        header: "Type",
        Cell({ cell }) {
          const cellValues = cell.getValue();
          const values = getCommonFieldValues({ cellValues }, "cellValues");
          const displayValue = getShortListValue(values);

          const hasMultipleValues = Array.isArray(values) && values.length > 1;
          const count = values.length - 1;

          return (
            <CellContainer>
              <TruncatedCell>{displayValue}</TruncatedCell>
              <ConditionComponent condition={hasMultipleValues}>
                <Tooltip
                  placement='top'
                  title={<TableCellTooltipContent data={values} />}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <div>
                    <DataCount data={{ count }} />
                  </div>
                </Tooltip>
              </ConditionComponent>
            </CellContainer>
          );
        },
      },
      {
        accessorKey: "sources",
        header: "Source",
        Cell({ cell }) {
          const values = cell.getValue();
          const sources = getCommonFieldValues({ sources: values }, "sources");
          const displayValue = getShortListValue(sources);

          const hasMultipleValues =
            Array.isArray(sources) && sources.length > 1;
          const count = sources.length - 1;
          return (
            <CellContainer>
              <TruncatedCell>{displayValue}</TruncatedCell>
              <ConditionComponent condition={hasMultipleValues}>
                <Tooltip
                  placement='top'
                  title={<TableCellTooltipContent data={values} />}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <div>
                    <DataCount data={{ count }} />
                  </div>
                </Tooltip>
              </ConditionComponent>
            </CellContainer>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell({ cell }) {
          const value = cell.getValue() || "n/a";
          return <TruncatedCell>{value}</TruncatedCell>;
        },
      },
    ],

    columnPinning: {
      left: ["mrt-row-expand", "mrt-row-select"],
      right: ["mrt-row-actions"],
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    data,
    manualPagination: true,
    manualFiltering: true,
    state: {
      pagination,
      globalFilter: searchKeyword,
      isLoading,
    },
    rowCount,
    onGlobalFilterChange: handleSearchChange,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    layoutMode: "grid",
    muiTopToolbarProps: {
      hidden: true,
    },
    muiSkeletonProps: {
      animation: "wave",
    },
    muiTableContainerProps: {
      sx: { height: "calc(100vh - 248px)", boxShadow: "none" },
    },
    muiTablePaperProps: {
      sx: { boxShadow: "none", borderRadius: 0, backgroundColor: "inherit" },
    },

    muiTableHeadRowProps: {
      sx: {
        height: "50px",
      },
    },
    muiLinearProgressProps: {
      color: "secondary",
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    muiTableBodyRowProps({ row }) {
      return {
        onClick: () => openAssetDrawer(row.original),
        sx: {
          ":hover": {
            cursor: "pointer",
          },
          backgroundColor: "inherit",
        },
      };
    },

    renderRowActionMenuItems: ({ closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],
    ...reactTableProps,
  });

  return {
    table,
  };
};
