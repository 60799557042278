import React from "react";
import {
  ListItemCheckbox,
  ListItemText,
} from "../../G6Graph/GraphGrouping/styled";
import { GroupOptionValueContainer, OptionValueContainer } from "../styled";
import { OriginationTypeView } from "./OriginationType";

const Component = ({ data, handleOnClickGroupValue, ...props }) => {
  return (
    <GroupOptionValueContainer>
      <OptionValueContainer>
        <ListItemCheckbox
          id={data.id}
          type='checkbox'
          checked={data.checked}
          onChange={(ev) => {
            handleOnClickGroupValue(ev, data);
          }}
          disabled={data.disabled}
          {...props}
        />
        <ListItemText htmlFor={data.id}>{data.label}</ListItemText>
      </OptionValueContainer>
      <OriginationTypeView data={data.originationTypes ?? []} />
    </GroupOptionValueContainer>
  );
};

export const GroupOption = React.memo(Component);
