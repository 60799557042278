import { AssetPropertyType } from "../../../../components/G6Graph/GraphGrouping/data";

export const AssetLabelSuffix = Object.freeze({
  [AssetPropertyType.PLATFORM]: "(Platform)",
  [AssetPropertyType.USER]: "(Customer)",
});

function getAssetPropertyType(type) {
  if (!type) return null;
  const isTag = type.startsWith("tags");
  if (isTag) return AssetPropertyType.USER;
  if (type.startsWith("common_fields"))
    return AssetPropertyType.PLATFORM_COMMON_FIELD;
  return AssetPropertyType.PLATFORM;
}

function getAssetPropertyNameWithoutType(field) {
  if (!field) return "";
  if (field.startsWith("tags")) return field.split("tags.")[1];
  if (field.startsWith("properties")) return field.split("properties.")[1];
  if (field.startsWith("common_fields"))
    return field.split("common_fields.")[1];
  return field;
}
function createPropertyAsPlatformField(field) {
  if (!field) return "";
  return `properties.${field}`;
}

function createPropertyAsCustomerField(field) {
  if (!field) return "";
  return `tags.${field}`;
}

function createPropertyAsCommonPlatformField(field) {
  if (!field) return "";
  return `common_fields.${field}`;
}

function createAssetLabel(field, source = AssetPropertyType.PLATFORM) {
  if (!field) return "";
  const suffix = AssetLabelSuffix[source] ?? "";
  return `${field} ${suffix}`;
}

function getAssetNameFromLabel(input) {
  if (!input) return "";
  const label = input.trim();
  const isPlatformAsset = label.endsWith(
    AssetLabelSuffix[AssetPropertyType.PLATFORM]
  );
  const isCustomerAsset = label.includes(
    AssetLabelSuffix[AssetPropertyType.USER]
  );
  if (isPlatformAsset || isCustomerAsset) {
    return label.split(" ")[0];
  }
  return label;
}

function checkIsDuplicateAssetField(assetName, data) {
  const asPlatformField = createPropertyAsPlatformField(assetName);

  const asCommonPlatformField = createPropertyAsCommonPlatformField(assetName);

  const asCustomerField = createPropertyAsCustomerField(assetName);

  return (
    (data[asPlatformField] || data[asCommonPlatformField]) &&
    data[asCustomerField]
  );
}

function createOptionKeyFromType(option, type) {
  let key = option;
  if (type === AssetPropertyType.USER) key = `tags.${option}`;
  if (type === AssetPropertyType.PLATFORM_COMMON_FIELD)
    key = `common_fields.${option}`;
  return key;
}

export const AssetPropertyUtils = Object.freeze({
  createOptionKeyFromType,
  getAssetPropertyType,
  getAssetPropertyNameWithoutType,
  createPropertyAsCustomerField,
  createPropertyAsPlatformField,
  createAssetLabel,
  getAssetNameFromLabel,
  createPropertyAsCommonPlatformField,
  checkIsDuplicateAssetField,
});
