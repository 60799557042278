import awsLogo from "../../../../assets/images/AWSDark.svg";
import bitbucketLogo from "../../../../assets/images/bitbucket.svg";
import jiraLogo from "../../../../assets/images/jira.svg";
import wizLogo from "../../../../assets/images/Wiz.png";
import hibobLogo from "../../../../assets/images/hibob.jpg";
import githubLogo from "../../../../assets/images/githubDark.svg";

export const ConnectorPlatformsConfiguration = {
  aws: {
    id: "aws",
    name: "AWS",
    logo: awsLogo,
    url: "/integrations/aws",
  },
  github: {
    id: "github",
    name: "GitHub",
    logo: githubLogo,
    url: "/integrations/github",
  },
  bitbucket: {
    id: "bitbucket",
    name: "Bitbucket",
    logo: bitbucketLogo,
    url: "/integrations/bitbucket",
  },
  jira: {
    id: "jira",
    name: "Jira",
    logo: jiraLogo,
    url: "/integrations/jira",
  },
  wiz: {
    id: "wiz",
    name: "Wiz",
    logo: wizLogo,
    url: "/integrations/wiz",
  },
  hibob: {
    id: "hibob",
    name: "Hibob",
    logo: hibobLogo,
    url: "/integrations/hibob",
  },
};
