import { Fragment, useMemo } from "react";
import { TableTooltipContentWrapper } from "../styled";

export const TableCellTooltipContent = ({ data }) => {
  const values = useMemo(() => {
    if (data.length === 1) return data[0];
    return data.join(", ");
  }, [data]);

  return (
    <Fragment>
      <TableTooltipContentWrapper>{values}</TableTooltipContentWrapper>
    </Fragment>
  );
};
