import { Typography } from "@mui/material";
import { Header, PageRoot, TableRoot } from "./styled";
import { InsightsBlocksRoot } from "../../cmst/ui/component/styled";
import { CMSTInsightCard } from "../../../shared-component/CMSTInsightCard";
import { useActionsTable } from "../hooks/useActionsTable";
import { MaterialReactTable } from "material-react-table";
import { DeleteModal } from "../../../shared-component/DeleteModal";
import { useDeleteAction } from "../hooks/useDeleteAction";
import { SavedQueryAlert } from "../../savedQueries/ui/components/Alert";
import { useEditAction } from "../hooks/useEditAction";
import { SaveQueryActionTypes } from "../../../../app/asset-management/config";
import { editActionPayloadTransformer } from "../../../../app/asset-management/editActionPayloadTransformer";

export const UI = () => {
  const {
    openState,
    close: closeDeleteAction,
    deleteAction,
    payload,
  } = useDeleteAction();
  const table = useActionsTable();
  const {
    openState: openEditActionState,
    close: closeOpenActionView,
    editAction,
    payload: editActionPayload,
  } = useEditAction();

  return (
    <PageRoot>
      <Header>
        <Typography variant='pageHeading'>CMST Actions</Typography>
      </Header>
      <InsightsBlocksRoot>
        <CMSTInsightCard
          label='Total Actions'
          value={"N/A"}
          variant='default'
        />
        <CMSTInsightCard
          label='Active Actions'
          value={"N/A"}
          variant='success'
        />
        <CMSTInsightCard
          label='Actions Triggered Today'
          value={"N/A"}
          variant='error'
        />
      </InsightsBlocksRoot>
      <TableRoot>
        <MaterialReactTable table={table} />
      </TableRoot>
      <DeleteModal
        meta={payload}
        title='Delete Action'
        onDelete={deleteAction}
        onClose={closeDeleteAction}
        open={openState}
      />
      <SavedQueryAlert
        open={openEditActionState}
        onClose={closeOpenActionView}
        onSave={editAction}
        data={editActionPayload.data}
        actionType={SaveQueryActionTypes.EDIT}
        meta={editActionPayload}
        payloadTransformer={editActionPayloadTransformer}
      />
    </PageRoot>
  );
};
