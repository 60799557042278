import awsLogo from "../../../assets/images/AWSDark.svg";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export const AwsCloudformationStepTwoContent = () => {
  return (
    <Box>
      <Stack direction="row" sx={{ alignItems: "flex-end", gap: "18.9px" }}>
        {/*<Box className="spinner">*/}
        {/*    <img src={antSpin} alt="ant spin" />*/}
        {/*</Box>*/}
        <Typography variant="h6" className="checkingintegrationText">
          Checking integration
        </Typography>
      </Stack>
      <Typography
        variant="body1"
        className="integrationMethodDesc"
        sx={{ my: "11px" }}
      >
        In the AWS tab opened in your browser, be sure to complete all the
        necessary steps.
      </Typography>
      <Typography variant="body1" className="integrationMethodDesc">
        This might take a few minutes.
      </Typography>
    </Box>
  );
};

export const stepTwoConfig = {
  awsLogo: awsLogo,
  headerTitle: "Integrating AWS using CloudFormation",
};
