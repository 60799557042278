const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const organization_id = localStorage.getItem("organization_id") || "";

const BusinessProcessService = {
  async getBusinessProcesses(token) {
    const organization_id = localStorage.getItem("organization_id");

    try {
      const response = await fetch(`${BASE_URL}/business_processes/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-ORG-ID": organization_id,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation:",
        error.message,
      );
      throw error;
    }
  },
  async addBusinessProcess(token, processData) {
    try {
      const response = await fetch(`${BASE_URL}/business_processes/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-ORG-ID": organization_id,
        },
        body: JSON.stringify(processData),
      });

      if (!response.ok) {
        throw new Error(
          "Network response was not ok when adding a business process",
        );
      }

      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the POST operation:",
        error.message,
      );
      throw error;
    }
  },
  async deleteBusinessProcessesByIds(token, ids) {
    try {
      const response = await fetch(`${BASE_URL}/business_processes/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-ORG-ID": organization_id,
        },
        body: JSON.stringify({ ids: ids }),
      });

      if (!response.ok) {
        throw new Error(
          "Network response was not ok when deleting business processes",
        );
      }

      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the DELETE operation:",
        error.message,
      );
      throw error;
    }
  },
  async connectBusinessProcessToEntity(token, businessProcessId, entityId) {
    try {
      const response = await fetch(
        `${BASE_URL}/business_processes/connect_business_process_entity`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-ORG-ID": organization_id,
          },
          body: JSON.stringify({
            business_process_id: businessProcessId,
            entity_id: entityId,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(
          "Network response was not ok when connecting business process to entity",
        );
      }

      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the POST operation:",
        error.message,
      );
      throw error;
    }
  },
};

export default BusinessProcessService;
