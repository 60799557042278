import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

const slice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    showNotification(state, action) {
      const notification = {
        open: true,
        props: { ...state.notification.props, ...action.payload },
      };
      state.notification = notification;
    },
    closeNotification(state) {
      const notification = {
        open: false,
        props: { ...initialState.notification.props },
      };
      state.notification = notification;
    },
  },
});

const appActions = slice.actions;
const reducer = slice.reducer;
export { reducer, appActions };
