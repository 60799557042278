import React from "react";
import Grid from "@mui/material/Grid";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Content from "../components/Content";
import PandoGraph from "../components/PandoGraph";

const IS_ON_PREMISES = process.env.REACT_APP_IS_ON_PREMISES === "true";

export const GraphComponent = () => {
  const { user } = useAuth0();

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100vw",
      }}
      alignItems="stretch"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Content component={PandoGraph} apiPath="/graph/all" />
      </Grid>
    </Grid>
  );
};

export default IS_ON_PREMISES
  ? GraphComponent
  : withAuthenticationRequired(GraphComponent, {
      onRedirecting: () => <Loading />,
    });
