import { useMemo, useState } from "react";
import {
  CopyButtonContainer,
  FieldDataContainer,
  SourceFlag,
  ValueContainer,
  ValueText,
} from "./styled";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { EMPTY_VALUE } from "./subPages/utils/assetDetailsSelector";
import { copyToClipboard } from "../../../../utils/browserUtils/copyToClipboard";
import { debounce } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export const InfoValue = ({
  data,
  options,
  valueContainerProps = {},
  containerProps = {},
}) => {
  const { enableCopy } = useMemo(() => {
    return createComponentConfig(data, options);
  }, [options]);

  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);
  const [hover, setHover] = useState(false);

  const showCopyButton = enableCopy && hover;

  const resetCopyState = () => {
    setCopied(false);
  };

  const iconNode = useMemo(() => {
    if (copied) return <CheckIcon />;
    return <ContentCopyRoundedIcon />;
  }, [copied]);

  const handleOnClickCopy = async () => {
    try {
      setError(false);
      await copyToClipboard(data.value ?? "");
      setCopied(true);
      debounce(resetCopyState, 2000)();
    } catch (error) {
      setError(true);
    }
  };

  const handleOnMouseEnter = () => {
    setHover(true);
  };

  const handleOnMouseLeave = () => {
    setHover(false);
  };

  return (
    <FieldDataContainer
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...containerProps}
    >
      <ValueContainer {...valueContainerProps}>
        <ValueText isna={data.value === EMPTY_VALUE}>{data.value}</ValueText>
      </ValueContainer>
      <ConditionComponent condition={showCopyButton}>
        <CopyButtonContainer copied={copied} onClick={handleOnClickCopy}>
          {iconNode}
        </CopyButtonContainer>
      </ConditionComponent>
      <ConditionComponent condition={data?.source}>
        <SourceFlag>{data.source}</SourceFlag>
      </ConditionComponent>
    </FieldDataContainer>
  );
};

function createComponentConfig(data, options) {
  const config = {
    enableCopy: options?.copy ?? true,
  };

  return config;
}
