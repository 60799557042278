import { Box, styled } from "@mui/material";

export const PageContainer = styled(Box)`
  background-color: #ebf1f4 !important;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
