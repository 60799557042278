import { Box, styled, Typography } from "@mui/material";

export const EmptyDataSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const EmptyDataSectionLabel = styled(Typography)`
  color: #cccccc !important;
  font-weight: 14px !important;
`;
