export function createCombosFromNodes(nodes, properties) {
  if (!properties) {
    return nodes;
  }
  const comboMap = new Map();
  nodes.forEach((node, index) => {
    let currentMap = comboMap;
    let parentId;
    properties.forEach((property, propertyIndex) => {
      let value = node[property];
      if (!value && node.tags) {
        value = node.tags[property];
      }
      if (!value) value = "not-applicable";
      if (!currentMap.has(value)) {
        const id = `combo${index}_${propertyIndex}`;
        const newCombo = {
          id,
          label: [value + " " + "(" + property + ")"],
          children: new Map(),
        };
        if (parentId !== undefined) {
          newCombo.parentId = parentId;
        }
        currentMap.set(value, newCombo);
      }
      const combo = currentMap.get(value);
      parentId = combo.id;
      currentMap = combo.children;
      if (propertyIndex === properties.length - 1) {
        node.comboId = combo.id;
      }
    });
  });
  const combos = [];
  const addCombosToArray = (map) => {
    map.forEach((combo) => {
      combos.push(combo);
      addCombosToArray(combo.children);
    });
  };
  addCombosToArray(comboMap);
  return combos;
}
