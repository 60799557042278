import { DataFilterOperators } from "../filterOperators";

const nonMultiValueOperatorSet = new Set([
  DataFilterOperators.IS.value,
  DataFilterOperators.IS_NOT.value,
]);
export function checkOperatorIsMultiValueOperator(operator) {
  if (nonMultiValueOperatorSet.has(operator)) return false;
  return true;
}
