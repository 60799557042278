import { useContext } from "react";
import { AssetInfoStateContext } from "../context/defs";

export const useGetAssetDetailState = () => {
  return useContext(AssetInfoStateContext).state;
};

export const useSetAssetDetailState = () => {
  return useContext(AssetInfoStateContext).dispatch;
};
