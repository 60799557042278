import { styled } from "@mui/material";

export const InputsSection = styled("div")`
  width: 100%;
  maxwidth: 600px;
  display: flex;
  flex-direction: column;
`;

export const RowInputsContainer = styled("div")`
  display: flex;
  column-gap: 24px;
`;

export const InputSectionContainer = styled("div")`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
`;
