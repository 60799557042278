import { useCallback, useMemo } from "react";
import { TagLabel, TagsContainer } from "./styled";
import { Chip } from "./Chip";
import { Typography } from "@mui/material";

export const Tags = ({ data }) => {
  const tagsList = useMemo(() => createTagList(data), [data]);

  const tagsRenderer = useCallback(() => {
    return tagsList.map(({ label, value }) => {
      const color = generateColor();
      return (
        <Chip variant="outlined" bgColor={color}>
          <TagLabel variant="caption">{`${label}:   `}</TagLabel>
          <Typography variant="caption">{value}</Typography>
        </Chip>
      );
    });
  }, [tagsList]);

  return <TagsContainer>{tagsRenderer()}</TagsContainer>;
};

function createTagList(tags) {
  const list = [];
  for (const label in tags) {
    const value = tags[label];
    const tagInstance = {
      label,
      value,
    };
    list.push(tagInstance);
  }
  return list;
}

function generateRandomColorIndex(length) {
  return Math.floor(Math.random() * length);
}

function generateColor() {
  const palette = ["#5B83EA", "#BD0816", "#C8511B", "#D9D9D9", "#33fff0"];
  const generatedIndex = generateRandomColorIndex(palette.length);
  return palette[generatedIndex];
}
