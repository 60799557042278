import { Typography } from "@mui/material";
import {
  GroupOptionContainer,
  GroupOptionSummary,
  GroupOptionValues,
} from "../styled";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useState } from "react";

export const AdapterOption = ({
  onChangeGroupOptionValue,
  getGroupOptions,
  isExpanded,
  data,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const execWithOptionPayload = (fn) => {
    return (ev) => {
      if (!fn) return;
      fn(ev, data);
    };
  };

  const onChange = () => {
    setExpanded((p) => !p);
  };

  return (
    <GroupOptionContainer
      disableGutters
      expanded={isExpanded || expanded}
      onChange={onChange}
    >
      <GroupOptionSummary expandIcon={<ArrowRightIcon />}>
        <Typography variant='body2'>{data.label}</Typography>
      </GroupOptionSummary>
      <GroupOptionValues>{getGroupOptions(data.value)}</GroupOptionValues>
    </GroupOptionContainer>
  );
};
