import { SaveQuery } from "../../../../base-components/Sidebar/component/SaveQueryView";
import { TableGroupingSelector } from "../../../AssetGrouping";
import { SearchInput } from "../../../SearchInput";
import { SelectOptionsMenu } from "../../../SelectOptionsMenu";
import { TableColumnSelector } from "../../../TableColumnSelector";
import { TableFilterSelector } from "../../../TableFilterSelector";
import { SaveQueryButtonRef, SearchComponentRef } from "../../refs";
import { SearchResultColumnSelector } from "./components/SearchResultColumnSelector";
import { TableActionsRoot } from "./styled";
import { KeyPressEventManager } from "../../../../../utils/KeyPressEventManager";
import { useSearchActionState } from "../../hooks/useSearchActionState";
import { useSearch } from "../../hooks/useSearch";
import { useColumnsState } from "../../hooks/useColumnsState";
import { useGroupingState } from "../../hooks/useGroupingState";
import { useAssetTableState } from "../../hooks/useAssetTableState";
import { InitialState } from "../../stateManagement/initialState";
import { useFetchGroupingTableData } from "../../hooks/useFetchGrouingTableData";
import { createGroupingOptionsFromSchema } from "../../utils/createGroupingOptionsFromSchema";
import { useStateDispatch } from "../../hooks/useStateDispatch";
import { Actions } from "../../stateManagement/actions";

export const TableActions = ({
  schema,
  onApplyFilters,
  searchResultsColumnSelectorProps,
}) => {
  const [searchValue, setSearchValue] = useSearchActionState();
  const search = useSearch();
  const [columns, setColumns] = useColumnsState();
  const [grouping, setGrouping] = useGroupingState();
  const { filters } = useAssetTableState();
  const fetchGroupingTableData = useFetchGroupingTableData();
  const dispatch = useStateDispatch();

  const applySearch = ({ query }) => {
    search({
      query,
    });
  };

  const handleOnSearchInputKeyDown = (ev) => {
    const isEnter = KeyPressEventManager.checkIsOnPressEnter(ev);
    if (isEnter) {
      applySearch({ query: searchValue });
    }
  };

  const checkOptionIsDisabled = () => {};

  const handleOnSearchChange = (ev) => {
    setSearchValue(ev.target.value);
  };

  const onClickEnter = () => {
    applySearch({ query: searchValue });
  };

  const fetchInitialGroupingData = (_payload) => {
    const payload = {
      search: searchValue,
      filters,
      grouping: _payload,
      pagination: InitialState.groupingTable.paginationConfig,
    };
    fetchGroupingTableData(payload);
  };

  const onSelectGrouping = (grouping) => {
    if (!grouping.length) {
      setGrouping({
        options: [],
        grouping: [],
        currentGroupingOption: null,
      });
    }

    const options = createGroupingOptionsFromSchema(grouping, schema);
    const groupingPayload = {
      options,
      grouping,
      currentGroupingOption: options[0],
      groupingTablePayload: {
        pagination: InitialState.groupingTable.paginationConfig,
      },
    };
    setGrouping(groupingPayload);
    fetchInitialGroupingData({
      group: grouping[0],
      option: options[0],
    });
  };

  const updateFiltersSelectedColumns = (cols) => {
    const updatedFilters = filters.map((filter) => {
      const {
        field: { value },
      } = filter;
      const isSelectedColumn = cols.some((col) => col.value === value);
      return { ...filter, addToColumns: isSelectedColumn };
    });
    dispatch({
      type: Actions.SET_FILTERS,
      payload: updatedFilters,
    });
  };

  const handleOnChangeColumns = (cols) => {
    updateFiltersSelectedColumns(cols);
    setColumns(cols);
  };

  return (
    <TableActionsRoot>
      <SearchResultColumnSelector
        onClick={searchResultsColumnSelectorProps?.onClick}
        anchorEl={SearchComponentRef.current}
      />
      <SearchInput
        onKeyDown={handleOnSearchInputKeyDown}
        onChange={handleOnSearchChange}
        value={searchValue}
        placeholder='Hit Enter to submit'
        onClickEnter={onClickEnter}
        ref={SearchComponentRef}
      />
      <TableGroupingSelector
        data={grouping}
        setData={onSelectGrouping}
        schema={schema}
      />
      <TableFilterSelector
        onApplyFilters={onApplyFilters}
        fieldOptions={schema}
        filters={filters}
      />
      <TableColumnSelector count={columns.length}>
        <SelectOptionsMenu
          values={columns}
          setValues={handleOnChangeColumns}
          data={schema}
          checkOptionIsDisabled={checkOptionIsDisabled}
        />
      </TableColumnSelector>
      <SaveQuery data={{ filters, columns }} />
    </TableActionsRoot>
  );
};
