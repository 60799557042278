import { CircularProgress, Typography } from "@mui/material";
import {
  Body,
  CancelButton,
  DeleteButton,
  Footer,
  Root,
  Title,
} from "./styled";
import DeleteIcon from "@mui/icons-material/Delete";
import ConditionComponent from "../../../utils/ConditionComponent";

export const DeleteModal = ({ title = "Delete", onDelete, meta, ...props }) => {
  const handleOnCancel = () => {
    props.onClose?.();
  };
  return (
    <Root {...props}>
      <Title>{title}</Title>
      <Body>
        <Typography>Are you sure you want to delete?</Typography>
      </Body>
      <Footer>
        <CancelButton onClick={handleOnCancel}>Cancel</CancelButton>
        <DeleteButton
          sx={{ height: "32px;", boxSizing: "border-box" }}
          onClick={onDelete}
        >
          <ConditionComponent
            falsyNode={
              <CircularProgress
                sx={{ color: "red" }}
                variant='indeterminate'
                size={"20px"}
              />
            }
            condition={!meta?.isLoading}
          >
            <DeleteIcon sx={{ fontSize: "20px !important" }} />
          </ConditionComponent>
        </DeleteButton>
      </Footer>
    </Root>
  );
};
