// Convert data to CSV format
export const exportToCSV = (data) => {
  let csvRows = [];

  // Headers
  csvRows.push("Metric,Result,Explanation,Formula");

  // Add detailed explanations
  Object.entries(data.Explanations).forEach(([key, metricDetails]) => {
    let explanation = Array.isArray(metricDetails.explanation)
      ? metricDetails.explanation.join("; ")
      : metricDetails.explanation;
    let formula = Array.isArray(metricDetails.formula)
      ? metricDetails.formula.join("; ")
      : metricDetails.formula;

    csvRows.push(
      `"${key}","${metricDetails.result}","${explanation}","${formula}"`,
    );
  });

  const csvString = csvRows.join("\r\n");

  // Create a blob and download
  const blob = new Blob([csvString], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Export data as JSON
export const exportToJSON = (data) => {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: "application/json" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
