import React from "react";
import PropTypes from "prop-types";
import styles from "./ButtonReset.module.css"; // Make sure the path is correct
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined"; // Importing the reset icon

function ButtonReset({ onClick }) {
  return (
    <button className={styles.buttonReset} onClick={onClick}>
      <RestartAltOutlinedIcon style={{ marginRight: 8 }} /> Reset
    </button>
  );
}

ButtonReset.propTypes = {
  onClick: PropTypes.func.isRequired, // Callback function to be executed on reset
};

export default ButtonReset;
