import React, { useEffect, useState } from "react";
import { useFetchApiData } from "../hooks/useFetchContentData";
import { LoadingIndicator } from "../components/LoadingIndicator/index";

const LOCAL_JWT = process.env.REACT_APP_LOCAL_USER_JWT || null;
const IS_ON_PREMISES = process.env.REACT_APP_IS_ON_PREMISES === "true";

function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          textAlign: "center",
          flexGrow: 1,
        }}
      >
        <h1 style={{ fontSize: "5rem", marginBottom: "2rem" }}>
          404 - Not Found
        </h1>
        <p style={{ fontSize: "2rem" }}>
          Sorry, there is no organization for this user.
        </p>
      </div>
    </div>
  );
}

const LocalContent = ({ component: Component, apiPath, ...props }) => {
  const [data, setData] = useState(null);
  const [unauth, setUnauth] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = LOCAL_JWT;
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}${apiPath}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.status === 401) {
          setUnauth(true);
        } else if (response.status === 404) {
          setData("NotFound");
        } else {
          const data = await response.json();
          setData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [apiPath]);

  if (data === "NotFound") {
    return <NotFound />;
  }

  if (unauth) {
    return <h1>401 - Unauthorized</h1>;
  }

  if (data === null) {
    return <></>;
  }

  return <Component data={data} {...props} />;
};

const Content = ({ component: Component, apiPath, ...props }) => {
  const { data, loading, unAuthorized } = useFetchApiData({
    path: apiPath,
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (data === "NotFound") {
    return <NotFound />;
  }

  if (unAuthorized) {
    return <h1>401 - Unauthorized</h1>;
  }

  if (data === null) {
    return <></>;
  }

  return <Component data={data} {...props} />;
};

export default IS_ON_PREMISES ? LocalContent : Content;
