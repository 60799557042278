import {
  Box,
  Button,
  FormControlLabel,
  Popover,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import MuiCloseIcon from "@mui/icons-material/Close";
import MuiDeleteIcon from "@mui/icons-material/DeleteOutline";
import Select from "react-select";
import Dropdown from "./Dropdown";

export const SelectorContainer = styled(Button)(() => {
  return `
  &&& {
  min-width: 200px;
  max-width: 600px;
  height: 36px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: none !important;
  color: #000;
  font-family: Inter, serif;
  display: flex;
  justify-content: space-between;
  padding: 5px;

  & > svg {
  transition: 0.1s transform ease-in;
  }
  & > svg.closed {
  transform: rotate(180deg);
  }

  & > svg.opened {
  transform: rotate(90deg);
  }

  :focus {
  outline: none;
  }
  }
  `;
});

export const MenuContainer = styled(Popover)(
  () => `
  & .MuiPopover-paper {
  width: 800px;
  min-height: 450px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  }
`
);

export const MenuHeaderContainer = styled("div")(
  () => `
display: flex;
height: 48px;
box-sizing: border-box;
align-items:center;
padding: 8px 12px;
border-bottom: 1px solid #ccc;
`
);

export const MenuContentContainer = styled("ul")(
  () => `
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px;
  padding-bottom: 0;
`
);

export const MenuFooterContainer = styled("div")(
  () => `
display: flex;
height: 56px;
border-top: 1px solid #ccc;
justify-content: space-between;
padding: 6px 12px;
box-sizing: border-box;
`
);

export const CloseIcon = styled(MuiCloseIcon)(
  () => `
margin-left: auto;
cursor: pointer;
`
);

const FilterMenuBottomButton = styled(Button)`
  && {
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    text-transform: none;
    border-radius: 8px;
    column-gap: 4px;
    padding: 6px;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
`;

export const ApplyFiltersButton = styled(FilterMenuBottomButton)(
  () => `
  &&& {
    background-color: #000033;
    color: #00ffb2;
  }
`
);

export const CancelFiltersButton = styled(FilterMenuBottomButton)(
  () => `
&&& {
border: 1px solid #ccc;
background-color: #fff;
color: #252525;
}
`
);

export const FooterPrimaryActionsContainer = styled(Box)(
  () => `
display: flex;
column-gap: 12px;
align-items:center;
`
);

export const FilterGroupButton = styled(FilterMenuBottomButton)(
  ({ textColor, bgColor, border }) => `
 &&& {
   color: ${textColor ?? "#000033"};
  background-color: ${bgColor ?? "transparent"};
  display: flex;
  border: ${border};
  margin-left: auto;
  &:hover {
  background-color: ${bgColor ?? "transparent"};
    }
  }
`
);

export const FilterGroupContainer = styled("li")(
  () => `
display: list-item;
height: auto;
width:100%
background-color: transparent;
&:hover {
  background-color: transparent;
}
`
);

export const FilterGroupContentWrapper = styled("div")(
  () => `
  min-height: 45px;
  border-radius: 8px;
  padding: 6px 8px;
  display: flex;
`
);

export const FilterSelectorRegion = styled("div")(
  () => `
  flex: 1;
display: flex;
column-gap: 24px;

`
);

export const DeleteIcon = styled(MuiDeleteIcon)(
  ({ theme }) => `
  margin-left: 16px; 
  cursor: pointer;
  align-self: center;
  &:hover {
    color: ${theme.palette.error.main}
  }
  
`
);

export const DropdownComponent = styled(Select)(
  () => `
  font-size: 12px;
  width: 100%;
`
);

export const FilterGroupSection = styled("div")(
  () => `
display: flex;
flex-direction: column;
width: calc((100% - 100px) / 2)
`
);

export const ConditionDropdown = styled(Dropdown)(
  () => `
width: 130px !important;
`
);

export const EmptyFiltersSection = styled("div")(
  () => `
flex: 1;
display: flex;
flex-direction: column;
align-items:center;
justify-content: center;
row-gap: 8px;
& > * { 
color: #00000070 !important;
}
`
);

export const FilterGroupErrorMessage = styled(Box)`
  && {
    display: flex;
    background-color: #e6c20030;
    color: #e6c200;
    width: fit-content;
    margin-left: auto;
    margin-right: 48px;
    padding: 4px 8px;
    border-radius: 8px;
    align-items: center;
    column-gap: 8px;
    font-weight: 600;
  }
`;

export const VisibleChecker = styled(Box)`
  display: flex;
  align-items: center;
`;

export const Label = styled((props) => (
  <Typography {...props} variant='body2' />
))`
  color: #000033;
`;

export const ComboSection = styled(FormControlLabel)`
  height: 100%;
  position: relative;
`;

export const BottomContentWrapper = styled("div")`
  margin-top: auto;
  margin-left: auto;
`;
