import { useCallback, useEffect, useReducer } from "react";
import { AssetInfoExternalPropsContext, AssetInfoStateContext } from "./defs";
import { InitialState } from "./state/initialState";
import { StateReducer } from "./state/reducer";
import { useAuth0 } from "@auth0/auth0-react";
import { getAssetInfo } from "../../../../../utils/api";
import { StateActions } from "./state/actions";

export const AssetInfoProvider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(StateReducer, InitialState);
  const { getAccessTokenSilently } = useAuth0();

  const fetchInitialData = useCallback(
    async (assetData) => {
      try {
        dispatch({
          type: StateActions.setRequestPending,
        });
        const token = await getAccessTokenSilently();
        const payload = await getAssetInfo(assetData.id, token);
        dispatch({
          type: StateActions.setRequestSuccess,
          payload: { ...payload },
        });
      } catch (error) {
        const payload = error.message ?? "Request Failed";
        dispatch({
          type: StateActions.setRequestFailure,
          payload,
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (props.open) {
      fetchInitialData(props.data);
    }
  }, [props.data, props.open]);

  return (
    <AssetInfoExternalPropsContext.Provider value={{ ...props }}>
      <AssetInfoStateContext.Provider value={{ state, dispatch }}>
        {children}
      </AssetInfoStateContext.Provider>
    </AssetInfoExternalPropsContext.Provider>
  );
};
