import { ActionType } from "../state-management/actions";
import { useAssetInventoryActionDispatch } from "./useAssetInventoryActionDispatch";
import { useAssetsTableMethods } from "./useAssetsTableMethods";
import { useGroupingTableState } from "./useGroupingTableState";
import { usePageData } from "./usePageData";
import { usePrimaryTableState } from "./usePrimaryTableState";

export function usePrimaryTableColumnState() {
  const dispatch = useAssetInventoryActionDispatch();
  const {
    filters,
    grouping,
    currentGroupingValue,
    searchKeyword,
    primaryTableColumns,
  } = usePageData();
  const { paginationConfig } = usePrimaryTableState();
  const { paginationConfig: groupingTablePaginationState } =
    useGroupingTableState();
  const { syncPayloadWithUrl } = useAssetsTableMethods();

  const setState = (data) => {
    const payload = {
      filters,
      grouping,
      currentGroupingValue,
      searchKeyword,
      primaryTableColumns: data,
      page: paginationConfig.pageIndex,
      limit: paginationConfig.pageSize,
    };
    const isGrouping = !!grouping.length;
    if (isGrouping) {
      payload.page = groupingTablePaginationState.pageIndex;
      payload.limit = groupingTablePaginationState.pageSize;
    }
    syncPayloadWithUrl(payload);
    dispatch({
      type: ActionType.SET_PRIMARY_TABLE_COLUMNS,
      payload: data,
    });
  };

  return [primaryTableColumns, setState];
}
