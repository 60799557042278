import {
  ApplyFiltersButton,
  CancelFiltersButton,
  FilterGroupButton,
  FooterPrimaryActionsContainer,
  MenuFooterContainer,
} from "./styled";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";

export const MenuFooter = ({
  handleOnReset,
  handleOnCancel,
  handleOnApply,
  handleOnClickCreateFilterGroup,
}) => {
  return (
    <MenuFooterContainer>
      <FooterPrimaryActionsContainer>
        <FilterGroupButton
          variant="text"
          onClick={handleOnReset}
          textColor="#FF0000"
          bgColor="#FF000010"
          border="1px solid #FF000080"
          disableRipple
        >
          <DeleteSweepOutlinedIcon />
          Clear All Filters
        </FilterGroupButton>
        <FilterGroupButton
          variant="text"
          onClick={handleOnClickCreateFilterGroup}
          border="1px solid #dee0e4"
          disableRipple
          bgColor="#F7F9FE"
        >
          <AddCircleIcon />
          Create Filter Group
        </FilterGroupButton>
      </FooterPrimaryActionsContainer>
      <FooterPrimaryActionsContainer>
        <CancelFiltersButton
          variant="outlined"
          onClick={handleOnCancel}
          disableRipple
        >
          Cancel
        </CancelFiltersButton>
        <ApplyFiltersButton
          variant="contained"
          onClick={handleOnApply}
          disableRipple
        >
          Apply
        </ApplyFiltersButton>
      </FooterPrimaryActionsContainer>
    </MenuFooterContainer>
  );
};
