import {
  Button,
  FormControlLabel,
  Popover,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const FilterTriggerSelectorContainer = styled(Button)(() => {
  return `
  &&& {
  min-width: 200px;
  max-width: 600px;
  height: 36px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: none !important;
  color: #000;
  font-family: Inter, serif;
  display: flex;
  justify-content: space-between;
  padding: 5px;

  & > svg {
  transition: 0.1s transform ease-in;
  }
  & > svg.closed {
  transform: rotate(180deg);
  }

  & > svg.opened {
  transform: rotate(90deg);
  }

  :focus {
  outline: none;
  }
  }
  `;
});

export const MenuContainer = styled(Popover)(({ theme }) => {
  return `
  & .MuiPopover-paper {
    width: 900px;
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: stretch;
     border:1px solid ${theme.palette.grey[400]};
    box-shadow: ${theme.shadows[2]};
    background-color: ${theme.palette.background.default};
  }
`;
});

export const MenuContentContainer = styled("div")`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px 12px !important;
`;

export const DataFilteringFooterContainer = styled("div")`
  flex-shrink: 0;
  display: flex;
  height: 56px;
  border-top: 1px solid #ccc;
  justify-content: space-between;
  padding: 6px 12px;
  box-sizing: border-box;
  margin-top: auto;
`;

export const ButtonGroupContainer = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const FilterGroupContainer = styled("div")(({ theme }) => {
  return `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${theme.palette.background.paper};
  padding: 0 12px;
  margin-top: 12px;
`;
});

export const FilterContentRoot = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  align-items: stretch;
`;

export const FilterGroupContentContainer = styled("div")`
  display: grid;
  grid-template-columns:
    calc(50% - 100px) minmax(100px, 180px) calc(50% - 100px)
    40px;
  padding: 4px;
  grid-template-rows: 1fr;
  column-gap: 12px;
`;

export const FilterActionsContainer = styled("div")`
  display: flex;
  align-items: flex-end;
`;

export const FilterActionButtonsRoot = styled("div")`
  display: flex;
  column-gap: 12px;
  margin-left: auto;
`;

export const SelectorSection = styled("div")`
  position: relative;
`;

export const WarningContainer = styled("div")(({ theme }) => {
  // warn: e6c20030
  return `
  background-color: ${theme.palette.error.main}30;
  color: ${theme.palette.error.main};
  width: fit-content;
  padding: 4px 8px;
  align-items: center;
  column-gap: 8px;
  font-weight: 600;
`;
});

export const SelectorLabel = styled((props) => <Typography {...props} />)`
  && {
    font-size: 11px;
    position: absolute;
    top: -7px;
    left: 8px;
    z-index: 5;
    padding: 1px 6px;
    color: #a9a9a9;
    background-color: #fff;
    font-weight: 700;
  }
`;

export const FilterGroupConjunctionContainer = styled((props) => (
  <Select
    {...props}
    components={{
      IndicatorSeparator: null,
    }}
    styles={{
      container(base) {
        return {
          ...base,
          paddingTop: "4px",
          ":active": {
            outline: "none",
          },
        };
      },
      control(base) {
        return {
          ...base,
          minHeight: "30px",
          height: "30px",
          ":active": {
            outline: "none",
          },
        };
      },
      indicatorsContainer(base) {
        return { ...base, height: "29px" };
      },
      menu(base) {
        return { ...base, zIndex: 12 };
      },
    }}
  />
))`
  font-size: 12px;
  width: 100px;
  height: 32px;
  &:active {
    outline: none;
  }
`;

export const FieldSelectorContainer = styled((props) => <Button {...props} />)`
  &.MuiButton-root {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    height: 38px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    justify-content: flex-start;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    display: inline;
  }
`;

export const OperatorSelectorContainer = styled((props) => (
  <Select
    {...props}
    styles={{
      menu(base) {
        return { ...base, zIndex: 12 };
      },
      container(base) {
        return {
          ...base,
          paddingTop: "4px",
          fontSize: "14px",
          ":active": {
            outline: "none",
          },
        };
      },
    }}
  />
))``;
export const ValueSelectorContainer = styled((props) => (
  <CreatableSelect
    isMulti
    createOptionPosition='first'
    styles={{
      menu(base) {
        return { ...base, zIndex: 12, width: "350px" };
      },
      menuList(base) {
        return { ...base, overflowX: "hidden", lineBreak: "anywhere" };
      },
      container(base) {
        return {
          ...base,
          paddingTop: "4px",
          fontSize: "14px",
          ":active": {
            outline: "none",
          },
        };
      },
    }}
    {...props}
  />
))``;
export const DeleteButtonContainer = styled(Button)(({ theme }) => {
  const { error } = theme.palette;

  return `
  && {
    min-width: 30px;
    max-height: 30px;
    margin-top: 8px;
    background-color: red;
    border-radius: 4px;
    &:hover {
      background-color: ${error.main};
      opacity: 0.8;
    }
    &:active {
      outline: none;
    }
  }
`;
});

export const SelectFieldAsColumnRoot = styled(FormControlLabel)(({ theme }) => {
  return `
  min-width: 0;
  min-height: 0;
  padding: 2px;
  padding-right: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0;
  vertical-align: auto;
  height: 30px;
  box-sizing: border-box !important;
  border: 1px solid ${theme.palette.grey[200]};
  border-radius: 8px;
  margin-top: 4px;
`;
});

export const ControlSwitch = styled((props) => (
  <Switch size='small' {...props} />
))(({ theme }) => {
  return `
  
  & .MuiSwitch-switchBase {
  padding: 0;
    & .MuiSwitch-thumb {
      margin-top: 4px;
      margin-left: 3px;
      background-color: ${theme.palette.primary.dark};
      }

       & .MuiSwitch-track {
      background-color: ${theme.palette.primary.dark};
      opacity: 0.2;
      }


      &.Mui-checked {
       & .MuiSwitch-thumb {
      background-color: ${theme.palette.primary.light};
      }

      & + .MuiSwitch-track {
        opacity: 1;
  background-color: ${theme.palette.primary.dark};
      }

    }
  }
  `;
});
