import { styled, Typography } from "@mui/material";

export const PageLayout = styled("section")`
  overflow: hidden;
  height: 100vh;
  padding: 24px;
`;

export const PageHeader = styled("div")`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const PageHeaderLabel = styled(({ children, ...props }) => (
  <Typography variant='h1' {...props}>
    {children}
  </Typography>
))`
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: 0.005em;
  font-weight: 500;
`;
