import { SaveQueryButton } from "../../../../containers/inventory/AssetsTable/styled";
import { useSavedQueriesTableState } from "../hooks/useSavedQueriesTableState";
import { SavedQueriesTable } from "./components/SavedQueriesTable";
import {
  PageActionsRoot,
  PageContent,
  PageHeader,
  PageHeading,
  PageRoot,
} from "./components/styled";
import AddIcon from "@mui/icons-material/Add";

export const SavedQueriesPage = () => {
  const { isLoading, pagination, data, error } = useSavedQueriesTableState();

  return (
    <PageRoot>
      <PageHeader>
        <PageHeading>Saved Queries</PageHeading>
      </PageHeader>
      <PageActionsRoot>
        <SaveQueryButton>
          <AddIcon sx={{ fontSize: "14px" }} />
          New Query
        </SaveQueryButton>
      </PageActionsRoot>
      <PageContent>
        <SavedQueriesTable
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          error={error}
        />
      </PageContent>
    </PageRoot>
  );
};
