// actions/integrationActions.js
import {
  SET_INTEGRATIONS,
  ADD_INTEGRATION,
  REMOVE_INTEGRATION,
} from "./actionTypes";

export const setIntegrations = (integrations) => ({
  type: SET_INTEGRATIONS,
  payload: integrations,
});

export const addIntegration = (integration) => ({
  type: ADD_INTEGRATION,
  payload: integration,
});

export const removeIntegration = (integrationId) => ({
  type: REMOVE_INTEGRATION,
  payload: integrationId,
});

export const fetchIntegrations = (token) => async (dispatch) => {
  try {
    // const integrations = await getIntegratedServices(token);
    // dispatch({
    //     type: SET_INTEGRATIONS,
    //     payload: integrations,
    // });
  } catch (error) {
    console.error("Error fetching integrations", error);
  }
};
