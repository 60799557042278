import React, { useMemo } from "react";

import IntegrationLayout from "../../../components/onboardingV2/integrationLayout";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { addGithubApiAccessKey, authenticateGithub } from "../../../utils/api";
import { addIntegration } from "../../../redux/actions/integrationActions";
import MultiStepSidebar from "../../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../../redux/actions/onboardingActions";
import createButtonConfig from "../../../shared/functions/buttons";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { GithubIntegrationConfig } from "../githubIntegrationContent";
import {
  GithubIntegrationCompleteConfig,
  GithubIntegrationCompleteContent,
} from "../githubIntegrationComplete";
import { GithubKeyIntegrationContent } from "./githubKeyContent";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import BannerAlert from "../../../components/banners/ErrorAlert";
import { IntegrationsProvider } from "../context/Provider";
import { useIntegrationValues } from "../hooks/useIntegrationValues";

const GithubKeyIntegration = ({ cancelUrl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const githubLoginRef = React.useRef();

  const [sidebarStep, setSidebarStep] = React.useState(1);
  const { getAccessTokenSilently } = useAuth0();
  const [errors, setErrors] = React.useState([]);
  const { accessToken, integrationName } = useIntegrationValues();

  const canContinue = useMemo(() => {
    return accessToken && integrationName;
  }, [accessToken, integrationName]);

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  const handleSuccessGithub = async (response) => {
    try {
      const code = response.code;
      const auth0Token = await getAccessTokenSilently();
      await authenticateGithub(auth0Token, code);
      dispatch(addIntegration("github"));
      if (sidebarStep < sidebarSteps.length) {
        setSidebarStep(sidebarStep + 1);
      }
    } catch (error) {
      console.log("handleSuccessGithub Error");
    }
  };

  const handleFailureGithub = (response) => {
    console.log("handleFailureGithub Error");
  };

  const handleOAuthClick = () => {
    if (githubLoginRef.current) {
      githubLoginRef.current.initiateLogin();
    }
  };

  // Sidebar
  const sidebarSteps = [
    {
      title: "Integration Method",
      description:
        "Select the preferred method for integrating your Github account",
    },
    { title: "Completion" },
  ];

  const multiSteps = (
    <MultiStepSidebar steps={sidebarSteps} currentStep={sidebarStep} />
  );

  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
  };

  const onContinue = () => {
    if (sidebarStep === 1) {
      getAccessTokenSilently().then((auth0Token) => {
        addGithubApiAccessKey(auth0Token, accessToken, integrationName).then(
          () => {
            setSidebarStep(2);
          },
          (error) => {
            let newErrors = [];
            if (typeof error.response?.data?.detail === "string") {
              newErrors.push(`${error.response.data.detail}`);
            } else if (error.response?.data?.detail) {
              newErrors = error.response.data.detail.map((detail) => {
                const errorLocation =
                  detail.loc.length > 1
                    ? detail.loc.slice(1).join(".")
                    : "unknown location";
                return `${errorLocation}: ${detail.msg || "An error occurred"}`;
              });
            } else {
              newErrors = ["Failed to onboard Bitbucket. Please try again."];
            }

            setErrors((currentErrors) => [...currentErrors, ...newErrors]);
            return;
          }
        );
      });
    }

    if (sidebarStep === 2) {
      history.push(cancelUrl);
    }
  };

  const footerButtonsStepOne = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel", false),
    createButtonConfig(
      "Continue",
      "right",
      onContinue,
      <KeyboardArrowRightOutlinedIcon />,
      "right",
      !canContinue
    ),
  ];
  const footerButtonsComplete = [
    createButtonConfig(
      "Done",
      "right",
      onContinue,
      <DoneOutlinedIcon />,
      "left",
      false
    ),
  ];

  const pageSteps = [
    {
      headerLogo: GithubIntegrationConfig.logo,
      headerTitle: "Access Key Integration",
      content: <GithubKeyIntegrationContent cancelUrl={cancelUrl} />,
      footerButtons: footerButtonsStepOne,
    },
    {
      headerLogo: GithubIntegrationCompleteConfig.logo,
      headerTitle: GithubIntegrationCompleteConfig.headerTitle,
      content: <GithubIntegrationCompleteContent />,
      footerButtons: footerButtonsComplete,
    },
  ];
  return (
    <>
      {errors.length > 0 &&
        errors.map((error, index) => (
          <BannerAlert
            key={index}
            message={error}
            topOffset={index * 80 + 20} // Adjusted for additional spacing; tweak as necessary
          />
        ))}
      <IntegrationLayout
        multisteps={multiSteps}
        headerLogo={pageSteps[sidebarStep - 1]?.headerLogo}
        headerTitle={pageSteps[sidebarStep - 1]?.headerTitle}
        content={pageSteps[sidebarStep - 1]?.content}
        footerButtons={pageSteps[sidebarStep - 1]?.footerButtons}
      />
    </>
  );
};

const Component = (props) => {
  return (
    <IntegrationsProvider>
      <GithubKeyIntegration {...props} />
    </IntegrationsProvider>
  );
};

export default Component;
