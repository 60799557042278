import { createContext } from "react";

export const GlobalStateContext = createContext({
  state: {},
  updaterFn: () => null,
});

export const GlobalStateDispatchContext = createContext();

export const IssuesTableStateContext = createContext();

export const ProjectIssuesControllerController = createContext();
