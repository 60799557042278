import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button as MUIButton,
  makeStyles,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SaveAltIcon from "@material-ui/icons/SaveAlt"; // <-- Import the Export icon
import { exportToCSV, exportToJSON } from "../../utils/exporters";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  expandButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#000033",
    color: "#00ffb2",
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
    },
  },
  customButton: {
    marginRight: "20px",
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "var(--dark-blue, #002733)", // Setting the text color
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "19px",
    textTransform: "none", // to avoid uppercase transformation
    "&:hover": {
      backgroundColor: theme.palette.grey[100], // subtle background on hover
      boxShadow: "none",
    },
    "& .MuiButton-label": {
      // to align the icon with the text
      justifyContent: "flex-start",
    },
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FairRiskResults = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleToggleExpandAll = () => {
    setExpanded(!expanded);
  };

  return (
    <Card elevation={3} style={{ marginTop: "20px" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5" gutterBottom>
              FAIR-CAM Risk Analysis Results
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <MUIButton
              className={classes.customButton}
              variant="contained"
              onClick={() => exportToCSV(data)}
              startIcon={<SaveAltIcon />}
            >
              Export to CSV
            </MUIButton>
            <MUIButton
              className={classes.customButton}
              variant="contained"
              onClick={() => exportToJSON(data)}
              startIcon={<SaveAltIcon />}
            >
              Export to JSON
            </MUIButton>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Main Results:</Typography>
          </Grid>
          {[
            { label: "Yearly Loss Event Frequency (LEF)", value: data.lef },
            { label: "Probable Loss Magnitude (PLM)", value: data.plm },
            { label: "Quantified Risk", value: data.risk },
          ].map((item, index) => (
            <Grid key={index} item xs={4}>
              <ListItemText primary={item.label} secondary={item.value} />
            </Grid>
          ))}

          <Grid item xs={12}>
            {/* Toggle button to expand/collapse all */}
            <MUIButton
              className={classes.customButton}
              variant="contained"
              size="medium"
              onClick={handleToggleExpandAll}
              startIcon={expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            >
              {expanded ? "Hide Explanation" : "Detailed Explanation"}
            </MUIButton>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={expanded}>
              {Object.entries(data.Explanations).map(([key, value]) => (
                <div key={key}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold", marginTop: "15px" }}
                  >
                    {key}
                  </Typography>
                  <List>
                    {[
                      { label: "Result", value: value.result },
                      {
                        label: "Explanation",
                        value: Array.isArray(value.explanation)
                          ? value.explanation.join(", ")
                          : value.explanation,
                      },
                      {
                        label: "Formula",
                        value: Array.isArray(value.formula)
                          ? value.formula.join(", ")
                          : value.formula,
                      },
                    ].map((item, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={item.label}
                          secondary={item.value}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ))}
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FairRiskResults;
