import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import { Header, PageBody } from "./styled";
import {
  PageHeader,
  PageLayoutContainer,
} from "../../components/PageLayout/styled";
import Content from "../../components/Content";
import PandoOverview from "../../components/PandoOverview";

const IS_ON_PREMISES = process.env.REACT_APP_IS_ON_PREMISES === "true";

const OverviewComponent = () => {
  return (
    <PageLayoutContainer>
      <Header>
        <PageHeader>Overview</PageHeader>
      </Header>
      <PageBody>
        <Content
          apiPath="/graph/all"
          component={PandoOverview}
          width={"100%"}
          height={"900px"}
          title={"Entity Relationships"}
        />
      </PageBody>
    </PageLayoutContainer>
  );
};

export default IS_ON_PREMISES
  ? OverviewComponent
  : withAuthenticationRequired(OverviewComponent, {
      onRedirecting: () => <Loading />,
    });
