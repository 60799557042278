import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import {
  ComboSection,
  FilterGroupButton,
  Label,
  SelectorContainer,
} from "../../../views/G6Graph/components/FilterSelector/styled";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { GroupByTriggerButtonRef } from "../../../views/G6Graph/components/FilterSelector/globalRefs";
import {
  ContentDivider,
  CurrentGroupingsListContainer,
  DynamicTagsListContainer,
  DynamicTagsSectionContainer,
  FixedListSectionContainer,
  ListContainer,
  ListItem,
  ListItemCheckbox,
  ListItemText,
  ListSectionLabel,
  MenuContainer,
  MenuContentContainer,
  MenuFooter,
  SelectedTagsSection,
  SelectionsSection,
  StyledInput,
  TagListsSection,
  TagsListContainer,
} from "./styled";
import { MenuHeader } from "../../../views/G6Graph/components/FilterSelector/MenuHeader";
import { defaultColumns } from "./defaultColumns";
import { Checkbox, FormGroup, Typography, debounce } from "@mui/material";
import styles from "./MultiSelectDragDrop.module.css";
import DragIcon from "./icons/DragIcon";
import { convertTagsToTagMap } from "./utils/convertTagsToTagMap";
import { createTagValuesMapFromGraphData } from "./utils/createTagValuesMapFromGraphData";
import { GraphGroupingTarget } from "../../../views/G6Graph/constants";
import ConditionComponent from "../../../utils/ConditionComponent";
import { AssetPropertyType } from "./data";

const ANCHOR_TOP = 68;
const ANCHOR_LEFT = 325;

const GraphGrouping = ({
  predefinedTags = defaultColumns,
  userOptions,
  onSelectionChange,
  selectedValues: selectedTags,
  setSelectedValues: setSelectedTags,
  graphData,
  onChangeCurrentGroupingSource,
  target,
  valuesMap,
  multiple = true,
  selectorContainerProps = {},
  menuContainerProps = {},
  getDisplayName,
  getAssetCount,
  getAssetLabel,
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const draggingItem = useRef();
  const tagsValuesMap = useMemo(() => {
    if (valuesMap) return valuesMap;
    return createTagValuesMapFromGraphData(graphData);
  }, [graphData, valuesMap]);

  const [isDragging, setIsDragging] = useState(false);

  const selectorDisplayName = useMemo(() => {
    let name;
    if (getDisplayName) name = getDisplayName();
    return name ?? "Group By";
  }, [getDisplayName]);

  const createAllTagsDataKey = (data) => {
    const src = `${data.source}` ?? "";
    return `${src}.${data.name}`;
  };

  const getAssetDisplayLabel = (asset) => {
    if (getAssetLabel) return getAssetLabel(asset) ?? "";
    return asset.name ?? "";
  };

  const selectedTagsMap = useMemo(() => {
    return convertTagsToTagMap(selectedTags, getAssetDisplayLabel);
  }, [selectedTags]);

  const filterData = (data) => {
    return data.filter((itm) => {
      const searchKeyword = searchInput.toLocaleLowerCase();
      const label = getAssetDisplayLabel(itm);
      const hasSearchKeyword = label.toLowerCase().includes(searchKeyword);
      const key = createAllTagsDataKey(itm);
      const checked = !!selectedTagsMap[key];
      return hasSearchKeyword && !checked;
    });
  };

  const uncheckedUserData = useMemo(() => {
    return filterData(userOptions);
  }, [userOptions, selectedTagsMap, searchInput]);

  const unCheckedPredefinedTags = useMemo(() => {
    return filterData(predefinedTags);
  }, [selectedTagsMap, searchInput, predefinedTags]);

  const formatTagsToOnSelectHandler = (tags) => {
    const selectedTagNames = tags.map((tag) => getAssetDisplayLabel(tag));
    onSelectionChange(selectedTagNames);
  };

  const addToCheckedTags = (tag) => {
    let newTags = [];
    if (multiple) {
      newTags = [...selectedTags, { ...tag, checked: true }];
    } else {
      newTags.push({ ...tag, checked: true });
    }
    setSelectedTags(newTags);
    formatTagsToOnSelectHandler(newTags);
  };

  const handleOnCheckTag = (tag) => {
    addToCheckedTags(tag);
  };

  const handleOnToggleMenu = () => {
    setMenuOpened((p) => !p);
  };

  const handleCloseMenu = () => setMenuOpened(false);

  const handleDragStart = (event, index) => {
    draggingItem.current = index;
    setIsDragging(true);
  };

  const handleDragEnter = (event, index) => {
    if (isDragging) {
      setDragOverIndex(index); // Update the dragged-over index
      const draggingIndex = draggingItem.current;
      if (index !== draggingIndex) {
        const items = [...selectedTags];
        const draggingItem = items[draggingIndex];
        items.splice(draggingIndex, 1);
        items.splice(index, 0, draggingItem);
        setSelectedTags(items);
        draggingItem.current = index;
      }
    }
  };
  const handleClearSelection = () => {
    setSelectedTags([]);
    onSelectionChange([]);
  };

  const removeFromCheckedTags = (tag) => {
    const newTags = selectedTags.filter((selectedTag) => {
      const selectedLabel = getAssetDisplayLabel(selectedTag);
      const label = getAssetDisplayLabel(tag);
      return selectedLabel !== label && selectedTag.source !== tag.source;
    });
    setSelectedTags(newTags);
    formatTagsToOnSelectHandler(newTags);
  };

  const delaySelection = useCallback((names) => {
    return debounce(onSelectionChange, 300)(names);
  }, []);

  const handleDragEnd = () => {
    setIsDragging(false);
    setDragOverIndex(null);
    const selectedNames = selectedTags.map((item) =>
      getAssetDisplayLabel(item)
    );
    delaySelection(selectedNames);
  };

  const searchItems = (value) => setSearchInput(value);

  const handleDragOver = (event, index) => {
    event.preventDefault(); // Needed to allow dropping

    const draggingIndex = draggingItem.current;
    if (index !== draggingIndex) {
      setDragOverIndex(index);
    }
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const handleOnClickCheckedTag = (tag) => {
    removeFromCheckedTags(tag);
  };

  const getAssetReferencesCount = (name, source) => {
    if (getAssetCount) return getAssetCount(name, source) ?? 0;
    return tagsValuesMap.get(name) ?? 0;
  };

  return (
    <Fragment>
      <SelectorContainer
        onClick={handleOnToggleMenu}
        ref={GroupByTriggerButtonRef}
        disableRipple
        {...selectorContainerProps}
      >
        <GroupWorkOutlinedIcon />
        {selectorDisplayName}
        <ArrowDropUpOutlinedIcon className={menuOpened ? "opened" : "closed"} />
      </SelectorContainer>
      <MenuContainer
        anchorEl={GroupByTriggerButtonRef.current}
        open={menuOpened}
        onClose={handleCloseMenu}
        anchorReference='anchorPosition'
        anchorPosition={{
          left: ANCHOR_LEFT,
          top: ANCHOR_TOP,
        }}
        {...menuContainerProps}
      >
        <MenuHeader handleOnClose={handleCloseMenu} />

        <MenuContentContainer>
          <SelectedTagsSection>
            <ListSectionLabel>Current Groupings</ListSectionLabel>
            <CurrentGroupingsListContainer>
              <ListContainer>
                {selectedTags.map((selectedTag, index) => {
                  const { checked } = selectedTag;
                  const label = getAssetDisplayLabel(selectedTag);
                  const indexRef = selectedTags.indexOf(selectedTag);
                  const isTag = selectedTag.source === AssetPropertyType.USER;

                  return (
                    <li
                      key={index}
                      draggable
                      onDragStart={(e) => handleDragStart(e, indexRef)}
                      onDragEnter={(e) => handleDragEnter(e, indexRef)}
                      onDragOver={(e) => handleDragOver(e, indexRef)}
                      onDragLeave={handleDragLeave}
                      onDragEnd={handleDragEnd}
                      className={`${styles.selectedItem}  ${
                        indexRef === dragOverIndex ? styles.dragOver : ""
                      }`}
                    >
                      <DragIcon className={styles.icon} />
                      <input
                        type='checkbox'
                        checked={checked}
                        onChange={() => handleOnClickCheckedTag(selectedTag)}
                      />
                      <Typography
                        variant='body2'
                        className={styles["list-item-text"]}
                      >
                        {label} {isTag && "(data)"}
                      </Typography>
                    </li>
                  );
                })}
              </ListContainer>
            </CurrentGroupingsListContainer>
          </SelectedTagsSection>
          <SelectionsSection>
            <StyledInput
              value={searchInput}
              onChange={(e) => searchItems(e.target.value)}
              placeholder='Search'
            />
            <TagListsSection>
              <DynamicTagsSectionContainer>
                <ListSectionLabel>Platform Fields</ListSectionLabel>
                <DynamicTagsListContainer>
                  <ListContainer>
                    {unCheckedPredefinedTags.map((tag, index) => {
                      const { checked } = tag;
                      const label = getAssetDisplayLabel(tag);
                      const numberOfReferences = getAssetReferencesCount(label);
                      return (
                        <ListItem key={index}>
                          <ListItemCheckbox
                            id={`predefined-tag-checkbox-${index}`}
                            type='checkbox'
                            checked={checked}
                            onChange={() => handleOnCheckTag(tag)}
                          />
                          <ListItemText
                            htmlFor={`predefined-tag-checkbox-${index}`}
                          >
                            {`${label} (${numberOfReferences})`}
                          </ListItemText>
                        </ListItem>
                      );
                    })}
                  </ListContainer>
                </DynamicTagsListContainer>
              </DynamicTagsSectionContainer>
              <ConditionComponent condition={userOptions.length}>
                <ContentDivider />
                <DynamicTagsSectionContainer>
                  <ListSectionLabel>Data Tags</ListSectionLabel>
                  <DynamicTagsListContainer>
                    <ListContainer>
                      {uncheckedUserData.map((tag, index) => {
                        const { checked } = tag;
                        const label = getAssetDisplayLabel(tag);
                        const numberOfReferences = getAssetReferencesCount(
                          label,
                          "tag"
                        );

                        return (
                          <ListItem key={index}>
                            <ListItemCheckbox
                              id={`userdata-tag-checkbox-${index}`}
                              type='checkbox'
                              checked={checked}
                              onChange={() => handleOnCheckTag(tag)}
                            />
                            <ListItemText
                              htmlFor={`userdata-tag-checkbox-${index}`}
                            >
                              {`${label} (${numberOfReferences})`}
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </ListContainer>
                  </DynamicTagsListContainer>
                </DynamicTagsSectionContainer>
              </ConditionComponent>
            </TagListsSection>
          </SelectionsSection>
        </MenuContentContainer>
        <MenuFooter>
          <ConditionComponent condition={!!target}>
            <FormGroup>
              <ComboSection
                onChange={onChangeCurrentGroupingSource}
                control={
                  <Checkbox
                    checked={target === GraphGroupingTarget.VISIBLE_DATA}
                  />
                }
                label={<Label>Show grouping for only visible data</Label>}
              />
            </FormGroup>{" "}
          </ConditionComponent>
          <FilterGroupButton
            variant='text'
            border='1px solid #dee0e4'
            disableRipple
            bgColor='#F7F9FE'
            onClick={handleClearSelection}
          >
            <DeleteSweepOutlinedIcon />
            <Label>Clear All Groupings</Label>
          </FilterGroupButton>
        </MenuFooter>
      </MenuContainer>
    </Fragment>
  );
};

export default GraphGrouping;
