import React from "react";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import CloudFormationIcon from "../assets/icons/onboarding/amazon-cloudformation.svg";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles({
  card: {
    cursor: "pointer",
    width: "300px", // Adjust card width here
    textAlign: "center",
    margin: "0 auto",
    "&:hover": {
      borderColor: "#000033",
      boxShadow: "0 2px 5px 2px rgba(63,81,181, .3)",
    },
  },
  disabled: {
    opacity: 0.5, // Adjust the opacity to your desired level
    pointerEvents: "none", // Disable pointer events to prevent click
  },
});

const CloudFormationCard = ({
  external_id,
  setExternal_id,
  isGenerated,
  setIsGenerated,
  setRole,
  accountId,
}) => {
  const classes = useStyles();

  const handleDeployClick = () => {
    if (!accountId || accountId.length !== 12) {
      return;
    }

    let id = external_id;
    if (!isGenerated) {
      id = uuidv4();
      setExternal_id(id);
      setIsGenerated(true);
      setRole(`arn:aws:iam::${accountId}:role/pando-security-caa-role`);
    }
    window.open(
      `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=https://s3.amazonaws.com/pandosec.com/platform/pandosec_template.yml&param_ExternalID=${id}&stackName=pandosecurity-readonly`,
      "_blank",
    );
  };

  const isDisabled = !accountId || accountId.length !== 12;

  return (
    <Card
      className={`${classes.card} ${isDisabled ? classes.disabled : ""}`}
      onClick={handleDeployClick}
      variant="outlined"
    >
      <CardContent style={{ textAlign: "center" }}>
        <img src={CloudFormationIcon} alt="Pando Icon" />
        <Typography variant="h6">CloudFormation</Typography>
      </CardContent>
    </Card>
  );
};

export default CloudFormationCard;
