import { Typography } from "@mui/material";
import { Container, Icon } from "./styled";

const Component = () => {
  return (
    <Container>
      <Icon />
      <Typography variant='h6'>Ooops! This Page cannot be found</Typography>
    </Container>
  );
};

export default Component;
