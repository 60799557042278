import { useContext } from "react";
import { GlobalStateMethodsContext } from "../context/defs";
import { useGlobalState } from "./useGlobalState";

export function useFetchData() {
  const fetchData = useContext(GlobalStateMethodsContext).fetchData;
  const { paginationConfig } = useGlobalState();
  const func = (_payload = {}) => {
    const payload = {
      pagination: paginationConfig,
      ..._payload,
    };
    fetchData(payload);
  };
  return func;
}
