import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Drawer,
  List,
  styled,
  Typography,
} from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { NavLink } from "react-router-dom";

export const SidebarRoot = styled(Drawer)(({ open, theme }) => {
  const openWidth = "210px";
  const closedWidth = "50px";
  const width = open ? openWidth : closedWidth;

  return `
  width: ${width};
  box-sizing: border-box;
  margin: 0;
  top: 0;
  height: 100vh;
  padding: 0;
  & .MuiDrawer-paper {
  border-right:1px solid ${theme.palette.grey["400"]};
  height: 100%;
  width: ${width};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transition: width 0.35s;
  box-sizing: border-box;
  padding-top: 24px;

  }
  `;
});

export const AvatarSection = styled("div")(() => {
  return `
margin: 24px 0;
padding-left: 15px;
display: flex;
align-items:center;
column-gap: 15px;
height: 24px;
white-space: nowrap;
  `;
});

export const CollapseNavButton = styled(Button)(({ theme, open }) => {
  const transform = open ? "rotate(0deg)" : "rotate(180deg)";
  return `
  min-width: 0;
  min-height: 0;
  border-top:1px solid ${theme.palette.grey["400"]};
  height: 80px;
  border-radius: 0;
  width: 100%;
  & svg {
    transform: ${transform};
    transition: transform 0.3s;
    position: relative;
    margin-left: auto;
  }
    &:focus {
    outline: none;
    }
`;
});

export const SidebarTab = styled(Button)`
  flex-shrink: 0;
  min-width: 0;
  min-height: 0;
  padding: 8px 0;
  padding-left: 15px;
  display: flex;
  column-gap: 20px;
  align-items: flex-start;
  height: 40px;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const LogoSection = styled("div")`
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  margin-bottom: 30px;
`;
export const LogoRoot = styled("img")`
  height: 30px;
`;

export const LogoutTabsRoot = styled("div")`
  margin-top: auto;
`;

export const TabLabel = styled(({ children }) => (
  <Typography variant='body2'>{children}</Typography>
))`
  white-space: nowrap;
  height: 24px;
`;

export const LogoutTab = styled(SidebarTab)`
  margin-bottom: 24px;
`;

export const LinksSection = styled(List)`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const LinkTab = styled(NavLink)(({ isNested, isActiveLink }) => {
  const columnGap = isNested ? "0" : "20px";
  const backgroundColor = isActiveLink ? "#002733" : "transparent";
  const color = isActiveLink ? "#00FFB2" : "#000";

  return `
   flex-shrink: 0;
  min-width: 0;
  min-height: 0;
  box-sizing: border-box;
  padding-left: 15px;
  display: flex;
  column-gap: ${columnGap};
  align-items: center;
  background-color: ${backgroundColor};
  color: ${color};
    white-space: nowrap;
  height: 30px;

&:hover {
opacity: 0.9;
color: ${color};
}

  & svg:first-child {
    width: 20px;
    height: 20px;
  }
  `;
});

export const NestedTab = styled(Accordion)`
  &.MuiAccordion-root {
    min-width: 50px;
    padding-left: 15px;
    justify-content: flex-start;
    background-color: inherit;
    box-shadow: none;
  }
`;

export const NestedTabSummary = styled(AccordionSummary)`
  && {
    &.MuiAccordionSummary-root {
      padding: 0;
      min-width: 0;
      min-height: 0;
      height: 40px;
      display: flex !important;
      justify-content: flex-start;
    }
    & .MuiAccordionSummary-content {
      column-gap: 20px;
    }
  }
`;

export const NestedTabContent = styled(AccordionDetails)`
  padding: 0;
  min-width: 0;
  min-height: 0;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-bottom: 8px;
`;

export const NewFlag = styled(FiberNewIcon)`
  transform: rotate(-20deg);
  color: blue;
  margin-left: 6px;
  margin-top: -4px;
`;
