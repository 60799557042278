import { useMemo } from "react";
import { useColumnsState } from "./useColumnsState";
import { usePrimaryTableState } from "./usePrimaryTableState";
import { createOptionId } from "../../../../components/Grouping/utils/createOptionId";
import { DataGroupsSources } from "../../../../components/Grouping/data";

export function useSearchResultsFieldColumns() {
  const columns = useColumnsState()[0];
  const { meta } = usePrimaryTableState();

  return useMemo(() => {
    const keys = meta.matched_keys;
    if (keys) {
      return createKeysToColumnTransformer(keys, columns);
    }
    return [];
  }, [meta, columns]);
}

function createKeysToColumnTransformer(keys, columns) {
  const checkIsSelected = (optionId) => {
    return columns.some((col) => col.id === optionId);
  };
  return keys.map((value) => {
    const option = {
      value,
      label: value,
      source: DataGroupsSources.PLATFORM,
    };
    option.id = createOptionId(option, "options");
    option.checked = checkIsSelected(option.id);
    return option;
  });
}
