import { useState } from "react";
import { Box, ButtonBase } from "@mui/material";
import MessageList from "../../components/botgpt/messageList";
import SuggestionButton from "../../components/botgpt/suggestionButton/SuggestionButton";
import TextInput from "../../components/botgpt/textInput/TextInput";
import ClearChatButton from "../../components/botgpt/clearChat";
import ChatFooter from "../../components/botgpt/chatFooter/ChatFooter";
import ChatMenu from "../../components/botgpt/menu/Menu";
import pandogptLogo from "./pandogpt.png";
import RefreshIcon from "@mui/icons-material/Refresh";
import styles from "./pandogpt.module.css";
import {
  fetchSemanticAnswer,
  likeMessage,
  dislikeMessage,
} from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import BannerAlert from "../../components/banners/ErrorAlert";
import ConditionComponent from "../../utils/ConditionComponent";
import { ResetButtonContainer } from "./styled";

const ChatContainer = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [lastUserMessageIndex, setLastUserMessageIndex] = useState(-1);
  const [isSending, setIsSending] = useState(false);
  const [followUps, setFollowUps] = useState([]);
  const [errors, setErrors] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const hasFollowUps = !!followUps.length;

  const handleSendMessage = async (messageContent = input, isUser = true) => {
    if (isSending) return;

    setIsSending(true);

    if (isUser) {
      const newMessage = {
        content: messageContent,
        author: "user",
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setLastUserMessageIndex(messages.length); // Update the index to current message
    }

    try {
      const history = messages
        .filter((msg) => msg.author === "user")
        .map((msg) => ({ content: msg.content, type: "human" }));
      const auth0Token = await getAccessTokenSilently();
      const response = await fetchSemanticAnswer(
        auth0Token,
        messageContent,
        history,
      );

      const answerMessage = {
        content: response.data.Answer,
        author: "chatgpt",
      };
      if (response.status !== 200) {
        throw new Error("Failed to fetch data. Please try again.");
      }

      setMessages((prevMessages) => [...prevMessages, answerMessage]);
      setFollowUps(response.data.FollowUpQuestions || []);
    } catch (error) {
      if (error.response) {
        // Error response from the server
        setErrors((prevErrors) => [
          ...prevErrors,
          `${error.response.status}: ${error.response.data.detail}`,
        ]);
      } else {
        // Something happened in setting up the request and triggered an Error
        setErrors((prevErrors) => [...prevErrors, `Error: ${error.message}`]);
      }
    }

    setIsSending(false);
    if (isUser) setInput("");
  };

  const handleClearMessages = () => {
    setMessages([]);
    setLastUserMessageIndex(-1);
    setIsSending(false);
    setFollowUps([]);
  };

  const handleRegenerateLastResponse = () => {
    if (lastUserMessageIndex >= 0 && messages[lastUserMessageIndex]) {
      // Remove last bot response if it exists
      if (messages.length > lastUserMessageIndex + 1) {
        setMessages(messages.slice(0, lastUserMessageIndex + 1));
      }
      handleSendMessage(messages[lastUserMessageIndex].content, false);
    }
  };

  const handleLike = async (index) => {
    try {
      const message = messages[index];
      const auth0Token = await getAccessTokenSilently();
      await likeMessage(auth0Token, message.content);
      // Handle successful like response if needed
    } catch (error) {
      console.error("Failed to like message:", error);
    }
  };

  const handleDislike = async (index) => {
    try {
      const message = messages[index];
      const auth0Token = await getAccessTokenSilently();
      await dislikeMessage(auth0Token, message.content);
      // Handle successful dislike response if needed
    } catch (error) {
      console.error("Failed to dislike message:", error);
    }
  };

  return (
    <Box className={styles.chatContainer}>
      <ChatMenu>
        <ResetButtonContainer>
          <ClearChatButton onClick={handleClearMessages} />
        </ResetButtonContainer>
      </ChatMenu>
      <div className={styles.chatBody}>
        {errors.map((error, index) => (
          <BannerAlert
            key={index}
            message={error}
            topOffset={index * 80 + 20}
          />
        ))}
        {messages.length > 0 && (
          <>
            <MessageList
              messages={messages}
              handleLike={handleLike}
              handleDislike={handleDislike}
            />
            <ConditionComponent condition={!isSending}>
              <ButtonBase
                className={styles.regenerateResponseBtn}
                sx={{
                  p: { xs: "4px 8px !important", sm: "8px 16px !important" },
                }}
                onClick={handleRegenerateLastResponse}
              >
                <RefreshIcon />
              </ButtonBase>
            </ConditionComponent>
          </>
        )}
        {messages.length === 0 && (
          <div className={styles.logoContainer}>
            <img
              className={styles.pandogptLogo}
              src={pandogptLogo}
              alt="Pando GPT"
            />
          </div>
        )}
      </div>
      <div className={styles.chatBottomContent}>
        <ConditionComponent condition={hasFollowUps}>
          <Box
            className={styles.suggestions}
            sx={{ mb: { xs: "5px", md: "10px" } }}
          >
            {followUps.map((question, index) => (
              <SuggestionButton
                key={`follow-up-${index}`}
                text={question}
                onClick={() => handleSendMessage(question)}
              />
            ))}
          </Box>
        </ConditionComponent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextInput
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onSend={() => handleSendMessage()}
            isSending={isSending}
          />
        </Box>
        <ChatFooter />
      </div>
    </Box>
  );
};

export default ChatContainer;
