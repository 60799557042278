import { Button, Input as MuiInput, styled } from "@mui/material";

export const SearchInputRoot = styled("div")(({ theme }) => {
  return `
  background-color: ${theme.palette.background.paper};
  border-radius: 2px;
  border: 1px solid ${theme.palette.grey["500"]};
  padding: 2px;
  padding-left: 6px;
  padding-right: 0;
  box-sizing: border-box;
  height: 30px;
  width: 350px;
  display: flex;
  align-items:center;
  & svg{
  width: 14px;
  height: 14px;
  }
  `;
});

export const Input = styled(MuiInput)`
  min-width: 0;
  min-height: 0;
  padding: 0;
  flex: 1;
  margin-left: 6px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
`;

export const EnterButton = styled(Button)(({ theme }) => {
  return `
  min-width: 0;
background-color: red;
font-size: 12px;
height:28px;
width: 28px;
border-radius: 0;
background-color: ${theme.palette.grey[500]};
`;
});
