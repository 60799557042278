export const WizIntegrationInitialState = {
  wizUrl: "",
  clientKey: "",
  legacyConnection: false,
  projectIdMapping: "",
  wizAuthUrl: "",
  clientSecret: "",
  httpsProxy: "",
  projectUUID: "",
  step: 1,
  loading: false,
  error: null,
  name: "",
};
