import { styled } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export const Icon = styled(LockIcon)`
  font-size: 72px;
`;
