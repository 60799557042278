import { useGroupingTableState } from "./useGroupingTableState";
import { useIsGrouping } from "./useIsGrouping";
import { usePrimaryTableState } from "./usePrimaryTableState";

export function useTablePagination() {
  const isGrouping = useIsGrouping();
  const { paginationConfig: primaryTablePagination } = usePrimaryTableState();
  const { paginationConfig: groupingPagination } = useGroupingTableState();

  if (isGrouping) return groupingPagination;
  return primaryTablePagination;
}
