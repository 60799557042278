import G6 from "@antv/g6";
import styles from "./Plugins.module.css";

const createPlugins = (pluginNames) => {
  const plugins = [];

  if (pluginNames.includes("toolbar")) {
    const toolbar = new G6.ToolBar({
      position: { x: 10, y: 60 },
      className: styles["g6-toolbar-ul"],
    });
    plugins.push(toolbar);
  }

  if (pluginNames.includes("tooltip")) {
    const tooltip = new G6.Tooltip({
      offsetX: 10,
      offsetY: 10,
      trigger: "click",
      itemTypes: ["node", "edge"],
      getContent: (e) => {
        const model = e.item.getModel();
        const property_whitelist = [
          "name",
          "source",
          "id",
          "category",
          "account",
          "deployment",
          "region",
        ];

        const properties = property_whitelist
          .map((property) => {
            if (model[property]) {
              return `<li><strong>${property.charAt(0).toUpperCase() + property.slice(1)}:</strong> ${model[property]}</li>`;
            }
            return "";
          })
          .join("");

        const outDiv = document.createElement("div");
        outDiv.style.padding = "10px";
        outDiv.style.border = "1px solid #ccc";
        outDiv.style.borderRadius = "4px";
        outDiv.style.backgroundColor = "#fff";
        outDiv.style.color = "#000";
        outDiv.innerHTML = `<h4 style="margin: 0 0 10px 0;">Item Details</h4>
                                <ul style="list-style: none; padding: 0;">${properties}</ul>`;
        return outDiv;
      },
    });
    plugins.push(tooltip);
  }

  return plugins;
};

export default createPlugins;
