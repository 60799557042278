const IS_ON_PREMISES = process.env.REACT_APP_IS_ON_PREMISES === "true";
const JWT_TOKEN = process.env.REACT_APP_JWT_TOKEN;

const helperFunctions = {
  async getAuth0Token(getAccessTokenSilently) {
    if (IS_ON_PREMISES) {
      return Promise.resolve(JWT_TOKEN);
    } else {
      return await getAccessTokenSilently();
    }
  },
};

export default helperFunctions;
