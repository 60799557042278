import { createContext } from "react";
import { InitialState } from "../stateManagement/initialState";

export const GlobalStateContext = createContext({
  state: {},
  updaterFn: () => null,
});

export const ProjectsTableContext = createContext({
  ...InitialState.projectsTableState,
});

export const CMSTControllerContext = createContext({
  getProjects: () => null,
});

export const CMSTStateDispatchContext = createContext();
