import { useState } from "react";
import {
  IntegrationValueMutatorsContext,
  IntegrationValuesStateContext,
} from "./contexts";

export const IntegrationsProvider = ({ children }) => {
  const [apiToken, setApiToken] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [email, setEmail] = useState("");
  const [integrationName, setIntegrationName] = useState("");

  return (
    <IntegrationValueMutatorsContext.Provider
      value={{
        setApiToken,
        setDomainUrl,
        setEmail,
        setIntegrationName,
      }}
    >
      <IntegrationValuesStateContext.Provider
        value={{
          apiToken,
          domainUrl,
          email,
          integrationName,
        }}
      >
        {children}
      </IntegrationValuesStateContext.Provider>
    </IntegrationValueMutatorsContext.Provider>
  );
};
