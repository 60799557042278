import { styled } from "@mui/material";
import { PageHeader, PageLayout } from "./PageLayout";
import { ResponsiveWidthRoot } from "./ResponsiveLayout";

export const CMSTTableSection = styled("div")`
  flex-shrink: 0;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 800px;
  margin: 0 auto;
  margin-top: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const InsightsSection = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 24px;
  min-height: 120px;
  max-width: 1440px;
  margin: 0 auto;

  padding: 12px;
  padding-bottom: 32px;
  border-radius: 6px;
  margin-top: 6px;
  background-color: #f7f7f7;
`;

export const PageRoot = styled(PageLayout)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const Header = styled(PageHeader)`
  padding: 24px;
`;

export const PageContent = styled("main")`
  padding: 0 24px 24px 24px;
`;

export const CMSTTableActionsRoot = styled(ResponsiveWidthRoot)`
  display: flex;
  align-items: center;
  margin: 12px 0;
  justify-content: flex-end;
  column-gap: 10px;
`;
