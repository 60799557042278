import { createSlice } from "@reduxjs/toolkit";
import { getProjectsSummary } from "./thunks/getProjectsSummary";
import { InitialState } from "./initialState";
import { getProjects } from "./thunks/getProjects";
import { getProjectIssues } from "./thunks/getProjectIssues";
import { getIssuesSummary } from "./thunks/getIssuesSummary";
import { getProjectsSchema } from "./thunks/getProjectsSchema";
import { getIssueDetail } from "./thunks/getIssueDetaiil";

const slice = createSlice({
  name: "cmst",
  initialState: InitialState,
  reducers: {
    setProjectTablePagination: (state, { payload }) => {
      state.projectsTableState.paginationConfig = payload;
    },
    setProjectsTableFilters: (state, { payload }) => {
      state.projectsTableState.filters = payload;
    },
    setProjectsTableColumns: (state, { payload }) => {
      state.projectsTableState.columns = payload;
    },
    setIssuesTableFilters: (state, { payload }) => {
      state.issuesTableState.filters = payload;
    },
    setISsuesTableColumns: (state, { payload }) => {
      state.issuesTableState.columns = payload;
    },
    setIssuesTablePagination: (state, { payload }) => {
      state.issuesTableState.paginationConfig = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProjectsSummary.pending, (state, { payload }) => {
      state.projectsSummaryState.isLoading = true;
      state.projectsSummaryState.data = {};
      state.projectsSummaryState.error = false;
      state.projectsSummaryState.errorPayload = null;
    });

    builder.addCase(getProjectsSummary.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.projectsSummaryState.data = payload.data;
      state.projectsSummaryState.meta = payload.meta;
      state.projectsSummaryState.isLoading = false;
      state.projectsSummaryState.error = false;
      state.projectsSummaryState.errorPayload = null;
    });

    builder.addCase(getProjectsSummary.rejected, (state, { payload }) => {
      state.projectsSummaryState.data = {};
      state.projectsSummaryState.meta = {};
      state.projectsSummaryState.isLoading = false;
      state.projectsSummaryState.error = true;
      state.projectsSummaryState.errorPayload = payload;
    });

    //

    builder.addCase(getProjects.pending, (state, { payload }) => {
      state.projectsTableState.isLoading = true;
      state.projectsTableState.data = [];
      state.projectsTableState.error = false;
      state.projectsTableState.errorPayload = null;
    });

    builder.addCase(getProjects.fulfilled, (state, action) => {
      const { payload } = action;
      state.projectsTableState.data = payload.data;
      state.projectsTableState.meta = payload.meta;
      state.projectsTableState.isLoading = false;
      state.projectsTableState.error = false;
      state.projectsTableState.errorPayload = null;
    });

    builder.addCase(getProjects.rejected, (state, { payload }) => {
      state.projectsTableState.data = [];
      state.projectsTableState.meta = {};
      state.projectsTableState.isLoading = false;
      state.projectsTableState.error = true;
      state.projectsTableState.errorPayload = payload;
    });

    //

    builder.addCase(getProjectIssues.pending, (state, { payload }) => {
      state.issuesTableState.isLoading = true;
      state.issuesTableState.data = [];
      state.issuesTableState.error = false;
      state.issuesTableState.errorPayload = null;
    });

    builder.addCase(getProjectIssues.fulfilled, (state, action) => {
      const { payload } = action;
      state.issuesTableState.data = payload.data;
      state.issuesTableState.meta = payload.meta;
      state.issuesTableState.isLoading = false;
      state.issuesTableState.error = false;
      state.issuesTableState.errorPayload = null;
    });

    builder.addCase(getProjectIssues.rejected, (state, { payload }) => {
      state.issuesTableState.data = [];
      state.issuesTableState.meta = {};
      state.issuesTableState.isLoading = false;
      state.issuesTableState.error = true;
      state.issuesTableState.errorPayload = payload;
    });

    //

    builder.addCase(getIssuesSummary.pending, (state, { payload }) => {
      state.issuesSummaryState.isLoading = true;
      state.issuesSummaryState.data = {};
      state.issuesSummaryState.error = false;
      state.issuesSummaryState.errorPayload = null;
    });

    builder.addCase(getIssuesSummary.fulfilled, (state, action) => {
      const { payload } = action;

      state.issuesTableState.options = payload.options;
      state.issuesSummaryState.data = payload.summary;
      state.issuesSummaryState.isLoading = false;
      state.issuesSummaryState.error = false;
      state.issuesSummaryState.errorPayload = null;
    });

    builder.addCase(getIssuesSummary.rejected, (state, { payload }) => {
      state.issuesSummaryState.data = {};
      state.issuesSummaryState.meta = {};
      state.issuesSummaryState.isLoading = false;
      state.issuesSummaryState.error = true;
      state.issuesSummaryState.errorPayload = payload;
    });

    //

    builder.addCase(getProjectsSchema.pending, (state, { payload }) => {});

    builder.addCase(getProjectsSchema.fulfilled, (state, action) => {
      const { payload } = action;
      state.projectsTableState.options = payload;
    });

    builder.addCase(getProjectsSchema.rejected, (state, { payload }) => {});
    builder.addCase(getIssueDetail.pending, (state, { payload }) => {
      state.issueDetailState.isLoading = true;
      state.issueDetailState.data = {};
      state.issueDetailState.meta = {};
      state.issueDetailState.error = false;
      state.issueDetailState.errorPayload = null;
    });

    builder.addCase(getIssueDetail.fulfilled, (state, action) => {
      const { payload } = action;

      state.issueDetailState.data = payload.data;
      state.issueDetailState.meta = payload.meta;
      state.issueDetailState.isLoading = false;
      state.issueDetailState.error = false;
      state.issueDetailState.errorPayload = null;
    });

    builder.addCase(getIssueDetail.rejected, (state, { payload }) => {
      state.issueDetailState.data = {};
      state.issueDetailState.meta = {};
      state.issueDetailState.isLoading = false;
      state.issueDetailState.error = true;
      state.issueDetailState.errorPayload = payload;
    });
  },
});
const reducer = slice.reducer;

const CMSTActions = slice.actions;

export { reducer, CMSTActions };
