import { TypeAdapter } from "../../utils/typeUtils/type-adapter";
import { DataFilteringOperators } from "./operators";

export function getOperatorsByValueType(valueTypes) {
  const operators = Object.values(DataFilteringOperators);
  if (!valueTypes || !valueTypes?.length) return operators;
  return operators.filter((operator) => {
    return valueTypes.some((type) => {
      const castType = TypeAdapter.castToJsType(type);
      if (castType) {
        return operator.types.has("number");
      }
      return operator.types.has("any");
    });
  });
}
