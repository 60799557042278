import { useMemo } from "react";
import {
  useGetWizIntegrationState,
  useSetWizIntegrationState,
} from "./useIntegrationState";
import createButtonConfig from "../../../shared/functions/buttons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { WizIntegrationActions } from "../context/reducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addWizIntegration } from "../../../utils/api";

export const useIntegrationButtons = () => {
  const state = useGetWizIntegrationState();
  const {
    step,
    loading,
    wizUrl,
    legacyConnection,
    wizAuthUrl,
    clientSecret,
    projectUUID,
    httpsProxy,
    projectIdMapping,
    clientKey,
    name,
  } = state;

  const setState = useSetWizIntegrationState();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const payloadIsValid = useMemo(() => {
    let isValid = true;
    if (!wizUrl) isValid = false;
    if (!wizAuthUrl) isValid = false;
    if (!clientSecret) isValid = false;
    if (!projectUUID) isValid = false;
    if (!clientKey) isValid = false;
    if (!name) isValid = false;
    return isValid;
  }, [wizUrl, wizAuthUrl, clientSecret, projectUUID, clientKey, name]);

  const handleOnCancelIntegration = () => {
    history.push("/integrations");
  };

  const handleOnCompleteIntegration = () => {
    setState({ type: WizIntegrationActions.reset });
    history.push("/integrations");
  };

  const handleOnContinueIntegration = async () => {
    try {
      setState({
        type: WizIntegrationActions.startIntegration,
      });
      const token = await getAccessTokenSilently();
      const payload = {
        wizUrl,
        legacyConnection,
        wizAuthUrl,
        clientSecret,
        projectUUID,
        httpsProxy: httpsProxy || null,
        projectIdMapping: projectIdMapping || null,
        clientKey,
        name,
      };
      await addWizIntegration(token, payload);
      setState({
        type: WizIntegrationActions.integrationSuccess,
      });
    } catch (error) {
      setState({
        type: WizIntegrationActions.integrationFailed,
        error: error.message,
      });
    }
  };

  const step1FooterButtons = [
    createButtonConfig(
      "Cancel",
      "left",
      handleOnCancelIntegration,
      null,
      "cancel",
      false
    ),
    createButtonConfig(
      "Continue",
      "right",
      handleOnContinueIntegration,
      <DoneOutlinedIcon />,
      "cancel",
      !payloadIsValid,
      loading
    ),
  ];

  const step2FooterButtons = [
    createButtonConfig(
      "Done",
      "right",
      handleOnCompleteIntegration,
      null,
      "cancel",
      false
    ),
  ];

  const buttons = useMemo(() => {
    if (step === 2) return step2FooterButtons;
    return step1FooterButtons;
  }, [state]);

  return buttons;
};
