import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import styles from "./cardfooter.module.css";

function CardFooter({ buttons }) {
  const renderButtonContent = ({ icon, iconPosition, text }) => {
    switch (iconPosition) {
      case "left":
        return (
          <>
            {icon && <span className={styles.btnIcon}>{icon}</span>}
            <Typography className={styles.btnText}>{text}</Typography>
          </>
        );
      case "right":
        return (
          <>
            <Typography className={styles.btnText}>{text}</Typography>
            {icon && <span className={styles.btnIcon}>{icon}</span>}
          </>
        );
      default:
        return <Typography className={styles.btnText}>{text}</Typography>;
    }
  };

  return (
    <Box className={styles.cardFooter}>
      {buttons.map((button, index) => (
        <ButtonBase
          key={index}
          className={`${styles.footerBtn} ${button.className}`}
          onClick={button.onClick}
          disabled={button.disabled}
        >
          {renderButtonContent(button)}
        </ButtonBase>
      ))}
    </Box>
  );
}

export default CardFooter;
