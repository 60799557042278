import { useMaterialReactTable } from "material-react-table";
import { usePrimaryTableState } from "./usePrimaryTableState";
import { usePrimaryTablePagination } from "./usePrimaryTablePagination";
import { useAssetsTableMethods } from "./useTableMethods";
import { useTableColumns } from "./useTableColumns";
import { useFetchPrimaryTableData } from "./useFetchPrimaryTableData";
import { checkTableIPaginationIsFirstRender } from "../../../../containers/inventory/AssetsTable/utils/checkTableIPaginationIsFirstRender";

export const usePrimaryTable = () => {
  const { isLoading, data, meta, paginationConfig } = usePrimaryTableState();
  const columns = useTableColumns();
  const { onClickAsset } = useAssetsTableMethods();
  const fetchPrimaryTableData = useFetchPrimaryTableData();

  const [pagination, setPagination] = usePrimaryTablePagination();

  const handleOnPaginationChange = (setter) => {
    const newPagination = setter(paginationConfig);
    const isFirstRender = checkTableIPaginationIsFirstRender(
      paginationConfig,
      newPagination
    );
    if (isFirstRender) return;
    const payload = {
      pagination: newPagination,
    };
    fetchPrimaryTableData(payload);
    setPagination(newPagination);
  };

  const onClickRow = (ev, row) => {
    onClickAsset(row.original);
  };

  return useMaterialReactTable({
    columns,
    state: {
      isLoading,
      pagination,
    },
    data,
    onPaginationChange: handleOnPaginationChange,
    rowCount: meta?.total ?? 0,
    paginationDisplayMode: "pages",
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePagination: true,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    layoutMode: "grid",
    manualPagination: true,
    muiTableBodyRowProps({ row }) {
      return {
        onClick: (ev) => onClickRow(ev, row),
        sx: {
          cursor: "pointer",
        },
      };
    },
    muiTableContainerProps: {
      sx: {
        height: "calc(100% - 72px)",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        height: "40px",
      },
    },
  });
};
