import { styled, Typography } from "@mui/material";

export const PageRoot = styled("div")`
  width: 100%;
  height: 100vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const PageHeading = styled(({ children, ...props }) => (
  <Typography variant='h5' {...props}>
    {children}
  </Typography>
))`
  font-weight: 500;
`;

export const PageHeader = styled("header")``;

export const PageContent = styled("section")`
  flex: 1;
  flex-shrink: 0;
`;

export const PageActionsRoot = styled("div")`
  display: flex;
`;
