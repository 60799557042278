import { useEffect } from "react";
import appJson from "../../package.json";
import componentsConfig from "../ui/components.config.json";
import { LocalStorage } from "../utils/browserUtils/LocalStorage";

export default function useInitAppConfig() {
  const appConfig = {
    version: appJson.version,
    app: "Pando Client",
    componentsConfig: {
      ...componentsConfig,
    },
  };

  useEffect(() => {
    LocalStorage.setItem("pando-app-config", appConfig);
  }, []);
}
