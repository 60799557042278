import React from "react";
import { Box } from "@mui/material";
import CardHeader from "./cardHeader";
import CardContent from "./cardContent";
import CardFooter from "./cardFooter";
import styles from "./styles.module.css";

const IntegrationLayout = ({
  multisteps,
  headerLogo,
  headerTitle,
  content,
  footerButtons,
}) => (
  <Box className={styles.sidebarAndCardWrapper}>
    {multisteps}
    <Box className={`${styles.card} ${styles.fullWidth}`}>
      <CardHeader logo={headerLogo} title={headerTitle} />
      <CardContent>{content}</CardContent>
      <CardFooter buttons={footerButtons} />
    </Box>
  </Box>
);

export default IntegrationLayout;
