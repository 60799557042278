import { generateRandomStr } from "../../utils/helperMethods/generateId";
import { ConditionOptions } from "./components/FilterSelector/constants";

export function transformUrlFilterParams(str) {
  try {
    const parsed = JSON.parse(str);
    validateReceivedFilters(parsed);
    return transformFilters(parsed);
  } catch (error) {
    return [];
  }
}

function transformFilters(filters) {
  return filters.map(transformFilterToFilterSelectorFormat);
}

function transformFilterToFilterSelectorFormat(filter) {
  const condition = createConditionOption(filter);
  const category = createOption(filter.category);
  const values = filter.values.map((val) => createOption(val));
  const id = generateRandomStr();
  return { category, condition, values, id };
}

function createOption(data) {
  return { label: data, value: data };
}

function createConditionOption(filter) {
  return ConditionOptions.find(
    (condition) => condition.value === filter.condition,
  );
}

function validateReceivedFilters(data) {
  if (!Array.isArray(data)) throw new Error();
  data.forEach(filterGroupValidator);
}

function filterGroupValidator(filter) {
  if (!filter.condition) throw new Error();
  if (!filter.category) throw new Error();
  if (!Array.isArray(filter.values)) throw new Error();
}
