import { createContext } from "react";

export const IntegrationValuesStateContext = createContext({
  account: "",
  integrationName: "",
  role: "",
  externalId: "",
  selfRole: "",
});

export const IntegrationValueMutatorsContext = createContext({
  setAccount: () => null,
  setIntegrationName: () => null,
  setRole: () => null,
  setExternalId: () => null,
  setSelfRole: () => null,
});
