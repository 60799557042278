import React, { useState, useEffect } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import history from "./utils/history";
import GraphComponent from "./views/Graph";
import GraphPage from "./views/G6Graph/G6Graph";

import { makeStyles } from "@material-ui/core/styles";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import initFontAwesome from "./utils/initFontAwesome";
import { ConnectorComponent } from "./views/Connector";
import Onboarding from "./views/Onboarding";
import OverviewPage from "./views/Overview/index";
import { TableComponent } from "./views/Table";
import Vulnerability from "./views/Vulnerability";
import GitHubOAuthCallback from "./views/callbacks/Github";
import { checkOnboarding } from "./utils/api";
import NavBar from "./components/NavBar";
import SpacedTableComponent from "./views/Findings";
import clsx from "clsx";
import FeatureService from "./services/feturesService";
import CRQ from "./views/CRQ";
import AwsIntegration from "./containers/awsIntegration";
import AwsCloudformation from "./containers/awsCloudformation";
import GithubIntegration from "./containers/githubIntegration";
import OnBoarding from "./pages/onBoarding";
import Header from "./components/onboardingV2/header";
import { fetchIntegrations } from "./redux/actions/integrationActions";
import BitbucketIntegration from "./containers/bitbucketIntegration";
import JiraIntegration from "./containers/jiraIntegration";
import SettingsPage from "./views/Settings";
import InitiativesPage from "./views/Initiatives";
import BusinessFlow from "./views/BusinessFlow";
import GithubIntegrationContent from "./containers/githubIntegration/githubIntegrationKey";
import GithubIntegrationContentKey from "./containers/githubIntegration/githubIntegrationKey";
import ChatContainerPage from "./pages/pandoGPT";
import { useInitUserInfo } from "./hooks/useInitUserInfo";
import useInitAppConfig from "./hooks/useInitAppConfig";
import { AppThemeProvider } from "./styling/theme/Provider";
import WizIntegration from "./containers/wizIntegration";
import HibobIntegration from "./containers/hibobIntegration";
import { ErrorBoundary } from "./components/ErrorBoundary";
import InventoryAssetsPage from "./ui/pages/inventory";
import SavedQueries from "./ui/pages/savedQueries/index";
import { useSetupInfrastructure } from "./hooks/useSetupIAppInffrastructure";
import { ThemeProvider } from "./ui/theming/Provider";
import ConditionComponent from "./utils/ConditionComponent";
import { Sidebar } from "./ui/base-components/Sidebar";
import { store } from "./state-management/store";
import ProtectedRoute from "./ui/utils/ProtectedRoute";
import { useUserInfo } from "./hooks/useAppUser";
import { AppFeatures } from "./core/features";
import UnauthorizedPage from "./ui/pages/unauthorized/index";
import NotFoundPage from "./ui/pages/notFound/index";
import CMST from "./ui/pages/cmst";
import CMSTProjectDetail from "./ui/pages/cmst-project-info";
import CMSTIssueDetail from "./ui/pages/cmst-issue-info";
import { AppEntry } from "./app/routing/Entry";
import { CMSTDOmainEntry } from "./ui/pages/cmst/DomainEntryPage";
import CMSTActions from "./ui/pages/cmst-actions";
import CMSTQueries from "./ui/pages/cmst-queries";
import InventoryQueries from "./ui/pages/inventory-queries";
import InventoryActions from "./ui/pages/inventory-actions";
import { ToastContainer } from "react-toastify";

initFontAwesome();

const LOCAL_JWT = process.env.REACT_APP_LOCAL_USER_JWT || null;

const useStylesApp = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: "calc(100% - 57px)",
    overflow: "hidden",
  },
  contentShift: {
    width: "calc(100% - 250px)",
  },
}));

const LocalApp = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const classes = useStylesApp();
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [features, setFeatures] = useState({});
  const [apiError, setApiError] = useState(null);

  useInitAppConfig();
  useEffect(() => {
    const token = LOCAL_JWT;
    FeatureService.getFeatures(token)
      .then((response) => {
        setFeatures(response);
        setIsApiLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setIsApiLoading(false);
      });
  }, []);

  if (isApiLoading && !apiError) {
    return <Loading />;
  }

  return (
    <AppThemeProvider>
      <Router history={history}>
        <div className={classes.root}>
          <Sidebar setOpenState={setSidebarOpen} />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: sidebarOpen,
            })}
          >
            <div>
              <Switch>
                <>
                  <Route path='/' exact component={OverviewPage} />
                  {features["GRAPHV6"] && (
                    <Route path='/graph' exact component={GraphPage} />
                  )}
                  {!features["GRAPHV6"] && (
                    <Route path='/graph' exact component={GraphComponent} />
                  )}

                  <Route path='/table' exact component={TableComponent} />

                  <Route
                    path='/connectors'
                    exact
                    component={ConnectorComponent}
                  />
                  <Route
                    path='/github_auth'
                    exact
                    component={GitHubOAuthCallback}
                  />
                  {features["VULNERABILITIES"] && (
                    <Route
                      path='/vulnerability'
                      exact
                      component={Vulnerability}
                    />
                  )}
                  {features["COUNTERMEASURES"] && (
                    <Route
                      path='/findings'
                      exact
                      component={SpacedTableComponent}
                    />
                  )}
                  {features["QUANTIFICATION"] && (
                    <Route path='/quantification' exact component={CRQ} />
                  )}
                  <Route path='/overview' exact component={OverviewPage} />
                  {features["PANDOGPT"] && (
                    <Route path='/chat' exact component={ChatContainerPage} />
                  )}
                  <Route path='/settings' exact component={SettingsPage} />
                </>
              </Switch>
            </div>
          </main>
        </div>
        <ToastContainer />
      </Router>
    </AppThemeProvider>
  );
};

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const classes = useStylesApp();
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [features, setFeatures] = useState({});
  const [apiError, setApiError] = useState(null);
  const { privileges } = useUserInfo();

  useSetupInfrastructure();
  useInitAppConfig();
  useInitUserInfo();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => FeatureService.getFeatures(token))
        .then((response) => {
          setFeatures(response);
        });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => checkOnboarding(token))
        .then((response) => {
          setIsOnboarded(response === 2);
          setIsApiLoading(false);
        })
        .catch((error) => setApiError(error));
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    isOnboarded,
    isApiLoading,
    isLoading,
  ]);

  const savedQueriesRouteGuard = () => {
    return privileges?.[AppFeatures.SAVED_QUERIES] && isAuthenticated;
  };

  const overviewPageRouteGuard = () => {
    return privileges?.[AppFeatures.OVERVIEW] && isAuthenticated;
  };

  const cmstPageRouteGuard = () => {
    return privileges?.[AppFeatures.CMST] && isAuthenticated;
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          // Dispatch the thunk action here
          store.dispatch(fetchIntegrations(token));
        })
        .catch((error) => {
          // Handle error for token retrieval
          console.error("Error fetching access token", error);
        });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return loginWithRedirect();
  }

  if (isApiLoading && !apiError) {
    return <Loading />;
  }

  return (
    <ThemeProvider>
      <ErrorBoundary>
        <Router history={history}>
          <div className={classes.root}>
            <ConditionComponent condition={isAuthenticated && isOnboarded}>
              <Sidebar />
            </ConditionComponent>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: sidebarOpen,
              })}
            >
              <div>
                <Switch>
                  {isAuthenticated ? (
                    !isOnboarded ? (
                      features["ONBOARDINGV2"] ? (
                        <>
                          <Header />
                          <Route path='/' exact component={OnBoarding} />
                          <Route
                            path='/integrations/aws'
                            exact
                            component={AwsIntegration}
                          />
                          <Route
                            path='/integrations/aws/cloudformation'
                            exact
                            component={AwsCloudformation}
                          />
                          <Route
                            path='/integrations/github'
                            exact
                            component={GithubIntegration}
                          />
                          <Route
                            path='/integrations/bitbucket'
                            exact
                            component={BitbucketIntegration}
                          />
                          <Route
                            path='/integrations/jira'
                            exact
                            component={JiraIntegration}
                          />
                          <Route
                            path='/integrations/github/key'
                            exact
                            component={GithubIntegrationContentKey}
                          />
                          <Redirect from='*' to='/' />
                        </>
                      ) : (
                        <>
                          <NavBar className={"navbar-container"} />
                          <Route path='/onboarding' component={Onboarding} />
                          <Redirect from='*' to='/onboarding' />
                        </>
                      )
                    ) : (
                      <>
                        <Route path='/' exact component={AppEntry} />
                        {features && features["GRAPHV6"] ? (
                          <Route path='/graph' exact component={GraphPage} />
                        ) : (
                          <Route
                            path='/graph'
                            exact
                            component={GraphComponent}
                          />
                        )}
                        <Route path='/table' exact component={TableComponent} />

                        <ProtectedRoute
                          path='/overview'
                          exact
                          component={OverviewPage}
                          routeGuard={overviewPageRouteGuard}
                        />

                        <Route
                          path='/inventory-queries'
                          exact
                          component={InventoryQueries}
                        />

                        <Route
                          path='/inventory-actions'
                          exact
                          component={InventoryActions}
                          routeGuard={savedQueriesRouteGuard}
                        />

                        <Route
                          path='/inventory'
                          component={InventoryAssetsPage}
                        />
                        <ProtectedRoute
                          path='/cmst'
                          component={CMSTDOmainEntry}
                          routeGuard={cmstPageRouteGuard}
                        />
                        <Route exact path='/cmst/projects' component={CMST} />
                        <Route
                          exact
                          path='/cmst/queries'
                          component={CMSTQueries}
                        />
                        <Route
                          path='/cmst/projects/:id/issues'
                          exact
                          component={CMSTProjectDetail}
                        />

                        <Route
                          path='/cmst/projects/:id/issues/:id'
                          exact
                          component={CMSTIssueDetail}
                        />
                        <Route
                          path='/cmst/actions'
                          exact
                          component={CMSTActions}
                        />

                        <Route
                          path='/connectors'
                          exact
                          component={ConnectorComponent}
                        />
                        <Route
                          path='/github_auth'
                          exact
                          component={GitHubOAuthCallback}
                        />
                        {features["VULNERABILITIES"] && (
                          <Route
                            path='/vulnerability'
                            exact
                            component={Vulnerability}
                          />
                        )}
                        {features["COUNTERMEASURES"] && (
                          <Route
                            path='/findings'
                            exact
                            component={SpacedTableComponent}
                          />
                        )}
                        {features["QUANTIFICATION"] && (
                          <Route path='/quantification' exact component={CRQ} />
                        )}

                        {features["CHAT"] && (
                          <Route
                            path='/chat'
                            exact
                            component={ChatContainerPage}
                          />
                        )}
                        <Route
                          path='/settings'
                          exact
                          component={SettingsPage}
                        />
                        <Route
                          path='/initiatives'
                          exact
                          component={InitiativesPage}
                        />
                        <Route path='/flows' exact component={BusinessFlow} />
                        <Route
                          path='/integrations'
                          exact
                          render={() => <OnBoarding onlyIntergrations />}
                        />
                        <Route
                          path='/integrations/wiz'
                          exact
                          render={() => <WizIntegration />}
                        />
                        <Route
                          path='/integrations/hibob'
                          exact
                          render={() => <HibobIntegration />}
                        />
                        <Route
                          path='/integrations/aws'
                          exact
                          render={() => (
                            <AwsIntegration cancelUrl='/integrations' />
                          )}
                        />
                        <Route
                          path='/integrations/aws/cloudformation'
                          exact
                          render={() => (
                            <AwsCloudformation cancelUrl='/integrations' />
                          )}
                        />
                        <Route
                          path='/integrations/aws/manual'
                          exact
                          render={() => (
                            <AwsCloudformation
                              manual
                              cancelUrl='/integrations'
                            />
                          )}
                        />
                        <Route
                          path='/integrations/github'
                          exact
                          render={() => (
                            <GithubIntegration cancelUrl='/integrations' />
                          )}
                        />
                        <Route
                          path='/integrations/bitbucket'
                          exact
                          render={() => (
                            <BitbucketIntegration cancelUrl='/integrations' />
                          )}
                        />
                        <Route
                          path='/integrations/jira'
                          exact
                          render={() => (
                            <JiraIntegration cancelUrl='/integrations' />
                          )}
                        />
                        <Route
                          path='/integrations/github/key'
                          exact
                          render={() => (
                            <GithubIntegrationContent cancelUrl='/integrations' />
                          )}
                        />

                        <Route
                          path='/unauthorized-access'
                          exact
                          component={UnauthorizedPage}
                        />
                        <Route path='*' component={NotFoundPage} />
                      </>
                    )
                  ) : (
                    loginWithRedirect()
                  )}
                </Switch>
              </div>
            </main>
          </div>
        </Router>
        <ToastContainer />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
