import { createContext } from "react";

export const IntegrationValuesStateContext = createContext({
  accessToken: "",
  integrationName: "",
});
export const IntegrationValueMutatorsContext = createContext({
  setAccessToken: () => null,
  setIntegrationName: () => null,
});
