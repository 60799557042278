import bitbucketLogo from "../../../assets/images/bitbucket.svg";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export const BitbucketIntegrationCompleteContent = () => {
  return (
    <Box>
      <Stack direction="row" sx={{ alignItems: "flex-end", gap: "18.9px" }}>
        <Typography variant="h6">Integration Complete!</Typography>
      </Stack>
    </Box>
  );
};

export const BitbucketIntegrationCompleteConfig = {
  logo: bitbucketLogo,
  headerTitle: "Bitbucket Integration Complete",
};
