import { AssetPropertyType } from "./data";

export const defaultColumns = [
  { checked: false, name: "account", source: AssetPropertyType.PLATFORM },
  { checked: false, name: "region", source: AssetPropertyType.PLATFORM },
  { checked: false, name: "deployment", source: AssetPropertyType.PLATFORM },
  { checked: false, name: "category", source: AssetPropertyType.PLATFORM },
  {
    checked: false,
    name: "operational_owner",
    source: AssetPropertyType.PLATFORM,
  },
  {
    checked: false,
    name: "business_owner",
    source: AssetPropertyType.PLATFORM,
  },
  {
    checked: false,
    name: "business_process",
    source: AssetPropertyType.PLATFORM,
  },
];
