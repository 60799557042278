import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { AppFeatures } from "../../core/features";
import { useUserInfo } from "../../hooks/useAppUser";

export const AppEntry = () => {
  const { privileges } = useUserInfo();

  if (privileges[AppFeatures.OVERVIEW]) return <Redirect to='/overview' />;
  return <Redirect to='/inventory' />;
};
