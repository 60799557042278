import { createRef, Fragment, useRef, useState } from "react";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { DataGroupingMenu } from "../../../../../components/Grouping/components/Menu";
import { AssetInventoryTableFixedColumns } from "../../../../../core/asset-inventory/data";
import { AssetInventoryValidators } from "../../../../../core/asset-inventory/utils/AssetInventoryDataValidator";
import { InventoryTableActionButton } from "../../../../../ui/pages/inventory/components/ActionButton";
import { usePageData } from "../../hooks/usePageData";

const SelectColumnsInventorySelector = createRef();

export const SelectColumns = ({ selectedValues, setSelectedValues }) => {
  const { assetOptionsMap } = usePageData();

  const menuContainerRef = useRef(null);
  const [selectorOpened, setSelectorOpened] = useState(false);

  const handleCloseMenu = () => setSelectorOpened(false);

  const handleOpenSelector = () => {
    menuContainerRef.current.onOpenMenu?.();
    setSelectorOpened(true);
  };

  const optionFilter = (option) => {
    return !AssetInventoryTableFixedColumns.find(
      (col) => col.value === option.value && option.source === col.source
    );
  };

  const onSelectValue = (values) => {
    const isWithinLimit =
      AssetInventoryValidators.validateSelectedColumnsAgainstMaxLimit(
        values.length
      );
    if (isWithinLimit) {
      setSelectedValues(values);
    } else {
      // show maximum column limit exceed warning
    }
  };

  return (
    <Fragment>
      <InventoryTableActionButton
        ref={SelectColumnsInventorySelector}
        actionCount={selectedValues.length + 3}
        tooltipProps={{
          show: true,
          title: "Select Columns",
        }}
        onClick={handleOpenSelector}
        icon={<ViewColumnIcon />}
      />
      <DataGroupingMenu
        title='Inventory Table Column'
        optionFilter={optionFilter}
        bindings={menuContainerRef}
        data={assetOptionsMap}
        handleCloseMenu={handleCloseMenu}
        menuOpened={selectorOpened}
        onSelectValue={onSelectValue}
        onClearGrouping={handleCloseMenu}
        selectOption={null}
        value={selectedValues}
        setValue={onSelectValue}
        viewConfig={{ showCurrentGroupings: true }}
        anchorEl={SelectColumnsInventorySelector.current}
      />
    </Fragment>
  );
};
