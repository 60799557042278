import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

const getColor = (theme, severity) => {
  switch (severity) {
    case "error":
      return {
        primary: theme.palette.error.light,
        bar: theme.palette.error.main,
      };
    case "warning":
      return {
        primary: theme.palette.warning.light,
        bar: theme.palette.warning.main,
      };
    case "success":
      return {
        primary: theme.palette.success.light,
        bar: theme.palette.success.main,
      };
    default:
      return {
        primary: theme.palette.error.light,
        bar: theme.palette.error.main,
      };
    // return {
    //     primary: theme.palette.grey[300],
    //     bar: theme.palette.grey[500],
    // };
  }
};

const DynamicColorLinearProgress = styled(LinearProgress)(({
  theme,
  severity,
}) => {
  const colors = getColor(theme, severity);
  return {
    height: "4px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.primary,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: colors.bar,
    },
  };
});

function BannerAlert({ message, topOffset, severity = "error" }) {
  const [open, setOpen] = useState(true);
  const duration = 10000;
  const [progress, setProgress] = useState(100);
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const delta = 100 / (duration / 100);
        return Math.max(prevProgress - delta, 0); // Ensure progress does not go below 0
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [duration]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      style={{ top: topOffset }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        action={action}
        sx={{
          width: "100%",
          maxWidth: 400,
          margin: "auto",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          minWidth={300}
          maxWidth={300}
          margin="auto"
        >
          <Box
            style={{
              wordWrap: "break-word",
              overflow: "hidden",
              whiteSpace: "normal",
              textAlign: "left",
            }}
          >
            {message}
          </Box>
          <DynamicColorLinearProgress
            severity={severity}
            variant="determinate"
            value={progress}
          />
        </Box>
      </Alert>
    </Snackbar>
  );
}

export default BannerAlert;
