import { styled } from "@mui/material";

export const DataCountContainer = styled("div")(() => {
  return `
  border-radius: 100px;
  border: 1px solid #8F9699;
  padding: 0 4px;
  box-sizing: border-box;
  color: #8F9699;
  min-width: 28px;
  `;
});
