import { SET_ONBOARDING_STEP } from "../actions/actionTypes";

const initialState = {
  currentStep: 1,
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONBOARDING_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    default:
      return state;
  }
};

export default onboardingReducer;
