import { debounce } from "@mui/material";
import {
  AssetCommandActionsContainer,
  AssetCommandHeader,
  AssetJSONSection,
  AssetJsonContentSection,
  CopyButton,
  ExpandJSONViewerButton,
} from "./styled";
import JSONViewer from "./JsonViewer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMemo, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { copyToClipboard } from "../../../../utils/browserUtils/copyToClipboard";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { EmptyJson } from "./EmptyJson";

export const AssetCommand = ({ data }) => {
  const COPIED_STATE_DELAY_DURATION = 2500;
  const MARKDOWN_EXPANDED_HEIGHT = "575px";
  const MARKDOWN_DEFAULT_HEIGHT = "175px";

  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState(false);

  const parsedAssetJSON = useMemo(() => {
    return JSON.stringify(data, null, 2);
  }, [data]);

  const viewerHeight = expanded
    ? MARKDOWN_EXPANDED_HEIGHT
    : MARKDOWN_DEFAULT_HEIGHT;
  const viewerModeLabel = expanded ? "Collapse" : "Expand";
  const copyButtonIcon = copied ? <CheckIcon /> : <ContentCopyIcon />;
  const copyButtonLabel = copied ? "Copied to clipboard" : "Copy";

  const expandViewer = () => setExpanded((p) => !p);
  const resetCopiedState = () => setCopied(false);
  const onClickCopy = () => {
    if (copied) return;
    copyToClipboard(parsedAssetJSON);
    setCopied(true);
    debounce(resetCopiedState, COPIED_STATE_DELAY_DURATION)();
  };

  return (
    <AssetJSONSection>
      <AssetCommandHeader>
        <ConditionComponent condition={!!data}>
          <AssetCommandActionsContainer>
            <CopyButton onClick={onClickCopy}>
              {copyButtonLabel}
              {copyButtonIcon}
            </CopyButton>
          </AssetCommandActionsContainer>
        </ConditionComponent>
      </AssetCommandHeader>
      <ConditionComponent condition={!!data} falsyNode={<EmptyJson />}>
        <AssetJsonContentSection expand={expanded}>
          <JSONViewer height={viewerHeight} value={parsedAssetJSON} />
          <ExpandJSONViewerButton onClick={expandViewer} variant='outlined'>
            {viewerModeLabel}
          </ExpandJSONViewerButton>
        </AssetJsonContentSection>
      </ConditionComponent>
    </AssetJSONSection>
  );
};
