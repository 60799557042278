import { useEffect, useReducer } from "react";
import { SavedQueriesGlobalStateContext } from "./defs";
import { Reducer } from "../stateManagement/reducer";
import { InitialState } from "../stateManagement/initialState";
import { SavedQueryStateActions } from "../stateManagement/actions";
import { InventoryService } from "../../../../services/inventoryService";

export const SavedQueriesProvider = ({ children }) => {
  const [state, updaterFn] = useReducer(Reducer, InitialState);

  const getSavedQueries = async () => {
    try {
      updaterFn({ type: SavedQueryStateActions.queryPending, payload: null });
      const results = await InventoryService.getInstance().getSavedQueries();
      updaterFn({
        type: SavedQueryStateActions.queryCompleted,
        payload: results,
      });
    } catch (error) {
      updaterFn({
        type: SavedQueryStateActions.queryFailed,
        payload: { error },
      });
    }
  };

  useEffect(() => {
    getSavedQueries();
  }, []);

  return (
    <SavedQueriesGlobalStateContext.Provider value={{ state, updaterFn }}>
      {children}
    </SavedQueriesGlobalStateContext.Provider>
  );
};
