import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

export const CMSTDOmainEntry = () => {
  const location = useLocation();
  const isRootPage = location.pathname === "/cmst";

  if (isRootPage) return <Redirect to='/cmst/projects' />;
  return null;
};
