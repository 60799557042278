import { styled } from "@mui/material";

export const GraphTopMenusSection = styled("div")(() => {
  return `
  position: absolute;
  top: 0;
  z-index: 2;
  margin: 12px 24px; 
  width: 100%;
  align-items: center;
  display: flex;
  column-gap: 16px;
  `;
});
