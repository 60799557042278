import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";

const JSONViewer = ({ value, ...props }) => {
  return (
    <CodeMirror
      value={value}
      editable={false}
      extensions={[json()]}
      {...props}
    />
  );
};

export default JSONViewer;
