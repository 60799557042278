import { useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Input } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import styles from "./textinput.module.css";

const TextInput = ({ value, onChange, onSend, isSending }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isSending) {
      onSend();
    }
  };

  return (
    <Box
      className={styles.textInputContainer}
      sx={{ p: { xs: "0px 10px", md: "5px 20px" } }}
    >
      <Input
        ref={inputRef}
        type="text"
        multiline
        maxRows={4}
        disableUnderline
        className={styles.textInput}
        placeholder="Ask PandoGPT…"
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        disabled={isSending}
      />
      {isSending ? (
        <div className={`${styles.sendButton} ${styles.loadingIcon}`}>
          <CircularProgress sx={{ color: "inherit" }} size={20} />
        </div>
      ) : (
        <button
          className={styles.sendButton}
          onClick={onSend}
          disabled={!value || isSending}
        >
          <FontAwesomeIcon icon={faArrowUp} className={styles.sendBtnIcon} />
        </button>
      )}
    </Box>
  );
};

export default TextInput;
