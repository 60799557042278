import { useEffect, useReducer } from "react";
import { GlobalStateMethodsContext, GlobalStateContext } from "./defs";
import { Reducer } from "../stateManagement/reducer";
import { InitialState } from "../stateManagement/initialState";
import { Actions } from "../stateManagement/actions";
import { InventoryService } from "../../../../services/inventoryService";

export const Provider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const fetchData = async (payload) => {
    try {
      dispatch({
        type: Actions.FETCH_QUERIES_PENDING,
      });
      const results = await InventoryService.getInstance().getActions(payload);
      dispatch({
        type: Actions.FETCH_QUERIES_SUCCESS,
        payload: results,
      });
    } catch (error) {
      dispatch({
        type: Actions.FETCH_QUERIES_ERROR,
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    const initialConfig = {
      pagination: state.paginationConfig,
    };
    fetchData(initialConfig);
  }, []);

  return (
    <GlobalStateMethodsContext.Provider value={{ dispatch, fetchData }}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalStateMethodsContext.Provider>
  );
};
