export const InitialState = {
  assetOptions: {
    data: {},
    apiConfig: {
      lastQueryTime: 0,
    },
  },
  pageMeta: {
    isLoading: false,
    error: false,
    errorPayload: null,
  },
};
