import React, { useEffect, useRef, useState } from "react";
import registerNode from "./Node";
import registerEventHandlers from "./EventHandler";
import getLayout from "./Layout";
import createPlugins from "./plugins/Plugins";
import ConditionComponent from "../../utils/ConditionComponent";
import { EmptyGraph } from "./EmptyGraph";
import { createG6Graph } from "./createGraphFactory";
import AssetDetailDrawer from "../../pages/inventory/components/AssetDetailDrawer";

const G6GraphComponent = ({
  data,
  labelProp,
  layoutType,
  plugins: pluginNames,
  showSidebar,
}) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [filteredData, setFilteredData] = useState({}); // <-- Add this state variable

  const containerRef = useRef();

  const closeSidebar = () => setSelectedNode(null);

  const graphDataIsEmpty = !filteredData.nodes || !filteredData.nodes?.length;

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    const noFilteredData =
      !filteredData || !filteredData.nodes || !filteredData.edges;
    const noGraphRef = !containerRef.current;

    if (noFilteredData || noGraphRef) return;
    registerNode(labelProp);

    const width = containerRef.current.scrollWidth;
    const height = containerRef.current.scrollHeight || 500;

    const layout = getLayout(layoutType);
    const plugins = createPlugins(pluginNames);
    const graph = createG6Graph({
      container: containerRef.current,
      width,
      height,
      plugins,
      layout,
      onLayoutEnd() {
        graph.refreshPositions();
      },
    });
    graph.edge((edge) => {
      return {
        id: edge.id,
        size: 1,
      };
    });

    graph.data(filteredData);

    registerEventHandlers(graph, setSelectedNode);
    graph.data(filteredData);
    graph.render();
    graph.fitView();

    return () => graph.destroy();
  }, [filteredData, labelProp, layoutType]);

  return (
    <div
      ref={containerRef}
      style={{ position: "relative", width: "100%", height: "100%" }}
    >
      <ConditionComponent condition={graphDataIsEmpty}>
        <EmptyGraph />
      </ConditionComponent>

      <ConditionComponent condition={showSidebar}>
        <AssetDetailDrawer
          open={!!selectedNode}
          toggleDrawer={closeSidebar}
          data={selectedNode}
        />
      </ConditionComponent>
    </div>
  );
};

export default G6GraphComponent;
