const initialState = {
  notification: {
    open: false,
    props: {
      hideDuration: 3000,
    },
  },
};

export { initialState };
