import "reactflow/dist/style.css";
import {
  Controls,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "reactflow";
import dagre from "dagre";
import CustomNode from "../components/BusinessFlow/CustomNode";
import React, { useCallback } from "react";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

// Any logic to dynamically update nodes or edges would go here
const nodeTypes = {
  myCustomNode: CustomNode,
};

const data = [
  {
    id: "client1",
    name: "Client",
    hasBadge: "New",
    icon: "http",
    vulnerabilities: [
      {
        name: "Cross-Site Scripting (XSS)",
        description:
          "Client applications may be vulnerable to XSS attacks, which can allow attackers to inject malicious scripts into the application and potentially steal sensitive data or perform unauthorized actions.",
      },
      {
        name: "Cross-Site Request Forgery (CSRF)",
        description:
          "Lack of proper CSRF protection can allow attackers to perform unauthorized actions on behalf of an authenticated user.",
      },
    ],
    owner: "Front-end Team",
    description:
      "The client application is a web-based user interface that allows customers to access and manage their banking accounts, perform transactions, and interact with various services provided by the bank.",
  },
  {
    id: "client2",
    name: "Client",
    icon: "http",
    vulnerabilities: [
      {
        name: "Cross-Site Scripting (XSS)",
        description:
          "Client applications may be vulnerable to XSS attacks, which can allow attackers to inject malicious scripts into the application and potentially steal sensitive data or perform unauthorized actions.",
      },
      {
        name: "Cross-Site Request Forgery (CSRF)",
        description:
          "Lack of proper CSRF protection can allow attackers to perform unauthorized actions on behalf of an authenticated user.",
      },
    ],
    owner: "Front-end Team",
    description:
      "This is a mobile client application that provides customers with on-the-go access to their banking accounts and services.",
  },
  {
    id: "api_gateway",
    name: "API Gateway",
    icon: "security-waf",
    vulnerabilities: [
      {
        name: "Broken Authentication",
        description:
          "Improperly implemented authentication mechanisms in the API Gateway can lead to unauthorized access, data breaches, and other security issues.",
      },
      {
        name: "Injection Flaws",
        description:
          "Improper input validation and sanitization in the API Gateway can lead to various injection attacks, such as SQL injection, NoSQL injection, and command injection.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The API Gateway may expose sensitive data or expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "API Team",
    description:
      "The API Gateway acts as a single entry point for all client applications, routing requests to the appropriate backend services and providing security controls, rate limiting, and other functionalities.",
  },
  {
    id: "account_mgmt_service",
    name: "Account Management Service",
    icon: "amazon-ecs",
    vulnerabilities: [
      {
        name: "Insecure Deserialization",
        description:
          "Insecure deserialization of data in the application can lead to code execution, data tampering, and other security issues.",
      },
      {
        name: "Broken Authentication",
        description:
          "Improperly implemented authentication mechanisms can lead to unauthorized access, data breaches, and other security issues.",
      },
    ],
    owner: "Accounts Team",
    description:
      "The Account Management Service handles the creation, maintenance, and management of customer accounts. It is responsible for tasks such as account opening, updating customer information, and managing account access and permissions.",
  },
  {
    id: "transaction_service",
    name: "Transaction Service",
    hasBadge: "New",
    icon: "amazon-ecs",
    vulnerabilities: [
      {
        name: "Sensitive Data Exposure",
        description:
          "Inadequate protection of sensitive transaction data can lead to data breaches and unauthorized access.",
      },
    ],
    owner: "Transactions Team",
    description:
      "The Transaction Service is a new component introduced to handle all financial transactions, including deposits, withdrawals, transfers, and payments. It processes and records transaction data while ensuring data integrity and compliance with banking regulations.",
  },
  {
    id: "loan_service",
    name: "Loan Service",
    icon: "amazon-ecs",
    vulnerabilities: [
      {
        name: "Broken Access Control",
        description:
          "Improperly implemented access controls in the application can lead to unauthorized access to sensitive data or functionality.",
      },
      {
        name: "Insecure Cryptographic Storage",
        description:
          "Improper storage and management of cryptographic keys and sensitive data can lead to data breaches and unauthorized access.",
      },
    ],
    owner: "Loans Team",
    description:
      "The Loan Service manages the entire loan lifecycle, from application processing and approval to disbursement and repayment tracking. It handles various loan products, such as mortgages, personal loans, and business loans.",
  },
  {
    id: "credit_card_service",
    name: "Credit Card Service",
    icon: "amazon-ecs",
    vulnerabilities: [
      {
        name: "Sensitive Data Exposure",
        description:
          "Inadequate protection of sensitive credit card data can lead to data breaches and unauthorized access.",
      },
    ],
    owner: "Credit Cards Team",
    description:
      "The Credit Card Service manages the bank's credit card offerings, including application processing, card issuance, transaction processing, and fraud monitoring. It handles sensitive credit card data and must comply with strict security requirements.",
  },
  {
    id: "investment_service",
    name: "Investment Service",
    icon: "amazon-ecs",
    vulnerabilities: [
      {
        name: "Sensitive Data Exposure",
        description:
          "Inadequate protection of sensitive financial information or personal data can lead to data breaches and unauthorized access.",
      },
      {
        name: "Insecure Cryptographic Storage",
        description:
          "Improper storage and management of cryptographic keys and sensitive data can lead to data breaches and unauthorized access.",
      },
    ],
    owner: "Investments Team",
    description:
      "The Investment Service provides customers with access to various investment products, such as stocks, bonds, mutual funds, and retirement accounts. It handles portfolio management, trade execution, and reporting.",
  },
  {
    id: "customer_support_service",
    name: "Customer Support Service",
    icon: "amazon-binstalk",
    owner: "Support Team",
    description:
      "The Customer Support Service handles customer inquiries, requests, and complaints through various channels, such as phone, email, and chat. It provides assistance with account-related issues, product information, and general banking services.",
  },
  {
    id: "fraud_detection_service",
    name: "Fraud Detection Service",
    hasBadge: "New",
    icon: "amazon-binstalk",
    vulnerabilities: [
      {
        name: "Insecure Design",
        description:
          "Flaws in the design and architecture of the fraud detection system can lead to false negatives, allowing fraudulent activities to go undetected.",
      },
    ],
    owner: "Security Team",
    description:
      "The Fraud Detection Service is a new component introduced to enhance the bank's security measures. It leverages advanced algorithms and machine learning models to detect and prevent fraudulent activities, such as unauthorized transactions, account takeovers, and identity theft.",
  },
  {
    id: "notification_service",
    name: "Notification Service",
    icon: "amazon-lambda",
    owner: "Notifications Team",
    description:
      "The Notification Service is responsible for delivering timely and relevant notifications to customers regarding their accounts, transactions, and other banking events. It supports various notification channels, including email, SMS, and push notifications.",
  },
  {
    id: "reporting_service",
    name: "Reporting Service",
    icon: "amazon-lambda",
    owner: "Reporting Team",
    description:
      "The Reporting Service generates various reports and analytics for internal and external stakeholders, such as financial statements, regulatory reports, and customer activity reports. It aggregates data from multiple sources and provides powerful data visualization and analysis capabilities.",
  },
  {
    id: "transactions_db",
    name: "Transactions Database",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Transactions Database stores all financial transaction data, including deposits, withdrawals, transfers, and payments. It maintains a complete audit trail and history of customer transactions, which is crucial for reporting, auditing, and compliance purposes.",
  },
  {
    id: "accounts_db",
    name: "Accounts Database",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Accounts Database stores customer account information, including personal details, account types, balances, and transaction histories. It is a critical component that supports various banking services and operations.",
  },
  {
    id: "loans_db",
    name: "Loans Database",
    hasBadge: "New",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Loans Database is a new database introduced to store and manage data related to the bank's various loan products, including loan applications, approvals, terms, and repayment schedules. It supports the Loan Service and provides a centralized repository for loan-related information.",
  },
  {
    id: "credit_cards_db",
    name: "Credit Cards Database",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Credit Cards Database stores sensitive information related to the bank's credit card offerings, including cardholder data, credit limits, transaction histories, and fraud monitoring data. It supports the Credit Card Service and must comply with strict security requirements.",
  },
  {
    id: "investments_db",
    name: "Investments Database",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Investments Database stores customer investment portfolios, trade histories, and related financial data. It supports the Investment Service and plays a crucial role in managing the bank's investment products and offerings.",
  },
  {
    id: "fraud_detection_db",
    name: "Fraud Detection Database",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Fraud Detection Database stores data related to fraudulent activities, patterns, and models used by the Fraud Detection Service. It plays a critical role in detecting and preventing fraudulent transactions and account takeovers.",
  },
  {
    id: "notifications_db",
    name: "Notifications Database",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Notifications Database stores customer notification preferences, delivery channels, and histories. It supports the Notification Service and ensures that customers receive timely and relevant updates about their accounts and banking activities.",
  },
  {
    id: "reports_db",
    name: "Reports Database",
    icon: "amazon-rds",
    vulnerabilities: [
      {
        name: "SQL Injection",
        description:
          "Improper input validation and sanitization in database queries can lead to SQL injection attacks, potentially allowing attackers to access or modify sensitive data.",
      },
      {
        name: "Excessive Data Exposure",
        description:
          "The database may expose more data than necessary, potentially leading to data breaches or unauthorized access.",
      },
    ],
    owner: "Database Team",
    description:
      "The Reports Database stores generated reports, data marts, and analytical data used by the Reporting Service. It serves as a centralized repository for reporting and analytics, supporting various stakeholders within the bank.",
  },
  {
    id: "redis_cache",
    name: "Redis Cache",
    icon: "amazon-elasticache",
    vulnerabilities: [
      {
        name: "Insecure Configuration",
        description:
          "Improper configuration of Redis Cache, such as lack of authentication or insecure permissions, can lead to unauthorized access and potential data breaches.",
      },
    ],
    owner: "Caching Team",
    description:
      "The Redis Cache is a high-performance, in-memory data store used to improve the performance and scalability of the bank's applications. It caches frequently accessed data, reducing the load on databases and improving response times for customer-facing services.",
  },
  {
    id: "rabbitmq",
    name: "RabbitMQ",
    hasBadge: "New",
    icon: "rabbitmq",
    vulnerabilities: [
      {
        name: "Insecure Configuration",
        description:
          "Improper configuration of RabbitMQ, such as default credentials or insecure permissions, can lead to unauthorized access and potential data breaches.",
      },
      {
        name: "Denial of Service",
        description:
          "RabbitMQ may be vulnerable to Denial of Service (DoS) attacks, which can disrupt the messaging system and impact the availability of services.",
      },
    ],
    owner: "Messaging Team",
    description:
      "RabbitMQ is a new component introduced to facilitate asynchronous messaging and event-driven communication between various services and applications within the bank's infrastructure. It ensures reliable and scalable message delivery, enabling loose coupling and improved resilience.",
  },
];

const initialEdges = [
  {
    id: "client1-api_gateway",
    source: "client1",
    target: "api_gateway",
    label: "HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
    animated: true,
  },
  {
    id: "client2-api_gateway",
    source: "client2",
    target: "api_gateway",
    label: "HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "api_gateway-service_mesh",
    source: "api_gateway",
    target: "service_mesh",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "service_mesh-api_gateway",
    source: "service_mesh",
    target: "api_gateway",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "api_gateway-account_mgmt_service",
    source: "api_gateway",
    target: "account_mgmt_service",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "api_gateway-transaction_service",
    source: "api_gateway",
    target: "transaction_service",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "api_gateway-loan_service",
    source: "api_gateway",
    target: "loan_service",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "api_gateway-credit_card_service",
    source: "api_gateway",
    target: "credit_card_service",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "api_gateway-investment_service",
    source: "api_gateway",
    target: "investment_service",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "api_gateway-customer_support_service",
    source: "api_gateway",
    target: "customer_support_service",
    label: "HTTP/HTTPS",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "account_mgmt_service-accounts_db",
    source: "account_mgmt_service",
    target: "accounts_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "account_mgmt_service-redis_cache",
    source: "account_mgmt_service",
    target: "redis_cache",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "transaction_service-transactions_db",
    source: "transaction_service",
    target: "transactions_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "transaction_service-redis_cache",
    source: "transaction_service",
    target: "redis_cache",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "transaction_service-rabbitmq",
    source: "transaction_service",
    target: "rabbitmq",
    label: "Publish",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "loan_service-loans_db",
    source: "loan_service",
    target: "loans_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "loan_service-redis_cache",
    source: "loan_service",
    target: "redis_cache",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "credit_card_service-credit_cards_db",
    source: "credit_card_service",
    target: "credit_cards_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "credit_card_service-redis_cache",
    source: "credit_card_service",
    target: "redis_cache",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "investment_service-investments_db",
    source: "investment_service",
    target: "investments_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "investment_service-redis_cache",
    source: "investment_service",
    target: "redis_cache",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "rabbitmq-fraud_detection_service",
    source: "rabbitmq",
    target: "fraud_detection_service",
    label: "Consume",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "rabbitmq-notification_service",
    source: "rabbitmq",
    target: "notification_service",
    label: "Consume",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "rabbitmq-reporting_service",
    source: "rabbitmq",
    target: "reporting_service",
    label: "Consume",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "fraud_detection_service-fraud_detection_db",
    source: "fraud_detection_service",
    target: "fraud_detection_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "notification_service-notifications_db",
    source: "notification_service",
    target: "notifications_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
  {
    id: "reporting_service-reports_db",
    source: "reporting_service",
    target: "reports_db",
    label: "Read/Write",
    markerEnd: { type: "arrowclosed", color: "#090808" },
  },
];

function getNodeFromName(name) {
  return data.find((node) => node.name === name);
}

const connectionLineStyle = { stroke: "#090808" };
//
// const initialNodes = data.map((node, index) => {
//     console.log(node.id);
//     return node
//     return {
//         id: node.id,
//         type: node.type,
//         data: {label: node.name},
//         position: {x: 190 * index * 2, y: 250},
//         sourcePosition: node.sourcePosition || 'right',
//         targetPosition: node.targetPosition || 'left',
//     }
// })

const initialNodes = data.map((node, index) => ({
  id: node.id,
  type: node.type || "myCustomNode", // Use the custom node type
  data: {
    label: node.name,
    badge: node.hasBadge ? "New" : undefined, // Set badge value or undefined
    icon: node.icon || "generic-server", // Set icon value or default to generic-server
    vulnerabilities: node.vulnerabilities || [], // Set vulnerabilities value or empty array
    description: node.description,
    owner: node.owner,
  },
}));

const applyDagreLayout = (nodes, edges, nodeWidth = 172, nodeHeight = 100) => {
  const g = new dagre.graphlib.Graph();
  g.setGraph({ rankdir: "TB" }); // LR for left-to-right graph
  g.setDefaultEdgeLabel(() => ({}));

  // Add nodes to the graph
  nodes.forEach((node) => {
    g.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  // Add edges to the graph
  edges.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  // Calculate the layout (synchronous)
  dagre.layout(g);

  // Apply the calculated positions
  return nodes.map((node) => {
    const nodeWithPosition = g.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });
};

function createEdge(sourceName, targetName) {
  const source = getNodeFromName(sourceName);
  const target = getNodeFromName(targetName);
  return {
    id: `e${source.id}-${target.id}`,
    source: source.id,
    target: target.id,
    markerEnd: { type: "arrowclosed", color: "#090808" },
  };
}

const BusinessFlowsPage = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(
    applyDagreLayout(initialNodes, initialEdges),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [selectedNode, setSelectedNode] = React.useState(null);

  const handleClose = () => {
    setIsSidebarOpen(false);
    setSelectedNode(null);
  };

  // Remove these lines
  const onNodeClick = useCallback((event, node) => {
    console.log("click", node);
    setSelectedNode(node);
    setIsSidebarOpen(true);
  }, []);

  return (
    <div style={{ height: "95vh" }}>
      <Drawer
        style={{ zIndex: 999 }}
        anchor="right"
        open={isSidebarOpen}
        onClose={handleClose}
      >
        <List style={{ minWidth: "400px", width: "40vw" }}>
          <ListItemButton button onClick={handleClose}>
            <ListItemText primary="Close" />
          </ListItemButton>
          {selectedNode && (
            <>
              <ListItem>
                <Typography variant="h6">Information</Typography>
              </ListItem>
              <ListItem>
                <ListItemText primary={`Name: ${selectedNode.data.label}`} />
                <br />
                {/* Add more information details here */}
              </ListItem>
              <Divider />
              <br />
              <ListItem style={{ marginBottom: 10 }}>
                <Typography variant="h6">Business Description</Typography>
              </ListItem>
              <br />
              <ListItem style={{ marginBottom: 10 }}>
                <ListItemText primary={selectedNode.data.description} />
              </ListItem>
              <br />
              <br />
              <Divider />
              <br />
              <ListItem>
                <Typography variant="h6">Security Issues</Typography>
              </ListItem>

              {selectedNode.data.vulnerabilities &&
              selectedNode.data.vulnerabilities.length > 0 ? (
                selectedNode.data.vulnerabilities.map(
                  (vulnerability, index) => (
                    <>
                      <ListItem key={index}>
                        <ListItemText primary={vulnerability.name} />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          style={{
                            color: "gray",
                          }}
                          primary={vulnerability.description}
                        />
                      </ListItem>
                    </>
                  ),
                )
              ) : (
                <ListItem>
                  <ListItemText primary="No security issues" />
                </ListItem>
              )}
            </>
          )}
        </List>
      </Drawer>
      <h1>Swap Flow</h1>
      <ReactFlow
        onNodeClick={onNodeClick}
        onNodesChange={onNodesChange}
        connectionLineStyle={connectionLineStyle}
        nodes={nodes}
        edges={edges}
        fitView
        nodeTypes={nodeTypes}
      >
        <MiniMap />
        <Controls />
      </ReactFlow>
    </div>
  );
};
export default BusinessFlowsPage;
