import { GroupingItem } from "./GroupingItem";
import {
  GroupingSectionContainer,
  GroupsSectionContent,
  SectionHeading,
} from "./styled";
import { useAssetsTableMethods } from "../../hooks/useAssetsTableMethods";
import { useCallback, useMemo } from "react";
import { usePageData } from "../../hooks/usePageData";
import { checkValueExists } from "../../../../../utils/helperMethods/type-guards";
import { useAssetInventoryActionDispatch } from "../../hooks/useAssetInventoryActionDispatch";
import { ActionType } from "../../state-management/actions";
import { useGroupingTableState } from "../../hooks/useGroupingTableState";

export const GroupingSection = () => {
  const dispatch = useAssetInventoryActionDispatch();

  const {
    assetOptionsMap,
    grouping,
    currentGroupingValue,
    filters,
    searchKeyword,
  } = usePageData();
  const { paginationConfig } = useGroupingTableState();

  const { fetchGroupingTableData } = useAssetsTableMethods();

  const selectedGroup = grouping[0];

  /**
   * @description when user selects a group by, select first option (since only one option is allowed).
   * dataGroupOptions is a key-value object with tags or properties prefix.
   */
  const selectedGroupingPropertyOptions = useMemo(() => {
    if (grouping.length) {
      const { value, source } = selectedGroup;
      const options = assetOptionsMap[source]?.[value]?.values ?? [];
      const results = [];
      options.forEach((option) => {
        if (checkValueExists(option)) results.push(String(option));
      });
      return results;
    }
    return [];
  }, [assetOptionsMap, grouping]);

  const heading = useMemo(() => {
    if (!selectedGroup) return "";
    return selectedGroup.label;
  }, [selectedGroup]);

  const handleOnClickGroupItem = (ev, value) => {
    dispatch({
      type: ActionType.SET_CURRENT_GROUPING_VALUE,
      payload: value,
    });
    const payload = {
      page: paginationConfig.pageIndex,
      limit: paginationConfig.pageSize,
      filters,
      group: value,
      searchKeyword,
    };

    fetchGroupingTableData(undefined, payload);
  };

  const renderTypes = useCallback(() => {
    return selectedGroupingPropertyOptions.map((value, index) => {
      const selected = value === currentGroupingValue?.value;
      return (
        <GroupingItem
          key={`data-group-${index}`}
          selected={selected}
          onClick={(ev) =>
            handleOnClickGroupItem(ev, { value, group: selectedGroup })
          }
        >
          {value}
        </GroupingItem>
      );
    });
  }, [currentGroupingValue, selectedGroupingPropertyOptions, grouping]);

  return (
    <GroupingSectionContainer>
      <SectionHeading>{heading}</SectionHeading>
      <GroupsSectionContent>{renderTypes()}</GroupsSectionContent>
    </GroupingSectionContainer>
  );
};
