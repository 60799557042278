import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EnterButton, Input, SearchInputRoot } from "./styled";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { forwardRef } from "react";

export const SearchInput = forwardRef(
  ({ onChange, value, onClickEnter, ...props }, ref) => {
    return (
      <SearchInputRoot ref={ref}>
        <FontAwesomeIcon icon={faMagnifyingGlass} color='#002733' />
        <Input
          onChange={(ev) => onChange(ev)}
          value={value}
          disableUnderline
          {...props}
        />
        <EnterButton onClick={onClickEnter}>
          <SubdirectoryArrowRightIcon />
        </EnterButton>
      </SearchInputRoot>
    );
  }
);
