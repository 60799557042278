import { filtersToColumnAdapter } from "../../../../containers/inventory/AssetsTable/utils/addSelectedFieldsToColumns";
import { AssetInventoryTableFixedColumns } from "../../../../core/asset-inventory/data";
import { createAssetOptionId } from "../../../../core/asset-management/createAssetOptionId";
import { Actions } from "../stateManagement/actions";
import { useColumnsState } from "./useColumnsState";
import { useFetchGroupingTableData } from "./useFetchGrouingTableData";
import { useFetchPrimaryTableData } from "./useFetchPrimaryTableData";
import { useIsGrouping } from "./useIsGrouping";
import { useStateDispatch } from "./useStateDispatch";

export function useApplyFilters() {
  const dispatch = useStateDispatch();
  const fetchPrimaryTableData = useFetchPrimaryTableData();
  const fetchGroupingTableData = useFetchGroupingTableData();
  const isGrouping = useIsGrouping();
  const [columns, setColumns] = useColumnsState();

  const filterFn = (filter) => {
    const id = createAssetOptionId(filter.field, "options");

    const isFixedColumnField = AssetInventoryTableFixedColumns.find(
      (col) => col.id === id
    );

    return !!isFixedColumnField;
  };

  const addSelectedFieldsToColumns = (filters) => {
    const updatedColumns = filtersToColumnAdapter(filters, columns, filterFn);
    setColumns(updatedColumns);
  };

  const func = (payload) => {
    addSelectedFieldsToColumns(payload);

    if (isGrouping) {
      fetchGroupingTableData({ filters: payload });
    } else {
      fetchPrimaryTableData({ filters: payload });
    }
    dispatch({
      type: Actions.SET_FILTERS,
      payload,
    });
  };

  return func;
}
