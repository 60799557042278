import {
  Header,
  Heading,
  ListRoot,
  Root,
  SectionIcon,
  SubHeading,
  TextSection,
} from "./styled";
import { ResultColumn } from "./ResultColumn";
import { Fragment, useMemo, useState } from "react";
import { useSearchResultsFieldColumns } from "../../../../hooks/useSearchResultsFieldColumns";
import { AssetInventoryValidators } from "../../../../../../../core/asset-inventory/utils/AssetInventoryDataValidator";
import { AssetTableAction } from "../ActionButton";
import {
  SearchComponentRef,
  SearchResultsColumnsSelector,
} from "../../../../refs";
import { SearchResultsColumnsButton } from "../../../../../../pages/inventory/components/SearchColumnsButton";
import ConditionComponent from "../../../../../../../utils/ConditionComponent";
import { EmptyDataSection } from "../../../../../../../components/EmptyData";
import { useColumnsState } from "../../../../hooks/useColumnsState";

export const SearchResultColumnSelector = ({ onClose, ...props }) => {
  const [open, setOpen] = useState(false);

  const cols = useSearchResultsFieldColumns();
  const [columns, setColumns] = useColumnsState();

  const checkedColumnCount = useMemo(() => {
    return cols.filter((col) => col.checked).length;
  }, [cols]);

  const removeFromCheckedCols = (data) => {
    const newCols = columns.filter((col) => col.id !== data.id);
    setColumns(newCols);
  };

  const addToCheckedCols = (col) => {
    setColumns([...columns, col]);
  };

  const handleOnChangeColumn = (_, data) => {
    const isWithinLimit =
      AssetInventoryValidators.validateSelectedColumnsAgainstMaxLimit(
        columns.length + 1
      );
    if (data.checked) {
      removeFromCheckedCols(data);
    } else if (isWithinLimit) {
      addToCheckedCols(data);
    }
  };

  const columnNodes = useMemo(() => {
    return cols.map((col) => (
      <ResultColumn onChange={handleOnChangeColumn} data={col} key={col.id} />
    ));
  }, [cols]);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleOnClick = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <AssetTableAction
        ref={SearchResultsColumnsSelector}
        actionCount={checkedColumnCount}
        sx={{ width: "auto" }}
        onClick={handleOnClick}
        tooltipProps={{
          show: true,
          title: "Search results columns",
        }}
        icon={<SearchResultsColumnsButton />}
      />
      <Root
        onClose={handleClose}
        anchorEl={SearchComponentRef.current}
        open={open}
        {...props}
      >
        <Header>
          <SectionIcon />
          <TextSection>
            <Heading>Fields containing search keyword</Heading>
            <SubHeading>Select to add to table column</SubHeading>
          </TextSection>
        </Header>
        <ConditionComponent
          condition={columnNodes.length}
          falsyNode={<EmptyDataSection label={"No Columns found"} />}
        >
          <ListRoot className='scrollable'>{columnNodes}</ListRoot>
        </ConditionComponent>
      </Root>
    </Fragment>
  );
};
