import { ConnectorPlatformsConfiguration } from "../data";

export function transformAdaptersToConnectors(adapters) {
  return adapters.map(applyTransforms);
}

function applyTransforms(adapter) {
  const connectorPlatform = ConnectorPlatformsConfiguration[adapter.source];
  if (connectorPlatform) {
    adapter.logo = connectorPlatform.logo;
  }
  adapter.name = adapter.config_json.name;
  return adapter;
}
