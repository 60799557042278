import React from "react";
import { Button } from "@mui/material";
import styles from "./ClearChatButton.module.css";

const ClearChatButton = (props) => (
  <Button
    {...props}
    aria-label="clear chat"
    className={styles.button}
    disableRipple
  >
    Reset
  </Button>
);

export default ClearChatButton;
