import G6 from "@antv/g6";

const registerNode = (labelProp) => {
  G6.registerNode("icon-node", {
    draw(cfg, group) {
      let { iconURL, size } = cfg;
      let label = cfg[labelProp]; // Using labelProp to get the label text
      if (!size || !Array.isArray(size)) return;

      size = size[0];
      const opacity =
        cfg.style && cfg.style.opacity !== undefined ? cfg.style.opacity : 1;

      const keyShape = group.addShape("image", {
        attrs: {
          x: -size / 2,
          y: -size / 2,
          width: size,
          height: size,
          img: iconURL,
          opacity: opacity,
        },
      });

      // Add text label based on name property
      if (label) {
        group.addShape("text", {
          attrs: {
            x: 0, // Position can be adjusted
            y: size / 2 + 10, // Position can be adjusted
            textAlign: "center",
            textBaseline: "middle",
            text: label,
            fill: `rgba(0, 0, 0, ${opacity})`, // Updated this line to include opacity
          },
        });
      }

      return keyShape;
    },
  });
};

export default registerNode;
