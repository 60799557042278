export function validateFilterGroups(filterGroups) {
  let errorsFound = false;

  const data = filterGroups.map((filterGroup) => {
    const { group, hasError } = validateGroup(filterGroup);
    errorsFound ||= hasError;
    return group;
  });

  return { errorsFound, data };
}

function validateGroup(group) {
  const missingFields = [];
  if (!group.category.value) {
    missingFields.push("Category");
  }
  if (!group.condition.value) {
    missingFields.push("Condition");
  }

  if (!group.values || !group.values.length) {
    missingFields.push("Value (s)");
  }

  const hasError = !!missingFields.length;
  if (hasError) {
    const missingFieldsAsStrings = missingFields.join(", ");
    const conjunction = missingFields.length > 1 ? "are" : "is";
    group.warning = `${missingFieldsAsStrings} ${conjunction} empty. Select value to proceed`;
  } else if (group.warning) {
    group.warning = "";
  }
  return { group, hasError };
}
