import { Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { EmptyGraphContainer } from "./styled";

export const EmptyGraph = () => {
  return (
    <EmptyGraphContainer>
      <FilterAltIcon />
      <Typography variant="body1">No results found for your filter</Typography>
    </EmptyGraphContainer>
  );
};
