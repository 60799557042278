import React from "react";
import { LoadingSection } from "../../../../../components/LayoutFillers";
import { useInitTable } from "../../hooks/useInitTable";
import { TableRoot } from "./Root";

const Component = () => {
  const isLoading = false;
  useInitTable();

  if (isLoading) {
    return <LoadingSection />;
  }

  return <TableRoot />;
};

export const Table = React.memo(Component);
