import { useAuth0 } from "@auth0/auth0-react";
import { CMSTService } from "../../../../services/CMSTService";
import { Actions } from "../stateManagement/actions";
import { useDispatch } from "./useDispatch";
import { useGlobalState } from "./useGlobalState";
import { showAlert } from "../../../../app/alert/showAlert";
import { createActionPayloadTransformer } from "../../../../app/asset-management/createActionPayloadTransformer";
import { InventoryService } from "../../../../services/inventoryService";

export function useSavedQueryAlert() {
  const { showNewAlertView, newAlertPayload } = useGlobalState();
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const openView = (payload) => {
    dispatch({
      type: Actions.OPEN_NEW_ALERT_VIEW,
      payload,
    });
  };

  const closeView = (payload) => {
    dispatch({
      type: Actions.CLOSE_NEW_ALERT_VIEW,
    });
  };

  const saveAlert = async (payload) => {
    try {
      dispatch({
        type: Actions.CREATE_ALERT_PENDING,
      });
      await InventoryService.getInstance().createAction(payload);
      dispatch({
        type: Actions.CREATE_ALERT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: Actions.CREATE_ALERT_ERROR,
        payload: error.payload,
      });
      throw error;
    }
  };

  return {
    ...newAlertPayload,
    openState: showNewAlertView,
    openView,
    closeView,
    saveAlert,
  };
}
