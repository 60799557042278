import React from "react";
import "react-step-progress-bar/styles.css";
import ChatContainer from "../../containers/pandoGPT";
import { Box } from "@mui/material";
import styles from "./styles.module.css";

export default function ChatContainerPage() {
  return (
    <Box className={styles.containerChatPage}>
      <ChatContainer />
    </Box>
  );
}
