import React, { useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Link } from "react-router-dom";
import FeatureService from "../services/feturesService";
import { useAuth0 } from "@auth0/auth0-react";
import Speedometer from "./Speedometer";

function flattenData(data) {
  let combined = [];

  data.forEach((item) => {
    if (item.source) {
      item.source.vulnerability_count = item.source_vul_count; // Add vulnerability count to the source entity
      combined.push(item.source);
    }
    if (item.target) {
      item.target.vulnerability_count = item.target_vul_count; // Add vulnerability count to the target entity
      combined.push(item.target);
    }
  });

  const uniqueCombined = Array.from(new Set(combined.map((c) => c.id))).map(
    (id) => {
      return combined.find((c) => c.id === id);
    },
  );

  return uniqueCombined;
}

function transformData(flattenedData) {
  return flattenedData
    .map((obj) => {
      let {
        BusinessProcess,
        BusinessOwner,
        BusinessOwnerPosition,
        id,
        businessProcess,
        business_process,
      } = obj;

      if (businessProcess && !BusinessProcess) {
        BusinessProcess = businessProcess;
      }
      if (business_process && !BusinessProcess) {
        BusinessProcess = business_process;
      }

      let processNames;
      if (BusinessProcess && BusinessProcess.includes("(")) {
        processNames = BusinessProcess.split("(")[1].split(")")[0].split(", ");
      } else {
        processNames = [BusinessProcess]; // Default to the BusinessProcess name itself if no parentheses are found
      }

      const entitiesCount = id ? 1 : 0; // Adjust this according to your logic
      const securityIssues =
        obj.vulnerability_count || obj.vulnerability_count || 0; // Adjust this according to your logic
      const criticality = "Low"; // Placeholder, adjust this according to your logic

      return processNames.map((processName) => ({
        BusinessProcess: processName,
        BusinessOwner,
        BusinessOwnerPosition,
        Criticality: criticality,
        EntitiesCount: entitiesCount,
        SecurityIssues: securityIssues,
      }));
    })
    .flat();
}

function toTitleCase(str) {
  if (!str) {
    return "";
  }

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function groupByBusinessProcess(data) {
  let criticality = {
    "Multi Factor Transaction Protection": "Extreme",
    "Subscription Management": "Major",
    Buy: "Extreme",
    Swap: "Extreme",
    Web3: "Major",
    "Third Party": "Extreme",
    "Asset Management": "Major",
    "Wallet Backend": "Extreme",
    Logs: "Moderate",
    "Mft Protection": "Extreme",
    Authentication: "Extreme",
    "Content Management": "Minor",
  };

  let risk_score = {
    "Multi Factor Transaction Protection": 9,
    "Subscription Management": 6,
    Buy: 9.5,
    Swap: 9.5,
    Web3: 7,
    "Third Party": 9,
    "Asset Management": 6.5,
    "Wallet Backend": 9,
    Logs: 5.5,
    "Mft Protection": 8,
    Authentication: 8,
    "Content Management": 3,
  };

  return data.reduce((acc, item) => {
    if (!acc[item.BusinessProcess]) {
      acc[item.BusinessProcess] = {
        BusinessProcess: toTitleCase(item.BusinessProcess),
        BusinessOwner: item.BusinessOwner,
        Position: item.BusinessOwnerPosition,
        Criticality: criticality[toTitleCase(item.BusinessProcess)],
        EntitiesCount: 0,
        SecurityIssues: 0,
        risk_score: risk_score[toTitleCase(item.BusinessProcess)],
      };
    }

    acc[item.BusinessProcess].EntitiesCount += item.EntitiesCount;
    acc[item.BusinessProcess].SecurityIssues += item.SecurityIssues;

    return acc;
  }, {});
}

const OverviewTable = ({ title, data }) => {
  const transdata = transformData(flattenData(data));
  const result = groupByBusinessProcess(transdata);
  const [features, setFeatures] = React.useState({});
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      FeatureService.getFeatures(token).then((response) => {
        setFeatures(response);
      }),
    );
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "BusinessProcess",
        header: "Business Process",
        size: 20,
        Cell: ({ row }) => {
          let processName = row.original.BusinessProcess;
          let match = processName.match(/\((.*?)\)/);
          let processSubpart = match ? match[1] : processName; // If there are parentheses, use the part in parentheses
          processSubpart =
            processSubpart.charAt(0).toUpperCase() + processSubpart.slice(1); // Capitalize the first letter
          if (processSubpart === "Swap") {
            return (
              <Link to={`/flows?bp=${encodeURIComponent(processSubpart)}`}>
                {row.original.BusinessProcess}
              </Link>
            );
          } else {
            return (
              <Link to={`/graph?bp=${encodeURIComponent(processSubpart)}`}>
                {row.original.BusinessProcess}
              </Link>
            );
          }
        },
      },
      {
        accessorKey: "BusinessOwner",
        header: "Business Owner",
        size: 20,
      },
      {
        accessorKey: "Position",
        header: "Position",
        size: 20,
      },

      {
        accessorKey: "EntitiesCount",
        header: "Entities count",
        size: 20,
      },
      {
        accessorKey: "SecurityIssues",
        header: " Security Issues",
        size: 50,
      },
      {
        accessorKey: "Criticality",
        header: features.RISK_SCORE
          ? "Pando Risk Score"
          : "Business Criticality",
        size: 20,
        Cell: ({ row }) => {
          if (!row.original.Criticality) {
            return <></>;
          }
          const imgSrc = require(
            "../assets/icons/" + row.original.Criticality + ".svg",
          );
          if (features.RISK_SCORE) {
            return (
              <Speedometer
                fontSize={11}
                size={30}
                value={row.original.risk_score || 5}
              />
            );
          }
          return (
            <div style={{ display: "flex", alignItems: "start" }}>
              <img
                src={imgSrc}
                alt="Criticality"
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
              />
              <span>{row.original.Criticality}</span>
            </div>
          );
        },
      },
    ],
    [features],
  );
  let transformedData = Object.values(result);

  transformedData.sort((a, b) => {
    // If both a and b have empty BusinessProcess, compare SecurityIssues
    if (!a.BusinessProcess && !b.BusinessProcess) {
      return a.SecurityIssues === 0 ? 1 : -1;
    }

    // If only a has empty BusinessProcess, it comes last
    if (!a.BusinessProcess) return 1;

    // If only b has empty BusinessProcess, it comes first
    if (!b.BusinessProcess) return -1;

    // If BusinessProcess for both is not empty, compare SecurityIssues
    if (a.SecurityIssues === 0 && b.SecurityIssues !== 0) return 1;
    if (a.SecurityIssues !== 0 && b.SecurityIssues === 0) return -1;

    return 0; // no change
  });

  return (
    <MaterialReactTable
      columns={columns}
      data={transformedData}
      defaultColumn={{ Filter: MaterialReactTable.DefaultColumnFilter }}
      enableColumnFilters={false}
      enableSorting={false}
      enableStickyHeader={true}
      muiTableContainerProps={{
        sx: {
          height: "100%",
        },
      }}
      muiTableBodyCellProps={{
        style: {
          fontFamily: "Inter",
          fontSize: "14px",
        },
      }}
      muiTableHeaderCellProps={{
        style: {
          fontFamily: "Inter",
          fontSize: "16px",
        },
      }}
      muiTableBodyRowProps={{
        sx: {
          height: "80px",
          overflow: "auto",
        },
      }}
    />
  );
};

export default OverviewTable;
