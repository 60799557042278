import { useMemo } from "react";
import { useIsGrouping } from "./useIsGrouping";
import { useGroupingTableState } from "./useGroupingTableState";
import { usePrimaryTableState } from "./usePrimaryTableState";

export function useInventoryPageData() {
  const isGrouping = useIsGrouping();
  const { data: primaryTableData } = usePrimaryTableState();
  const { data: groupingData } = useGroupingTableState();

  return useMemo(() => {
    if (isGrouping) {
      return groupingData ?? [];
    } else {
      return primaryTableData ?? [];
    }
  }, [isGrouping, primaryTableData, groupingData]);
}
