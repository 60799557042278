import { Typography, styled } from "@mui/material";
import { forwardRef } from "react";

export const IntegrationLabel = styled(
  forwardRef((props, ref) => (
    <Typography {...props} ref={ref} variant="caption" />
  )),
)`
  color: #999999;
  font-family: Roboto;
  font-weight: 500 !important;
`;
