import { useContext } from "react";
import { IntegrationValuesStateContext } from "../context/contexts";

export const useGetWizIntegrationState = () => {
  return useContext(IntegrationValuesStateContext).state;
};

export const useSetWizIntegrationState = () => {
  return useContext(IntegrationValuesStateContext).dispatch;
};
