import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { defaultTheme } from "./default";

const theme = createTheme({
  typography: {
    pageHeading: {
      fontSize: "1.3rem",
      lineHeight: "1.4",
      letterSpacing: "0.0075em",
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#6b6b6b",
    },
    h1: {
      fontSize: "2rem",
      lineHeight: "1.25",
      letterSpacing: "0.005em",
      fontWeight: 400,
      color: "#6b6b6b",
    },
    h2: {
      fontSize: "1.5rem",
      lineHeight: "1.3",
      letterSpacing: "0.005em",
      fontWeight: 400,
      color: "#6b6b6b",
    },
    h3: {
      fontSize: "1.3rem",
      lineHeight: "1.35",
      letterSpacing: "0.005em",
      fontWeight: 400,
      color: "#6b6b6b",
    },
    h4: {
      fontSize: "1.125rem",
      lineHeight: "1.4",
      letterSpacing: "0.0075em",
      fontWeight: 500,
      color: "#6b6b6b",
    },
    h5: {
      fontSize: "1rem",
      lineHeight: "1.45",
      letterSpacing: "0.0075em",
      fontWeight: 400,
      color: "#6b6b6b",
    },
    h6: {
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.01em",
      fontWeight: 400,
      color: "#6b6b6b",
    },
    subtitle1: {
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.01em",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      lineHeight: "1.57",
      letterSpacing: "0.01em",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.015em",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.43",
      letterSpacing: "0.015em",
      fontWeight: 400,
    },
    button: {
      fontSize: "0.875rem",
      lineHeight: "1.75",
      letterSpacing: "0.02em",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.75rem",
      lineHeight: "1.66",
      letterSpacing: "0.03em",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.75rem",
      lineHeight: "2.66",
      letterSpacing: "0.08em",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
  palette: {
    primary: {
      main: "#002733",
      light: "#00FFB2",
    },
    success: {
      main: "#32cd32",
      light: "rgb(187, 247, 208)",
    },
    secondary: {
      main: "#A0A0A0",
    },
    error: {
      main: "#F91C51",
      light: "#fdbaca",
    },
    warning: {
      light: "#E6C200",
      main: "#9A3412",
    },
    info: {
      main: "#5B83EA",
    },
    grey: {
      main: "#8F9699",
      400: "#EEEFF0",
      500: "#ccc",
    },
    background: {
      default: "#fff",
      paper: "#F9FAFB",
      dropdownPaper: "#F6F7F7",
    },
  },
  shadows: {
    2: "0px 0px 20px 0px #0027331A",
    1: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    0: "none",
  },
  border: {
    main: "1px solid #ccc",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          ":focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

export const lightModeTheme = deepmerge(defaultTheme, createTheme(theme));
