import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { AuthManager } from "../infrastructure/auth/AuthManager";
import { ApiClient } from "../infrastructure/api/ApiClient";
import { DotEnv } from "../infrastructure/env-manager/dotenv";
import { LocalStorageKeys } from "../constants/constants";
import { InventoryService } from "../services/inventoryService";
import { UserService } from "../services/UserService";
import { CMSTService } from "../services/CMSTService";

export const useSetupInfrastructure = () => {
  const { getAccessTokenSilently } = useAuth0();

  const setupAuthManager = () => {
    AuthManager.setGetAuthToken(getAccessTokenSilently);
  };

  const setupApiClient = () => {
    const { baseUrl } = DotEnv;
    const organizationId = localStorage.getItem(
      LocalStorageKeys.ORGANIZATION_ID
    );
    ApiClient.setAuthManager(AuthManager);
    ApiClient.init(baseUrl, organizationId, { registerInterceptors: true });
  };

  const setupServices = () => {
    const apiClient = ApiClient.getInstance();
    InventoryService.setApiClient(apiClient);
    UserService.setApiClient(apiClient);
    CMSTService.setApiClient(apiClient);
  };

  useEffect(() => {
    setupAuthManager();
    setupApiClient();
    setupServices();
  }, []);
};
