import { Actions } from "../stateManagement/actions";
import { useAssetTableState } from "./useAssetTableState";
import { useStateDispatch } from "./useStateDispatch";

export function useColumnsState() {
  const { columns } = useAssetTableState();
  const dispatch = useStateDispatch();

  const setter = (payload) => {
    dispatch({
      type: Actions.SET_COLUMNS,
      payload,
    });
  };
  return [columns, setter];
}
