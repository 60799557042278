import { createAsyncThunk } from "@reduxjs/toolkit";
import { CMSTService } from "../../../services/CMSTService";

export const getProjectIssues = createAsyncThunk(
  "project-issues-by-id",
  async (payload, thunkApi) => {
    try {
      //
      const data = await CMSTService.getInstance().getProjectIssues(payload);
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);
