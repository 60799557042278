import MultiStepSidebar from "../../../components/onboardingV2/MultiStepSideBar";
import { useGetWizIntegrationState } from "../hooks/useIntegrationState";

const steps = [
  {
    title: "Integration",
    description: "",
  },
  {
    title: "Completion",
  },
];
export const useIntegrationMultiStepSidebar = () => {
  const { step } = useGetWizIntegrationState();
  return <MultiStepSidebar steps={steps} currentStep={step} />;
};
