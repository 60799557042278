import { styled, Typography } from "@mui/material";

const valueColorMap = {
  default: "#002733",
  error: "#F91C51",
  success: "#00A372",
};

export const Root = styled("div")(({ theme }) => {
  const shadow = theme.shadows[1];
  return `
display: flex;
flex-direction: column;
width: 350px;
border-radius: 8px;
box-shadow: ${shadow};
padding: 24px;
row-gap: 16px;
background-color: #fff;
`;
});

export const Value = styled(({ children, ...props }) => (
  <Typography variant='h2' {...props}>
    {children}
  </Typography>
))(({ insightVariant }) => {
  const color = valueColorMap[insightVariant];
  return `  
  font-weight: 500 !important;
  color: ${color};
`;
});
