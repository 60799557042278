import { useEffect } from "react";
import { ActionType } from "../state-management/actions";
import { useAssetInventoryActionDispatch } from "./useAssetInventoryActionDispatch";
import { usePageData } from "./usePageData";

export function useFilters() {
  const { filters } = usePageData();

  const dispatch = useAssetInventoryActionDispatch();

  function setState(data) {
    dispatch({
      type: ActionType.SET_FILTERS,
      payload: data,
    });
  }

  return [filters, setState];
}
