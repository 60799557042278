import { useMemo } from "react";
import { useIssueDetail } from "../../hooks/useIssueDetail";
import { CMST_Chip_Variants } from "./Chip/data";
import {
  AiAnalysisSection,
  AssetSummary,
  ContentRoot,
  DescriptionText,
  FooterContainer,
  OverviewSection,
  InfoLabel,
  IssueInfoContainer,
  IssuesSummaryContainer,
  JiraRequestInfo,
  OverviewLabel,
  PageContent,
  PageOverviewSection,
  PageRoot,
  PotentialRisksSection,
  RequestorProfile,
  RequestorSummaryInfo,
  RequestorUserInfo,
  Row,
  SecurityAssessment,
  SummaryCardLabel,
  SummaryCardRoot,
  SummaryCardText,
  UserAvatar,
  UserDetailsRoot,
  OverviewText,
  OverviewChipsSection,
  JiraTicketSection,
  JiraTagsSection,
  OverviewSubHeading,
  TicketInfoSection,
  TicketInfoSectionRoot,
  ActionsContainer,
  ActionButtonsRoot,
  ActionButton,
} from "./styled";
import { CMSTChip } from "./Chip";
import { SecurityTasks } from "./SecurityTasks";
import ConditionComponent from "../../../../../utils/ConditionComponent";
import { CoreDataTypes } from "../../../../../core/data-types";

const workSuspensionTransform = (value) => {
  return value ? "Yes" : "Not Recommended";
};

function createChipVariantsMap(profile) {
  return {
    workSuspensionRequired: profile.workSuspensionRequired
      ? CMST_Chip_Variants.ERROR
      : CMST_Chip_Variants.SUCCESS,
    securityApprovalRequired: profile.securityApprovalRequired
      ? CMST_Chip_Variants.ERROR
      : CMST_Chip_Variants.SUCCESS,
    securityReviewRequired: profile.securityReviewRequired
      ? CMST_Chip_Variants.ERROR
      : CMST_Chip_Variants.SUCCESS,
  };
}

export const Content = ({ data: profile }) => {
  const chipVariantsMap = createChipVariantsMap(profile);

  const securityRisksNodes = useMemo(() => {
    return profile.securityRisks.map((risk) => (
      <CMSTChip key={risk} label={risk} variant={CMST_Chip_Variants.ERROR} />
    ));
  }, [profile]);

  const jiraTicketTagsNodes = useMemo(() => {
    return profile.jiraTicket.tags.map((tag, index) => (
      <CMSTChip key={index} label={""} variant={CMST_Chip_Variants.SECONDARY} />
    ));
  }, [profile]);

  return (
    <PageContent className='scrollable'>
      <IssuesSummaryContainer>
        <SummaryCardRoot bg='#4338CA20'>
          <SummaryCardLabel color='#3730A3'>Project</SummaryCardLabel>
          <SummaryCardText color='#4338CA'>
            {profile.projectName}
          </SummaryCardText>
        </SummaryCardRoot>
        <SummaryCardRoot bg='#E5E7EB'>
          <SummaryCardLabel>Issue ID</SummaryCardLabel>
          <SummaryCardText>{profile.id}</SummaryCardText>
        </SummaryCardRoot>
        <SummaryCardRoot borderColor='#E5E7EB'>
          <SummaryCardLabel>Security Review Required</SummaryCardLabel>
          <CMSTChip
            label={workSuspensionTransform(profile.securityReviewRequired)}
            variant={chipVariantsMap.securityReviewRequired}
          />
        </SummaryCardRoot>
        <SummaryCardRoot borderColor='#E5E7EB'>
          <SummaryCardLabel>Security Approval Required</SummaryCardLabel>
          <CMSTChip
            label={workSuspensionTransform(profile.securityApprovalRequired)}
            variant={chipVariantsMap.securityApprovalRequired}
          />
        </SummaryCardRoot>
        <SummaryCardRoot borderColor='#E5E7EB'>
          <SummaryCardLabel>Work Suspension</SummaryCardLabel>
          <CMSTChip
            label={workSuspensionTransform(profile.workSuspensionRequired)}
            variant={chipVariantsMap.workSuspensionRequired}
          />
        </SummaryCardRoot>
        <SummaryCardRoot borderColor='#E5E7EB'>
          <SummaryCardLabel>Security Relevance Score</SummaryCardLabel>
          <CMSTChip
            label={profile.securityRelevanceScore}
            variant={CMST_Chip_Variants.SECONDARY}
          />
        </SummaryCardRoot>

        <SummaryCardRoot borderColor='#E5E7EB'>
          <SummaryCardLabel>Urgency Score</SummaryCardLabel>
          <CMSTChip
            label={profile.securityRelevanceScore}
            variant={CMST_Chip_Variants.WARN}
          />
        </SummaryCardRoot>
        <SummaryCardRoot borderColor='#E5E7EB'>
          <SummaryCardLabel>Data Type</SummaryCardLabel>
          <CMSTChip
            label={profile.dataType}
            variant={CMST_Chip_Variants.SECONDARY}
          />
        </SummaryCardRoot>
      </IssuesSummaryContainer>
      <PageOverviewSection>
        <OverviewSection className='scrollable'>
          <OverviewLabel>General Overview</OverviewLabel>
          <OverviewText>{profile.generalOverview}</OverviewText>
        </OverviewSection>
        <OverviewSection className='scrollable'>
          <OverviewLabel>Security Rationale</OverviewLabel>
          <OverviewText>{profile.securityRationale}</OverviewText>
        </OverviewSection>
        <OverviewSection>
          <OverviewLabel>Related Assets</OverviewLabel>
          <ConditionComponent
            falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
            condition={!!profile.relatedAssets}
          >
            <OverviewChipsSection>{profile.relatedAssets}</OverviewChipsSection>
          </ConditionComponent>
        </OverviewSection>
        <OverviewSection>
          <OverviewLabel>Security Risks</OverviewLabel>
          <ConditionComponent
            condition={securityRisksNodes.length}
            falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
          >
            <OverviewChipsSection>{securityRisksNodes}</OverviewChipsSection>
          </ConditionComponent>
        </OverviewSection>
      </PageOverviewSection>
      <SecurityTasks
        data={profile.securityTasks}
        key={`${profile.securityTasks.length}`}
      />
      <ActionsContainer>
        <OverviewLabel>Actions</OverviewLabel>
        <ActionButtonsRoot>
          <ActionButton disabled bgColor='#A855F7'>
            Submit an update to Jira
          </ActionButton>
          <ActionButton disabled bgColor='#22C55E'>
            Message reporter and Assignee on Slack
          </ActionButton>
          <ActionButton disabled bgColor='#3B82F6'>
            Email reporter and Assignee
          </ActionButton>
        </ActionButtonsRoot>
      </ActionsContainer>

      <JiraTicketSection>
        <OverviewLabel>Original Jira Ticket</OverviewLabel>
        <TicketInfoSectionRoot>
          <TicketInfoSection>
            <OverviewSubHeading>Reporter</OverviewSubHeading>
            <OverviewText>{profile.jiraTicket.reporter}</OverviewText>
          </TicketInfoSection>

          <TicketInfoSection>
            <OverviewSubHeading>Assignee (s)</OverviewSubHeading>
            <OverviewText>{profile.jiraTicket.assignees}</OverviewText>
          </TicketInfoSection>

          <TicketInfoSection>
            <OverviewSubHeading>Summary</OverviewSubHeading>
            <OverviewText>{profile.jiraTicket.summary}</OverviewText>
          </TicketInfoSection>

          <TicketInfoSection>
            <OverviewSubHeading>Description</OverviewSubHeading>
            <OverviewText>{profile.jiraTicket.description}</OverviewText>
          </TicketInfoSection>
        </TicketInfoSectionRoot>
        <JiraTagsSection>
          <OverviewSubHeading>Original Tags</OverviewSubHeading>
          <ConditionComponent
            condition={jiraTicketTagsNodes.length}
            falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
          >
            <OverviewChipsSection>{jiraTicketTagsNodes}</OverviewChipsSection>
          </ConditionComponent>
        </JiraTagsSection>
      </JiraTicketSection>
    </PageContent>
  );
};
