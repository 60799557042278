import { SubPageContainer, SubPageHeading, ValueText } from "../styled";

export const SecurityPage = () => {
  return (
    <SubPageContainer>
      <SubPageHeading>Security</SubPageHeading>
      <ValueText isna>N/A</ValueText>
    </SubPageContainer>
  );
};
