import { Fragment, useMemo, useRef, useState } from "react";
import ConditionComponent from "../../../utils/ConditionComponent";
import {
  DeleteButtonContainer,
  FieldSelectorContainer,
  FilterActionButtonsRoot,
  FilterActionsContainer,
  FilterContentRoot,
  FilterGroupConjunctionContainer,
  FilterGroupContainer,
  FilterGroupContentContainer,
  OperatorSelectorContainer,
  SelectorLabel,
  SelectorSection,
  ValueSelectorContainer,
  WarningContainer,
} from "../styled";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Typography } from "@mui/material";
import { DataGroupingMenu } from "../../Grouping/components/Menu";
import { checkValueExists } from "../../../utils/helperMethods/type-guards";
import { FilterTriggerButtonRef } from "../../../views/G6Graph/components/FilterSelector/globalRefs";
import { EmptyValueOperatorsMap } from "../../../core/asset-inventory/filterOperators";
import { usePageData } from "../../../containers/inventory/AssetsTable/hooks/usePageData";
import { checkOperatorIsMultiValueOperator } from "../../../core/asset-inventory/utils/checkOperatorIsMultiValueOperator";
import { SelectFieldAsColumn } from "./SelectFieldAsColumn";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export const FilterGroup = ({
  withConjunction,
  data: filterGroup,
  operatorOptions = [],
  conjunctionOptions = [],
  onDeleteFilterGroup,
  updateFilterGroup,
}) => {
  const { assetOptionsMap } = usePageData();

  const [menuOpened, setMenuOpened] = useState(false);

  const menuContainerRef = useRef();

  const dataGroupValueDTO = useMemo(
    () => mapFilterToDataGrouping(filterGroup),
    [filterGroup]
  );

  const createValueOptions = (selectedField, dataGroupOptions) => {
    const { value: field, source } = selectedField;
    const fieldValues = dataGroupOptions[source]?.[field].values ?? [];
    const fieldValueOptions = [];
    fieldValues.forEach((option) => {
      if (!checkValueExists(option)) return;
      const value = String(option);
      const fieldValueOptionPayload = {
        value,
        label: value,
        source,
        parentPayload: {
          field: value,
          source,
        },
      };
      fieldValueOptions.push(fieldValueOptionPayload);
    });
    return fieldValueOptions;
  };

  const valueOptions = useMemo(() => {
    if (!filterGroup.field) return [];
    return createValueOptions(filterGroup.field, assetOptionsMap);
  }, [assetOptionsMap, filterGroup]);

  const selectedFieldLabel = useMemo(() => {
    const { field } = filterGroup;
    if (!field.value) return "";

    return `${field.source}.${field.value}`;
  }, [filterGroup]);

  const handleOnClickFieldSelector = () => {
    setMenuOpened(true);
    menuContainerRef.current?.onOpenMenu();
  };

  const handleCloseMenu = () => {
    menuContainerRef.current?.onClose();
    setMenuOpened(false);
  };

  const onSelectValue = (values) => {
    handleCloseMenu();
    if (!values.length) return;
    const selectedField = values[0];
    const fieldPayload = {
      value: selectedField.value,
      label: selectedField.label,
      source: selectedField.source,
    };
    const updatedFilterGroup = {
      ...filterGroup,
      field: fieldPayload,
      operator: {},
      values: [],
    };
    updateFilterGroup(updatedFilterGroup);
  };

  const valueSelectorIsDisabled = useMemo(() => {
    const isEmptyOperator =
      !!EmptyValueOperatorsMap[filterGroup.operator.value];
    return isEmptyOperator;
  }, [filterGroup]);

  const isMultiValueOperator = useMemo(() => {
    return checkOperatorIsMultiValueOperator(filterGroup.operator.value);
  }, [filterGroup]);

  const handleOnChangeOperator = (selectedOperator) => {
    const updatedFilterGroup = {
      ...filterGroup,
      operator: selectedOperator,
    };
    if (EmptyValueOperatorsMap[selectedOperator.value]) {
      updatedFilterGroup.values = [];
    }
    updateFilterGroup(updatedFilterGroup);
  };

  const handleOnChangeValues = (selectedValues) => {
    let updatedValues = selectedValues;
    if (!Array.isArray(selectedValues)) {
      updatedValues = [selectedValues];
    }
    const updatedFilterGroup = {
      ...filterGroup,
      values: updatedValues,
    };
    updateFilterGroup(updatedFilterGroup);
  };

  const handleOnChangeAddToColumns = () => {
    const updatedFilterGroup = {
      ...filterGroup,
      addToColumns: !filterGroup.addToColumns,
    };
    updateFilterGroup(updatedFilterGroup);
  };

  const handleOnChangeConjunction = (selectedConjunction) => {
    const updatedFilterGroup = {
      ...filterGroup,
      conjunction: selectedConjunction,
    };
    updateFilterGroup(updatedFilterGroup);
  };

  return (
    <Fragment>
      <FilterGroupContainer>
        <ConditionComponent condition={withConjunction}>
          <SelectorSection sx={{ marginBottom: "12px" }}>
            <SelectorLabel>Conjunction</SelectorLabel>
            <FilterGroupConjunctionContainer
              placeholder='CONJ'
              options={conjunctionOptions}
              onChange={handleOnChangeConjunction}
              value={filterGroup.conjunction}
            />
          </SelectorSection>
        </ConditionComponent>
        <FilterContentRoot>
          <FilterGroupContentContainer>
            <SelectorSection>
              <SelectorLabel>Field</SelectorLabel>
              <FieldSelectorContainer onClick={handleOnClickFieldSelector}>
                {selectedFieldLabel}
              </FieldSelectorContainer>
            </SelectorSection>

            <SelectorSection>
              <SelectorLabel>Operator</SelectorLabel>
              <OperatorSelectorContainer
                value={filterGroup.operator}
                options={operatorOptions}
                onChange={handleOnChangeOperator}
              />
            </SelectorSection>

            <SelectorSection>
              <SelectorLabel>Values</SelectorLabel>
              <ValueSelectorContainer
                isMulti={isMultiValueOperator}
                isDisabled={valueSelectorIsDisabled}
                onChange={handleOnChangeValues}
                options={valueOptions}
                value={filterGroup.values}
                isSearchable
                isClearable
              />
            </SelectorSection>
          </FilterGroupContentContainer>
          <FilterActionsContainer>
            <ConditionComponent condition={filterGroup.warning}>
              <WarningContainer>
                <ReportProblemIcon sx={{ fontSize: "18px" }} />
                <Typography variant='caption' fontWeight={600}>
                  {filterGroup.warning}
                </Typography>
              </WarningContainer>
            </ConditionComponent>
            <FilterActionButtonsRoot>
              <SelectFieldAsColumn
                checked={filterGroup.addToColumns}
                onChange={handleOnChangeAddToColumns}
              />
              <DeleteButtonContainer
                onClick={() => onDeleteFilterGroup(filterGroup)}
              >
                <DeleteRoundedIcon sx={{ fontSize: "18px", color: "#fff" }} />
              </DeleteButtonContainer>
            </FilterActionButtonsRoot>
          </FilterActionsContainer>
        </FilterContentRoot>
      </FilterGroupContainer>
      <DataGroupingMenu
        title='Filter Field'
        bindings={menuContainerRef}
        data={assetOptionsMap}
        handleCloseMenu={handleCloseMenu}
        menuOpened={menuOpened}
        onClearGrouping={handleCloseMenu}
        selectOption={filterGroup.field}
        setValue={onSelectValue}
        value={dataGroupValueDTO}
        anchorEl={FilterTriggerButtonRef.current}
        isMulti={false}
        menuContainerProps={{
          anchorReference: "anchorPosition",
          anchorPosition: {
            left: 500,
            top: 200,
          },
        }}
      />
    </Fragment>
  );
};

function mapFilterToDataGrouping(filterGroup) {
  const fieldExists = Object.keys(filterGroup.field).length > 0;
  if (fieldExists) {
    const { label, source, value } = filterGroup.field;
    const fieldPayload = {
      label: label,
      source: source,
      value: value,
    };
    return [fieldPayload];
  }
  return [];
}
