import { styled } from "@mui/material";
import {
  PageHeader,
  PageLayout,
} from "../../../../shared-component/PageLayout";
import { InsightsSection } from "../../../../shared-component/CMSTLayout";

export const InsightsBlocksRoot = styled(InsightsSection)`
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 24px;
  width: 100%;
`;

export const PageRoot = styled(PageLayout)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const Header = styled(PageHeader)`
  padding: 24px;
`;

export const PageContent = styled("main")`
  padding: 0 24px 24px 24px;
  flex: 1;
`;
