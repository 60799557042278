import {
  CancelButton,
  DescriptionInput,
  Field,
  FieldLabel,
  NameInput,
  QuerySummaryRoot,
  SaveButton,
  SaveMModalHeader,
  SaveModalViewRoot,
  SummaryChip,
  SummaryRoot,
  TitleText,
  ViewBody,
  ViewFooter,
} from "./styled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { createFiltersPayload } from "./utils/createFiltersPayload";
import { createColumnsPayload } from "./utils/createColumnsPayload";
import ConditionComponent from "../../../../../utils/ConditionComponent";
import { useAssetTableState } from "../../hooks/useAssetTableState";
import { SaveQueryActionTypes } from "../../../../../app/asset-management/config";
import { useUserInfo } from "../../../../../hooks/useAppUser";
import { formValidator } from "./utils/formValidator";
import { showAlert, showErrorAlert } from "../../../../../app/alert/showAlert";
import { InvalidPayloadError } from "../../../../../app/errors/form-errors/InvalidPayloadError";

const Component = ({
  data,
  actionType = SaveQueryActionTypes.CREATE,
  action,
  ...props
}) => {
  const { grouping, currentGroupingOption } = useAssetTableState();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const checkFieldError = (field) => {
    return errors.find((error) => error.field === field);
  };

  const { username } = useUserInfo();

  const statusColor = data.filters.length ? "#5CB85C" : "#9E9E9E";
  const filtersStatus = data.filters.length ? "active" : "inactive";

  const numOfCols = data.columns.length;

  const handleOnChangeName = (ev) => {
    setName(ev.target.value);
  };

  const handleOnChangeDescription = (ev) => {
    setDescription(ev.target.value);
  };

  const iconNode =
    actionType === SaveQueryActionTypes.CREATE ? <CheckIcon /> : <CheckIcon />;

  const resetStates = () => {
    setName("");
    setDescription("");
    setLoading(false);
    setErrors([]);
  };

  const createAction = async () => {
    try {
      setLoading(true);
      setErrors([]);
      const errors = formValidator({
        name,
        description,
      });
      if (errors.length) {
        throw new InvalidPayloadError("Invalid payload", errors);
      }
      const filtersPayload = createFiltersPayload(data.filters);
      const columnsPayload = createColumnsPayload(data.columns);
      const payload = {
        name,
        created_by: username,
        description,
        filters: filtersPayload,
        columns: columnsPayload,
        group: "",
      };
      if (grouping.length) {
        const groupingValue = currentGroupingOption.value;
        const group = grouping[0]?.value;
        payload.group = `${group},${groupingValue}`;
      }
      await action?.(payload);
      setLoading(false);
      props?.onClose();
      resetStates();
      showAlert("Query Created Successfully", { type: "success" });
    } catch (error) {
      if (error.errors) {
        setErrors(error.errors);
        showErrorAlert("Fill all required fields");
      }
      setLoading(false);
    }
  };

  const editAction = async () => {
    try {
      setLoading(true);
      setErrors([]);
      const payload = {
        ...data,
        name,
        description,
      };
      const errors = formValidator({
        name,
        description,
      });
      if (errors.length) {
        throw new InvalidPayloadError("Invalid payload", errors);
      }
      await action?.(payload);
      props?.onClose();
      resetStates();
      showAlert("Query Edited Successfully", { type: "success" });
    } catch (error) {
      if (error.errors) {
        setErrors(error.errors);
        showErrorAlert("Fill all required fields");
      } else {
        const error = { field: "global", message: "Failed to Edit" };
        setErrors([error]);
        showErrorAlert(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnSave = (payload) => {
    if (actionType === SaveQueryActionTypes.CREATE) {
      createAction(payload);
    } else if (actionType === SaveQueryActionTypes.EDIT) {
      editAction(payload);
    }
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
    }
  }, [data]);

  return (
    <SaveModalViewRoot
      {...props}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: 40,
      }}
    >
      <SaveMModalHeader>
        <TitleText>Save Query</TitleText>
      </SaveMModalHeader>
      <QuerySummaryRoot>
        <FieldLabel>Query Summary:</FieldLabel>
        <SummaryRoot>
          <SummaryChip bgColor='#64B5F6'>
            Number of Columns: {numOfCols}
          </SummaryChip>
          <SummaryChip bgColor={statusColor}>
            Filters Status: {filtersStatus}
          </SummaryChip>
        </SummaryRoot>
      </QuerySummaryRoot>
      <ViewBody>
        <Field>
          <FieldLabel>Query Name *</FieldLabel>
          <NameInput
            error={checkFieldError("name")}
            onChange={handleOnChangeName}
            value={name}
            required
          />
        </Field>
        <Field>
          <FieldLabel>Query Description *</FieldLabel>
          <DescriptionInput
            value={description}
            onChange={handleOnChangeDescription}
            multiline
            error={checkFieldError("description")}
            rows={5}
          />
        </Field>
      </ViewBody>
      <ViewFooter>
        <CancelButton onClick={props.onClose}>
          <CloseIcon />
        </CancelButton>
        <SaveButton onClick={handleOnSave}>
          <ConditionComponent
            falsyNode={
              <CircularProgress
                sx={{ color: "#00ffb2" }}
                variant='indeterminate'
                size={"20px"}
              />
            }
            condition={!loading}
          >
            {iconNode}
          </ConditionComponent>
        </SaveButton>
      </ViewFooter>
    </SaveModalViewRoot>
  );
};

export const SaveQueryView = (props) => {
  if (!props.data || !props.data.filters || !props.data.columns) return null;
  return <Component {...props} />;
};
