import { DataGroupsSources } from "../../components/Grouping/data";

export const AssetInventoryTableFixedColumns = Object.freeze([
  {
    label: "common_fields.name",
    value: "common_fields.name",
    source: DataGroupsSources.PLATFORM,
    id: "options-platform.common_fields.nmme",
  },
  {
    label: "types",
    value: "types",
    source: DataGroupsSources.PLATFORM,
    id: "options-platform.common_fields.types",
  },
  {
    label: "sources",
    value: "sources",
    source: DataGroupsSources.PLATFORM,
    id: "options-platform.sources",
  },
]);
