import { IntegrationInitialState } from "./state";

export const IntegrationActions = Object.freeze({
  setHostname: "setHostname",
  setUserServiceId: "setUserServiceId",
  setApiToken: "setApiToken",
  setVerifySSL: "setVerifySSL",
  setHttpsProxy: "setHttpsProxy",
  setHttpsProxyUserName: "setHttpsProxyUserName",
  setHttpsProxyPassword: "setHttpsProxyPassword",
  setIntegrationStep: "setIntegrationStep",
  startIntegration: "startIntegration",
  integrationSuccess: "integrationSuccess",
  integrationFailed: "integrationFailed",
  reset: "reset",
  setName: "setName",
});

export const HibobIntegrationReducer = (oldState, action) => {
  const state = { ...oldState };
  if (action.type === IntegrationActions.reset) {
    return IntegrationInitialState;
  }
  if (action.type === IntegrationActions.startIntegration) {
    state.loading = true;
    state.error = null;
    return state;
  }
  if (action.type === IntegrationActions.integrationSuccess) {
    state.loading = false;
    state.error = null;
    state.step = 2;
    return state;
  }

  if (action.type === IntegrationActions.setName) {
    state.name = action.payload;
    return state;
  }

  if (action.type === IntegrationActions.integrationFailed) {
    state.loading = false;
    state.error = action.payload || "Something went wrong";
    state.step = 1;
    return state;
  }
  if (action.type === IntegrationActions.setUserServiceId) {
    
    state.userServiceId = action.payload;
    return state;
  }

  if (action.type === IntegrationActions.setHostname) {
    state.hostname = action.payload;
    return state;
  }

  if (action.type === IntegrationActions.setApiToken) {
    state.apiToken = action.payload;
    return state;
  }

  if (action.type === IntegrationActions.setVerifySSL) {
    state.verifySSL = action.payload;
    return state;
  }
  if (action.type === IntegrationActions.setHttpsProxy) {
    state.httpsProxy = action.payload;
    return state;
  }
  if (action.type === IntegrationActions.setHttpsProxyUserName) {
    state.httpsProxyUsername = action.payload;
    return state;
  }
  if (action.type === IntegrationActions.setHttpsProxyPassword) {
    state.httpsProxyPassword = action.payload;
    return state;
  }

  if (action.type === IntegrationActions.setIntegrationStep) {
    state.step = action.payload;
    return state;
  }

  return state;
};
