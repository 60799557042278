import { DataFiltering } from "../../../../../components/Filtering";
import { DataGrouping } from "../../../../../components/Grouping";
import { InventoryTableActionsRoot } from "../../../../../ui/pages/inventory/components/styled";
import { SearchInput } from "../../../../../ui/shared-component/SearchInput";
import { SelectColumns } from "../SelectColumns";
import { useIsGrouping } from "../../hooks/useIsGrouping";
import { useAssetInventoryActionDispatch } from "../../hooks/useAssetInventoryActionDispatch";
import { usePageData } from "../../hooks/usePageData";
import { useFilters } from "../../hooks/useFilters";
import { usePrimaryTableColumnState } from "../../hooks/usePrimaryTableColumnState";
import { useGroupingState } from "../../hooks/useGroupingState";
import { usePrimaryTableState } from "../../hooks/usePrimaryTableState";
import { useGroupingTableState } from "../../hooks/useGroupingTableState";
import { useAssetsTableMethods } from "../../hooks/useAssetsTableMethods";
import { ActionType } from "../../state-management/actions";
import { SaveQuery } from "../../../../../ui/base-components/Sidebar/component/SaveQueryView";
import { SaveQueryView } from "../InventoryTable/components/SavePopover";
import { Reducer } from "../../state-management/reducer";
import { KeyPressEventManager } from "../../../../../utils/KeyPressEventManager";
import { filtersToColumnAdapter } from "../../utils/addSelectedFieldsToColumns";
import { AssetInventoryTableFixedColumns } from "../../../../../core/asset-inventory/data";
import { createOptionId } from "../../../../../components/Grouping/utils/createOptionId";
import { useMemo, useState } from "react";
import { SearchResultColumnSelector } from "../SearchResultColumnSelector";
import { InventorySearchComponentRef } from "./refs";
import { InventoryService } from "../../../../../services/inventoryService";

export const InventoryActions = () => {
  const isGrouping = useIsGrouping();
  const dispatch = useAssetInventoryActionDispatch();
  const pageData = usePageData();
  const { assetOptionsMap, searchKeyword, currentGroupingValue } = pageData;
  const filters = useFilters()[0];
  const [
    openSearchResultsColumnSelectorView,
    setOpenSearchResultsColumnSelectorView,
  ] = useState(false);
  const [openSaveQueryView, setOpenSaveQueryView] = useState(false);

  const onCloseSaveQueryView = () => setOpenSaveQueryView(false);
  const onOpenSaveQueryView = () => setOpenSaveQueryView(true);

  const [primaryTableColumns, setTableColumns] = usePrimaryTableColumnState();
  const [grouping, setGrouping] = useGroupingState();
  const setFilters = useFilters()[1];
  const { paginationConfig } = usePrimaryTableState();
  const { paginationConfig: groupingTablePaginationConfig } =
    useGroupingTableState();

  const { fetchPrimaryTableData, fetchGroupingTableData } =
    useAssetsTableMethods();

  const allColumns = useMemo(() => {
    return primaryTableColumns.concat(AssetInventoryTableFixedColumns);
  }, [primaryTableColumns]);

  const onChangeGrouping = (grouping) => {
    const isEmpty = !grouping.length;
    if (isEmpty) {
      const payload = {
        page: 0,
        limit: paginationConfig.pageSize,
        filters: filters,
        group: null,
        searchKeyword,
      };
      fetchPrimaryTableData(undefined, payload);
    } else {
      const actionPayload = {
        type: ActionType.SET_GROUPING,
        payload: grouping,
      };
      const updatedState = Reducer(pageData, actionPayload);
      const {
        filters: newFilters,
        searchKeyword,
        currentGroupingValue,
      } = updatedState;
      const payload = {
        page: groupingTablePaginationConfig.pageIndex,
        limit: groupingTablePaginationConfig.pageSize,
        filters: newFilters,
        group: currentGroupingValue,
        searchKeyword,
      };
      fetchGroupingTableData(undefined, payload);
    }
    setGrouping(grouping);
  };

  function applySearchToGroupingTable(keyword) {
    const payload = {
      page: groupingTablePaginationConfig.pageIndex,
      limit: groupingTablePaginationConfig.pageSize,
      filters,
      group: currentGroupingValue,
      searchKeyword: keyword,
    };
    fetchGroupingTableData(undefined, payload);
  }

  function applySearchToPrimaryTable(keyword) {
    const payload = {
      page: paginationConfig.pageIndex,
      limit: paginationConfig.pageSize,
      filters,
      group: currentGroupingValue,
      searchKeyword: keyword,
    };
    fetchPrimaryTableData(undefined, payload);
  }

  const handleSearchChange = (query) => {
    if (isGrouping) {
      applySearchToGroupingTable(query);
    } else {
      applySearchToPrimaryTable(query);
    }
  };

  const onChangeSearchValue = (ev) => {
    const value = ev.target.value;
    dispatch({
      type: ActionType.SET_SEARCH_VALUE,
      payload: { value },
    });
  };

  const handleUpdateGroupingTable = (filters) => {
    const payload = {
      page: groupingTablePaginationConfig.pageIndex,
      limit: groupingTablePaginationConfig.pageSize,
      filters,
      group: currentGroupingValue,
      searchKeyword,
    };
    fetchGroupingTableData(undefined, payload);
  };

  const handleUpdatePrimaryTable = (filters) => {
    const payload = {
      page: paginationConfig.pageIndex,
      limit: paginationConfig.pageSize,
      filters,
      group: currentGroupingValue,
      searchKeyword,
    };
    fetchPrimaryTableData(undefined, payload);
  };

  const filterFn = (filter) => {
    const id = createOptionId(filter.field, "options");

    const isFixedColumnField = AssetInventoryTableFixedColumns.find(
      (col) => col.id === id
    );

    return !!isFixedColumnField;
  };

  const addSelectedFieldsToColumns = (filters) => {
    const updatedColumns = filtersToColumnAdapter(
      filters,
      primaryTableColumns,
      filterFn
    );
    setTableColumns(updatedColumns);
  };

  const handleOnApplyFilters = async (currentFilters) => {
    addSelectedFieldsToColumns(currentFilters);
    if (isGrouping) {
      handleUpdateGroupingTable(currentFilters);
    } else {
      handleUpdatePrimaryTable(currentFilters);
    }
    setFilters(currentFilters);
  };

  const onClearGrouping = () => {
    const actionPayload = {
      type: ActionType.SET_GROUPING,
      payload: grouping,
    };

    dispatch(actionPayload);

    const updatedState = Reducer(pageData, actionPayload);
    const { filters, searchKeyword, currentGroupingValue } = updatedState;
    const payload = {
      page: groupingTablePaginationConfig.pageIndex,
      limit: groupingTablePaginationConfig.pageSize,
      filters,
      group: currentGroupingValue,
      searchKeyword,
    };
    fetchGroupingTableData(undefined, payload);
  };

  const handleOnKeyDown = (ev) => {
    const isEnter = KeyPressEventManager.checkIsOnPressEnter(ev);
    if (isEnter) {
      handleSearchChange(searchKeyword);
    }
  };

  const handleOnClickEnter = () => {
    handleSearchChange(searchKeyword);
  };

  const handleOnClickSearchResultColumnSelector = () => {
    setOpenSearchResultsColumnSelectorView(true);
  };

  const handleOnCloseSearchResultsColumnSelector = () => {
    setOpenSearchResultsColumnSelectorView(false);
  };

  return (
    <InventoryTableActionsRoot>
      <SearchResultColumnSelector
        onClose={handleOnCloseSearchResultsColumnSelector}
        onClick={handleOnClickSearchResultColumnSelector}
        open={openSearchResultsColumnSelectorView}
        anchorEl={InventorySearchComponentRef.current}
      />
      <SearchInput
        onKeyDown={handleOnKeyDown}
        onChange={onChangeSearchValue}
        value={searchKeyword}
        placeholder='Hit Enter to submit'
        onClickEnter={handleOnClickEnter}
        ref={InventorySearchComponentRef}
      />
      <DataGrouping
        data={assetOptionsMap}
        onClearGrouping={onClearGrouping}
        valueAccessorStates={[grouping, onChangeGrouping]}
      />
      <DataFiltering
        dataGroupOptions={assetOptionsMap}
        filters={filters}
        onApplyFilters={handleOnApplyFilters}
        columns={allColumns}
      />
      <SelectColumns
        selectedValues={primaryTableColumns}
        setSelectedValues={setTableColumns}
      />
      <SaveQuery
        data={{ filters, columns: allColumns }}
        open={openSaveQueryView}
        saveButtonProps={{
          onClick: onOpenSaveQueryView,
        }}
        onClose={onCloseSaveQueryView}
        action={InventoryService.getInstance().createQuery}
      />
    </InventoryTableActionsRoot>
  );
};
