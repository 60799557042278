import { CoreDataTypes } from "../../../../core/data-types";

function transformAssigneesFromInfo(assignees = []) {
  return assignees.map((info) => info.name);
}

export function createAssetInfo(payload) {
  const {
    assignees_info = [],
    description,
    summary,
    issue_id,
    platform_summary = {},
    reporter_profile = {},
  } = payload;

  const relatedAssets =
    platform_summary.related_assets !== "None"
      ? platform_summary.related_assets
      : CoreDataTypes.N_A;

  const issueProfile = {
    id: issue_id,
    projectName: "",
    securityApprovalRequired: platform_summary?.security_approval_required,
    securityReviewRequired: platform_summary?.security_review_required,
    workSuspensionRequired: platform_summary?.work_suspension_required,
    securityRelevanceScore: platform_summary?.security_relevance_score,
    urgencyScore: platform_summary?.urgency_score,
    dataType: platform_summary.data_type,
    generalOverview: platform_summary.general_overview || CoreDataTypes.N_A,
    securityRationale: platform_summary.security_rationale || CoreDataTypes.N_A,
    relatedAssets,
    securityTasks: platform_summary.security_tasks ?? [],
    securityRisks: platform_summary.security_risks ?? [],
    jiraTicket: {
      reporter: reporter_profile?.name || CoreDataTypes.N_A,
      summary: summary || CoreDataTypes.N_A,
      assignees: transformAssigneesFromInfo(assignees_info),
      description: description || CoreDataTypes.N_A,
      tags: [],
    },
  };

  return issueProfile;
}
