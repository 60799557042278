import { useMemo } from "react";
import { usePageData } from "../../hooks/usePageData";
import {
  DataTableSectionContent,
  GroupDataTableSectionContainer,
  SectionHeading,
} from "./styled";

export const GroupingTableSection = ({ children }) => {
  const { currentGroupingValue, grouping } = usePageData();

  const heading = useMemo(() => {
    const { value, source } = grouping[0];
    const prefix = `${source}.${value}`;
    let name = "";
    if (currentGroupingValue?.value) {
      name = `${currentGroupingValue?.value}`;
    }
    const heading = `${prefix} > ${name.toUpperCase()} Data Table`;

    return heading;
  }, [currentGroupingValue, grouping]);

  return (
    <GroupDataTableSectionContainer>
      <SectionHeading>{heading}</SectionHeading>
      <DataTableSectionContent>{children}</DataTableSectionContent>
    </GroupDataTableSectionContainer>
  );
};
