import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LocalStorageKeys } from "../constants/constants";
import { UserService } from "../services/UserService";
import { UserStateActions } from "../state-management/UserState";

export const useInitUserInfo = () => {
  const dispatch = useDispatch();

  const initUserData = async () => {
    try {
      const [
        {
          data: { role },
        },
        { data: privileges },
      ] = await Promise.all([
        UserService.getInstance().getRole(),
        UserService.getInstance().getPrivileges(),
      ]);
      const organizationId = localStorage.getItem(
        LocalStorageKeys.ORGANIZATION_ID
      );
      const userData = {
        role,
        organizationId,
        privileges,
      };
      dispatch(UserStateActions.setUserInfoAction(userData));
    } catch (error) {}
  };

  useEffect(() => {
    initUserData();
  }, []);
};
