import { Container } from "./styled";
import { GroupingSection } from "./GroupingSection";
import { GroupingTableSection } from "./GroupingTableSection";
import { DataTable } from "./DataTable";

export const Grouping = () => {
  return (
    <Container>
      <GroupingSection />
      <GroupingTableSection>
        <DataTable />
      </GroupingTableSection>
    </Container>
  );
};
