import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Typography,
  styled,
  IconButton as MuiIconButton,
} from "@mui/material";
import MuiPersonIcon from "@mui/icons-material/Person";

export const AssetDetailDrawerContainer = styled(Drawer)(
  () => `
& .MuiPaper-root {
box-sizing: border-box; 
height: 100vh;
min-width: 500px;
max-width: 700px;
}
`
);

export const DrawerContent = styled("section")(
  () => `
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 24px;
  row-gap: 24px;
  background-color: #cccccc50
`
);

export const AssetInfoContainer = styled(Box)(
  () => `
&& {
display: flex;
height: fit-content;
border-radius:  12px;  
background-color: white;
padding: 1em;
position: relative;
}
`
);

export const ShortAssetInfoContainer = styled(AssetInfoContainer)`
  && {
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    padding: 12px;
  }
`;

export const AssetJSONSection = styled(AssetInfoContainer)(
  () => `
flex-direction: column;
`
);

export const AssetJsonContentSection = styled(Box)(
  ({ expand }) => `
margin-top: 16px;
height: ${expand ? "600px" : "200px"};
transition: 0.3s height ease;
`
);

export const AssetCommandHeader = styled("div")(
  () => `
display: flex;
justify-content: space-between;
align-items: center;
`
);

export const AssetCommandActionsContainer = styled("div")(
  () => `
display: flex; 
column-gap: 8px;
align-items:center;
justify-content: flex-end;
width: 100%;
`
);

export const InfoLabel = styled((props) => (
  <Typography {...props} variant='body2' />
))(
  () => `
&& {
font-weight: 600 !important;
margin-right: 12px !important;
}
`
);

export const ShortInfoLabel = styled(InfoLabel)`
  width: auto;
  min-width: auto;
`;

export const InfoValue = styled((props) => (
  <Typography {...props} variant='body2' />
))(
  () => `
  && {
  display: flex;
  }
`
);

export const StyledAccordion = styled(Accordion)(() => {
  return `
  &.MuiPaper-root {
    height: auto !important;
    border-radius: 12px !important;  
    background-color: white;
    padding: 14px !important;
    box-shadow: none;
    box-sizing: border-box;
    width: 100%;
  }
  `;
});

export const AssetTypeIcon = styled("img")`
  && {
    width: 24px;
    height: 24px;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)(
  () => `
  &.MuiAccordionSummary-root {
  min-height: 24px !important;
    height: 24px !important;
    width: 100%;
    padding: 0 !important;
  }
`
);

export const StyledAccordionDetails = styled(AccordionDetails)(
  () => `
width: 100%;
margin-top: 32px;
padding: 0 !important;
`
);

export const Row = styled("div")(
  () => `
display: flex;
column-gap: 32px;
border-box: box-sizing;
`
);

export const AssetViewRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

export const FlexInfoLabel = styled(InfoLabel)`
  display: flex;
`;

export const BusinessInsightQuestionContainer = styled(Accordion)(
  () => `
    &.MuiPaper-root {
    height: auto !important;
    border-radius: 14px !important;  
    background-color: white;
    padding: 8px !important;
    box-shadow: 0px 4px 15px 0px #0000001A;
    border-bottom: none;
  }

& .MuiSvgIcon-root { 
height: 24 !important;
width: 24 !important;
color: #ccc !important;
}

  &.MuiPaper-root:not(:first-child) {
  margin-top: 12px;
  }
    &.MuiPaper-root:before {
    background-color: transparent !important;
    }
`
);

export const StyledBusinessInsightQuestion = styled(AccordionSummary)(
  () => `
  min-height: 24px;
  height: 24px;
`
);

export const BusinessInsightAnswer = styled(AccordionDetails)(
  () => `

`
);

export const TagChip = styled("div")(
  ({ bgColor }) => `
display: flex;
padding: 4px 8px;
border-radius: 12px;
background-color: ${bgColor}30;
border: 1px solid ${bgColor};
`
);

export const TagLabel = styled(Typography)(
  () => `
font-weight: 500;
color: #00000060;
margin-right: 4px;
`
);

export const TagsContainer = styled(Box)(
  () => `
display: flex;
flex-wrap: wrap;
column-gap: 16px;
row-gap: 12px;
box-sizing: border-box;
`
);

export const ExpandJSONViewerButton = styled(Button)(
  () => `
text-transform: none;
border: 1px solid #5C757D40 !important;
border-radius: 24px !important;
bottom: 0;
padding: 3px !important;
left: calc(50% - 50px);
position: relative;
color: #002733;
font-weight: 400;
background-color: #F3F6FD !important;
width: 100px !important;
&:focus {
outline: none;
}
`
);

export const CopyButton = styled(Button)(
  () => `
text-transform: none;
display: flex;
border-radius: 6px !important;
padding: 6px 16px !important;
column-gap: 4px;
background-color: #002733 !important;
color: #00FFB2 !important;
transition: 0.3 width ease-in-out;
height: 32px;

& > svg {
height: 14px !important;
width: 14px !important;
}
& > * {
color: #00FFB2 !important;
}

&:hover {
background-color: #002733 !important;
color: #00FFB290 !important;
}
`
);

export const EmptyDataSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const EmptyDataSectionLabel = styled(Typography)`
  color: #cccccc !important;
  font-weight: 14px !important;
`;

export const WrappedContainer = styled("div")`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-left: 24px;
  column-gap: 12px;
  row-gap: 12px;
`;

export const PersonContainer = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #eeeff0;
  border-radius: 12px;
  padding: 4px 8px;
`;

export const PersonIcon = styled(MuiPersonIcon)`
  && {
    height: 16px;
    width: 16px;
    color: #ccc;
  }
`;

export const NAValue = styled((props) => (
  <Typography {...props} variant='caption' />
))`
  && {
    color: #00000070;
  }
`;

export const BusinessInsightsReactionsContainer = styled("div")`
  margin-left: auto;
  position: relative;
  right: 0;
`;

export const BusinessInsightsContent = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const IconButton = styled(MuiIconButton)`
  &:focus {
    outline: none;
  }
`;

export const InfoValueContainer = styled("div")`
  display: flex;
  flex: 1;
`;

export const CopyValueButton = styled(CopyButton)(
  ({ bottom = "8px !important", top }) => {
    return `
  && {
    position: absolute;
    right: 12px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    padding: 0 !important;
    border-radius: 24px !important;
    bottom: ${bottom};
    top: ${top};
  }
  `;
  }
);
