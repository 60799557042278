import { useMemo, useState } from "react";
import ConditionComponent from "../../../utils/ConditionComponent";
import {
  ExpandOriginationTypesButton,
  OriginationTypeContainer,
  OriginationTypeLabel,
} from "../styled";
import { generateOptionColor } from "../utils/generateColor";

const ORIGINATION_TYPE_DISPLAY_LIMIT = 3;

export const OriginationTypeView = ({ data }) => {
  const [showAll, setShowAll] = useState(false);

  const additionalOriginationTypes =
    data.length - ORIGINATION_TYPE_DISPLAY_LIMIT;
  const showAdditionalOriginationTypeCounter = additionalOriginationTypes > 0;
  const remainderButtonLabel = !showAll
    ? `+${additionalOriginationTypes}`
    : "-";

  const originationTypeOptions = useMemo(() => {
    if (showAll) return data;
    return data?.slice(0, ORIGINATION_TYPE_DISPLAY_LIMIT);
  }, [showAll, data]);

  const toggle = () => {
    setShowAll((p) => !p);
  };

  const typeNodes = useMemo(() => {
    return originationTypeOptions.map((type) => {
      const formattedType = type.trim().toLowerCase();
      const color = generateOptionColor(formattedType);
      return (
        <OriginationTypeLabel color={color} key={type}>
          {type}
        </OriginationTypeLabel>
      );
    });
  }, [originationTypeOptions]);

  return (
    <OriginationTypeContainer>
      {typeNodes}
      <ConditionComponent condition={showAdditionalOriginationTypeCounter}>
        <ExpandOriginationTypesButton onClick={toggle}>
          {remainderButtonLabel}
        </ExpandOriginationTypesButton>
      </ConditionComponent>
    </OriginationTypeContainer>
  );
};
