import React from "react";
import { Box, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import "./module.css";
import { useAuth0 } from "@auth0/auth0-react";
import DOMPurify from "dompurify";

export const WelcomeStep = () => {
  const { user } = useAuth0();
  const sanitizedUserName = DOMPurify.sanitize(user.name);
  const videoUrl = "https://s3.amazonaws.com/pandosec.com/assets/Pando.mp4";
  // Ensure sanitizedUserName is a string
  const userNameString =
    typeof sanitizedUserName === "string" ? sanitizedUserName : "User";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "13px",
        }}
      >
        <Typography className="cardTitle">
          Hi {userNameString},<br /> Welcome to Pando Security
        </Typography>
        <Typography className="cardSubtitle">
          We're excited to welcome you aboard!
        </Typography>
      </Box>
    </Box>
  );
};
