import React from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SecurityIcon from "@material-ui/icons/Security"; // Import a security-related icon

const useStyles = makeStyles({
  infoBox: {
    padding: "15px",
    backgroundColor: "#F5F9FF",
    borderRadius: "5px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    marginRight: "10px",
    marginLeft: "10px",
    width: "100%",
    maxHeight: "300px",
  },
  infoTitle: {
    fontWeight: "bold",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "8px",
  },
  methodology: {
    marginTop: "15px",
    fontWeight: "500",
    color: "#2F80ED", // Using a different color for emphasis
  },
});

const EventBasedRiskInfoBox = () => {
  const classes = useStyles();
  return (
    <Box className={classes.infoBox}>
      <Typography variant="h6" className={classes.infoTitle}>
        <SecurityIcon className={classes.icon} color="primary" />{" "}
        {/* Icon to highlight the title */}
        Event-Based Risk Quantification
      </Typography>
      <Typography variant="body2">
        The Event-Based Calculator developed by Pando Security Research Team
        employs sophisticated algorithms that assess an extensive range of
        leading data sources and real-time information. This allows us to gauge
        both the likelihood and potential financial impact of cyberattacks on
        your organization.{" "}
      </Typography>
      <Divider
        variant="middle"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      />{" "}
      {/* Divider to separate sections */}
      <Typography variant="body1" className={classes.methodology}>
        Methodology: FAIR-CAM Framework
      </Typography>
    </Box>
  );
};

export default EventBasedRiskInfoBox;
