import { Typography } from "@mui/material";
import { PersonContainer, PersonIcon } from "./styled";
import PropTypes from "prop-types";

const Person = ({ data }) => {
  return (
    <PersonContainer>
      <PersonIcon />
      <Typography variant="caption">{data.name}</Typography>
    </PersonContainer>
  );
};

Person.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default Person;
