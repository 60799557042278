import { Box, Link } from "@mui/material";
import {
  AssetInfoContainer,
  AssetTypeIcon,
  InfoLabel,
  NAValue,
  Row,
  InfoValue,
  ShortAssetInfoContainer,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  WrappedContainer,
  ShortInfoLabel,
  AssetViewRow,
} from "./styled";
import { Fragment, useMemo } from "react";
import { Tags } from "./Tags";
import { AssetCommand } from "./AssetCommand";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { EmptyDataSection } from "./EmptySections";
import { AssetStatusColorPalette } from "./utils";
import awsLogo from "../../../../assets/images/genericTable/awsPlatform.svg";
import Person from "./Person";
import { BusinessInsights } from "./BusinessInsights";
import { InfoValue as DetailedInfo } from "./InfoValue";

export const AssetDetailView = ({ data }) => {
  const {
    id,
    type,
    region,
    account_id,
    raw_data: assetJSON,
    status,
    source,
    tags,
    name,
    technical_summary,
    operational_owners,
    business_owners,
    business_purpose,
    evidence,
  } = data;

  const tagsIsEmpty = !tags || !Object.keys(tags).length;

  const statusColor = useMemo(() => {
    if (status === "Unknown") return AssetStatusColorPalette.unknown;
    return AssetStatusColorPalette.success;
  }, [status]);

  return (
    <Fragment>
      <DetailedInfo value={id} label={"Asset ID"} />
      <DetailedInfo value={name} label={"Asset Name"} />
      <DetailedInfo label='Technical Summary' value={technical_summary} />
      <Row>
        <DetailedInfo
          container={ShortAssetInfoContainer}
          value={account_id}
          labelContainer={ShortInfoLabel}
          label={"Account Id"}
        >
          <InfoValue>{account_id}</InfoValue>
        </DetailedInfo>
        <DetailedInfo
          container={ShortAssetInfoContainer}
          value={type}
          labelContainer={ShortInfoLabel}
          label={"Asset Type"}
        >
          <InfoValue>{account_id}</InfoValue>
        </DetailedInfo>
      </Row>
      <AssetViewRow>
        <DetailedInfo
          container={ShortAssetInfoContainer}
          label={"Source"}
          labelContainer={ShortInfoLabel}
          copyToClipboardValue={source}
        >
          <ConditionComponent
            condition={source === "aws"}
            falsyNode={<InfoValue>{source}</InfoValue>}
          >
            <AssetTypeIcon src={awsLogo} value={"aws"} />
          </ConditionComponent>
        </DetailedInfo>
        <DetailedInfo
          container={ShortAssetInfoContainer}
          labelContainer={ShortInfoLabel}
          label={"Region"}
          copyToClipboardValue={region}
        >
          <InfoValue>{region}</InfoValue>
        </DetailedInfo>
        <DetailedInfo
          container={ShortAssetInfoContainer}
          labelContainer={ShortInfoLabel}
          label={"Status"}
          copyToClipboardValue={status}
        >
          <InfoValue color={statusColor}>{status}</InfoValue>
        </DetailedInfo>
      </AssetViewRow>

      <Box>
        <StyledAccordion>
          <StyledAccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <InfoLabel sx={{ width: "auto !important" }}>Tags</InfoLabel>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <ConditionComponent
              condition={!tagsIsEmpty}
              falsyNode={<EmptyDataSection label={"No tags found"} />}
            >
              <Tags data={tags} />
            </ConditionComponent>
          </StyledAccordionDetails>
        </StyledAccordion>
      </Box>

      <AssetCommand data={assetJSON} />

      <BusinessInsights data={{ business_purpose }} />

      <AssetInfoContainer>
        <InfoLabel>Relevant responsible people</InfoLabel>
        <WrappedContainer>
          <ConditionComponent
            condition={operational_owners.length}
            falsyNode={<NAValue>N/A</NAValue>}
          >
            {operational_owners.map((person) => (
              <Person key={person} data={{ name: person }} />
            ))}
          </ConditionComponent>
        </WrappedContainer>
      </AssetInfoContainer>
      <AssetInfoContainer>
        <InfoLabel>Relevant accountable people</InfoLabel>
        <WrappedContainer>
          <ConditionComponent
            condition={business_owners.length}
            falsyNode={<NAValue>N/A</NAValue>}
          >
            {business_owners.map((person) => (
              <Person key={"bo" + person} data={{ name: person }} />
            ))}
          </ConditionComponent>
        </WrappedContainer>
      </AssetInfoContainer>

      <AssetInfoContainer>
        <InfoLabel>Related Resources</InfoLabel>
        <WrappedContainer>
          <ConditionComponent
            condition={evidence.length}
            falsyNode={<NAValue>N/A</NAValue>}
          >
            {evidence.map((resource) => (
              <Link
                target='_blank'
                rel='noreferrer'
                underline='always'
                key={resource.key}
                href={resource.link}
                variant='caption'
              >
                {resource.key}
              </Link>
            ))}
          </ConditionComponent>
        </WrappedContainer>
      </AssetInfoContainer>
    </Fragment>
  );
};
