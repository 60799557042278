import { Root } from "./styled";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const SearchResultsColumnsButton = () => {
  return (
    <Root>
      <ManageSearchIcon sx={{ transform: "rotate(90deg)" }} />
      <ArrowDropDownIcon
        sx={{
          width: "16px !important",
        }}
      />
    </Root>
  );
};
