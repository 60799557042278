import { Typography } from "@mui/material";
import { EmptyFiltersSection } from "./styled";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

export const EmptyFilters = ({ children }) => {
  const label = children ?? " Apply graph filters by creating Filter Groups";
  return (
    <EmptyFiltersSection>
      <FilterAltOffIcon />

      <Typography variant='body1'>Empty filters</Typography>
      <Typography variant='body2'>{label}</Typography>
    </EmptyFiltersSection>
  );
};
