import { Actions } from "../stateManagement/actions";
import { useAssetTableState } from "./useAssetTableState";
import { useStateDispatch } from "./useStateDispatch";

export function useSearchActionState() {
  const { searchKeyword } = useAssetTableState();
  const dispatch = useStateDispatch();

  const setter = (value) => {
    dispatch({
      type: Actions.SET_SEARCH_INPUT,
      payload: value,
    });
  };
  return [searchKeyword, setter];
}
