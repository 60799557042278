export default function createButtonConfig(
  text,
  iconPosition,
  onClick,
  icon = null,
  className = "",
  disabled = false,
  loading = false
) {
  return {
    text,
    iconPosition,
    onClick,
    icon,
    className,
    disabled,
    loading,
  };
}
