import React, { useEffect } from "react";
import GitHubLogin from "../components/github-oauth";
import { useAuth0 } from "@auth0/auth0-react";

import { useHistory } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import {
  Stepper,
  Step,
  StepLabel,
  Divider,
  Box,
  Button,
  Container,
  TextField,
  CircularProgress,
} from "@mui/material";
import GitHubIcon from "@material-ui/icons/GitHub";
import CloudIcon from "@mui/icons-material/Cloud";
import Typography from "@material-ui/core/Typography";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReactPlayer from "react-player";

import DOMPurify from "dompurify";
import {
  authenticateGithub,
  sendSlackNotification,
  sendAwsOnboarding,
  checkOnboarding,
  finishOnboarding,
} from "../utils/api";
import CloudFormationCard from "../components/CloudFormationCard";

const GITHUB_CLIENT_ID = "7fb75e3b108747c19873";
const GITHUB_REDIRECT_URI =
  process.env.REACT_APP_FRONTEND_URL + "/github_auth/";

const Onboarding = () => {
  const [stage, setStage] = React.useState(1);

  const [awsError, setAwsError] = React.useState("");
  const [account, setAccount] = React.useState("");
  const [isAwsAccountIdEntered, setIsAwsAccountIdEntered] =
    React.useState(false);

  const [role, setRole] = React.useState("");
  const [external_id, setExternal_id] = React.useState("");
  const [isGenerated, setIsGenerated] = React.useState(false); // New state

  const [self_role, setSelf_role] = React.useState("");

  const [onboardingStatus, setOnboardingStatus] = React.useState(0);
  const [isBackDisabled, setBackDisabled] = React.useState(false);

  const history = useHistory();
  const { getAccessTokenSilently, user } = useAuth0();
  const [githubError, setGithubError] = React.useState(false);

  const sanitizedUserName = DOMPurify.sanitize(user.name);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (stage === 1) {
      setGithubError(false);
    }
  }, [stage]);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const auth0Token = await getAccessTokenSilently();
        const status = await checkOnboarding(auth0Token);
        setOnboardingStatus(status);
        setIsLoading(false);
        if (status === 0 || status === null) {
          setStage(1);
        } else if (status === 1) {
          setStage(4);
          setBackDisabled(true); // disable the back button
        }
      } catch (error) {
        console.error("Error fetching onboarding status: ", error);
      }
    };

    fetchOnboardingStatus();
  }, []);

  useEffect(() => {
    if (stage === 1) {
      setGithubError(false);
    }
  }, [stage]);

  // Create a custom step icon component
  const ColorlibStepIcon = (props) => {
    const { active, completed } = props;
    const isLastStep = props.icon === 4;

    const icons = {
      1: <WavingHandIcon />,
      2: <GitHubIcon />,
      3: <CloudIcon />,
      4: <CheckCircleOutlineIcon />,
    };

    return (
      <div>
        {React.cloneElement(icons[String(props.icon)], {
          style: {
            color:
              completed || (isLastStep && stage === 4)
                ? "green"
                : active
                  ? "action"
                  : "disabled",
          },
        })}
      </div>
    );
  };

  const handleSuccessGithub = async (response) => {
    try {
      const code = response.code;
      const auth0Token = await getAccessTokenSilently();

      // Call the API function
      await authenticateGithub(auth0Token, code);

      // Continue to the next stage
      setStage(3);
    } catch (error) {
      setStage(2);
      setGithubError(true);
    }
  };

  const handleFailureGithub = (response) => {
    console.log(response);
    setStage(2);
    setGithubError(true);
  };
  const handleAccountChange = (event) => {
    const newValue = event.target.value;
    // This will test if newValue consists only of digits
    if (/^\d*$/.test(newValue)) {
      setAccount(newValue);

      const accountId = newValue.trim(); // remove any whitespace

      // Check if the input is a number and is 12 digits long
      setIsAwsAccountIdEntered(/^[0-9]{12}$/.test(accountId));
    }
  };

  const handleRoleChange = (event) => {
    if (!isGenerated) {
      setRole(event.target.value);
    }
  };

  const handleExternal_idChange = (event) => {
    if (!isGenerated) {
      setExternal_id(event.target.value);
    }
  };

  const handleContinue = async () => {
    if (stage === 1) {
      setStage(2);
    } else if (stage === 2) {
      setStage(3);
    } else if (stage === 3) {
      try {
        const auth0Token = await getAccessTokenSilently();
        await sendAwsOnboarding(
          account,
          role,
          external_id,
          self_role,
          auth0Token,
        );
        await finishOnboarding(auth0Token);
        setStage(4);
        setBackDisabled(true);
        const payload = {
          channel: "#customer-onboarding",
          username: "webhookbot",
          text: `User ${sanitizedUserName} has completed onboarding.`,
          icon_emoji: ":pando:",
        };
        sendSlackNotification(payload)
          .then((response) => console.log("Slack message sent successfully"))
          .catch((error) =>
            console.error("Error sending Slack message: ", error),
          );
      } catch (error) {
        if (error.response && error.response.data) {
          const errData =
            typeof error.response.data === "string"
              ? JSON.parse(error.response.data)
              : error.response.data;
          if (errData && errData.detail && Array.isArray(errData.detail)) {
            const errorMessage = errData.detail
              .map((err) => `${err.loc[1]}: ${err.msg}`)
              .join("\n");
            setAwsError(errorMessage);
          } else {
            setAwsError(error.message);
          }
        } else {
          setAwsError(error.message);
        }

        console.error("Error sending AWS details: ", error);
      }
    }
  };

  const handleBack = () => {
    if (!isBackDisabled) {
      if (stage === 2) {
        setStage(1);
      } else if (stage === 3) {
        setStage(2);
      } else if (stage === 4) {
        setStage(3);
      }
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        width: "90vw",
        paddingLeft: "10%",
      }}
    >
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
          height: "10vh",
          maxHeight: "100px",
          borderRadius: "12px",
          border: "1px solid #e8e8e8",
        }}
      >
        <Stepper
          activeStep={stage === 4 ? stage : stage - 1}
          alternativeLabel
          sx={{
            "& .MuiStepConnector-line": {
              borderTopWidth: "4px", // Increase this to make the line larger
            },
            "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
              borderColor: "#000033",
            },
            "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
              borderColor: "green",
            },
          }}
          style={{ width: "100%" }} // This makes the Stepper span the entire width of its parent Container
        >
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
        </Stepper>
      </Container>

      <Container
        style={{
          height: "65vh",
          padding: 20,
          borderRadius: "12px",
          minHeight: "620px",
          border: "1px solid #e8e8e8",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "49vh", marginBottom: "20px" }}>
          {stage === 1 && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                flex={1}
              >
                <Typography
                  variant="h6"
                  style={{
                    overflow: "hidden",
                    color: "var(--dark-blue, #002733)",
                    textOverflow: "ellipsis",
                    fontFamily: "Inter",
                    fontSize: 30,
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Hello {sanitizedUserName}, welcome to our Pando Security!
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    marginTop: "20px", // Added margin-top for spacing
                  }}
                >
                  Complete onboarding in just 3 minutes.
                </Typography>
              </Box>

              <Box flex={1} p={5}>
                <ReactPlayer
                  url="https://s3.amazonaws.com/pandosec.com/assets/Pando.mp4"
                  controls
                  playing
                />
              </Box>
            </Box>
          )}

          {stage === 2 && (
            <Box style={{ width: "100%", padding: 0, margin: 0 }}>
              <Box
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <Typography>
                  <GitHubIcon /> Setup GitHub Connector
                </Typography>
              </Box>
              <Divider
                style={{
                  width: "100%",
                  height: "0.01px",
                  paddingBottom: "15px",
                }}
                sx={{ borderBottomWidth: 2 }}
              />

              <Box
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "50px",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "16px" }}
                >
                  In this step, we're requesting read-only access to your GitHub
                  account. <br />
                  This means we'll be able to view your repositories, but we
                  won't be able to make any changes. <br />
                  We prioritize your privacy and security, so you can rest
                  assured that your data will be handled safely and securely.{" "}
                  <br />
                  <br />
                  <CheckCircleOutlineIcon
                    style={{ color: "green", fontSize: "20px" }}
                  />{" "}
                  Read org and team membership, read org projects
                  <br />
                  <CheckCircleOutlineIcon
                    style={{ color: "green", fontSize: "20px" }}
                  />{" "}
                  Access user email addresses (read-only)
                </Typography>
              </Box>

              <Box style={{ width: "100%", marginTop: "60px" }}>
                <GitHubLogin
                  className="custom-github-btn"
                  clientId={GITHUB_CLIENT_ID}
                  redirectUri={GITHUB_REDIRECT_URI}
                  onSuccess={handleSuccessGithub}
                  onFailure={handleFailureGithub}
                  scope="repo, read:org"
                  buttonText="Login with GitHub"
                />
              </Box>
              {githubError && (
                <Box
                  sx={{
                    backgroundColor: "#f8d7da",
                    color: "#721c24",
                    marginTop: "20px",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #f5c2c7",
                  }}
                >
                  <Typography variant="body2"> An error occurred </Typography>
                </Box>
              )}
            </Box>
          )}

          {stage === 3 && (
            <Box style={{ width: "100%", padding: 0, margin: 0 }}>
              <Box
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <Typography>
                  <CloudIcon /> Setup AWS Integration
                </Typography>
              </Box>
              <Divider
                style={{
                  width: "100%",
                  height: "0.01px",
                  paddingBottom: "15px",
                }}
                sx={{ borderBottomWidth: 2 }}
              />

              <Box
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "50px",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "14px" }}
                >
                  In this step, we're requesting ReadOnlyAccess access to your
                  AWS account. <br />
                  Please enter the AWS details for integration with our platform
                  or use CloudFormation automation
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box flex={1} paddingRight={2}>
                  <TextField
                    label="AWS Account ID"
                    value={account}
                    onChange={handleAccountChange}
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 12,
                      pattern: "\\d*",
                    }}
                    InputProps={{
                      readOnly: isGenerated,
                    }}
                    style={{
                      marginBottom: "10px",
                      backgroundColor: isGenerated ? "#F2F2F2" : "transparent",
                    }}
                  />
                  <TextField
                    label="Role ARN"
                    value={role}
                    onChange={handleRoleChange}
                    disabled={!isAwsAccountIdEntered}
                    fullWidth
                    InputProps={{
                      readOnly: isGenerated, // Make TextField read-only if UUID is generated
                    }}
                    required
                    style={{
                      marginBottom: "10px",
                      backgroundColor: isGenerated ? "#F2F2F2" : "transparent",
                    }}
                  />
                  <TextField
                    label="External ID (UUID)"
                    value={external_id}
                    onChange={handleExternal_idChange}
                    InputProps={{
                      readOnly: isGenerated, // Make TextField read-only if UUID is generated
                    }}
                    fullWidth
                    required
                    disabled={!isAwsAccountIdEntered}
                    style={{
                      marginBottom: "10px",
                      backgroundColor: isGenerated ? "#F2F2F2" : "transparent",
                    }}
                  />
                  {awsError && (
                    <Box
                      sx={{
                        backgroundColor: "#f8d7da",
                        color: "#721c24",
                        marginTop: "1px",
                        padding: "5px",
                        borderRadius: "4px",
                        border: "1px solid #f5c2c7",
                        maxHeight: "85px",
                        overflowY: "auto",
                      }}
                    >
                      <Typography variant="body2" style={{ fontSize: "10px" }}>
                        {" "}
                        An error occurred:
                      </Typography>
                      {awsError.split("\n").map((line, index) => (
                        <Typography
                          key={index}
                          variant="body2"
                          style={{ fontSize: "10px" }}
                        >
                          {" "}
                          &bull; {line}{" "}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex={1} paddingLeft={2}>
                  <CloudFormationCard
                    external_id={external_id}
                    setExternal_id={setExternal_id}
                    isGenerated={isGenerated}
                    setIsGenerated={setIsGenerated}
                    disabled={!isAwsAccountIdEntered}
                    setRole={setRole}
                    accountId={account}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {stage === 4 && (
            <Box style={{ width: "100%", padding: 0, margin: 0 }}>
              <Box
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <Typography>
                  <CheckCircleOutlineIcon /> Onboarding Completed
                </Typography>
              </Box>
              <Divider
                style={{
                  width: "100%",
                  height: "0.01px",
                  paddingBottom: "15px",
                }}
                sx={{ borderBottomWidth: 2 }}
              />

              <Box
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "50px",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: "16px" }}
                >
                  Congratulations! You have completed the onboarding process. We
                  will contact you shortly to provide further information and
                  assistance.
                </Typography>
              </Box>
            </Box>
          )}
        </div>
        <Divider
          style={{ width: "100%", height: "1px", paddingBottom: "100px" }}
          sx={{ borderBottomWidth: 2 }}
        />
        <Box
          display="flex"
          style={{
            width: "100%",
            justifyContent: "space-between",
            marginTop: "auto",
          }}
        >
          <Box style={{ display: "flex", justifyContent: "flex-start" }}>
            {stage !== 1 && (
              <Button
                variant="outlined"
                onClick={handleBack}
                disabled={isBackDisabled}
                style={{
                  color: "#000033",
                  borderColor: "#000033",
                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: "#000033",
                    borderColor: "#000033",
                  },
                }}
              >
                Back
              </Button>
            )}
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              height: "100%",
            }}
          >
            {stage === 1 && (
              <Button
                variant="outlined"
                onClick={handleContinue}
                style={{
                  color: "#000033",
                  borderColor: "#000033",
                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: "#000033",
                    borderColor: "#000033",
                  },
                }}
              >
                Continue
              </Button>
            )}
            {stage === 2 && (
              <Button
                variant="outlined"
                onClick={handleContinue}
                style={{
                  color: "#000033",
                  borderColor: "#000033",
                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: "#000033",
                    borderColor: "#000033",
                  },
                }}
              >
                Continue
              </Button>
            )}

            {stage === 3 && (
              <Button
                variant="outlined"
                onClick={handleContinue}
                disabled={isBackDisabled}
                style={{
                  color: "#000033",
                  borderColor: "#000033",
                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: "#000033",
                    borderColor: "#000033",
                  },
                }}
              >
                Finish
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Onboarding;
