import { useEffect, useState } from "react";
import "@alenaksu/json-viewer";
import { JSON_VIEWER_ID } from "./constants";
import {
  CommandJsonContent,
  JsonUtilsContainer,
  JsonViewerContainer,
  SearchContainer,
  SearchInput,
} from "../../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CopyButton } from "../../../AssetDetailDrawer/styled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { copyToClipboard } from "../../../../../../utils/browserUtils/copyToClipboard";
import { debounce } from "@mui/material";

const getViewNode = () => {
  return document.getElementById(JSON_VIEWER_ID);
};

const COPIED_STATE_DELAY_DURATION = 2500;

export const JsonViewer = ({ data }) => {
  const [searchValue, setSearchValue] = useState("");
  const [jsonViewerNode, setJsonViewerNode] = useState(null);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);

  const copyButtonIcon = copied ? <CheckIcon /> : <ContentCopyIcon />;
  const copyButtonLabel = copied ? "Copied to clipboard" : "Copy";

  const onSearch = (value, node) => {
    const viewerSearchIterator = node.search(value);
    if (viewerSearchIterator && viewerSearchIterator?.next) {
      viewerSearchIterator.next();
    }
  };

  const handleOnChangeInput = (ev) => {
    const value = ev.target.value;
    setSearchValue(value);
    onSearch(value, jsonViewerNode);
  };

  const resetCopiedState = () => setCopied(false);

  const onClickCopy = async () => {
    try {
      const stringifiedData = JSON.stringify(data);
      await copyToClipboard(stringifiedData);
      setCopied(true);
      debounce(resetCopiedState, COPIED_STATE_DELAY_DURATION)();
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const node = getViewNode();
    if (node) {
      setJsonViewerNode(node);
      node.data = data;
    }
  }, []);

  return (
    <CommandJsonContent>
      <JsonUtilsContainer>
        <SearchContainer>
          <SearchInput
            type='text'
            placeholder='Search...'
            value={searchValue}
            autoComplete='off'
            onChange={handleOnChangeInput}
          />

          <FontAwesomeIcon icon={faMagnifyingGlass} color='inherit' />
        </SearchContainer>
        <CopyButton onClick={onClickCopy}>
          {copyButtonLabel}
          {copyButtonIcon}
        </CopyButton>
      </JsonUtilsContainer>
      <JsonViewerContainer>
        <json-viewer id={JSON_VIEWER_ID} />;
      </JsonViewerContainer>
    </CommandJsonContent>
  );
};
