import { InputBase, Popover, styled, Typography } from "@mui/material";
import { SaveQueryButton } from "../../../../styled";

export const SaveModalViewRoot = styled(Popover)(({ theme }) => {
  return `
 & .MuiPopover-paper {
    width: 350px;
    height: 380px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 12px;
    border:1px solid ${theme.palette.grey[400]};
    box-shadow: ${theme.shadows[2]};
    background-color: ${theme.palette.background.default};
  }
  `;
});

export const SaveMModalHeader = styled("div")(() => {
  return `
  height: 20px;
  `;
});

export const QuerySummaryRoot = styled("div")(() => {
  return `
  margin-top: 8px;
  `;
});

export const SummaryRoot = styled("div")(() => {
  return `
  margin-top: 6px;
  display: flex;
  column-gap: 12px;
  `;
});

export const SummaryChip = styled("div")(({ bgColor = "#000000" }) => {
  return `
padding: 2px 6px;
border-radius: 8px;
background-color: ${bgColor}20;
border: 1px solid ${bgColor};
font-size: 12px;
`;
});

export const TitleText = styled(({ children, ...props }) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))(() => {
  return `
  font-weight: 600;
  `;
});

export const ViewFooter = styled("div")(() => {
  return `
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  `;
});

export const SaveButton = styled(SaveQueryButton)(() => {
  return `
  &.MuiButton-root{
    height: 36px;
    min-width: 48px
  }
  `;
});

export const CancelButton = styled(SaveQueryButton)(() => {
  return `
  &.MuiButton-root { 
  background-color: rgb(246, 248, 250) !important;
  border: 1px solid rgb(209, 217, 224);
  color: #000 !important;
  cursor: pointer;
  height: 36px;
  box-sizing: border-box;
  
  svg {
  height: 16px;
  width: 16px;
  }
  }
  `;
});

export const ViewBody = styled("div")(() => {
  return `
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  row-gap: 12px;
  `;
});

export const Field = styled("div")(() => {
  return `
  display: flex; 
  flex-direction: column;
  `;
});

export const FieldLabel = styled(({ children, ...props }) => (
  <Typography variant='caption' {...props}>
    {children}
  </Typography>
))(() => {
  return `
  `;
});

export const NameInput = styled(InputBase)(() => {
  return `
  font-size: 12px;
    border: 1px solid rgb(209, 217, 224);
  background-color: rgb(246, 248, 250) !important;
  border-radius: 4px;
  padding: 3px 6px;
  `;
});

export const DescriptionInput = styled(InputBase)(() => {
  return `
  font-size: 12px;
  height: 100%;
  border: 1px solid rgb(209, 217, 224);
  background-color: rgb(246, 248, 250) !important;
  border-radius: 4px;
  padding: 6px;
  `;
});
