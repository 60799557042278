import G6 from "@antv/g6";

G6.registerLayout("custom-layout", {
  execute() {
    const self = this;
    const nodes = self.nodes;
    const combos = self.combos;
    console.log(combos);

    // Assume an initial start position for the first node
    let posX = 0;
    let posY = 0;

    // Define spacing and other parameters for your asymmetric grid
    const spacingX = 100; // Horizontal spacing
    const spacingY = 80; // Vertical spacing
    const rowCapacity = 5; // Number of nodes per row before wrapping

    nodes.forEach((node, index) => {
      // Assign position directly to nodes for custom layout
      node.x = posX;
      node.y = posY;

      // Update positions for next node
      if ((index + 1) % rowCapacity === 0) {
        // Move down to next row and reset X position
        posY += spacingY;
        posX = 0;
      } else {
        // Move right for the next node in the same row
        posX += spacingX;
      }

      // Additional logic can be added here for combos or to further customize the layout
    });

    // Apply concentric layout logic to combos if needed
    // This would be more complex as you'd manually set combo positions or apply another logic
  },
});

G6.registerLayout("bigraph-layout", {
  // Default configurations
  getDefaultCfg: function getDefaultCfg() {
    return {
      center: [0, 0], // The center of the layout
      biSep: 100, // The separation of these two parts
      nodeSep: 20, // The separation between nodes in the same part
      direction: "horizontal", // The direction of the two parts
      nodeSize: 20, // The node size
    };
  },
  // Execute the layout
  execute: function execute() {
    var self = this;
    var center = self.center;
    var biSep = self.biSep;
    var nodeSep = self.nodeSep;
    var nodeSize = self.nodeSize;
    var part1Pos = 0,
      part2Pos = 0;
    // Layout the graph in horizontally
    if (self.direction === "horizontal") {
      part1Pos = center[0] - biSep / 2;
      part2Pos = center[0] + biSep / 2;
    }
    var nodes = self.nodes;
    var edges = self.edges;
    var part1Nodes = [];
    var part2Nodes = [];
    var part1NodeMap = new Map();
    var part2NodeMap = new Map();
    // Separate the nodes and init the positions
    nodes.forEach(function (node, i) {
      if (node.cluster === "part1") {
        part1Nodes.push(node);
        part1NodeMap.set(node.id, i);
      } else {
        part2Nodes.push(node);
        part2NodeMap.set(node.id, i);
      }
    });

    // Sort the nodes in part1
    part1Nodes.forEach(function (p1n) {
      var index = 0;
      var adjCount = 0;
      edges.forEach(function (edge) {
        var sourceId = edge.source;
        var targetId = edge.target;
        if (sourceId === p1n.id) {
          index += part2NodeMap.get(targetId);
          adjCount++;
        } else if (targetId === p1n.id) {
          index += part2NodeMap.get(sourceId);
          adjCount++;
        }
      });
      index /= adjCount;
      p1n.index = index;
    });
    part1Nodes.sort(function (a, b) {
      return a.index - b.index;
    });

    // Sort the nodes in part2
    part2Nodes.forEach(function (p2n) {
      var index = 0;
      var adjCount = 0;
      edges.forEach(function (edge) {
        var sourceId = edge.source;
        var targetId = edge.target;
        if (sourceId === p2n.id) {
          index += part1NodeMap.get(targetId);
          adjCount++;
        } else if (targetId === p2n.id) {
          index += part1NodeMap.get(sourceId);
          adjCount++;
        }
      });
      index /= adjCount;
      p2n.index = index;
    });
    part2Nodes.sort(function (a, b) {
      return a.index - b.index;
    });

    // Place the ndoes
    var hLength =
      part1Nodes.length > part2Nodes.length
        ? part1Nodes.length
        : part2Nodes.length;
    var height = hLength * (nodeSep + nodeSize);
    var begin = center[1] - height / 2;
    if (self.direction === "vertical") {
      begin = center[0] - height / 2;
    }
    part1Nodes.forEach(function (p1n, i) {
      if (self.direction === "horizontal") {
        p1n.x = part1Pos;
        p1n.y = begin + i * (nodeSep + nodeSize);
      } else {
        p1n.x = begin + i * (nodeSep + nodeSize);
        p1n.y = part1Pos;
      }
    });
    part2Nodes.forEach(function (p2n, i) {
      if (self.direction === "horizontal") {
        p2n.x = part2Pos;
        p2n.y = begin + i * (nodeSep + nodeSize);
      } else {
        p2n.x = begin + i * (nodeSep + nodeSize);
        p2n.y = part2Pos;
      }
    });
  },
});
const getLayout = (layoutType, onEndLayout = null) => {
  const dagreLayout = {
    type: "dagre",
    nodeSize: 20,
    nodeSpacing: 20,
    sortByCombo: true,
  };

  const comboLayout = {
    type: "comboCombined",
    nodeSize: 20,
    nodeSpacing: 20,
    spacing: 50,
    sortByCombo: true,
    preventOverlap: true,
    innerLayout: new G6.Layout["grid"]({
      sortByCombo: true,
    }),
    outerLayout: new G6.Layout["dagre"]({
      sortByCombo: true,
      nodesep: 50,
      ranksep: 50,
      rankdir: "BT",
      align: "UR",
      controlPoints: false,
      workerEnabled: true,
    }),
  };

  const comboCircularLayout = {
    type: "comboCombined",
    nodeSize: 20,
    nodeSpacing: 40,
    sortByCombo: true,
    preventOverlap: true,
    innerLayout: new G6.Layout["custom-layout"]({
      // sortByCombo: true,
    }),
    outerLayout: new G6.Layout["circular"]({
      center: [0, 0],
      radius: 1000,
      ordering: null,
    }),
  };

  const defaultLayout = {
    type: "force2",
    // center: [200, 200], // The center of the graph by default
    // linkDistance: 100,
    // clustering: true,
    // nodeStrength: 10000,
    // edgeStrength: 200,
    // nodeSize: 30,
    // workerEnabled: true, // Whether to activate web-worker
    // gpuEnabled: true     // Whether to enable the GPU parallel computing, supported by G6 4.0

    // comboPadding: (d) => {
    //     if (d.id === 'combo1') {
    //         return 100;
    //     }
    //     return 10;
    // },
    // gpuEnabled: true,
    workerEnabled: true,
  };

  if (onEndLayout) {
    defaultLayout["onLayoutEnd"] = () => onEndLayout;
  }

  switch (layoutType) {
    case "Dagre":
      return dagreLayout;
    case "Default":
      return defaultLayout;
    case "Combo":
      return comboLayout;
    case "Circular":
      return comboCircularLayout;
    default:
      return defaultLayout;
  }
};

export default getLayout;
