import { useReducer } from "react";
import { Reducer } from "../stateManagement/reducer";
import { InitialState } from "../stateManagement/initialState";
import {
  AssetTableStateContext,
  GroupingTableContext,
  MethodsContext,
  PrimaryTableContext,
  SaveQueryPayloadContext,
} from "./defs";
import { Actions } from "../stateManagement/actions";
import { useSaveTableView } from "../hooks/useSaveTableView";

const doNothing = () => {};

export const Provider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState, (isp) => {
    return { ...isp, schema: props.schema, columns: props.defaultColumns };
  });

  const { saveView } = useSaveTableView();

  const meta = {
    columns: state.columns,
    grouping: state.grouping,
    currentGroupingOption: state.currentGroupingOption,
    currentGroupingOptions: state.currentGroupingOptions,
    schema: state.schema,
    searchKeyword: state.searchKeyword,
    filters: state.filters,
    defaultPayload: props?.defaultPayload,
  };

  const fetchGroupingTableData = async (payload, options) => {
    try {
      if (!props.service?.getTableData) throw new Error("Cannot fetch table");
      if (options?.saveView) {
        saveView(payload);
      }
      dispatch({
        type: Actions.FETCH_GROUPING_TABLE_DATA_PENDING,
      });
      const data = await props.service?.getTableData?.(payload);
      dispatch({
        type: Actions.FETCH_GROUPING_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: Actions.FETCH_GROUPING_TABLE_DATA_ERROR,
        payload: error,
      });
    }
  };

  const fetchPrimaryTableData = async (payload, options) => {
    try {
      if (!props.service?.getTableData) throw new Error("Cannot fetch table");
      if (options?.saveView) {
        saveView(payload);
      }
      dispatch({
        type: Actions.FETCH_PRIMARY_TABLE_DATA_PENDING,
      });
      const data = await props.service?.getTableData?.(payload);
      dispatch({
        type: Actions.FETCH_PRIMARY_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: Actions.FETCH_GROUPING_TABLE_DATA_ERROR,
        payload: error,
      });
    }
  };

  return (
    <MethodsContext.Provider
      value={{
        dispatch,
        fetchPrimaryTableData,
        fetchGroupingTableData,
        onClickAsset: props.onClickAsset ?? doNothing,
        saveQuery: props.service?.saveQuery,
      }}
    >
      <PrimaryTableContext.Provider value={state.primaryTable}>
        <GroupingTableContext.Provider value={state.groupingTable}>
          <AssetTableStateContext.Provider value={meta}>
            <SaveQueryPayloadContext.Provider value={state.saveQueryPayload}>
              {children}
            </SaveQueryPayloadContext.Provider>
          </AssetTableStateContext.Provider>
        </GroupingTableContext.Provider>
      </PrimaryTableContext.Provider>
    </MethodsContext.Provider>
  );
};
